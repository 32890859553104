import React, { useState, useEffect } from 'react'
import { Container, Row } from 'react-bootstrap'
import bg from '../../assets/img/spacia/web/Background.png'
import logo from '../../assets/img/spacia/web/logo.png'
import googleLogo from '../../assets/img/Web/Spacia/spacia/web/google 1.png';
import {useHistory, useLocation} from "react-router-dom";
import queryString from "query-string";
import axios from 'axios';
import { Constants } from '../../utils/constants';

const SavePassword = () => {
    const [token, setToken] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const location = useLocation();
    const search = queryString.parse(location.search);
    const params = search ? search.payload : "";
    const payload = JSON.parse(atob(params));

    useEffect(() => {
        console.log('payload is', payload);
        if (payload.otp) {
            setToken(payload.otp);
        }
    }, []);

	useEffect(() => {
        console.log('payload is', payload);
		if (payload.otp) {
			setToken(payload.otp);
		}
	}, []);

    const bgImage = {
        width:'100vw',
        height:'100vh',
        background: 'white',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        position:'relative',
        // boxShadow: '0 4 38 rgba(0, 0, 0, 0.06)',
        backgroundImage:`url(${bg})` 

    }
    const formCard ={
        width:'35%',
        position:'absolute',
        right:'10vw',
        top:'10vh',
        backgroundColor:'white',
        padding:30,
        // paddingBottom:50,
        // boxShadow: '0px 4px 38px rgba(0, 0, 0, 0.06);',
        boxShadow: "0px 4px 38px #000000, 6%"
    }

    const logopos={
        position:'relative',
        left:'5vw',
        top:'5vh'
    }

    const googleButton = {
        display:"flex",
        width:'100%',
        border:'1px solid #D9D9D9',
        padding:10,
        borderRadius:10,
        backgroundColor:'white'
    }

    const history = useHistory()

    const activateAccount = () =>{
        const resourceUrl = `${Constants.BASE_URL}/users/api/v1/users/save-password`;

        axios.post(resourceUrl, {
            confirmPassword, password, token
        })
            .then(res => {
                console.log(res.data);
                history.push("/signin")
            })
            .catch(err => {
                console.log(err);
            })
    }

    return (
        <Row style={bgImage}>
            {/* <div > */}
                <div style={logopos}>
                    <img src={logo} alt="logo"/>
                </div>
                <div style={formCard}>
                    <Container>
                        <h5>Change password</h5>
                        <br/>
                        <h6>Please fill in your new password to continue to the signin page.</h6>
                        <br/>
                        <div className="form-group">
                          <label htmlFor="">New Password</label>
                          <input type="password" className="form-control" placeholder="" onChange={e => setPassword(e.target.value)} value={password} name="" id="" aria-describedby="emailHelpId" />
                        </div>

                        <div className="form-group">
                          <label htmlFor="">Confirm New Password</label>
                          <input type="password" className="form-control" placeholder="" name="" id="" onChange={e => setConfirmPassword(e.target.value)} value={confirmPassword} aria-describedby="emailHelpId" />
                        </div>
                        <br/>
                    <br/>
                        <div style={{margin:'auto', textAlign:'center'}}>
                        <button onClick={activateAccount} className="button" style={{width:'100%'}}>Change Password</button>
                        </div>
                    </Container>
                </div>
            {/* </div> */}
        </Row>
    )
}

export default SavePassword
