import styled from 'styled-components';
import { Modal } from "react-bootstrap";

export const StyledModal = styled(Modal)`
  ul.react-tabs__tab-list {
    display: flex;
    border: none
  }
  
  li.react-tabs__tab {
    width: 50%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
  }
  
  li.react-tabs__tab:last-child {
    border-top-right-radius: .7rem;
  }
  
  li.react-tabs__tab:first-child {
    border-top-left-radius: .7rem;
  }
  
  li[aria-selected=false] {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  li.react-tabs__tab:first-child[aria-selected=true] {
    border-right: 4px ridge rgba(0,0,0,0.1)
  }

  li.react-tabs__tab:last-child[aria-selected=true] {
    border-left: 4px ridge rgba(0,0,0,0.1)
  }
  
  .modal-content {
    border-radius: .7rem !important;
  }
  
  div > .button {
    width: 130px;
  }
`

export const RevenueShareRateModal = styled(StyledModal)`
    .modal-dialog {
      max-width: 560px;
    }
`

export const InvoiceModal = styled(StyledModal)`
  .modal-dialog {
    max-width: 50%;
  }
  
    .modal-header {
      border-bottom: none;
      padding-bottom: 0;
    }
  
  .modal-body {
    padding: 0;
    width: 85%;
    margin-left: auto;
    margin-right: 10%;
  }

  .modal-body > header {
    display: flex;
    justify-content: space-between;
  }
  
  .modal-body > header > div {
    display: flex;
    align-items: center;
  }
  
  .modal-body > header > div > h2 {
    font-size: 1.4rem;
    font-weight: bolder;
    margin-bottom: 0;
  }

  .modal-body > header > div > h2:last-child {
    margin-left: 30px;
  }
  
  table {
    width: 100%;
    font-size: 1rem
  }
  
  table th:first-child,
  table th:nth-child(2) {
    padding-right: 100px;
  }
  
  table td {
    padding: 20px;
  }
  
  table td:last-child,
  table th:last-child {
    text-align: right;
  }
  
  table th {
    padding-left: 20px;
    font-weight: normal;
    font-size: .92rem;
  }
  
  table > tbody > tr:nth-child(odd) {
    //background-color: #f99500;
    background-color: #F7F7F7;
  }
  
  div[role='table'] {
    margin-top: 30px;
    overflow-y: auto;
    max-height: 340px;
    padding-bottom: 30px;
  }

  div[role='table'] thead th {
    position: sticky;
    top: 0;
    background-color: white;
  }
  
  input[type='checkbox']:checked:before {
    background-color: red;
  }

  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background: #C0C0C0;
    border-radius: 30px;
  }
`

export const MobileWalletModal = styled(Modal)`
  .modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }
`
