import React, { useRef, useState, useEffect } from "react";
import {Modal, Row, Spinner} from "react-bootstrap";
import { FaTimes, FaImage, FaCaretDown } from "react-icons/fa";
import imageHolder from "../assets/img/Web/Spacia/Corp. User/Group 428.png";
import {Constants} from "../utils/constants";
import axios from "axios";
import SERVICES from "../services";

function FormModal({
	declineButton,
	acceptButton,
	body,
	title,
	isOpen,
	isClose,
	onSubmit,
	id,
	children,
	username,
	firstName,
	lastName,
	contacts,
	role,
	avatar,
}) {

	const inputFile = useRef(null);

	const [fullName, setFullName] = useState('');
	const [userRole, setUserRole] = useState('');
	const [mobile, setMobile] = useState('');
	const [email, setEmail] = useState(username);
	const [url, setUrl] = useState('');
	const [loading, setLoading] = useState(false);
  	const [currentUserRole, setCurrentUserRole] = useState('');

  const input = {
	backgroundColor: "#ECECEC",
	border: "1px solid #DDDDDD",
};

const roles = (userRole === 'ROLE_SUBSCRIBER_OWNER' || userRole === 'ROLE_HOST') ?
[
  {
	value: userRole,
	label: 'ACCOUNT OWNER',
	owner: userRole
  }
] :
[
  {
	value: 'ROLE_EMPLOYEE',
	label: 'Employee',
	owner: 'ROLE_SUBSCRIBER_OWNER'
  },
  {
	value: 'ROLE_APPROVER_EMPLOYEE',
	label: 'Administrator',
	owner: 'ROLE_SUBSCRIBER_OWNER'
  },
  {
	value: 'ROLE_HOST_OPERATIONS',
	label: 'Operations',
	owner: 'ROLE_HOST'
  }
]

	const updateUser = () => {
		setLoading(true);

		// make ajax request to update user
		const resourceUrl = `${Constants.BASE_URL}/users/api/v1/users/${id}`;

		const [firstName, ...restOfName] = fullName.split(' ');

		let lastName = undefined;
		if (restOfName && Array.isArray(restOfName)) {
			lastName = restOfName.join(" ");
		} else if (restOfName && typeof restOfName === 'string') {
			lastName = restOfName;
		}

		axios.put(resourceUrl, {
			avatar: url,
			role: userRole,
			contacts: [
				{
					isContactable: true,
					isPrimary: true,
					type: "mobile",
					value: mobile
				}
			],
			firstName: (!firstName) ? null : firstName,
			lastName: (!lastName) ? null : lastName
		}).then(res => {
			const response = res.data;
			const [updatedUser, ...rest] = response ? response.data : [];

			console.log('updated user here:', updatedUser);

			onSubmit(updatedUser);

			setLoading(false);
		}).catch(err => {
			setLoading(false);
		})
	}

	const handleFileUpload = (event) => {
		event.stopPropagation();
		event.preventDefault();
		const file = event.target.files[0];
		console.log(file);

		if(window.FileReader){
			let reader = new FileReader();
			reader.onload = function(r){
				console.log(r.target.result);
				setUrl(r.target.result);
			}
			reader.readAsDataURL(file);
		}
		else {
			console.log(`Browser doesn't support file reader`);
		}
	}

	const initializeModal = () => {
		console.log('firstName', firstName);
		// make validation and set full name
		let fullName = undefined;
		if (firstName && lastName) {
			fullName = `${firstName} ${lastName}`;
		} else if (firstName && !lastName) {
			fullName = firstName;
		} else if (!firstName && lastName) {
			fullName = lastName;
		} else if (!firstName && !lastName) {
			fullName = 'N/A';
		}

		setFullName(fullName);

		setUserRole(role);
		// set user's email
		setEmail(username);

		setUrl('');

		console.log('avatar here is:', avatar);

		let mobile = null;
		mobile = contacts && contacts.length > 0 ? contacts[0] : null;
		let value = mobile ? mobile.value : null;

		console.log('mobile is:', value);

		setMobile(value);

    const currentUser = SERVICES.getUser();
    const currentUserRole = (currentUser) ? currentUser.role : '';
    setCurrentUserRole(currentUserRole);
	};

	const destroyModal = () => {
		setUserRole('');
		// set user's email
		setEmail('');

		setUrl('');

		setMobile('');
	};

	return (
		<Modal show={isOpen} onHide={isClose} onEnter={initializeModal} onExit={destroyModal}>
			<Modal.Header
                    style={{
                        borderBottom: "none",
                        paddingLeft: "30px",
					paddingBottom: 0,

                        paddingRight: "30px",
						paddingBottom: 0,
						fontFamily: "Montserrat",
                        margin: "0px",
                    }}
                    closeButton
                >
                    <Modal.Title
                        id="contained-modal-title-vcenter"
                        style={{ width: "100%", textAlign: "center" }}
                    >
                       
                    </Modal.Title>
                </Modal.Header>

				<div className="text-primary" style={{textAlign: "center"}}>Edit User</div>

			<div style={{ textAlign: "center", padding: 30, paddingTop: 10 }}>
				<div style={{ textAlign: "left", width: 120 }} onClick={() => inputFile.current.click()}>
					<img src={url || avatar || imageHolder} alt="image" width={120} height={120} style={{cursor: 'pointer', display: 'block', objectFit: (url || avatar) && 'cover'}}/>
				</div>
				<input type="file" id='file' ref={inputFile} style={{display: 'none'}} accept={'image/*'} onChange={handleFileUpload}/>
				<a>
					<h6
						style={{
							textAlign: "Left",
							textDecoration: "underline",
							// color: "blue",
							paddingTop: 10,
							cursor: 'pointer'
						}}
						className="text-primary"
						onClick={() => inputFile.current.click()}
					>
						Change Image
					</h6>
				</a>

				<br />
				<div className="form-group">
					{/* <label for="">Email</label> */}
					<input
						style={input}
						type="name"
						className="form-control"
						name=""
						id=""
						aria-describedby="emailHelpId"
						placeholder="FullName"
						value={fullName}
						onChange={(e) => setFullName(e.target.value)}
					/>
					<br />
					<input
						style={input}
						type="email"
						className="form-control"
						readOnly
						aria-describedby="emailHelpId"
						placeholder="Email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					{/* <label for="">Role</label> */}
					<br />
					<input
						style={input}
						type="text"
						className="form-control"
						aria-describedby="emailHelpId"
						placeholder="Phone Number"
						value={mobile}
						onChange={(e) => setMobile(e.target.value)}
					/>
					<br />
					<Row>
						<div className="form-group col-md-12">
							<select className="form-control" value={userRole} onChange={e => setUserRole(e.target.value)}>
								<option selected defaultValue={'' || null || undefined} disabled>Select Role</option>
                {
                  roles.filter(role => role.owner === currentUserRole).map(role => {
                    return (
                      <option value={role.value}>{role.label}</option>
                    )
                  })
                }
							</select>
						</div>
					</Row>
				</div>
			</div>
			{/* </Modal.Body> */}
			{/* <Modal.Footer> */}
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					padding: 10,
					paddingBottom: 30
				}}
			>
				<button onClick={isClose} className="btn btn-outline-primary"
                                style={{
                                    borderRadius: "20px",
                                    marginLeft: "0px",
                                    width:"8rem"
                                }}>
					{declineButton}
				</button>
				<button onClick={updateUser}  className="btn btn-outline-primary"
                                style={{
                                    borderRadius: "20px",
                                    marginLeft: "20px",
                                    width:"8rem"
                                }}>
					{acceptButton}
					<div style={{display: loading ? 'inline-flex' : 'none', paddingLeft: '10px', paddingRight: '15px'}}>
						<Spinner animation="border" role="status" style={{width: '1rem', height: '1rem'}}>
							<span className="visually-hidden"></span>
						</Spinner>
					</div>
				</button>
			</div>
			{/* </div> */}
			{/* </Modal.Footer> */}
		</Modal>
	);
}

export default FormModal;
