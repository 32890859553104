/**
 * Project: spaces
 * File: Loader
 * Created by Pennycodes on 2/4/2022.
 * Copyright spaces
 */
import {Spaces} from "./Spaces";

class Loader {
    loader: HTMLElement | null

    constructor() {
        this.loader = Spaces.loader()
        this._init()
    }

    private _init () {
        setTimeout(() => {
            // @ts-ignore
            this.loader.setAttribute("style", "pointer-events: none; opacity: 0; transition: 0.2s ease-in-out;");
            setTimeout(() => {
                // @ts-ignore
                this.loader.setAttribute("style", "display: none;")
            }, 1000);
        }, 450);
    }
    public static bootstrap () {
        new Loader()
    }

}

export {Loader}
