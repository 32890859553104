import React, { useEffect, useState } from "react";
import { Container, Image, Row, Col, Card, Button, Form } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";
import "firebase/auth";
import logo from '../../../assets/img/spacia/web/spacia_logo.png';
import { Constants } from '../../../utils/constants';
import { ErrorAlert } from "../../../components/Settings/alerts/ErrorAlert";
import {SignInForCheckInStyles} from "./SignInForCheckInStyles";

const SignInForCheckIn = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [bookingId, setBookingId] = useState(0);
    const [forPromo, setForPromo] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isEmptyEmail, setIsEmptyEmail] = useState(false);
    const [isEmptyPassword, setIsEmptyPassword] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const location = useLocation();
    const [errorModal, setErrorModal] = useState(false);

    function hasText(text) {
        return text != null && text !== '';
    }

    useEffect(() => {
        const search = queryString.parse(location.search);
        const bookingId = search && search.bookingId ? search.bookingId : 0;
        const forPromo = search && search.forPromo ? search.forPromo : false;

        console.log('search is:', search);
        console.log('booking id:', bookingId);
        console.log('forPromo value:', forPromo);

        setBookingId(bookingId);

        setForPromo(forPromo);
    }, []);

    useEffect(() => {
        if (hasText(email)) {
            setIsEmptyEmail(false);
        }
        if (hasText(password)) {
            setIsEmptyPassword(false);
        }
    }, [email, password]);

    const areFieldsOkay = () => {
        const requiredFields = [email, password];
        // is any of the fields empty
        const anyEmpty = requiredFields.some(field => !field);
        if (anyEmpty) {
            if (!hasText(email)) {
                setIsEmptyEmail(true);
            }
            if (!hasText(password)) {
                setIsEmptyPassword(true);
            }
            return false;
        } else {
            return true;
        }
    }

    const signIn = () => {
        if (!areFieldsOkay()) return;

        setLoading(true);
        let credentials = {
            password: password,
            username: email,
            // This is a hardcoded value,  WHY????
            companyId: 1,
        };
        axios.post(`${Constants.BASE_URL}/users/api/v1/login`, credentials)
            .then((res) => onLoginSuccessful(res))
            .catch((err) => onLoginError(err))
            .finally(() => {
                setLoading(false);
        })
    };

    const onLoginSuccessful = (res) => {
        console.log(res.data);

        const currentUser = res.data.data[0];
        console.log('current user:', currentUser);
        const userId = currentUser ? currentUser.id : 0;
        const username = currentUser ? currentUser.username : '<empty>';

        const stringed = `userId=${userId}&username=${username}`;
        const encodedUser = btoa(stringed);

        let url = `${Constants.BASE_URL}/booking/booking/${bookingId}/checkInOrOut?loggedInUser=${encodedUser}`;

        if (forPromo) {
            url = `${Constants.BASE_URL}/booking/booking/${bookingId}/checkInOrOut/promo?loggedInUser=${encodedUser}`;
        }

        // check in user now
        window.location.replace(url);
    };

    const onLoginError = (err) => {
        const {response: {data: {errors}}} = err;
        console.log(errors);

        setErrorModal(true);

        if (errors && Array.isArray(errors) && errors.length > 0) {
            const { message } = errors[0];
            setErrorMsg(message);
        }
    }

    return (
        <SignInForCheckInStyles>
            <section className="min-vh-100 sign-in-wrapper" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Container className="py-3">
                    <Row>
                        <Col><Image src={logo} width="150px" fluid /></Col>
                    </Row>
                    <Row>
                        <Col md="6" className="pt-4 offset-6 form-container">
                            <Card>
                                <ErrorAlert show={ errorModal } close={()=>setErrorModal(false)}  message={ errorMsg || "A problem was encountered, please try again" }/>
                                <Card.Body className="p-5">
                                    <h5>Sign in to your account</h5>
                                    <Form style={{marginTop: '30px'}}>
                                        <Form.Group controlId="formGroupEmail">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="email" className={ isEmptyEmail ? "form-control is-invalid" : "form-control"}
                                                          value={email}
                                                          onChange={(e) => setEmail(e.target.value)}
                                                          placeholder="Eg. lindaantwi@gmail.com" />
                                            <div className="invalid-feedback">Please enter your email</div>
                                        </Form.Group>
                                        <Form.Group controlId="formGroupPassword">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type="password" className={ isEmptyPassword ? "form-control is-invalid" : "form-control"}
                                                          value={password}
                                                          onChange={(e) => setPassword(e.target.value)} />
                                            <div className="invalid-feedback">Please enter password</div>
                                        </Form.Group>

                                        {/*<Button variant="primary" style={{marginTop: '40px'}} size="lg" block onClick={signIn} disabled={loading}> {loading ? 'Loading...' : 'Sign in'} </Button>*/}
                                        <button className="button" type="button" onClick={signIn} disabled={loading} style={{width: '100%',marginTop: '40px'}}>
                                            {loading ? 'Loading...' : 'Sign in'}
                                        </button>
                                    </Form>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </SignInForCheckInStyles>
    );
};

export default SignInForCheckIn;
