import {Container, Row, Col, Card, Image, Modal} from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
// import SERVICES from '../services';
import SERVICES from '../../../services';
import { Constants } from '../../../utils/constants';
import confirm from '../../../img/Web/Spacia/bookings-received.svg'
import moneybag from '../../../img/Web/Spacia/revenue.svg';
import booking_icon from '../../../img/Web/Spacia/bookings.svg';
import home from '../../../img/Web/Spacia/total-props.svg';
import published from '../../../img/Web/Spacia/published-props.svg';
import pending from '../../../img/Web/Spacia/pending-props.svg';
import totalUsersIcon from '../../../img/Web/Spacia/total-users.svg';
import disabledUserIcon from '../../../img/Web/Spacia/disabled-users.svg';
import unpublishedListingIcon from '../../../img/Web/Spacia/unpublished-listings.svg';
import pendingInvitesIcon from '../../../img/Web/Spacia/pending-invites.svg';
import bookingCancelledIcon from '../../../img/Web/Spacia/booking-cancelled.svg';
import moment from "moment-timezone";
import ReactTooltip from 'react-tooltip';
import finishSetupIllustration from "../../../assets/img/png/finish-setup-illustration.png";
import closeButton from "../../../assets/img/svg/blue-close-button.svg";
import {useHistory} from "react-router-dom";


const Dashboard = () => {

    const [totalPropertiesCount, setTotalPropertiesCount] = useState('N/A')
    const [totalBookingsToday, setTotalBookingsToday] = useState(0);
    const [totalBookingCancellationWithin7Days, setTotalBookingCancellationWithin7Days] = useState(0);
    const [publishedPropertiesTotal, setPublishedPropertiesTotal] = useState('N/A')
    const [unpublishedPropertiesTotal, setUnpublishedPropertiesTotal] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const [pendingInvitesCount, setPendingInvitesCount] = useState(0);
    const [inactiveUsersCount, setInactiveUsersCount] = useState(0);
    const [bookings, setBookings] = useState(0);
    const [pendingPropertiesLength, setPendingPropertiesLength] = useState('N/A');
    const [bookingsReceived, setBookingsReceived] = useState(0);
    const [totalRevenue, setTotalRevenue] = useState(0.00);


    const [publishedProperties, setPublishedProperties] = useState([])
    const [pendingProperties, setPendingProperties] = useState([])
    const [showFinishSetupModal, setShowFinishSetupModal] = useState(false);

    const history = useHistory();


    useEffect(() => {
        // show modal if it's host's first sign in
        const currentUser = SERVICES.getUser();
        const role = currentUser?.role;

        const isHostAccount = role != null && role === 'ROLE_HOST';
        console.log('is current user a host account?', isHostAccount);

        if (isHostAccount) {
            const isFirstSignIn = currentUser?.isFirstSignIn;

            if (isFirstSignIn) {
                setShowFinishSetupModal(true);

                const dashboardContainer = document.querySelector('.dashboard-container');
                if (dashboardContainer != null) {
                    dashboardContainer.classList.add('blur-background');
                }
            }
        }
    }, []);

    const bookingsuccess = (res) => {
        console.log("Bookings")
        setBookings(res.data.data.length)
    }

    const buildMetricsForUserAccounts = () => {
        const currentUser = SERVICES.getUser();
        const role = currentUser?.role;

        if (role != null) {
            switch (role) {
                case 'ROLE_HOST':
                    return hostDashboardMetrics();

                case 'ROLE_SUBSCRIBER_OWNER':
                    return corporateAdminDashboardMetrics();

                case 'ROLE_ADMINISTRATOR':
                    return adminDashboardMetrics();

                default:
                    return null;
            }
        }
    }

    const hostDashboardMetrics = () => {
        return (
          <>
              <Col className='mb-4'>
                  <Card>
                      <Card.Body>
                          <Row>
                              <Col md="auto">
                                  <Image src={confirm} fluid />
                              </Col>
                              <Col>
                                  <h6>Bookings Today</h6>
                                  <h1 className="text-primary font-weight-bold">{totalBookingsToday} </h1>
                              </Col>
                          </Row>
                      </Card.Body>
                  </Card>
              </Col>
              <Col className='mb-4'>
                  <Card>
                      <ReactTooltip />
                      <Card.Body data-tip={"Within past 7 days"}>
                          <Row>
                              <Col md="auto">
                                  <Image src={bookingCancelledIcon} fluid />
                              </Col>
                              <Col>
                                  <h6>Bookings Cancelled</h6>
                                  <h1 className="text-danger font-weight-bold">{totalBookingCancellationWithin7Days} </h1>
                              </Col>
                          </Row>
                      </Card.Body>
                  </Card>
              </Col>
              <Col className='mb-4'>
                  <Card>
                      <Card.Body>
                          <Row>
                              <Col md="auto">
                                  <Image src={home} fluid />
                              </Col>
                              <Col>
                                  <h6>Total Properties</h6>
                                  <h1 className="text-success font-weight-bold">{totalPropertiesCount} </h1>
                              </Col>
                          </Row>
                      </Card.Body>
                  </Card>
              </Col>
              <Col className='mb-4'>
                  <Card>
                      <Card.Body>
                          <Row>
                              <Col md="auto">
                                  <Image src={published} fluid />
                              </Col>
                              <Col>
                                  <h6>Published Properties</h6>
                                  <h1 className="text-warning font-weight-bold">{publishedPropertiesTotal} </h1>
                              </Col>
                          </Row>
                      </Card.Body>
                  </Card>
              </Col>
              <Col className='mb-4'>
                  <Card>
                      <Card.Body>
                          <Row>
                              <Col md="auto">
                                  <Image src={unpublishedListingIcon} fluid />
                              </Col>
                              <Col>
                                  <h6>Unpublished Properties</h6>
                                  <h1 className="text-info font-weight-bold">{unpublishedPropertiesTotal} </h1>
                              </Col>
                          </Row>
                      </Card.Body>
                  </Card>
              </Col>
              <Col className='mb-4'>
                  <Card>
                      <Card.Body>
                          <Row>
                              <Col md="auto">
                                  <Image src={confirm} fluid />
                              </Col>
                              <Col>
                                  <h6>Total Bookings Received</h6>
                                  <h1 className="text-primary font-weight-bold">{bookingsReceived} </h1>
                              </Col>
                          </Row>
                      </Card.Body>
                  </Card>
              </Col>
          </>
        )
    }

    const corporateAdminDashboardMetrics = () => {
        return (
          <>
              <Col className='mb-4'>
                  <Card>
                      <Card.Body>
                          <Row>
                              <Col md="auto">
                                  <Image src={totalUsersIcon} fluid />
                              </Col>
                              <Col>
                                  <h6>Active Users</h6>
                                  <h1 className="font-weight-bold">{totalUsers} </h1>
                              </Col>
                          </Row>
                      </Card.Body>
                  </Card>
              </Col>
              <Col className='mb-4'>
                  <Card>
                      <Card.Body>
                          <Row>
                              <Col md="auto">
                                  <Image src={booking_icon} fluid />
                              </Col>
                              <Col>
                                  <h6>Active Reservations</h6>
                                  <h1 className="font-weight-bold" style={{color: '#5A7A84'}}>{bookings} </h1>
                              </Col>
                          </Row>
                      </Card.Body>
                  </Card>
              </Col>
              <Col className='mb-4'>
                  <Card>
                      <Card.Body>
                          <Row>
                              <Col md="auto">
                                  <Image src={pendingInvitesIcon} fluid />
                              </Col>
                              <Col>
                                  <h6>Pending Invites</h6>
                                  <h1 className="text-success font-weight-bold">{pendingInvitesCount} </h1>
                              </Col>
                          </Row>
                      </Card.Body>
                  </Card>
              </Col>
              <Col className='mb-4'>
                  <Card>
                      <Card.Body>
                          <Row>
                              <Col md="auto">
                                  <Image src={disabledUserIcon} fluid />
                              </Col>
                              <Col>
                                  <h6>Inactive Users</h6>
                                  <h1 className="text-danger font-weight-bold">{inactiveUsersCount} </h1>
                              </Col>
                          </Row>
                      </Card.Body>
                  </Card>
              </Col>
          </>
        )
    }

    const adminDashboardMetrics = () => {
        return (
          <>
              <Col className='mb-4'>
                  <Card>
                      <Card.Body>
                          <Row>
                              <Col md="auto">
                                  <Image src={home} fluid />
                              </Col>
                              <Col>
                                  <h6>Total Properties</h6>
                                  <h1 className="text-success font-weight-bold">{totalPropertiesCount} </h1>
                              </Col>
                          </Row>
                      </Card.Body>
                  </Card>
              </Col>
              <Col>
                  <Card>
                      <Card.Body>
                          <Row>
                              <Col md="auto">
                                  <Image src={published} fluid />
                              </Col>
                              <Col>
                                  <h6>Published Properties</h6>
                                  <h1 className="text-warning font-weight-bold">{publishedPropertiesTotal}</h1>
                              </Col>
                          </Row>
                      </Card.Body>
                  </Card>
              </Col>
              <Col>
                  <Card>
                      <Card.Body>
                          <Row>
                              <Col md="auto">
                                  <Image src={moneybag} fluid />
                              </Col>
                              <Col>
                                  <h6>Revenue</h6>
                                  <h1 className="font-weight-bold" style={{ color: '#5A7A84' }}>{totalRevenue}</h1>
                              </Col>
                          </Row>
                      </Card.Body>
                  </Card>
              </Col>
              <Col>
                  <Card>
                      <Card.Body>
                          <Row>
                              <Col md="auto">
                                  <Image src={booking_icon} fluid />
                              </Col>
                              <Col>
                                  <h6>Bookings</h6>
                                  <h1 style={{color: '#5A7A84'}}>{bookings}</h1>
                              </Col>
                          </Row>
                      </Card.Body>
                  </Card>
              </Col>
          </>
        )
    }

    function amountFormatter(num, digits) {
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "K" },
            { value: 1e6, symbol: "M" },
            { value: 1e9, symbol: "G" },
            { value: 1e12, symbol: "T" },
            { value: 1e15, symbol: "P" },
            { value: 1e18, symbol: "E" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        let item = lookup.slice().reverse().find(function(item) {
            return num >= item.value;
        });
        return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
    }

    useEffect(() => {
        const currentUser = SERVICES.getUser()
        console.log('current user:', currentUser);
        const userId = currentUser ? currentUser.id : 0;
        const role = currentUser ? currentUser.role : null;

        // GET PROPERTIES
        axios.get(`${Constants.BASE_URL}/booking/api/v1/listings/total/count`, {params: { userId: userId}})
            .then(res => {
                if (res.status === 200) {
                    const totalPropertyCount = res.data['totalPropertyCount'];
                    console.log('total property count: ' + totalPropertyCount);

                    setTotalPropertiesCount(totalPropertyCount);
                }
            });

        // GET PUBLISHED PROPERTIES
        axios.get(`${Constants.BASE_URL}/booking/api/v1/listings/total/published/count`, {params: { userId: userId}})
            .then(res => {
                if (res.status === 200) {
                    const totalPublishedPropertyCount = res.data['totalPropertyCount'];
                    console.log('total published property count: ' + totalPublishedPropertyCount);

                    setPublishedPropertiesTotal(totalPublishedPropertyCount);
                }
            });

        // GET UNPUBLISHED PROPERTIES
        axios.get(`${Constants.BASE_URL}/booking/api/v1/listings/total/unpublished/count`, {params: { userId: userId}})
          .then(res => {
              if (res.status === 200) {
                  const totalUnpublishedPropertyCount = res.data['totalPropertyCount'];
                  console.log('total unpublished property count: ' + totalUnpublishedPropertyCount);

                  setUnpublishedPropertiesTotal(totalUnpublishedPropertyCount);
              }
          });

        // GET BOOKINGS
        axios.get(`${Constants.BASE_URL}/booking/api/v1/booking/made/count`, {params: { userId: userId}})
            .then(res => {
                if (res.status === 200) {
                    const totalBookingMadeCount = res.data['totalBookingCount'];
                    console.log('total bookings made count: ' + totalBookingMadeCount);

                    setBookings(totalBookingMadeCount);
                }
            })

        // GET BOOKINGS RECEIVED COUNT
        axios.get(`${Constants.BASE_URL}/booking/api/v1/booking/received/count`, {params: { propertyOwnerId: userId}})
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    const totalBookingReceivedCount = responseData['totalBookingCount'];
                    console.log('total bookings received count: ' + totalBookingReceivedCount);

                    if (role && (role === 'ROLE_HOST' || role === 'ROLE_SUBSCRIBER_OWNER')) {
                        setBookingsReceived(totalBookingReceivedCount);
                    } else if (role && role === 'ROLE_ADMINISTRATOR') {
                        setBookingsReceived('N/A');
                    }
                }
            });

        // GET TOTAL REVENUE
        axios.get(`${Constants.BASE_URL}/booking/api/v1/booking/total/revenue`, {params: { userId: userId}})
            .then(res => {
                if (res.status === 200) {
                    let totalRevenue = res.data['totalRevenueCount'];
                    console.log('total revenue amount: ' + totalRevenue);

                    totalRevenue = amountFormatter(totalRevenue, 2);

                    setTotalRevenue(totalRevenue);
                }
            });


        // GET TOTAL ACTIVE USERS
        axios.get(`${Constants.BASE_URL}/users/api/v1/users/${userId}/sub-accounts/count`,{params: {enabled: true}})
          .then(res => {
              console.log('response:', res.data);
              const response = res.data;
              const allUsersCount = response?.totalUsersCount;

              console.log('total count of users:', allUsersCount);
              setTotalUsers(allUsersCount);
          }).catch(err => {
              // handle exception
        })

        // GET PENDING USERS
        const ownerId = currentUser ? currentUser.id : 0;
        const resourceUrl = `${Constants.BASE_URL}/users/api/v1/users/${ownerId}/sub-accounts`;

        axios
          .get(resourceUrl, { params: { sortBy: "createdDate", verified: false } })
          .then((res) => {
              if (res.status === 200) {
                  console.log(res);
                  const response = res.data;
                  const pendingUsersCount = response.totalCount;
                  console.log('unverified accounts:', pendingUsersCount);

                  setPendingInvitesCount(pendingUsersCount);
              }
          });

        // GET INACTIVE USERS
        axios.get(`${Constants.BASE_URL}/users/api/v1/users/${userId}/sub-accounts/count`,{params: {enabled: false}})
          .then(res => {
              console.log('response:', res.data);
              const response = res.data;
              const allUsersCount = response?.totalUsersCount;

              console.log('total count of users:', allUsersCount);
              setInactiveUsersCount(allUsersCount);
          }).catch(err => {
            // handle exception
        })


        const endOfCurrentDayAsObject = moment(new Date())
                                   .endOf('day') // end of current day
                                   .add(1, 'seconds'); // plus 1 second (gives you 12:00 am of the next day)

        const formattedEndOfCurrentDay = endOfCurrentDayAsObject.format('yyyy-MM-DDTHH:mm:ss');

        const last7Days = endOfCurrentDayAsObject
                            .subtract(7, 'days')// minus 7 days
                            .format('yyyy-MM-DDTHH:mm:ss');
        console.log('end of day:', last7Days);

        const cancelledBookingConfig = {
            params: {
                hostId: ownerId,
                status: 'CANCELLED',
                from: `${last7Days}Z`,
                to: `${formattedEndOfCurrentDay}Z`
            }
        }
        // GET CANCELLED BOOKING WITHIN LAST 7 DAYS
            axios.get(`${Constants.BASE_URL}/reporting/api/v1/admin/bookings/daily/per/host`, cancelledBookingConfig)
              .then(res => {
                  console.log('response:', res.data);
                  const response = res.data;
                  const data = response?.data;

                  const count = (data != null && data.length > 0) ? data.count : 0;
                  console.log('count of cancelled booking within last 7 days:', count);

                  setTotalBookingCancellationWithin7Days(count);
              })

        console.log('current date and time:', new Date());


        const startOfDay = moment(new Date()).startOf('day').format('yyyy-MM-DDTHH:mm:ss');
        const endOfDay = moment(new Date()).endOf('day').add(1, 'seconds').format('yyyy-MM-DDTHH:mm:ss');
        console.log('start of current day:', startOfDay);

        const bookingsTodayConfig = {
            params: {
                hostId: ownerId,
                from: `${startOfDay}Z`,
                to: `${endOfDay}Z`
            }
        }
        // GET CANCELLED BOOKING WITHIN LAST 7 DAYS
        axios.get(`${Constants.BASE_URL}/reporting/api/v1/admin/bookings/daily/per/host`, bookingsTodayConfig)
          .then(res => {
              console.log('response:', res.data);
              const response = res.data;
              const data = response?.data;

              const count = (data != null && data.length > 0) ? data.count : 0;
              console.log('count of bookings today:', count);

              setTotalBookingsToday(count);
          })
    }, [])

    const handleStuffOnModalHide = () => {
        setShowFinishSetupModal(false);

        const dashboardContainer = document.querySelector('.dashboard-container');
        if (dashboardContainer != null) {
            dashboardContainer.classList.remove('blur-background');
        }
    }

    return (
        <Container className='dashboard-container'>
            <Row>
                <Col><h4 className="mb-4">Dashboard</h4></Col>
                {/* <Col md="auto">
                    <Button variant="danger">Add New Property</Button>
                </Col> */}
            </Row>
            <Row xs={2} md={3} lg={3}>
                {
                    buildMetricsForUserAccounts()
                }
                {/*<Col className='mb-4'>*/}
                {/*    <Card>*/}
                {/*        <Card.Body>*/}
                {/*            <Row>*/}
                {/*                <Col md="auto">*/}
                {/*                    <Image src={home} fluid />*/}
                {/*                </Col>*/}
                {/*                <Col>*/}
                {/*                    <h6>Total Properties</h6>*/}
                {/*                    <h1 className="text-success font-weight-bold">{totalPropertiesCount} </h1>*/}
                {/*                </Col>*/}
                {/*            </Row>*/}
                {/*        </Card.Body>*/}
                {/*    </Card>*/}
                {/*</Col>*/}
                {/*<Col>*/}
                {/*    <Card>*/}
                {/*        <Card.Body>*/}
                {/*            <Row>*/}
                {/*                <Col md="auto">*/}
                {/*                    <Image src={published} fluid />*/}
                {/*                </Col>*/}
                {/*                <Col>*/}
                {/*                    <h6>Published Properties</h6>*/}
                {/*                    <h1 className="text-warning font-weight-bold">{publishedPropertiesTotal}</h1>*/}
                {/*                </Col>*/}
                {/*            </Row>*/}
                {/*        </Card.Body>*/}
                {/*    </Card>*/}
                {/*</Col>*/}
                {/*<Col>*/}
                {/*    <Card>*/}
                {/*        <Card.Body>*/}
                {/*            <Row>*/}
                {/*                <Col md="auto">*/}
                {/*                    <Image src={pending} fluid />*/}
                {/*                </Col>*/}
                {/*                <Col>*/}
                {/*                    <h6>Pending Properties</h6>*/}
                {/*                    <h1 className="text-primary font-weight-bold">{pendingPropertiesLength} </h1>*/}
                {/*                </Col>*/}
                {/*            </Row>*/}
                {/*        </Card.Body>*/}
                {/*    </Card>*/}
                {/*</Col>*/}
                {/*<Col>*/}
                {/*    <Card>*/}
                {/*        <Card.Body>*/}
                {/*            <Row>*/}
                {/*                <Col md="auto">*/}
                {/*                    <Image src={moneybag} fluid />*/}
                {/*                </Col>*/}
                {/*                <Col>*/}
                {/*                    <h6>Revenue</h6>*/}
                {/*                    <h1 className="font-weight-bold" style={{ color: '#5A7A84' }}>{totalRevenue}</h1>*/}
                {/*                </Col>*/}
                {/*            </Row>*/}
                {/*        </Card.Body>*/}
                {/*    </Card>*/}
                {/*</Col>*/}
                {/*<Col>*/}
                {/*    <Card>*/}
                {/*        <Card.Body>*/}
                {/*            <Row>*/}
                {/*                <Col md="auto">*/}
                {/*                    <Image src={booking_icon} fluid />*/}
                {/*                </Col>*/}
                {/*                <Col>*/}
                {/*                    <h6>Bookings</h6>*/}
                {/*                    <h1 style={{color: '#5A7A84'}}>{bookings}</h1>*/}
                {/*                </Col>*/}
                {/*            </Row>*/}
                {/*        </Card.Body>*/}
                {/*    </Card>*/}
                {/*</Col>*/}
                {/*<Col>*/}
                {/*    <Card>*/}
                {/*        <Card.Body>*/}
                {/*            <Row>*/}
                {/*                <Col md="auto">*/}
                {/*                    <Image src={confirm} fluid />*/}
                {/*                </Col>*/}
                {/*                <Col>*/}
                {/*                    <h6>Bookings Received</h6>*/}
                {/*                    <h1 className="text-primary font-weight-bold" style={{ color: "#3989DD" }}>{bookingsReceived}</h1>*/}
                {/*                </Col>*/}
                {/*            </Row>*/}
                {/*        </Card.Body>*/}
                {/*    </Card>*/}
                {/*</Col>*/}

                {/* <Card title="Total Properties" Icon={home} supportTitle="75" style={{ color: '#02BD04', fontWeight: 'bold' }} />
                <Card title="Published Properties" Icon={file} supportTitle="20" style={{ color: '#F99500', fontWeight: 'bold' }} />
                <Card title="Pending Properties" Icon={box} supportTitle="15" style={{ color: '#2B86FF', fontWeight: 'bold' }} />
                <Card title="Favorites" Icon={heart} supportTitle="75" style={{ color: '#066875', fontWeight: 'bold' }} />
                <Card title="Featured Properties" Icon={star} supportTitle="20" style={{ color: '#D22129', fontWeight: 'bold' }} />
                <div className="stripCard col-md-3" style={{ border: 'none' }} ></div> */}
            </Row>

            <Modal show={showFinishSetupModal} onHide={handleStuffOnModalHide} onEnter={e => console.log(e)}>
                <header className={'position-relative'} style={{zIndex: '3'}}>
                    <figure className="text-right" style={{padding: '15px'}}>
                        <img onClick={handleStuffOnModalHide} src={closeButton} alt="close button" style={{width: '25px'}}/>
                    </figure>
                </header>
                <Modal.Body style={{height: '310px'}}>
                    <div className="text-center position-relative" style={{bottom: '55px'}}>
                        <figure>
                            <img
                              className={'position-relative'}
                              src={finishSetupIllustration}
                              alt="Finish Setup Illustration"
                              style={{width: '40%'}}
                            />
                        </figure>
                        <p className={'text-primary font-weight-bolder mb-2'} style={{fontSize: '1.03rem'}}>Finish Setting Up Your Account</p>
                        <p style={{fontSize: '0.89rem'}}>
                            Please take a minute to fill in the necessary information and upload the neccessary documents to set up
                            your company profile.
                        </p>
                        <div className={'mt-4'}>
                            <button
                              className={'btn btn-primary text-white text-uppercase'}
                              style={{cursor: 'pointer'}}
                              onClick={() => history.push("/settings/company/profile")}
                            >
                                set up account
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </Container>
    )
}

export default Dashboard
