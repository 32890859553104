import React, {useRef, useState} from "react";
import Interactive from "../Interactive";
import InteractiveOption from "./InteractiveOption";
import {Dropdown} from "react-bootstrap";
import {Link, useHistory} from "react-router-dom";
import {FaEllipsisH} from "react-icons/fa";
const Workspace = () => {
	const [dropdown1, setdropdown1] = useState("Private Office");
	const [dropdown2, setdropdown2] = useState("Serviced");
	const [hotdesk, sethotdesk] = useState("Hot Hour");
	const [meeting, setmeeting] = useState("Board Room");

	let history = useHistory();
  const workSpaceDropdownElement = useRef(null);
  const privateOfficeDropdownElement = useRef(null);
  const hotDeskDropdownElement = useRef(null);
  const meetingAndVenueDropdownElement = useRef(null);

	const submit =()=>{
		localStorage.setItem('Workspace', dropdown1);
		localStorage.setItem('addSpec', dropdown2);
		history.push("/listpropertyform")
	}
	return (
		<div>
			<Interactive title="What type of Property is it?">
				<div>
					<h6 className="flatText">Type of Workspace</h6>
					<div className="selectCard" style={{cursor: 'pointer'}} onClick={() => workSpaceDropdownElement.current.click()}>
						<h6 className="flatText">{dropdown1}</h6>

						<Dropdown>
							<Dropdown.Toggle
                ref={workSpaceDropdownElement}
								style={{
									backgroundColor: "transparent",
									color: "black",
									border: "none",
								}}
								id="dropdown-basic"
							></Dropdown.Toggle>

							<Dropdown.Menu>
								<Dropdown.Item
									onClick={() =>
										setdropdown1("Private Office")
									}
									href="#/action-2"
								>
									Private Office
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => setdropdown1("Co-working Space")}
									href="#/action-3"
								>
									Co-working Space
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() =>
										setdropdown1("Meeting Room")
									}
									href="#/action-3"
								>
									Meeting Room
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() =>
										setdropdown1("Dedicated Desk")
									}
									href="#/action-3"
								>
									Dedicated Desk
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</div>

				<br />

				{dropdown1 === "Private Office" && (
					<div>
						<h6 className="flatText">Additional Specification</h6>
						<div className="selectCard" style={{cursor: 'pointer'}} onClick={() => privateOfficeDropdownElement.current.click()}>
							<h6 className="flatText">{dropdown2}</h6>

							<Dropdown>
								<Dropdown.Toggle
                  ref={privateOfficeDropdownElement}
									style={{
										backgroundColor: "transparent",
										border: "none",
										color: "black",
									}}
									id="dropdown-basic"
								>
									{/* <FaEllipsisH color="black"/> */}
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<Dropdown.Item
										onClick={() => setdropdown2("Serviced")}
										href="#/action-2"
									>
										Serviced
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() => setdropdown2("Furnished")}
										href="#/action-3"
									>
										Furnished
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() =>
											setdropdown2("Unfurnished")
										}
										href="#/action-3"
									>
										Unfurnished
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>
				)}

				{dropdown1 === "Co-working Space" && (
					<div>
						<h6 className="flatText">Additional Specification</h6>
						<div className="selectCard" style={{cursor: 'pointer'}} onClick={() => hotDeskDropdownElement.current.click()}>
							<h6 className="flatText">{hotdesk}</h6>

							<Dropdown>
								<Dropdown.Toggle
                  ref={hotDeskDropdownElement}
									style={{
										backgroundColor: "transparent",
										border: "none",
										color: "black",
									}}
									id="dropdown-basic"
								>
									{/* <FaEllipsisH color="black"/> */}
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<Dropdown.Item
										onClick={() => sethotdesk("Hour")}
									>
										Hour
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() => sethotdesk("Day")}
									>
										Day
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() => sethotdesk("Week")}
									>
										Week
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() => sethotdesk("Month")}
									>
										Month
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>
				)}

				{dropdown1 === "Meeting Room" && (
					<div>
						<h6 className="flatText">Additional Specification</h6>
						<div className="selectCard" style={{cursor: 'pointer'}} onClick={() => meetingAndVenueDropdownElement.current.click()}>
							<h6 className="flatText">{meeting}</h6>

							<Dropdown>
								<Dropdown.Toggle
                  ref={meetingAndVenueDropdownElement}
									style={{
										backgroundColor: "transparent",
										border: "none",
										color: "black",
									}}
									id="dropdown-basic"
								>
									{/* <FaEllipsisH color="black"/> */}
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<Dropdown.Item
										onClick={() => setmeeting("Board Room")}
										href="#/action-2"
									>
										Board Room
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() => setmeeting("Meeting")}
										href="#/action-3"
									>
										Meeting
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() => setmeeting("Lounge")}
										href="#/action-3"
									>
										Lounge
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>
				)}

				{/* Dropdown 2 */}

				<Link onClick={submit}>
					<button className="ListPropBtn">Continue</button>
				</Link>
			</Interactive>
		</div>
	);
};

export default Workspace;
