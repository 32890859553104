import React ,{useEffect, useState}from "react";
import {Container, Row} from "react-bootstrap";
import bg from "../../../assets/img/spacia/web/Background.png";
import logo from "../../../assets/img/spacia/web/logo.png";
import googleLogo from "../../../assets/img/Web/Spacia/spacia/web/google 1.png";
import axios from 'axios'

import SERVICES from '../../../services'
import { useHistory } from "react-router-dom";
import InformationModal from "../../../components/informationModal";
import FeedbackModal from "../../../components/feedbackModal";
import { Constants } from '../../../utils/constants';
import SuccessAlert from "../../../components/Settings/alerts/SuccessAlert";
import {ErrorAlert} from "../../../components/Settings/alerts/ErrorAlert";



const ResetPassword = () => {
	const bgImage = {
		width: "100vw",
		height: "100vh",
		background: "white",
		backgroundAttachment: "fixed",
		backgroundPosition: "center",
		backgroundSize: "cover",
		position: "relative",
		// boxShadow: '0 4 38 rgba(0, 0, 0, 0.06)',
		backgroundImage: `url(${bg})`,
	};
	const formCard = {
		width: "35%",
		position: "absolute",
		right: "10vw",
		top: "10vh",
		backgroundColor: "white",
		padding: 30,
		// paddingBottom:50,
		// boxShadow: '0px 4px 38px rgba(0, 0, 0, 0.06);',
		boxShadow: "0px 4px 38px #000000, 6%",
	};

	const logopos = {
		position: "relative",
		left: "5vw",
		top: "5vh",
	};

	const googleButton = {
		display: "flex",
		width: "100%",
		border: "1px solid #D9D9D9",
		padding: 10,
		borderRadius: 10,
		backgroundColor: "white",
	};


	const [user, setuser] = useState('');
	const [successAlert, setSuccessAlert] = useState(false);
	const [resetError, setResetError] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');
	const history = useHistory();

	const resetButton = () => {
	console.log(user);
	axios.post(`${Constants.BASE_URL}/users/api/v1/users/reset-password?username=${encodeURIComponent(user)}`,
	{ params: {  } })
	.then((res) => {
			console.log(res);

			setSuccessAlert(true);
			history.push("/gotomail")
		})
	.catch((err)=>{
		console.log(err.response.data);
		const errorObj = err.response.data;

		const errors = errorObj ? errorObj.errors : [];
		const index0 = (errors.length > 0) ? errors[0] : null;

		const errorMsg = index0 ? index0.message : 'An error occurred. Please try again.';
		setErrorMsg(errorMsg);

		setResetError(true);
	})
	}


	return (
		<Row style={bgImage}>
			{/* <div > */}
			<div style={logopos}>
				<img src={logo} alt="logo" />
			</div>
			<div style={formCard}>
				<SuccessAlert message={"Reset link has been sent your email"} show={successAlert} close={() => setSuccessAlert(false)} />
				<ErrorAlert message={errorMsg} show={resetError} close={() => setResetError(false)} />
				<Container>
					<h5>Reset your password</h5>
					<br />
					<h6>
						Please provide your email address below so we send you a link to reset your password
					</h6>

					<br />

					<div className="form-group">
						<label for="">Email</label>
						<input
							type="email"
							className="form-control"
							placeholder="Eg. lindaantwi@gmail.com"
							name=""
							id=""
							aria-describedby="emailHelpId"
							value={user} 
							onChange={(e)=>setuser(e.target.value)}
						/>
					</div>
					<br />
					<br />
					<div style={{margin: "auto", textAlign: "center"}}>
						<button className="button" disabled={!user}  onClick={resetButton} style={{width: "100%", cursor: !user ? 'not-allowed' : 'pointer'}}>
							Request Password Reset
						</button>
					</div>
				</Container>
			</div>
			{/* </div> */}

		</Row>
	);
};

export default ResetPassword;
