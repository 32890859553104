// @ts-nocheck

import React, {FC, useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {ErrorAlert} from "../../components/Settings/alerts/ErrorAlert";
import {LeftComponent, PageTitle} from "../../layout/PageData";
import {MenuOutline, SearchOutline} from "react-ionicons";
import Search from "../../components/Search";
import {v4 as uuidv4} from "uuid";
import {Spinner} from "react-bootstrap";
import classnames from "classnames";
import Pagination from "../../components/Pagination";
import {Listing} from "../../utils/interfaces";
import moment from "moment-timezone";
import * as SERVICES from "../../services/localService";
import axios, { AxiosResponse } from "axios";
import {Constants} from "../../utils/constants";
import {useHistory} from "react-router-dom";
import imgplaceholder from "../../assets/img/Web/Spacia/imgplaceholder.png";
import demo from "../../assets/img/Web/Spacia/Demo.png";

const Properties: FC = () => {
    enum Status {PUBLISHED = 'PUBLISHED', UNPUBLISHED = 'UNPUBLISHED'}

    const [allProperties, setAllProperties] = useState([] as Array<Listing>)
    const [currentUserRole, setCurrentUserRole] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPageSize, setTotalPageSize] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [listingStatus, setListingStatus] = useState(Status.PUBLISHED);
    
    const [requestCompleted, setRequestCompleted] = useState(false);
    
    const [errorMsg, setErrorMsg] = useState('');
    const [errorAlert, setErrorAlert] = useState(false);

    useLayoutEffect(() => {
        const appCapsule = document.querySelector('#appCapsule') as HTMLDivElement;

        if (appCapsule != null && appCapsule.classList.contains("add-safe-space")) {
            appCapsule.style.paddingBottom = '0';
        }
    }, [])

    const handleResolvedListings = (response: AxiosResponse<any>) => {
        console.log('response for all properties:', response.data.data);

        const responseData = response.data;

        const totalCount = responseData ? responseData.totalCount : 0;
        const pageSize = responseData ? responseData.pageSize : 0;

        const resolvedListing = responseData ?
            responseData.data as Array<Listing> : [] as Array<Listing>;

        setTotalPageSize(totalCount);
        setItemsPerPage(pageSize);
        setAllProperties(resolvedListing);

        // prevent spinner from displaying
        setRequestCompleted(true);
    }

    // initialize current user role state
    useEffect(() => {
        const currentUser = SERVICES.getUser();
        const userRole = currentUser?.role;

        setCurrentUserRole(userRole);
    }, []);

    useEffect(() => {
        setRequestCompleted(false);
        setAllProperties([]);

        const currentUser = SERVICES.getUser();
        const userRole = currentUser?.role;
        const userId = currentUser.id;

        const resourceUrl = `${Constants.BASE_URL}/booking/api/v1/listings/${userId}`;
        const fetchAllPropertiesUrl = `${Constants.BASE_URL}/booking/api/v1/listings`;

        if (userRole && userRole === 'ROLE_ADMINISTRATOR') {
            axios.get(fetchAllPropertiesUrl, { params: { status: listingStatus, page: (currentPage - 1) } })
                .then(res => {
                    handleResolvedListings(res);
                })
                .catch(err => {
                    console.log(err);
                })
        } else {
            axios.get(resourceUrl, { params: { status: listingStatus, page: (currentPage - 1) } })
                .then(res => {
                    handleResolvedListings(res);
                })
                .catch(err => {
                    setRequestCompleted(true);
                });
        }
    }, [currentUserRole, listingStatus])

    const changePage = useCallback((selected) => {
        setRequestCompleted(false);
        setAllProperties([]);

        const currentUser = SERVICES.getUser();
        const userRole = currentUser?.role;
        const userId = currentUser.id;

        console.log('selected page number is', selected);
        setCurrentPage(selected);

        const fetchAllPropertiesUrl = `${Constants.BASE_URL}/booking/api/v1/listings`;
        const resourceUrl = `${Constants.BASE_URL}/booking/api/v1/listings/${userId}`;

        const fetchAllPropertiesAxiosConfig = {
            params: {
                status: listingStatus,
                page: (selected - 1),
                search: searchValue
            }
        }

        if (userRole && userRole === 'ROLE_ADMINISTRATOR') {
            axios.get(fetchAllPropertiesUrl, fetchAllPropertiesAxiosConfig)
                .then(res => {
                    handleResolvedListings(res);
                })
                .catch(err => {
                    setRequestCompleted(true);
                })
        } else {
            axios.get(resourceUrl, { params: { status: listingStatus, page: (selected - 1),  search: searchValue} })
                .then(res => {
                    handleResolvedListings(res);
                })
                .catch(err => {
                    setRequestCompleted(true);
                });
        }
    }, []);

    const performSearch = (e: any) => {
        e.preventDefault();

        setRequestCompleted(false);
        setAllProperties([]);

        setCurrentPage(1);

        const currentUser = SERVICES.getUser();
        const userRole = currentUser?.role;
        const userId = currentUser.id;

        const localUrl = `${Constants.BASE_URL}/booking/api/v1/listings/${userId}`;
        const fetchAllPropertiesUrl = `${Constants.BASE_URL}/booking/api/v1/listings`;

        console.log('user role in properties:', userRole);

        if (userRole && userRole === 'ROLE_ADMINISTRATOR') {
            axios.get(fetchAllPropertiesUrl, { params: { page: 0, status: listingStatus, search: searchValue } })
                .then(res => {
                    handleResolvedListings(res);
                })
                .catch(err => {
                    setRequestCompleted(true);
                })
        } else {
            axios.get(localUrl, { params: { page: 0, status: listingStatus, search: searchValue } })
                .then(res => {
                    handleResolvedListings(res);
                })
                .catch(err => {
                    setRequestCompleted(false);
                });
        }
    }

    const handleSwitch = (e: any) => {

        if (e.target.id === "publishedBtn") {
            setListingStatus(Status.PUBLISHED);
        }else {
            setListingStatus(Status.UNPUBLISHED);
        }
    }

    const componentToRender = () => {
        if (requestCompleted && Array.isArray(allProperties) && allProperties.length > 0) {
            return (
                <div>
                    {
                        allProperties.map(listing => {
                            return (
                                <Search
                                    key={uuidv4()}
                                    listing={listing}
                                    fromPropertiesPage={true}
                                />
                            );
                        })
                    }
                </div>
            )
        } else if (requestCompleted && Array.isArray(allProperties) && allProperties.length === 0) {
            return (
                <div className="container-for-perfect-center">
                    <div className='perfect-center'>
                        <p style={{fontWeight: 'bold', fontSize: '1.3rem'}}>No Items Found</p>
                    </div>
                </div>
            )
        } else if (!requestCompleted) {
            return (
                <div className='container-for-perfect-center'>
                    <div className='perfect-center'>
                        <Spinner size="sm" animation="border" role="status" style={{width: '50px', height: '50px'}}>
                            <span className="visually-hidden"></span>
                        </Spinner>{" "}
                    </div>
                </div>
            )
        }
    }

    return (
        <div className='mobile-view p-3'>
            <ErrorAlert show={errorAlert} message={ errorMsg } close={() => setErrorAlert(false)} />
            <PageTitle>Properties</PageTitle>
            <LeftComponent>
                <a href="#" className="headerButton" data-bs-toggle="modal" data-bs-target="#sidebarPanel">
                    <MenuOutline
                        color={'#000000'}
                        title={''}
                    />

                </a>
            </LeftComponent>
            <div className='mt-2'>
                <form className="search-form" onSubmit={performSearch}>
                    <div className="form-group searchbox">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={'Search Properties'}
                            value={searchValue}
                            onChange={e => setSearchValue(e.target.value)}
                        />
                        <i className="input-icon">
                            <SearchOutline
                            />
                        </i>
                    </div>
                </form>
            </div>
            <div className={'mt-3 text-center'}>
                <div className="btn-group btn-lg " role="group">
                    <input type="radio" className="btn-check" name="publishedBtn" id="publishedBtn" checked={listingStatus === Status.PUBLISHED} onChange={handleSwitch} onClick={handleSwitch} />
                    <label className={listingStatus === Status.PUBLISHED ? 'reservation-btn btn btn-primary' : 'reservation-btn btn btn-outline-primary'} htmlFor="publishedBtn">Published</label>

                    <input type="radio" className="btn-check" name="unpublishedBtn" id="unpublishedBtn"   checked={listingStatus === Status.UNPUBLISHED} onChange={handleSwitch}  onClick={handleSwitch} />
                    <label className={listingStatus === Status.UNPUBLISHED ? 'reservation-btn btn btn-primary' : 'reservation-btn btn btn-outline-primary'} htmlFor="unpublishedBtn">Unpublished</label>

                </div>
            </div>
            <div className={'section py-3'}>
                {/*<div className="section mt-5 p-3">*/}
                {
                    componentToRender()
                }
                <div style={{paddingBottom: '10px'}}>
                    {
                        Array.isArray(allProperties) && allProperties.length > 0 &&
                      <div className='mobile-pagination'>
                        <Pagination
                          className="pagination-bar"
                          currentPage={currentPage}
                          totalCount={totalPageSize}
                          pageSize={itemsPerPage}
                          onPageChange={changePage}
                        />
                      </div>
                    }
                </div>
                {/*{Items.map(({name, location, image, amount},key)=><Search key={key.toString()} name={name} location={location} amount={amount} image={image} />)}*/}

            </div>
        </div>
    )
}

export default Properties
