
import styled from 'styled-components';

export const StartBookingStyles = styled.div`
  .MuiOutlinedInput-root {
    height: 33px;
  }
  .MuiTextField-root {
    width: 100%;
  }
  .MuiOutlinedInput-input {
    font-size: .948rem;
  }
`