import { useState, useRef, useEffect } from "react";
import { Link, NavLink, useLocation } from 'react-router-dom';
import { BsFillPlayFill, BsCheck, BsChevronDown } from "react-icons/bs";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
// import Typography from "@mui/material/Typography";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CorporatePage = () => {
	const [view, setView] = useState("Enterprise");
	// const location = useLocation();
	// const queryParams = new URLSearchParams(location.search);
	// const scrollTo = queryParams.get("#faqSection");
	// const targetContainerRef = useRef(null);

	// useEffect(() => {
	// 	if (scrollTo) {
	// 		// Scroll to the specific container
	// 		const container = document.getElementById(scrollTo);
	// 		if (container) {
	// 			container.scrollIntoView({ behavior: 'smooth' });
	// 		}
	// 	}
	// }, [scrollTo]);

	return (
		<div>
			{/* Hero Section */}
			<div
				style={{
					background:
						"linear-gradient(180deg, #C8DBE8 0%, rgba(229, 235, 239, 0) 100%)",
					height: "100%",
					position: "relative",
				}}
			>
				{/* Header Area */}
				<div className="landingpage_header">
					<img
						src="/assets/img/new-logo.svg"
						alt="Spacia"
						width={150}
						onClick={() => window.location.replace("/home")}
						className="header_icon_route"
					/>
					<div style={{ fontSize: "16px", marginLeft: "270px", gap: "20px" }}>
						<NavLink to="/host" style={{ color: "black" }} activeClassName="active-link">Become A Host</NavLink>
						<NavLink to="/corporate-page" style={{ color: "black" }} activeClassName="active-link">Enterprise Solutions</NavLink>
						<NavLink to="/signin" style={{ color: "black" }} activeClassName="active-link">Find A Space</NavLink>
						<a href="/corporate-page#faqSection" style={{ color: "black" }} >FAQ</a>

					</div>
				</div>
				<div
					style={{
						textAlign: "center",
						color: "black",
						lineHeight: "1",
						fontWeight: "800",
						fontFamily: "Montserrat",
						marginTop: "10px",
					}}
				>
					<h1 style={{ fontWeight: "600" }}>INTRODUCING</h1>
					<h1 style={{ fontWeight: "600" }}>SPACIA ENTERPRISE</h1>
				</div>
				<div
					style={{
						marginTop: "30px",
						width: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<button
						style={{
							backgroundColor: "#6E92AA",
							borderRadius: "90px",
							display: "inline-flex",
							justifyContent: "center",
							alignItems: "center",
							padding: "10px 10px",
							color: "#FFFFFF",
							boxShadow: "0px 20px 45px rgba(110, 146, 170, 0.2)",
						}}
					>
						<BsFillPlayFill
							style={{
								color: "#FFFFFF",
								backgroundColor: "#FFFFFF1A",
								padding: "5px",
								borderRadius: "100px",
								fontSize: "30px",
								marginRight: "10px",
							}}
						/>
						<p>Watch Demo</p>
					</button>
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						margin: "30px 0px",
					}}
				>
					<img
						src="/assets/img/backgrounds/men-with-laptops-only.svg"
						alt="men with laptops"
					// width={"900px"}
					/>
				</div>
				<img
					src="/assets/img/enterprise/telegram-kite.svg"
					alt="telegram kite"
					style={{ position: "absolute", bottom: "15rem", left: "0" }}
				/>
				<img
					src="/assets/img/enterprise/dollar-kite.svg"
					alt="telegram kite"
					style={{ position: "absolute", bottom: "20rem", right: "0" }}
				/>
			</div>
			{/* Spacia plans */}
			<div style={{ padding: "40px 40px 0px 40px" }}>
				<div
					style={{ height: "400px", padding: "40px" }}
					className="enterprise_your_business"
				>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							fontFamily: "Poppins",
						}}
					>
						<h6
							style={{
								backgroundColor: "#fff",
								borderRadius: "43px",
								padding: "5px 16px",
								fontWeight: "500",
							}}
						>
							Plans Tailored for your business
						</h6>
						<div>
							<button
								style={{
									backgroundColor: view === "Enterprise" ? "#00B6F0" : "#fff",
									borderRadius: "43px",
									padding: "5px 16px",
									marginRight: "20px",
								}}
								onClick={() => {
									setView("Enterprise");
								}}
							>
								Enterprise
							</button>
							<button
								style={{
									backgroundColor: view === "Corporate" ? "#00B6F0" : "#fff",
									borderRadius: "43px",
									padding: "5px 16px",
									marginRight: "20px",
								}}
								onClick={() => {
									setView("Corporate");
								}}
							>
								Corporate
							</button>
							<button
								style={{
									backgroundColor:
										view === "Corporate Plus" ? "#00B6F0" : "#fff",
									borderRadius: "43px",
									padding: "5px 16px",
								}}
								onClick={() => {
									setView("Corporate Plus");
								}}
							>
								Corporate Plus
							</button>
						</div>
					</div>
					<div
						style={{
							color: "white",
							display: "flex",
							flexDirection: "column",
							alignItems: "flex-end",
							width: "100%",
							marginTop: "10rem",
							fontFamily: "Montserrat",
						}}
					>
						<h1>Multiple work locations</h1>
						<h1>One membership</h1>
					</div>
				</div>
				<div
					style={{
						height: "400px",
						backgroundColor: "black",
						color: "white",
						fontFamily: "Montserrat",
						padding: "50px",
						borderRadius: "0px 0px 20px 20px",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					{/* Enterprise */}
					{view === "Enterprise" && (
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								width: "100%",
							}}
						>
							<div>
								<h1 style={{ fontWeight: "900", margin: "20px 0px", fontSize: "55px" }}>
									Enterprise
								</h1>
								<h6>Tailored for teams of 5-10</h6>
								<h1 style={{ fontWeight: "900", margin: "20px 0px", fontSize: "55px" }}>
									ksh 29,000
									<span style={{ fontSize: "16px", fontWeight: "500" }}>
										/month per user{" "}
									</span>
								</h1>
								<h3
									style={{
										color: "#00B6F0",
										marginBottom: "20px",
										fontSize: "27px",
									}}
								>
									Up to 14% savings
								</h3>
								<button
									style={{
										backgroundColor: "#00B6F0",
										color: "white",
										// boxShadow: "0px 4px 31px rgba(0, 0, 0, 0.15)",
										borderRadius: "50px",
										padding: "15px 48px",
										lineHeight: "16px",
										fontSize: "16px",
									}}
									onClick={() => window.location.assign("/package-signup")}
								>
									Get Started
								</button>
							</div>
							<div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>

									<p>
										Unlimited{" "}
										<span style={{ color: "#00B6F0" }}>
											Dedicated Co-Working Space
										</span>
									</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>10 Hour Meeting Room Access</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>High speed Wi-Fi</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>Food & Beverage Discounts</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>Networking Events</p>
								</div>
							</div>
						</div>
					)}
					{/* Corporate */}
					{view === "Corporate" && (
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								width: "100%",
							}}
						>
							<div>
								<h1 style={{ fontWeight: "900", margin: "20px 0px", fontSize: "55px" }}>
									Corporate
								</h1>
								<h6>Tailored for teams of 11-19</h6>
								<h1 style={{ fontWeight: "900", margin: "20px 0px", fontSize: "55px" }}>
									ksh 25,000
									<span style={{ fontSize: "16px", fontWeight: "500" }}>
										/month per user{" "}
									</span>
								</h1>
								<h3
									style={{
										color: "#00B6F0",
										marginBottom: "20px",
										fontSize: "27px",
									}}
								>
									Up to 21% savings
								</h3>
								<button
									style={{
										backgroundColor: "#00B6F0",
										color: "white",
										// boxShadow: "0px 4px 31px rgba(0, 0, 0, 0.15)",
										borderRadius: "50px",
										padding: "15px 48px",
										lineHeight: "16px",
										fontSize: "16px",
									}}
									onClick={() => window.location.assign("/package-signup")}
								>
									Get Started
								</button>
							</div>
							<div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>

									<p>
										Unlimited{" "}
										<span style={{ color: "#00B6F0" }}>
											Dedicated Co-Working Space
										</span>
									</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>15 Hour Meeting Room Access</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>Food & Beverage Discounts</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>Dedicated High speed Wi-Fi</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>Networking Events</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>Business Address</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>Mail Handling Services</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>Free Printing & Scanning</p>
								</div>
							</div>
						</div>
					)}
					{/* Corporate Plus */}
					{view === "Corporate Plus" && (
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								width: "100%",
							}}
						>
							<div>
								<h1 style={{ fontWeight: "900", margin: "20px 0px", fontSize: "55px" }}>
									Corporate Plus
								</h1>
								<h6>Tailored for teams of 20 and above</h6>
								<h1 style={{ fontWeight: "900", margin: "20px 0px", fontSize: "55px" }}>
									ksh 23,000
									<span style={{ fontSize: "16px", fontWeight: "500" }}>
										/month per user{" "}
									</span>
								</h1>
								<h3
									style={{
										color: "#00B6F0",
										marginBottom: "20px",
										fontSize: "27px",
									}}
								>
									Up to 27% savings
								</h3>
								<button
									style={{
										backgroundColor: "#00B6F0",
										color: "white",
										// boxShadow: "0px 4px 31px rgba(0, 0, 0, 0.15)",
										borderRadius: "50px",
										padding: "15px 48px",
										lineHeight: "16px",
										fontSize: "16px",
									}}
									onClick={() => window.location.assign("/package-signup")}
								>
									Get Started
								</button>
							</div>
							<div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>

									<p>
										Unlimited{" "}
										<span style={{ color: "#00B6F0" }}>
											Dedicated Co-Working Space
										</span>
									</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>

									<p>Private Office Access</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>20 Hour Meeting Room Access</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>Food & Beverage Discounts</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>Dedicated High speed Wi-Fi</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>Networking Events</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>Business Address</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>Mail Handling Services</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>Free Printing & Scanning</p>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			{/* Our members love us */}
			<div
				style={{
					fontFamily: "Montserrat",
					marginTop: "70px",
					padding: "0px 100px",
				}}
			>
				<h6 style={{ color: "#3078FF", marginBottom: "20px" }}>
					WHAT THEY SAY
				</h6>
				<h1 style={{ color: "#011A49", fontSize: "38px" }}>
					Our members love us
				</h1>

				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						marginTop: "50px",
					}}
				>
					<div
						style={{
							height: "300px",
							border: "1px solid #DDE9FF",
							boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
							borderRadius: "24px",
							padding: "10px 30px",
						}}
					>
						<img
							src="/assets/img/enterprise/byteblanket.jpg"
							alt="byte blanket"
						/>{" "}
						<h6
							style={{
								color: "#011A49",
								marginBottom: "15px",
								marginTop: "6px",
							}}
						>
							So convenient!
						</h6>{" "}
						<p style={{ color: "#011A49", fontSize: "15px" }}>
							I just got a Flexi membership, and was amazed by the amazing cafes
							at Letswork, and the perks included when you check in, including
							the .....
						</p>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								marginTop: "30px",
							}}
						>
							<img
								src="/assets/img/enterprise/feedback-lady.svg"
								alt="feedback lady"
							/>{" "}
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									marginLeft: "10px",
								}}
							>
								<h6>Nawfa Nurie</h6>
								<p style={{ color: "#C4C4C4", fontSize: "14px" }}>
									ByteBlanket
								</p>
							</div>
						</div>
					</div>
					<div
						style={{
							height: "300px",
							border: "1px solid #DDE9FF",
							boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
							borderRadius: "24px",
							padding: "10px 30px",
							marginLeft: "50px",
						}}
					>
						<img
							src="/assets/img/enterprise/byteblanket.jpg"
							alt="byte blanket"
						/>{" "}
						<h6
							style={{
								color: "#011A49",
								marginBottom: "15px",
								marginTop: "6px",
							}}
						>
							So convenient!
						</h6>{" "}
						<p style={{ color: "#011A49", fontSize: "15px" }}>
							I just got a Flexi membership, and was amazed by the amazing cafes
							at Letswork, and the perks included when you check in, including
							the .....
						</p>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								marginTop: "30px",
							}}
						>
							<img
								src="/assets/img/enterprise/feedback-lady.svg"
								alt="feedback lady"
							/>{" "}
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									marginLeft: "10px",
								}}
							>
								<h6>Nawfa Nurie</h6>
								<p style={{ color: "#C4C4C4", fontSize: "14px" }}>
									ByteBlanket
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Sign up today */}
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					margin: "70px 80px",
					fontFamily: "Montserrat",
				}}
			>
				<h2
					style={{
						marginBottom: "30px",
						fontWeight: "500",
						color: "#011A49",
						fontSize: "37px",
					}}
				>
					Sign up today and find a{" "}
					<span style={{ color: "#3078FF" }}>space</span> to call home
				</h2>
				<p style={{ fontSize: "14px", color: "#011A49" }}>
					Buy a pass to work from hotels, cafes or co-working spaces OR book
					private meeting rooms,
				</p>
				<p style={{ fontSize: "14px", color: "#011A49" }}>
					offices or creative spaces across our cities.
				</p>
				<div style={{ margin: "40px 0px" }}>
					<button
						style={{
							backgroundColor: "#3078FF",
							color: "white",
							boxShadow: "0px 4px 31px rgba(0, 0, 0, 0.15)",
							borderRadius: "50px",
							padding: "10px 30px",
							fontSize: "14px",
							fontWeight: "500",
						}}
						onClick={() => window.location.assign("/package-signup")}
					>
						Get Started
					</button>
				</div>
			</div>
			{/* FAQ */}
			<section
				id="faqSection"
				// ref={targetContainerRef}
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					margin: "70px 80px",
					fontFamily: "Montserrat",
					padding: "0px 100px 100px 100px",
				}}
			>
				<p style={{ fontWeight: "500", color: "#3078FF" }}>
					HAVE ANYTHING TO ASK
				</p>
				<h1
					style={{ color: "#011A49", fontSize: "40px", marginBottom: "40px" }}
				>
					Frequently asked questions
				</h1>

				<div
					style={{
						display: "flex",
						alignItems: "center",
						marginBottom: "20px",
					}}
				>
					<div
						style={{
							height: "40px",
							width: "4px",
							backgroundColor: "#3078FF",
							marginRight: "20px",
						}}
					></div>
					<Accordion sx={{ width: "100%" }}>
						<AccordionSummary
							expandIcon={<BsChevronDown />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<p>What benefits do I receive from a Enterprise Account?</p>
						</AccordionSummary>
						<AccordionDetails>
							<p style={{ color: "gray" }}>
								{" "}
								Unlimited Dedicated Co-Working, Space 10 Hour Meeting Room
								Access, High speed Wi-Fi, Food & Beverage, Discounts Networking
								Events
							</p>
						</AccordionDetails>
					</Accordion>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						marginBottom: "20px",
						width: "100%",
					}}
				>
					<div
						style={{
							height: "40px",
							width: "4px",
							backgroundColor: "#3078FF",
							marginRight: "20px",
						}}
					></div>
					<Accordion sx={{ width: "100%" }}>
						<AccordionSummary
							expandIcon={<BsChevronDown />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<p>What is the price of a membership for my team?</p>
						</AccordionSummary>
						<AccordionDetails>
							<p style={{ color: "gray" }}>
								{" "}
								It ranges from 20,000 - 25,000 Ksh. It all depends on the size
								of your team.
							</p>
						</AccordionDetails>
					</Accordion>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						marginBottom: "20px",
					}}
				>
					<div
						style={{
							height: "40px",
							width: "4px",
							backgroundColor: "#3078FF",
							marginRight: "20px",
						}}
					></div>
					<Accordion sx={{ width: "100%" }}>
						<AccordionSummary
							expandIcon={<BsChevronDown />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<p>
								My manager has allocated me a package to work from one of your
								spaces, where should I go?
							</p>
						</AccordionSummary>
						<AccordionDetails>
							<p>
								{" "}
								Lorem ipsum dolor sit amet, consectetur adipiscing elit.
								Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
								eget.
							</p>
						</AccordionDetails>
					</Accordion>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						marginBottom: "20px",
					}}
				>
					<div
						style={{
							height: "40px",
							width: "4px",
							backgroundColor: "#3078FF",
							marginRight: "20px",
						}}
					></div>
					<Accordion sx={{ width: "100%" }}>
						<AccordionSummary
							expandIcon={<BsChevronDown />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<p>Do I need a trade license to work from your spaces?</p>
						</AccordionSummary>
						<AccordionDetails>
							<p>
								{" "}
								Lorem ipsum dolor sit amet, consectetur adipiscing elit.
								Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
								eget.
							</p>
						</AccordionDetails>
					</Accordion>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						marginBottom: "20px",
					}}
				>
					<div
						style={{
							height: "40px",
							width: "4px",
							backgroundColor: "#3078FF",
							marginRight: "20px",
						}}
					></div>
					<Accordion sx={{ width: "100%" }}>
						<AccordionSummary
							expandIcon={<BsChevronDown />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<p>What if I require a meeting room or a private office space?</p>
						</AccordionSummary>
						<AccordionDetails>
							<p>
								{" "}
								Lorem ipsum dolor sit amet, consectetur adipiscing elit.
								Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
								eget.
							</p>
						</AccordionDetails>
					</Accordion>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "center",
					}}
				>
					<div
						style={{
							height: "40px",
							width: "4px",
							backgroundColor: "#3078FF",
							marginRight: "20px",
						}}
					></div>
					<Accordion sx={{ width: "100%" }}>
						<AccordionSummary
							expandIcon={<BsChevronDown />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<p>
								I have annual leave booked for this year, will my credits expire
								while I am away?
							</p>
						</AccordionSummary>
						<AccordionDetails>
							<p>
								{" "}
								Lorem ipsum dolor sit amet, consectetur adipiscing elit.
								Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
								eget.
							</p>
						</AccordionDetails>
					</Accordion>
				</div>
			</section>
		</div>
	);
};

export default CorporatePage;
