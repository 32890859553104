import orderSummaryImage from '../../../../assets/img/png/order-summary-image.png';
//import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Constants } from '../../../../utils/constants';
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { v4 as uuid } from 'uuid';
import { toAbsoluteUrl } from "../../../../layout/helpers";
import closeButton from "../../../../assets/img/svg/blue-close-button.svg";
import { Modal } from "react-bootstrap";
import successImage from '../../../../assets/img/png/success-with-bubbles.png';
import rejectedImage from '../../../../assets/img/png/rejected-image.png';
import finishSetupIllustration from "../../../../assets/img/png/finish-setup-illustration.png";
import SERVICES from '../../../../services';
import axios from 'axios';
import aesEcb from "aes-ecb";

function roundToTwo(num: number): number {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(num);
}

function generateKey(keyLength: number) {
    const randomChars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@()+$";
    let result = "";
    for (let i = 0; i < keyLength; i++) {
        result += randomChars.charAt(
            Math.floor(Math.random() * randomChars.length)
        );
    }
    return result;
}

const BookingOrderSummary = () => {
    const [currency, setCurrency] = useState('');
    const [orderSummary, setOrderSummary] = useState<any>(null);
    const [summaryList, setSummaryList] = useState<any[]>([]);
    const [order, setOrder] = useState<any>({});
    const [listing, setListing] = useState<any>();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [referer, setReferer] = useState();
    const [spaceType, setSpaceType] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [total, setTotal] = useState(0);

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showFailureModal, setShowFailureModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [authorizationUrl, setAuthorizationUrl] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [errorModal, setErrorModal] = useState(false);
    const [companyRSAPublicKey, setCompanyRSAPublicKey] = useState("");

    const location = useLocation();
    const history: any = useHistory();


    useEffect(() => {
        const currency = SERVICES.getCurrency();
        setCurrency(currency);

        console.log('location:', location);
        const locationState = location.state;

        const order = locationState?.order;
        const listing = locationState?.listing;
        const user = locationState?.user;
        const startDate = locationState?.startDate;
        const endDate = locationState?.endDate;

        const total = order ? order.total : 0;
        const subCategory = listing ? listing.subCategory : '';
        const packagePlan = user?.packagePlan;
        const packageUsages = packagePlan?.packageUsages;
        const firstIndex = (packageUsages && packageUsages.length > 0) ? packageUsages[0] : null;
        const accessDuration = firstIndex?.accessDuration;
        const duration = accessDuration ? accessDuration.duration : 0;

        console.log('package plan:', packagePlan);
        console.log('order:', order, '.order total:', total);

        setTotal(total);
        setSpaceType(subCategory);
        setQuantity(duration);
        setOrder(order);
        setListing(listing);
        setStartDate(startDate);
        setEndDate(endDate);
    }, []);

    const observePaymentStatusCallBack = (
        invoiceToken: any,
        callBackurl: any
    ) => {
        console.log("callBackurl from paystack is:", callBackurl);

        const sse = new EventSource(callBackurl);
        console.log("sse:", sse);

        // sse.addEventListener(invoiceToken, (e: MessageEvent) => {
        //     console.log("in event listener:", e.data);

        //     let data;
        //     try {
        //         data = JSON.parse(e.data);
        //     } catch (ex) {
        //         console.log("an error occurred while parse event data.", ex);
        //     }

        //     console.log("data value:", data);
        //     if (data) {
        //         console.log("parsed data:", data);
        //         const paymentProcessingTimeout = Number(
        //             Constants.paymentProcessingTimeout
        //         );

        //         const { isPaid } = data;

        //         setTimeout(() => {
        //             if (!isPaid) {

        //                 sse.close();
        //             }
        //         }, paymentProcessingTimeout);

        //         if (isPaid) {
        //             console.log("invoice has been paid");

        //             sse.close();
        //         }
        //     } else {
        //         console.log("ERROR: data is null");
        //         // setIsSuccessful(false);
        //         // setIsProcessingPayment(false);
        //     }
        // });

        sse.addEventListener("event", (e: MessageEvent) => {
            console.log("in event listener:", e.data);

            let data;
            try {
                data = JSON.parse(e.data);
            } catch (ex) {
                console.log("an error occurred while parse event data.", ex);
            }

            console.log("data value:", data);
            if (data) {
                console.log("parsed data:", data);
                const paymentProcessingTimeout = Number(
                    Constants.paymentProcessingTimeout
                );

                const isPaid = data.isPaid;

                setTimeout(() => {
                    if (!isPaid) {
                        sse.close();
                    }
                }, paymentProcessingTimeout);

                if (isPaid) {
                    console.log("invoice has been paid");

                    sse.close();
                }
            } else {
                console.log("ERROR: data is null");
                // setIsSuccessful(false);
                // setIsProcessingPayment(false);
            }
        });

        sse.addEventListener("error", (e: any) => {
            console.log("addEventListener error :", e);
            // setIsSuccessful(false);
            // setIsProcessingPayment(false);
        });
    };

    const isAllOfTheQuantityZero = () => {
        if (summaryList && Array.isArray(summaryList) && summaryList.length > 0) {
            return summaryList.every(e => e.quantity === 0);
        }

        return true;
    };

    const handleStuffOnModalHide = (type: string) => {
        switch (type) {
            case 'success':
                setShowSuccessModal(false);

                history.push({
                    pathname: '/filterprops',
                    state: {
                        bookingSuccessful: true
                    }
                });


                break;

            case 'failure':
                setShowFailureModal(false);

                if (referer && referer === 'package-signup') {
                    history.push('/corporate-page');
                }

                break;
        }

        const orderSummaryContainer = document.querySelector('.pkg-order-summary');
        if (orderSummaryContainer != null) {
            orderSummaryContainer.classList.remove('blur-background');
        }
    };

    const displayModal = (type: string) => {
        switch (type) {
            case 'success':
                setShowSuccessModal(true);
                break;

            case 'failure':
                setShowFailureModal(true);
                break;
        }

        const orderSummaryContainer = document.querySelector('.pkg-order-summary');
        if (orderSummaryContainer != null) {
            orderSummaryContainer.classList.add('blur-background');
        }
    };

    const proceedToCreateBookingFromOrder = () => {
        const currentUser = SERVICES.getUser();
        const userId = currentUser ? currentUser.id : 0;
        const listingId = listing ? listing.id : 0;
        const orderId = order ? order.id : 0;

        console.log('start date:', startDate, 'end date:', endDate);

        const payload = {
            userId,
            listingId,
            startOn: startDate,
            endOn: endDate,
            orderId
        };

        axios.post(`${Constants.BASE_URL}/booking/api/v1/booking/make/immediate/payment`, payload)
            .then(res => {
                console.log('response after creating booking:', res);

                if (res.status === 200) {
                    const response = res.data;

                    const bookingResponse = response?.data;

                    const bookingIdsArray: number[] = [];
                    // @ts-ignore
                    bookingResponse && bookingResponse.forEach(booking => {
                        bookingIdsArray.push(booking.id);
                    });

                    const currentUser = SERVICES.getUser();

                    const userId = currentUser && currentUser.id;

                    const resourceUrl = `${Constants.BASE_URL}/booking/api/v1/booking/email`;

                    // trigger booking confirmation email
                    axios.get(resourceUrl, { params: { bookingIds: bookingIdsArray && bookingIdsArray.join(',') } })
                        .then(res => {
                            console.log(res);

                            displayModal('success');
                        })
                        .catch(err => console.log(err));
                }

            }).catch(err => {
                console.log('what is here');

                const errorResponse = err.response;
                console.log('error response:', errorResponse);

                const data = errorResponse?.data;
                let message = data?.message;
                const errorOnBooking = data?.errorOnBooking;

                console.log('error on booking:', errorOnBooking);

                if (errorOnBooking && message) {
                    switch (errorOnBooking) {
                        case 'SPACE_NOT_PART_OF_PACKAGE':
                        case 'NUMBER_OF_CHECKINS_EXCEEDED':
                            message += `. Please proceed to make payment`;
                    }
                }

                // setBookingErrorAlert(true);
                // setShowErrorModal(true);
                // setBookingErrorMsg(message);
                // setErrorOnBooking(errorOnBooking);

                // setLoading(false);
            });
    };

    const makeRequestToProcessPayment = (
        bit256: string,
        payload: { key?: any; data: any; },
        processPaymentUrl: string,
        userId: any,
        isMultiple: boolean
    ) => {
        const encrypt = aesEcb.encrypt(bit256, JSON.stringify(payload.data));
        console.log("encrypted buffer:", encrypt);
        payload.data = encrypt;

        axios
            .post(processPaymentUrl, payload, { params: { userId } })
            .then((res) => {
                if (res.status === 200) {
                    console.log(res);

                    const data = res.data.data;
                    const index0 = data && data[0];
                    console.log("RESPONSE DATA PAYSTACK:", data);

                    const authorizationUrl =
                        index0.paymentRequestResponse.data.authorizationUrl;
                    console.log("authorization url:", authorizationUrl);

                    setAuthorizationUrl(authorizationUrl);

                    // set modal to true

                    const callbackUrl =
                        index0 && index0["subscriptionCallbackUrl"];

                    console.log("subscription callback url:", callbackUrl);

                    // move to authorizationUrl with react router
                    // window.open(authorizationUrl, "_blank");
                    console.log("order.referenceCode:", order.referenceCode);
                    console.log("order here:", order);

                    // observe payment status
                    observePaymentStatusCallBack(
                        order.referenceCode,
                        callbackUrl
                    );

                    // eventSource.onmessage = (e) => {
                    //     console.log("event source data:", e.data);

                    //     const data = JSON.parse(e.data);
                    //     console.log("SSE data:", data);

                    //     // const { status, message } = data;
                    //     const isPaid = data.isPaid;

                    //     if (isPaid === "success") {
                    //         // hide spinner
                    //         // setIsLoading(false);

                    //         // display success modal
                    //         displayModal("success");
                    //     } else {
                    //         // hide spinner
                    //         setIsLoading(false);

                    //         // display failure modal
                    //         displayModal("failure");
                    //     }
                    // };

                    // history.push({
                    //     pathname: "/payment-success",
                    //     state: {
                    //         callbackUrl: isMultiple && callbackUrl,
                    //     },
                    // });

                    // hide spinner
                    setIsLoading(false);
                } else {
                    // hide spinner
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                console.log(err.response);

                const {
                    response: {
                        data: { errors },
                    },
                } = err;
                // const { field } = errors[0];
                let errorMsg =
                    errors && Array.isArray(errors) && errors.length > 0
                        ? errors[0].field
                        : "";

                setErrorModal(true);

                errorMsg = errorMsg
                    ? errorMsg.charAt(0).toUpperCase() + errorMsg.slice(1)
                    : err.response.data.message;

                setErrorMsg(errorMsg);
                console.log("error message:", errorMsg);

                setIsLoading(false);
            });
    };

    const processPayment = () => {
        //e.preventDefault();

        const errors = [];

        if (errors.length === 0) {
            const requestId = uuid();
            const currentUser = SERVICES.getUser();
            const userId = currentUser
                ? currentUser.id
                : order
                    ? order.billingAccount.id
                    : 0;
            const companyId = currentUser
                ? currentUser.companyId
                : order
                    ? order.billingAccount.company.id
                    : 0;
            //const companyId = order ? order.billingAccount.company.id : 0;
            const rsaKey = order
                ? order.billingAccount.company.rsaPubliceKey
                : "";
            console.log("rsaKey: ", rsaKey);
            console.log("invoice amount: ", order.total);
            console.log("invocie referenceCode: ", order.referenceCode);

            // display spinner
            setIsLoading(true);

            var currency = SERVICES.getCurrency();
            currency = currency
                ? currency
                : summaryList
                    ? summaryList[0].bundle.pricing.currency
                    : "";
            console.log("currency:", currency);

            var invoiceTokens: any[] = [];
            invoiceTokens.push(order.referenceCode);

            const resourceUrl = `${Constants.BASE_URL}/platform/company/v1/company/${companyId}/rsa/key/public`;

            console.log(
                "[ " +
                requestId +
                " ] about to make request to fetch company RSA public key"
            );
            axios
                .get(resourceUrl, { params: { userId } })
                .then((res) => {
                    if (res.status === 200) {
                        let responseData = res.data.data[0];
                        console.log(
                            "[ " +
                            requestId +
                            " ] done processing request to fetch company RSA public key\n" +
                            "response data:",
                            res.data.data[0]
                        );

                        const publicKey = responseData.rsaPublicKey;
                        setCompanyRSAPublicKey(publicKey);

                        // generate 256 bit key
                        const bit256 = generateKey(32);
                        console.log("256 bit key is", bit256);

                        // encrypt 256 bit key with RSA Public Key
                        const url = `${Constants.BASE_URL}/users/v1/generate/base64/encrypted/key`;
                        axios
                            .post(url, {
                                bitKey: bit256,
                                rsaPublicKey: publicKey,
                            })
                            .then((res) => {
                                if (res.status === 200) {
                                    console.log(
                                        "encrypted key string:",
                                        res.data
                                    );
                                    const requestKey = res.data;

                                    // process payment
                                    const processPaymentUrl = `${Constants.BASE_URL}/users/v1.1/process/payment`;

                                    const payload = {
                                        key: requestKey,
                                        data: {
                                            requestId,
                                            invoiceIds: [],
                                            invoiceTokens,
                                            currency,
                                            paymentMethodCode: null,
                                            userId,
                                            paymentMethod: "webCheckout",
                                            amount: order.total,
                                            mobilewallet: null,
                                            electronicCardRequest: null,
                                            bankTransferRequest: null,
                                            isCashOnDelivery: false,
                                        },
                                    };

                                    console.log("raw payload:", payload);

                                    makeRequestToProcessPayment(
                                        bit256,
                                        payload,
                                        processPaymentUrl,
                                        userId,
                                        false
                                    );
                                } else {
                                    setIsLoading(false);
                                }
                            })
                            .catch((err) => {
                                console.log(err.response);

                                const {
                                    response: {
                                        data: { message },
                                    },
                                } = err;
                                const errorMsg = message;

                                setErrorModal(true);
                                setErrorMsg(errorMsg);
                            });
                    } else {
                        setIsLoading(false);
                    }
                })
                .catch((err) => {
                    console.log(err.response);

                    const {
                        response: {
                            data: { message },
                        },
                    } = err;
                    const errorMsg = message;

                    setErrorModal(true);
                    setErrorMsg(errorMsg);
                });
        }
    };

    {/* const makeRequestToProcessPayment = (
        bit256: string,
        payload: { key?: any; data: any },
        processPaymentUrl: string,
        userId: any,
        isMultiple: boolean
    ) => {
        const encrypt = aesEcb.encrypt(bit256, JSON.stringify(payload.data));
        console.log("encrypted buffer:", encrypt);
        payload.data = encrypt;

        axios
            .post(processPaymentUrl, payload, { params: { userId } })
            .then((res) => {
                if (res.status === 200) {
                    console.log(res);

                    const data = res.data.data;
                    const index0 = data && data[0];
                    console.log("RESPONSE DATA PAYSTACK:", data);

                    const authorizationUrl =
                        index0.paymentRequestResponse.data.authorizationUrl;
                    console.log("authorization url:", authorizationUrl);

                    setAuthorizationUrl(authorizationUrl);

                    // set modal to true

                    const callbackUrl =
                        index0 && index0["subscriptionCallbackUrl"];

                    console.log("subscription callback url:", callbackUrl);

                    // move to authorizationUrl with react router
                    // window.open(authorizationUrl, "_blank");
                    console.log("order.referenceCode:", order.referenceCode);
                    console.log("order here:", order);

                    // observe payment status
                    observePaymentStatusCallBack(
                        order.referenceCode,
                        callbackUrl
                    );

                    // eventSource.onmessage = (e) => {
                    //     console.log("event source data:", e.data);

                    //     const data = JSON.parse(e.data);
                    //     console.log("SSE data:", data);

                    //     // const { status, message } = data;
                    //     const isPaid = data.isPaid;

                    //     if (isPaid === "success") {
                    //         // hide spinner
                    //         // setIsLoading(false);

                    //         // display success modal
                    //         displayModal("success");
                    //     } else {
                    //         // hide spinner
                    //         setIsLoading(false);

                    //         // display failure modal
                    //         displayModal("failure");
                    //     }
                    // };

                    // history.push({
                    //     pathname: "/payment-success",
                    //     state: {
                    //         callbackUrl: isMultiple && callbackUrl,
                    //     },
                    // });

                    // hide spinner
                    setIsLoading(false);
                } else {
                    // hide spinner
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                console.log(err.response);

                const {
                    response: {
                        data: { errors },
                    },
                } = err;
                // const { field } = errors[0];
                let errorMsg =
                    errors && Array.isArray(errors) && errors.length > 0
                        ? errors[0].field
                        : "";

                setErrorModal(true);

                errorMsg = errorMsg
                    ? errorMsg.charAt(0).toUpperCase() + errorMsg.slice(1)
                    : err.response.data.message;

                setErrorMsg(errorMsg);
                console.log("error message:", errorMsg);

                setIsLoading(false);
            });
    }; 

    const processPayment = () => {
        //e.preventDefault();

        const errors = [];

        if (errors.length === 0) {
            const requestId = uuid();
            const currentUser = SERVICES.getUser();
            const userId = currentUser
                ? currentUser.id
                : order
                ? order.billingAccount.id
                : 0;
            const companyId = currentUser
                ? currentUser.companyId
                : order
                ? order.billingAccount.company.id
                : 0;
            //const companyId = order ? order.billingAccount.company.id : 0;
            const rsaKey = order
                ? order.billingAccount.company.rsaPubliceKey
                : "";
            console.log("rsaKey: ", rsaKey);
            console.log("invoice amount: ", order.total);
            console.log("invocie referenceCode: ", order.referenceCode);

            // display spinner
            setIsLoading(true);

            var currency = SERVICES.getCurrency();
            currency = currency
                ? currency
                : summaryList
                ? summaryList[0].bundle.pricing.currency
                : "";
            console.log("currency:", currency);

            var invoiceTokens: any[] = [];
            invoiceTokens.push(order.referenceCode);

            const resourceUrl = `${Constants.BASE_URL}/platform/company/v1/company/${companyId}/rsa/key/public`;

            console.log(
                "[ " +
                    requestId +
                    " ] about to make request to fetch company RSA public key"
            );
            axios
                .get(resourceUrl, { params: { userId } })
                .then((res) => {
                    if (res.status === 200) {
                        let responseData = res.data.data[0];
                        console.log(
                            "[ " +
                                requestId +
                                " ] done processing request to fetch company RSA public key\n" +
                                "response data:",
                            res.data.data[0]
                        );

                        const publicKey = responseData.rsaPublicKey;
                        setCompanyRSAPublicKey(publicKey);

                        // generate 256 bit key
                        const bit256 = generateKey(32);
                        console.log("256 bit key is", bit256);

                        // encrypt 256 bit key with RSA Public Key
                        const url = `${Constants.BASE_URL}/users/v1/generate/base64/encrypted/key`;
                        axios
                            .post(url, {
                                bitKey: bit256,
                                rsaPublicKey: publicKey,
                            })
                            .then((res) => {
                                if (res.status === 200) {
                                    console.log(
                                        "encrypted key string:",
                                        res.data
                                    );
                                    const requestKey = res.data;

                                    // process payment
                                    const processPaymentUrl = `${Constants.BASE_URL}/users/v1.1/process/payment`;

                                    const payload = {
                                        key: requestKey,
                                        data: {
                                            requestId,
                                            invoiceIds: [],
                                            invoiceTokens,
                                            currency,
                                            paymentMethodCode: null,
                                            userId,
                                            paymentMethod: "webCheckout",
                                            amount: order.total,
                                            mobilewallet: null,
                                            electronicCardRequest: null,
                                            bankTransferRequest: null,
                                            isCashOnDelivery: false,
                                        },
                                    };

                                    console.log("raw payload:", payload);

                                    makeRequestToProcessPayment(
                                        bit256,
                                        payload,
                                        processPaymentUrl,
                                        userId,
                                        false
                                    );
                                } else {
                                    setIsLoading(false);
                                }
                            })
                            .catch((err) => {
                                console.log(err.response);

                                const {
                                    response: {
                                        data: { message },
                                    },
                                } = err;
                                const errorMsg = message;

                                setErrorModal(true);
                                setErrorMsg(errorMsg);
                            });
                    } else {
                        setIsLoading(false);
                    }
                })
                .catch((err) => {
                    console.log(err.response);

                    const {
                        response: {
                            data: { message },
                        },
                    } = err;
                    const errorMsg = message;

                    setErrorModal(true);
                    setErrorMsg(errorMsg);
                });
        }
    }; */}

    return (
        <section className={'pkg-order-summary'} style={{ padding: '0 70px 70px 70px' }}>
            <header style={{ paddingTop: '30px' }}>
                <figure style={{ width: '150px' }}>
                    <img
                        alt={"logo"}
                        decoding={"async"}
                        className={"w100 img-fluid"}
                        src={toAbsoluteUrl("/img/logo-black.png")}
                    />
                </figure>
            </header>
            <div className="row">
                <div className="col-7">
                    <h4 style={{ marginTop: '30px', fontSize: '2rem' }}>Order Summary</h4>
                    <div style={{ fontSize: '1.17rem', marginTop: '50px' }}>
                        <h5>Order No: <span style={{ fontWeight: 'normal' }}>{order ? order.id : ''}</span></h5>
                        <table style={{ tableLayout: 'fixed', width: '100%' }}>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th scope={'col'}>Quantity</th>
                                    <th scope={'col'}>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{spaceType}</td>
                                    <td style={{ borderBottom: '1px dotted black' }} />
                                    <td>x{quantity}</td>
                                    <td>{total}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div style={{ marginTop: '50px' }}>
                            <p style={{ fontSize: '.93rem', color: '#262626' }}>Total + VAT 16%</p>
                            <p className={'text-primary mb-3'} style={{ fontSize: '2rem', fontWeight: 700, marginTop: '15px' }}>
                                {currency}.<span>{order && order.total ? order.total.toLocaleString() : 0}</span>
                            </p>
                            <p>
                                By Clicking “Pay” I agree to the <br />
                                company's <span style={{ textDecoration: 'underline' }}>Terms and conditions</span> for the service
                            </p>
                        </div>

                        <div className={'d-flex justify-content-between'} style={{ width: '35%', marginTop: '100px' }}>
                            <button
                                className="btn btn-outline-info" style={{ width: '100px', borderRadius: '100px', height: '40px' }}
                                onClick={() => { history.go(-1); }}
                            >
                                Back
                            </button>
                            <div style={{ display: (!order) ? 'none' : 'block' }}>
                                <button
                                    className="btn btn-outline-info"
                                    style={{
                                        width: "160px",
                                        borderRadius: "100px",
                                        height: "40px",
                                    }}
                                    onClick={() => {
                                        setShowConfirmModal(true);
                                    }}
                                >
                                    Pay
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-5 order-summary-bg">
                    <figure>
                        <img className={'order-summary'} src={orderSummaryImage} alt="Order Summary" />
                    </figure>
                </div>
            </div>

            {/* payment successful modal */}
            <Modal show={showSuccessModal} onHide={() => handleStuffOnModalHide('success')}>
                <header className={'position-relative'} style={{ zIndex: 3 }}>
                    <figure className="text-right" style={{ padding: '15px' }}>
                        <img onClick={() => handleStuffOnModalHide('success')} src={closeButton} alt="close button" style={{ width: '25px' }} />
                    </figure>
                </header>
                <Modal.Body style={{ height: '350px' }}>
                    <div className="text-center position-relative" style={{ bottom: '25px' }}>
                        <figure>
                            <img
                                className={'position-relative'}
                                src={successImage}
                                alt="Success"
                                style={{ width: '40%' }}
                            />
                        </figure>
                        <p className={'text-primary font-weight-bolder mt-5'} style={{ fontSize: '1.3rem' }}>
                            Payment Successful!
                        </p>
                        <p>Please check your email for booking confirmation</p>
                        <div className={'mt-4'}>
                            <button
                                className="btn btn-primary"
                                style={{ borderRadius: '30px' }}
                                onClick={() => handleStuffOnModalHide('success')}
                            >
                                Go Back
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* payment failed modal */}
            <Modal show={showFailureModal} onHide={() => handleStuffOnModalHide('failure')}>
                <header className={'position-relative'} style={{ zIndex: 3 }}>
                    <figure className="text-right" style={{ padding: '15px' }}>
                        <img onClick={() => handleStuffOnModalHide('failure')} src={closeButton} alt="close button" style={{ width: '25px' }} />
                    </figure>
                </header>
                <Modal.Body style={{ height: '300px' }}>
                    <div className="text-center position-relative" style={{ bottom: '25px' }}>
                        <figure>
                            <img
                                className={'position-relative'}
                                src={rejectedImage}
                                alt="Failure"
                                style={{ width: '25%' }}
                            />
                        </figure>
                        <p className={'text-primary font-weight-bolder mt-5'} style={{ fontSize: '1.3rem' }}>
                            Payment Failed!
                        </p>
                        <div>
                            <button
                                className="btn btn-primary"
                                style={{ borderRadius: '30px' }}
                                onClick={() => handleStuffOnModalHide('failure')}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* confirm payment modal */}
            <Modal
                show={showConfirmModal}
                onHide={() => setShowConfirmModal(false)}
                contentClassName="padding-40px"
                style={{
                    overflow: "hidden",
                    borderRadius: "30px",
                }}
                // size="lg"
            >
                <Modal.Header
                    style={{
                        borderBottom: "none",
                        paddingLeft: "30px",
			paddingBottom: 0,

                        paddingRight: "30px",
                        margin: "0px",
                    }}
                    closeButton
                >
                    <Modal.Title
                        id="contained-modal-title-vcenter"
                        style={{ width: "100%", textAlign: "center" }}
                    >
                        {/* <h6 className="text-primary">Confirm Payment</h6> */}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{}}>
                    <div
                        className="text-center position-relative"
                        style={{ bottom: "15px" }}
                    >
                        <span
                            className={"text-primary mt-5"}
                            style={{ fontSize: "1.3rem", fontWeight: 200 }}
                        >
                                    You are about to be redirected to checkout
                        </span>
                        <div
                            className={"mt-4"}
                            style={
                                {
                                    // display:
                                    //     referer && referer === "package-signup"
                                    //         ? "display"
                                    //         : "none",
                                }
                            }
                        >
                            <button
                                className="btn btn-outline-primary"
                                style={{
                                    borderRadius: "20px",
                                    marginLeft: "0px",
                                    width: "8rem"
                                }}
                                onClick={() => setShowConfirmModal(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-outline-primary"
                                style={{
                                    borderRadius: "20px",
                                    marginLeft: "20px",
                                    width: "8rem"
                                }}
                                onClick={() => {
                                    processPayment();
                                    setShowConfirmModal(false);
                                    setShowAuthModal(true);
                                    // history.push("/sign-in");
                                }}
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </Modal.Body>
                {/* <ModalFooter */}
            </Modal>

            {/* authentication modal */}

            <Modal
                show={showAuthModal}
                onHide={() => {
                    setShowAuthModal(false);
                    //   if user is signed in move to dashboard else move to sign in page
                    history.push("/filterprops");
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header
                    style={{
                        borderBottom: 'none',
                        paddingLeft: '30px',
                        paddingRight: '30px',
                        margin: '0px',
                    }}
                    closeButton
                >
                    <Modal.Title
                        id="contained-modal-title-vcenter"
                        style={{ width: '100%', textAlign: 'center' }}
                    >
                        <h6
                            style={{ fontSize: '1.3rem', fontWeight: 200 }}
                            className="text-primary"
                        >
                            Web Checkout
                        </h6>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{ height: '850px', padding: '0px', width: '500px', margin: 'auto', }}
                >
                    <iframe
                        src={authorizationUrl}
                        style={{
                            padding: '0px',
                            width: '500px',
                            height: '100%',
                            margin: '0',
                            border: 'none',
                        }}
                        title="Web Checkout"
                    ></iframe>
                </Modal.Body>
                <Modal.Footer
                    style={{
                        borderTop: 'none',
                        padding: '0px',
                        margin: '0px',
                    }}
                >
                </Modal.Footer>
            </Modal>
        </section>
    );
};

export default BookingOrderSummary;
