import { BehaviorSubject } from 'rxjs';
import axios from 'axios';
import { Constants } from '../../../utils/constants';

const companyDetailsSubject = new BehaviorSubject({});
const updateProfileSubject = new BehaviorSubject({});
const supportedCountriesSubject = new BehaviorSubject({});
const supportedBusinessDocumentsSubject = new BehaviorSubject({});
const existingCompanyRegulatoryDocumentBySupportedSubject = new BehaviorSubject({});
const regulatoryDocumentComplianceSubject = new BehaviorSubject({});
const deleteCompanyRegulatoryDocumentSubject = new BehaviorSubject({});
const uploadCompanyRegulatoryDocumentSubject = new BehaviorSubject({});

export const companyServiceRxJS = {
    companyDetailsSubject: companyDetailsSubject.asObservable(),
    getCompanyDetailsSubject,
    updateProfile,
    updateProfileSubject: updateProfileSubject.asObservable(),
    resetCompanyDetailsSubject,
    resetGetCompanyDetailsSubject,
    supportedCountriesSubject: supportedCountriesSubject.asObservable(),
    getSupportedCountries,
    supportedBusinessDocumentsSubject: supportedBusinessDocumentsSubject.asObservable(),
    getSupportedBusinessDocuments,
    existingCompanyRegulatoryDocumentBySupportedSubject: existingCompanyRegulatoryDocumentBySupportedSubject.asObservable(),
    getExistingCompanyRegulatoryDocumentBySupportedSubject,
    regulatoryDocumentComplianceSubject: regulatoryDocumentComplianceSubject.asObservable(),
    getRegulatoryDocumentCompliance,
    deleteCompanyRegulatoryDocumentSubject: deleteCompanyRegulatoryDocumentSubject.asObservable(),
    deleteCompanyRegulatoryDocument,
    uploadCompanyRegulatoryDocumentSubject: uploadCompanyRegulatoryDocumentSubject.asObservable(),
    uploadCompanyRegulatoryDocument
}

function getCompanyDetailsSubject(id, userId) {
    if (id && userId) {
        axios.get(`${Constants.BASE_URL}/platform/company/v1/company/` + id + "?userId="+ userId).then(e => {
            companyDetailsSubject.next(e.data);
        }).catch(err => {
            console.log("error : "+ err);

            let errStatus = err?.response?.status;
            errStatus = (errStatus === undefined) ? 400 : errStatus;

            const errorObj = { status : errStatus, message : err?.response?.data?.message }
            companyDetailsSubject.next(errorObj);

            //companyDetailsSubject.next({"requestId":"548C706FDB34B90D43BE359DB746DCDD","message":"success","status":200,"totalCount":1,"page":0,"pageSize":0,"data":[{"id":1,"name":"Spacia","address":"P.O.Box 6297, Buokrom-Kumasi","type":"platformOwner","contacts":[{"id":2,"contactType":"email","value":"nicholas.akorful68@gmail.com"}, {"id":3,"contactType":"mobile","value":"0244653168"}],"locations":[{"id":3,"latitude":"-0.1234","longitude":"0.1357","streetName":"A-line"}],"messageTemplates":null,"city":"Kumasi","region":"Ashanti","country":{"initial":"gh","name":"ghana","language":"en","currency":"GHS"},"domain":"spaciaafrica.com","webUrl":"https://www.spaciaafrica.com","isEnabled":true,"createdOn":"2021-07-05T09:53:52.428Z","updatedOn":"2021-07-05T09:53:52.442Z","corporateCustomers":[],"rsaPrivateKey":null,"rsaPubliceKey":null}]})
        });
    }
}

function updateProfile(userId, companyId, payload, token){
    if(userId && companyId && payload){
        const headers = {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json'
        };

        axios.put(`${Constants.BASE_URL}/platform/company/v1/company/` + companyId, payload, headers).then(e => {
            updateProfileSubject.next(e.data);
        }).catch(err => {
            console.log("error : "+ err);

            let status = err?.response?.status;
            status = (status === undefined) ? 400 : status;

            const error = { status : status, message : err?.response?.data?.message }
            updateProfileSubject.next(error);
        });
    }
}

function resetCompanyDetailsSubject(){
    updateProfileSubject.next({});
}

function resetGetCompanyDetailsSubject(){
    companyDetailsSubject.next({});
}

function getSupportedCountries(token){
    const headers = {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
    };

    axios.get(`${Constants.BASE_URL}/platform/company/v1/supported/countries`, null, headers).then(e => {
        supportedCountriesSubject.next(e.data);
    }).catch(err => {
        console.log("error : "+ err);

        let errStatus = err?.response?.status;
        errStatus = (errStatus === undefined) ? 400 : errStatus;

        const errorObj = { status : errStatus, message : err?.response?.data?.message }
        supportedCountriesSubject.next(errorObj);
    });
}

function getSupportedBusinessDocuments(isRequired, userId, token){
    const headers = {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
    };

    axios.get(`${Constants.BASE_URL}/platform/company/v1/supported/regulatory/documents?isRequired=${ isRequired }&userId=${ userId }`, null, headers).then(e => {
        supportedBusinessDocumentsSubject.next(e.data);
    }).catch(err => {
        console.log("error : "+ err);

        let errStatus = err?.response?.status;
        errStatus = (errStatus === undefined) ? 400 : errStatus;

        const errorObj = { status : errStatus, message : err?.response?.data?.message }
        supportedBusinessDocumentsSubject.next(errorObj);
    });
}

function getExistingCompanyRegulatoryDocumentBySupportedSubject(){

}


function getRegulatoryDocumentCompliance(companyId, userId, token){
    const headers = {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
    };

    axios.get(`${Constants.BASE_URL}/platform/company/v1/company/${ companyId }/regulatory/compliance?userId=${ userId }`, null, headers).then(e => {
        regulatoryDocumentComplianceSubject.next(e.data);
    }).catch(err => {
        console.log("error : "+ err);

        let errStatus = err?.response?.status;
        errStatus = (errStatus === undefined) ? 400 : errStatus;

        const errorObj = { status : errStatus, message : err?.response?.data?.message }
        regulatoryDocumentComplianceSubject.next(errorObj);
    });
}

function deleteCompanyRegulatoryDocument(id, userId, token){
    const headers = {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
    };

    axios.delete(`${Constants.BASE_URL}/platform/company/v1/company/regulatory/documents/${ id }?userId=${ userId }`, null, headers).then(e => {
        deleteCompanyRegulatoryDocumentSubject.next(e.data);
    }).catch(err => {
        console.log("error : "+ err);

        let errStatus = err?.response?.status;
        errStatus = (errStatus === undefined) ? 400 : errStatus;

        const errorObj = { status : errStatus, message : err?.response?.data?.message }
        deleteCompanyRegulatoryDocumentSubject.next(errorObj);
    });
}

function uploadCompanyRegulatoryDocument(userId, supportRegDocId, formData, token){
    const headers = {
        'Authorization': 'Bearer '+ token,
        'content-type': 'multipart/form-data; boundary='+ token
    };

    const config = { headers: headers };

    axios.post(`${Constants.BASE_URL}/platform/company/v1/company/regulatory/documents?userId=${ userId }&supportedRegulatoryDocumentId=${ supportRegDocId }`, formData, config).then(e => {
        uploadCompanyRegulatoryDocumentSubject.next(e.data);
    }).catch(err => {
        console.log("error : "+ err);

        let errStatus = err?.response?.status;
        errStatus = (errStatus === undefined) ? 400 : errStatus;

        const errorObj = { status : errStatus, message : err?.response?.data?.message }
        uploadCompanyRegulatoryDocumentSubject.next(errorObj);
    });
}