import React, {useEffect, useState} from "react";
import {BundleMetricData, Package, packageSelectData} from "../../../../data/package-select-data";
import MembershipPlanCard from "../../../../components/bundles/membership-plan-card";
import axios from 'axios';
import {Constants} from '../../../../utils/constants';
import SERVICES from '../../../../services';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {resetBundleQuantity} from "../../../../redux/actions/dashboard";

enum BUNDLE_TYPE {
    LOW = "LOW",
    MEDIUM = "MEDIUM",
    HIGH = "HIGH"
}

const BUNDLE_QUANTITY_DEFAULTS = {
    [BUNDLE_TYPE.LOW]: {
        minUsers: 5,
        maxUsers: 10
    },
    [BUNDLE_TYPE.MEDIUM]: {
        minUsers: 11,
        maxUsers: 19
    },
    [BUNDLE_TYPE.HIGH]: {
        minUsers: 20,
        maxUsers: Number.MAX_SAFE_INTEGER
    },
}

const MembershipPlan = () => {
    // @ts-ignore
    const bundleTypeAndQuantities = useSelector((state) => state.dashboard.bundleTypeAndQuantities);

    const history: any = useHistory();
    const dispatch = useDispatch();

    const [bundlePackages, setBundlePackages] = useState<Array<Package>>();
    const [bundleMetrics, setBundleMetrics] = useState<Array<BundleMetricData>>([])
    const [totalPurchased, setTotalPurchased] = useState(0);
    const [currency, setCurrency] = useState('');
    const [ranFirstTime, setRanFirstTime] = useState(false);
    const [selectedBundleType, setSelectedBundleType] = useState<string>("")

    useEffect(() => {
        setBundlePackages(packageSelectData.packages);

        const currency = SERVICES.getCurrency();
        setCurrency(currency || '');

        const currentUser = SERVICES.getUser();
        const ownerId = currentUser ? currentUser.id : 0;

        const config = {
            params: {ownerId}
        }

        axios.get(`${Constants.BASE_URL}/users/api/v1/bundle/metrics`, config)
            .then(res => {
                const response = res.data;
                const responseData = response?.data;

                if (responseData && Array.isArray(responseData) && responseData.length > 0) {
                    setBundleMetrics(responseData);
                }
            }).catch(err => {
            // todo: handle exception
        })
    }, []);

    function resetBundleQuantities() {
        const bundleTypeAndQuantities = bundleMetrics.map(e => {
            const bundle = e.bundle;
            const bundleType = bundle?.bundleType;
            const bundleTypeValue = bundleType?.value;

            const bundleMinUsers = bundle?.minUsers || BUNDLE_QUANTITY_DEFAULTS[bundleTypeValue as BUNDLE_TYPE]?.minUsers || 0;
            const bundleMaxUsers = bundle?.maxUsers || BUNDLE_QUANTITY_DEFAULTS[bundleTypeValue as BUNDLE_TYPE]?.maxUsers || 0;
            return {bundleType: bundleTypeValue, quantity: bundleMinUsers, minUsers: bundleMinUsers, maxUsers: bundleMaxUsers};
        });

        dispatch(resetBundleQuantity(bundleTypeAndQuantities));
    }

    useEffect(() => {
        resetBundleQuantities();
    }, [bundleMetrics]);

    useEffect(() => {
        resetBundleQuantities();
    }, [selectedBundleType])

    const reCalculateOrderAmount = () => {
        if (bundleTypeAndQuantities && Array.isArray(bundleTypeAndQuantities) && bundleTypeAndQuantities.length > 0) {
            const bundleTypeAndQuantity = bundleTypeAndQuantities.find(bundleTypeAndQuantity => bundleTypeAndQuantity.bundleType === selectedBundleType);
            const bundle = bundleMetrics.find(bundleMetric => bundleMetric.bundle.bundleType.value === selectedBundleType)?.bundle;
            const bundleWithQuantity = {
                ...bundle,
                quantity: bundleTypeAndQuantity?.quantity || 0
            }

            let sum = 0;
            if (bundleWithQuantity) {
                const currentPricing = bundleWithQuantity.pricing;
                const cost = currentPricing ? currentPricing.cost : 0;

                sum += (cost * bundleWithQuantity.quantity);
            }
            setTotalPurchased(sum);
        }
    }

    useEffect(() => {
        reCalculateOrderAmount();
    }, [bundleTypeAndQuantities]);

    const isAllOfTheQuantityZero = () => {
       return selectedBundleType === "";
    }

    const purchaseBundle = () => {
        const currentUser = SERVICES.getUser();
        const ownerId = currentUser?.id;

        const bundleRequest = {
            ownerId,
            bundleTypeAndQuantityList: bundleTypeAndQuantities.map((typeAndQuantity: { bundleType: string, minUsers?: number, maxUsers?: number, quantity: number }) => {
                delete typeAndQuantity.maxUsers;
                delete typeAndQuantity.minUsers;

                if (typeAndQuantity.bundleType === selectedBundleType) {
                    return typeAndQuantity
                } else {
                    return {...typeAndQuantity, quantity: 0}
                }
            })
        }

        axios.post(`${Constants.BASE_URL}/users/api/v1/bundle/purchase/order/summary`, bundleRequest)
            .then(res => {
                const response = res.data;
                const responseData = response?.data;

                // @ts-ignore
                history.push({
                    pathname: '/package-order-summary',
                    state: {
                        currency,
                        summary: responseData,
                        referer: 'membership-plan'
                    }
                });
            }).catch(err => {
            // handle exception
        })
    }


    return (
        <section className={'membership-plan py-5'}>
            <h3 style={{fontSize: '1.6rem'}}>Select a plan and the number of seats you require</h3>
            <div className="position-relative d-flex justify-content-between" style={{marginTop: '50px'}}>
                {
                    bundleMetrics && Array.isArray(bundleMetrics) && bundleMetrics.map((bundleMetric, index) => {
                        return (
                            <MembershipPlanCard
                                selected={selectedBundleType === bundleMetric.bundle.bundleType.value}
                                key={index}
                                bundleMetric={bundleMetric}
                                bundleTypeAndQuantities={bundleTypeAndQuantities}
                                onSelected={() => setSelectedBundleType(bundleMetric.bundle.bundleType.value)}
                            />
                        )
                    })
                }
            </div>
            <div className="row" style={{marginTop: '100px', display: (bundleMetrics.length > 0) ? 'flex' : 'none'}}>
                <div className="col-6">

                </div>
                <div className="col-6 d-flex justify-content-end">
                    <div className={'me-5'}>
                        <p style={{
                            textDecoration: 'underline',
                            color: '#262626',
                            fontWeight: 600,
                            fontSize: '1.3rem'
                        }}>Total + VAT</p>
                        <p className={'text-primary'}
                           style={{textDecoration: 'underline', fontWeight: 700, fontSize: '1.4rem'}}>
                            <span>{currency}</span>.<span>{totalPurchased.toLocaleString()}</span>
                        </p>
                    </div>
                    <div className={'d-flex align-items-end'}>
                        <button
                            className="btn btn-primary text-uppercase confirm-order"
                            disabled={isAllOfTheQuantityZero()}
                            onClick={purchaseBundle}
                        >
                            confirm order
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MembershipPlan
