/**
 * Project: spaces
 * File: Toast
 * Created by Pennycodes on 2/5/2022.
 * Copyright spaces
 */
import {Spaces} from "./Spaces";
import {getById} from "../utils/DOMHelpers";

class Toast {
    toastCloseButton: NodeListOf<Element>
    toastTapToClose: NodeListOf<Element>
    toastBoxes: NodeListOf<Element>

    constructor() {
        this.toastCloseButton = Spaces.selectAll('.toast-box .close-button')
        this.toastTapToClose = Spaces.selectAll('.toast-box.tap-to-close')
        this.toastBoxes = Spaces.selectAll('.toast-box')
        this._init()
    }

    private _init () {
        // close button toast
        const closeToastBox = this.closeToastBox
        this.toastCloseButton.forEach(function (el) {
            el.addEventListener("click", function (e) {
                e.preventDefault();
                closeToastBox()
            })
        })
// tap to close toast
        this.toastTapToClose.forEach(function (el) {
            el.addEventListener("click", function (_e) {
                closeToastBox();
            })
        })

    }

    private closeToastBox () {
        console.log('dddd', this.toastBoxes)
        this.toastBoxes.forEach(function (el) {
            el.classList.remove("show")
        })
    }

    public showToast(target: string, time?: number) {
        const a = getById(target)
        const toast = new Toast()
        toast.closeToastBox()
        setTimeout(() => {
            // @ts-ignore
            a.classList.add("show")
        }, 100);
        if (time) {
            time = time + 100;
            setTimeout(() => {
                toast.closeToastBox()
            }, time);
        }

    }

    public static bootstrap() {
        new Toast()
    }
}

export {Toast}
