import React from "react";
import {FaTrashAlt} from "react-icons/fa";

const imgWithClick = { cursor: "pointer" };

const Photo = ({ index, onClick, photo, margin, direction, top, left }) => {
  console.log('photo object:', photo);

  const { id, src, alt, removeImage } = photo;

  const imgStyle = { marginRight: '10px', objectFit: 'cover', borderRadius: '6px' };
  if (direction === "column") {
    imgStyle.position = "absolute";
    imgStyle.left = left;
    imgStyle.top = top;
  }

  const handleClick = event => {
    onClick(event, { photo, index });
  };


  return (
    <div className='parent visible'>
      <FaTrashAlt
        onClick={(event) => {
          removeImage(photo);
        }}
        style={{ position: "relative", color: "grey", top: '10px' }}
      />
      <div className='sortable-image-container' style={{marginBottom: '10px'}}>
        <img
          style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
          onClick={onClick ? handleClick : null}
          src={src}
          width={100}
          height={100}
          alt={alt}
        />
      </div>
    </div>
  );
};

export default Photo;
