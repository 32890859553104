import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import configureStore from './redux/configureStore';
import axios from 'axios';
import { Constants } from './utils/constants';
import MobileLayout from './screens/MobileLayout';
import DesktopLayout from './screens/DesktopLayout';
import { isMobile } from 'react-device-detect';
import moment from 'moment-timezone';

import { SnackbarProvider } from 'notistack';

const { store, persistor } = configureStore();

const App = () => {
  useEffect(() => {
    moment.tz.setDefault('Africa/Nairobi');
    console.log('current date:', moment(new Date()).format('yyyy-MM-DDTHH:mm:ss'));

    // Get 'keywords' meta element
    const keywordsMeta = document.querySelector(`meta[name='keywords']`);

    if (keywordsMeta) {
      const resourceUrl = `${Constants.BASE_URL}/booking/api/v1/listings/tags`;

      axios.get(resourceUrl).then((res) => {
        const data = res.data.data;

        // Get all tag texts
        const tagTexts = data.map((tag) => tag.text);

        // Get the current content of the 'keywords' meta element
        const content = keywordsMeta.content;

        // Convert keyword content to a list of strings
        const existingKeywords = content ? content.split(',') : '';

        // Create a Set to contain all tag keywords
        const tags = new Set(existingKeywords);

        // Add all tags from the server to the set; duplicates will be deduplicated
        tagTexts.forEach((item) => tags.add(item));

        // Convert the set of tags to a string containing commas and update the keywords meta element
        keywordsMeta.content = Array.from(tags).join(',');
      });
    }

    import('bootstrap');
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
      <SnackbarProvider
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      autoHideDuration={3000}
      maxSnack={2} // You can adjust the maximum number of displayed notifications
    >

        {isMobile ? <MobileLayout /> : <DesktopLayout />}
    </SnackbarProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
