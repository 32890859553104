import {toast, ToastContainer} from "react-toastify";
import point from "../../../../assets/img/png/point.png";
import {TextField} from "../../../../components";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {Constants} from "../../../../utils/constants";
import axios from "axios";
import { ReportIssueStyles as Section } from "./styles";
import Styles from '../../../../components/TextField/styles';

const ReportIssue = () => {

    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [msisdn, setMsisdn] = useState('');
    const [description, setDescription] = useState('');

    interface IssueDetails {
        name: string;
        email: string;
        phoneNumber: string;
        problemDescription: string;
    }

    const triggerReportIssueEmail = (event: any): void => {
        event.preventDefault();

        const issueDetails: IssueDetails = {
            name: fullName,
            email,
            phoneNumber: msisdn,
            problemDescription: description
        }

        const resourceUrl = `${Constants.BASE_URL}/platform/company/api/v1/issue/report`;
        axios.post(resourceUrl, issueDetails)
            .then(res => {
                if (res.status === 200) {
                    toast.success('We have received your submission',
                        { theme: 'colored' });

                    setFullName('');
                    setEmail('');
                    setMsisdn('');
                    setDescription('');
                }
            })
            .catch(err => {
                toast.error('Sorry, error submitting your information', { theme: 'colored'});
            })
    }

    return (
        <Section>
            <div id="CTA">
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{minWidth: '350px'}}
                />
                <div className="content">
                    <img src={point} alt="" />
                    <div className="form">
                        <h2>Report a technical problem</h2>
                        {/*<h2>Get your team on Spacia</h2>*/}
                        <form onSubmit={triggerReportIssueEmail}>
                            <TextField
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                                label="Name *"
                                placeholder="Full Name *"
                            />
                            <TextField
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                label="Email Address *"
                                placeholder="example@gmail.com"
                            />
                            <TextField
                                value={msisdn}
                                onChange={(e) => setMsisdn(e.target.value)}
                                label="Phone Number *"
                                placeholder="Phone number"
                            />
                            <Styles>
                                <label htmlFor="description">Problem Description *</label>
                                <textarea id='description' rows={4} value={description} style={{resize: 'none'}}
                                onChange={e => setDescription(e.target.value)}/>
                            </Styles>
                            <button className="button" type="submit">
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </Section>
    )
}

export default ReportIssue
