import styled from 'styled-components';
import InformationModal from "../../components/informationModal";

export const ResetPasswordModal = styled(InformationModal)`
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 600px;
    }
  }
`
