import styled from "styled-components";
import { colors, functions, snippets } from "../../../../styles";
import bannerCover from "../../../../assets/img/png/banner_cover.png";


const Styles = styled.div`
  @font-face {
    font-family: "ProximaBold";
    src: local("ProximaBold"),
    url("../../../../assets/fonts/ProximaNova-Bold.woff") format("woff");
  }

  @font-face {
    font-family: "ProximaRegular";
    src: local("ProximaRegular"),
    url("../../../../assets/fonts/ProximaNova-Regular.woff") format("woff");
  }

  * {
    font-size: 14px;
    font-family: 'ProximaRegular' sans-serif;
  }

  #Banner {
    height: 70rem;
    background-image: url(${bannerCover});
    padding: 90px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: ${functions.rgba(colors.black, 0.5)};
      width: 100%;
      height: 100%;
      z-index: 0;
    }

    & > * {
      z-index: 1;
      position: relative;
    }

    .nav-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 1;

      .wrapper {
        display: flex;
        gap: 70px;
        align-items: center;

        & > * {
          color: ${colors.white};
          font-weight: bold;
          font-family: 'ProximaBold' sans-serif;
        }

        h1 {
          font-size: 48px;
        }

        h3 {
          font-size: 18px;
        }

        .login:hover {
          cursor: pointer
        }
      }
    }

    .main-content {
      ${snippets.absoluteCenter}
      ${snippets.flexCenter}
      gap: 40px;

      h1, p {
        color: ${colors.white};
        text-align: center;
      }

      h1 {
        font-style: normal;
        font-weight: 900;
        font-size: 39.5038px;
        line-height: 61px;
        text-align: center;
        letter-spacing: 0.153462px;
        font-family: 'ProximaBold' sans-serif !important;
      }

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        letter-spacing: 0.153462px;
      }
    }
  }

  #Cards {
    background-color: ${colors.white};
    padding: 100px;
    position: relative;
    z-index: 10;

    .content {
      position: absolute;
      bottom: 70%;
      left: 50%;
      transform: translateX(-50%);
      width: 80%;
      max-width: 120rem;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 30px;

      .card {
        background-color: ${colors.white};
        box-shadow: 0px 13px 19px rgba(0, 0, 0, 0.07);
        padding: 40px;
        padding-bottom: 80px;

        &.primary {
          background-color: ${colors.primary};

          h2, p {
            color: ${colors.white};
          }
        }

        h2 {
          color: ${colors.grey4};
          margin-bottom: 16px;
          font-size: 1.25rem;
          font-family: 'ProximaBold' sans-serif !important;
        }

        p {
          color: ${colors.grey5};
          font-size: .9rem;
        }
      }
    }
  }

  #CTA {
    background-color: ${colors.grey1};
    padding: 64px;
    margin-top: -70px;

    .content {
      padding: 40px;
      display: flex;
      width: 70%;
      max-width: 70rem;
      margin: 0 auto;
      gap: 40px;
      background-color: ${colors.white};

      img {
        margin-botttom: -40px;
        margin-left: -40px;
      }

      .form {
        flex: 1;

        h2 {
          color: ${colors.grey4};
          margin-bottom: 40px;
          text-align: center;
          font-size: 1.5rem;
        }

        form {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .button {
            margin-top: 30px;
          }
        }
      }
    }
  }

  #Newsletter {
    background-color: ${colors.grey1};
    padding-top: 60px;
    padding-bottom: 173px;

    .content {
      margin: 0 auto;
      width: 70%;
      max-width: 85rem;
      background-color: ${colors.primary};
      display: flex;
      padding: 30px 50px;
      align-items: center;
      justify-content: space-between;

      h2 {
        color: ${colors.white};
      }
    }
  }

  footer {
    background-color: ${colors.white};
    padding: 50px 100px;

    .content {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      gap: 200px;

      .list {
        h4 {
          color: ${colors.grey4};
          margin-bottom: 20px;
        }

        ul {
          display: flex;
          flex-direction: column;
          gap: 10px;

          li {
            color: ${colors.grey5};

            a {
              color: ${colors.grey5};
            }
          }
        }
      }

      .download-links {
        margin-top: auto;
        display: flex;
        flex-direction: column;
        gap: 10px;

        img {
          width: 150px;
        }
      }
    }
  }
`;

export default Styles;
