import {Dropdown, Image} from "react-bootstrap";
import default_avatar from "../../assets/img/avatar.png";
import {FaEllipsisH} from "react-icons/fa";
import React, {FC} from "react";
import moment from "moment-timezone";

const UserActivity: FC<any> = (
    {
        profile,
        location,
        checkInDate,
        checkOutDate,
        name
    }
) => {
    const admin = {
        backgroundColor: "#F3D5D1",
        color: "#F85A47",
        border: "none",
        fontWeight: "bold",
        borderRadius: 5,
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 20,
        paddingRight: 20,
    };

    const approver = {
        backgroundColor: "#F3D5D1",
        color: "#F99500",
        border: "none",
        fontWeight: "bold",
        borderRadius: 5,
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 20,
        paddingRight: 20,
    };

    const edit = {
        border: "none",
        backgroundColor: "transparent",
        display: "flex",
        paddingLeft: 0,
        color: "transparent",
    };

    const avatar = {
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        marginRight: "10px",
    };

    return (
        <>
            <tr>
                <td>
                    <Image
                        className="rounded-circle mr-2"
                        width="40px"
                        height="40px"
                        src={profile ? profile : default_avatar}
                        style={{ objectFit: 'cover' }}
                        alt="avatar" />
                    {name}
                </td>
                <td>{location}</td>

                <td className={'text-primary'}>
                    { moment(checkInDate).format('HH:mm') }
                </td>

                <td className={'text-primary'}>
                    { moment(checkOutDate).format('HH:mm') }
                </td>

                <td>
                    <Dropdown>
                        <Dropdown.Toggle color="red" style={edit}>
                            <FaEllipsisH color="black" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick = {() => {}}>Edit</Dropdown.Item>
                            {/* <Dropdown.Item onClick = {onDelete} href="#/action-2">Delete</Dropdown.Item> */}
                            <Dropdown.Item
                                onClick={() => {}}
                                href="#/action-3"
                            >
                                {'Enable'}
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => {}} href="#/action-3">
                                Reset Password
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </td>
            </tr>
        </>
    );
}

export default UserActivity