import React, {useRef, useState} from "react";
import Interactive from "../Interactive";
import InteractiveOption from "./InteractiveOption";
import {Dropdown} from "react-bootstrap";
import {Link, useHistory} from "react-router-dom";
import {FaEllipsisH} from "react-icons/fa";
const Stay = () => {
	const [dropdown1, setdropdown1] = useState("Serviced Apartments");
	const [dropdown2, setdropdown2] = useState("Studio");
	const [hotdesk, sethotdesk] = useState("Hot Hour");
	const [meeting, setmeeting] = useState("Board Room");

	let history = useHistory();

	const workSpaceDropdownElement = useRef(null);
	const hotelDropdownElement = useRef(null);
	const servicedApartmentsDropdownElement = useRef(null);

	const submit =()=>{
		localStorage.setItem('Workspace', dropdown1);
		localStorage.setItem('addSpec', dropdown2);
		history.push("/listpropertyform")
	}
	return (
		<div>
			<Interactive title="What type of Property would you like to visit">
				<div>
					<h6 className="flatText">Type of Workspace</h6>
					<div className="selectCard" style={{cursor: 'pointer'}} onClick={() => workSpaceDropdownElement.current.click()}>
						<h6 className="flatText">{dropdown1}</h6>

						<Dropdown>
							<Dropdown.Toggle
								ref={workSpaceDropdownElement}
								style={{
									backgroundColor: "transparent",
									color: "black",
									border: "none",
								}}
								id="dropdown-basic"
							></Dropdown.Toggle>

							<Dropdown.Menu>
								<Dropdown.Item
									onClick={() =>{
										setdropdown1("Serviced Apartments")
										setdropdown2("Studio")
									}
									}
									href="#/action-2"
								>	
									Serviced Apartments
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => {
										setdropdown1("Hotel")
										setdropdown2("Standard")
									}}
									href="#/action-3"
								>
									Hotel
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => {
										setdropdown1("Bed & Breakfast")
									}}
									href="#/action-3"
								>
									Bed & Breakfast
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => {
										setdropdown1("Homestays")
									}}
									href="#/action-3"
								>
									Homestays
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</div>

				<br />

				{dropdown1 === "Hotel" && (
					<div>
						<h6 className="flatText">Additional Specification</h6>
						<div className="selectCard" style={{cursor: 'pointer'}} onClick={() => hotelDropdownElement.current.click()}>
							<h6 className="flatText">{dropdown2}</h6>

							<Dropdown>
								<Dropdown.Toggle
									ref={hotelDropdownElement}
									style={{
										backgroundColor: "transparent",
										border: "none",
										color: "black",
									}}
									id="dropdown-basic"
								>
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<Dropdown.Item
										onClick={() => setdropdown2("Standard")}
										href="#/action-2"
									>
										Standard
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() => setdropdown2("Junior Suite")}
										href="#/action-3"
									>
										Junior Suite
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() =>
											setdropdown2("Executive Suite")
										}
										href="#/action-3"
									>
										Executive Suite
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>
				)}

			{dropdown1 === "Serviced Apartments" && (
					<div>
						<h6 className="flatText">Additional Specification</h6>
						<div className="selectCard" style={{cursor: 'pointer'}} onClick={() => servicedApartmentsDropdownElement.current.click()}>
							<h6 className="flatText">{dropdown2}</h6>

							<Dropdown>
								<Dropdown.Toggle
									ref={servicedApartmentsDropdownElement}
									style={{
										backgroundColor: "transparent",
										border: "none",
										color: "black",
									}}
									id="dropdown-basic"
								>
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<Dropdown.Item
										onClick={() => setdropdown2("Studio")}
										href="#/action-2"
									>
										Studio
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() => setdropdown2("1 Bedroom")}
										href="#/action-3"
									>
										1 Bedroom
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() =>
											setdropdown2("2 Bedroom")
										}
										href="#/action-3"
									>
										2 Bedroom
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() =>
											setdropdown2("3 Bedroom")
										}
										href="#/action-3"
									>
										3 Bedroom
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() =>
											setdropdown2("Penthouse")
										}
										href="#/action-3"
									>
										Penthouse
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>
				)}

				

{/* <div>
					<h6 className="flatText">Additional Specification</h6>
					<div className="selectCard">
						<h6 className="flatText" style={{color:'grey'}}>None</h6>
					</div>
				</div> */}
				

				{/* Dropdown 2 */}

				<Link onClick={submit}>
					<button className="ListPropBtn">Continue</button>
				</Link>
			</Interactive>
		</div>
	);
};

export default Stay;
