import React ,{useEffect, useState}from "react";
import {Container, Row} from "react-bootstrap";
import bg from "../../../assets/img/spacia/web/Background.png";
import logo from "../../../assets/img/spacia/web/spacia_logo.png";
import axios from 'axios'
import { Constants } from '../../../utils/constants';



const GoToMail = () => {
	const bgImage = {
		width: "100vw",
		height: "100vh",
		background: "white",
		backgroundAttachment: "fixed",
		backgroundPosition: "center",
		backgroundSize: "cover",
		position: "relative",
		// boxShadow: '0 4 38 rgba(0, 0, 0, 0.06)',
		backgroundImage: `url(${bg})`,
	};
	const formCard = {
		width: "35%",
		position: "absolute",
		right: "10vw",
		top: "10vh",
		backgroundColor: "white",
		padding: 30,
		// paddingBottom:50,
		// boxShadow: '0px 4px 38px rgba(0, 0, 0, 0.06);',
		boxShadow: "0px 4px 38px #000000, 6%",
	};

	const logopos = {
		position: "relative",
		left: "5vw",
		top: "5vh",
	};

	const googleButton = {
		display: "flex",
		width: "100%",
		border: "1px solid #D9D9D9",
		padding: 10,
		borderRadius: 10,
		backgroundColor: "white",
	};


	const [user, setuser] = useState('')


	const resetButton = () => {
	console.log(user)
	axios.post(`${Constants.BASE_URL}/users/api/v1/users/reset-password?username=${encodeURIComponent(user.username)}`,
	{ params: {  } })
	.then(
		(res) => {
			console.log(res)
		}
	)
	}


	return (
		<Row style={bgImage}>
			{/* <div > */}
			<div style={logopos}>
				<img src={logo} alt="logo" />
			</div>
			<div style={formCard}>
				<Container>
					<h5>Password reset request submitted</h5>
					<br />
					<hr/>
					<h6>
						Please go to your mail to complete your password reset
					</h6>

					<br />
					<br />
					<br />
					<div style={{margin: "auto", textAlign: "center"}}>
						{/* <button className="button"  onClick={resetButton} style={{width: "100%"}}>
							Activate Account
						</button> */}
					</div>
				</Container>
			</div>
			{/* </div> */}
		</Row>
	);
};

export default GoToMail;
