import React, { useEffect, useState } from "react";
import {PageTitle} from "../../../../layout/PageData";
import {InputTags} from "react-bootstrap-tagsinput";
import SERVICES from "../../../../services";
import {Constants} from "../../../../utils/constants";
import axios from "axios";
import {Modal} from "react-bootstrap";
import closeButton from "../../../../assets/img/svg/blue-close-button.svg";
import successImage from "../../../../assets/img/svg/success-white-background.svg";
import warningImage from "../../../../assets/img/svg/red-warning.svg";
import {useHistory} from "react-router-dom";

const InviteUser = () => {
    const [state, setState] = useState([]);
    const [role, setRole] = useState("");
    const [currentUserRole, setCurrentUserRole] = useState('');
    const [subAccountRoles, setSubAccountRoles] = useState([]);

    const [noAddedEmail, setNoAddedEmail] = useState(false);
    const [isRoleNotSelected, setIsRoleNotSelected] = useState(false);

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);

    const history = useHistory();

    useEffect(() => {
        const currentUser = SERVICES.getUser();
        const currentUserRole = currentUser?.role;
        const id = currentUser ? currentUser.id : 0;

        console.log('current user role:', currentUserRole);

        setCurrentUserRole(currentUserRole);
        // setCurrentUserId(id);
    }, []);

    useEffect(() => {
        console.log('state holding email of invitees:', state);
    }, [state]);

    useEffect(() => {
        switch (currentUserRole) {
            case 'ROLE_SUBSCRIBER_OWNER':
                // set value and label
                const ownerSubAccounts = [
                    // {
                    // 	value: 'ROLE_SUBSCRIBER_APPROVER',
                    // 	label: 'Approver'
                    // },
                    // {
                    // 	value: 'ROLE_SUBSCRIBER_INDIVIDUAL',
                    // 	label: 'Regular'
                    // },
                    {
                        value: 'ROLE_APPROVER_EMPLOYEE',
                        label: 'Administrator (can book meetings)'
                    },
                    {
                        value: 'ROLE_EMPLOYEE',
                        label: 'Employee'
                    }
                ];

                setSubAccountRoles(ownerSubAccounts);
                break;

            case 'ROLE_HOST':
                // set value and label
                const hostSubAccounts = [
                    {
                        value: 'ROLE_HOST_OPERATIONS',
                        label: 'Operations Account'
                    }
                ]
                setSubAccountRoles(hostSubAccounts);
                break;

            case 'ROLE_ADMINISTRATOR':
                // set value and label
                const adminSubAccounts = [
                    {
                        value: 'ROLE_SUBSCRIBER_OWNER',
                        label: 'Corporate Admin'
                    }
                ]
                setSubAccountRoles(adminSubAccounts);
        }
    }, [currentUserRole]);

    const areFieldsOkay = () => {
        const requiredFields = [role];
        // is any of the fields empty
        const anyEmpty = requiredFields.some(field => !field) || state.length === 0;
        if (anyEmpty) {
            if (!role) {
                setIsRoleNotSelected(true);
            }
            if (state.length === 0) {
                setNoAddedEmail(true);
            }
            return false;
        }  else {
            return true;
        }

    }

    const inviteUser = (e) => {
        e.preventDefault();

        if (!areFieldsOkay()) return;

        const user = SERVICES.getUser();

        const inviterId = user ? user.id : 0;

        let emailsOfInvitees = state;

        if(!emailsOfInvitees.length){
            emailsOfInvitees = Array
              .from(document.querySelectorAll('input[data-testid=input-tags]'))
              .map(o=>o.value)
              .filter(o=>!!o);
        }

        let localUrl = `${Constants.BASE_URL}/users/api/v1/users/invite`;

        axios
          .post(localUrl, {
              emailsOfInvitees,
              inviterId,
              role: role
          })
          .then((res) => {
              const response = res.data;
              const responseError = response?.errors;

              const hasError = (responseError != null) && (responseError.length > 0);
              // if (hasError) {
              //     const errorMessage = responseError[0].message;
              //
              //     setErrorAlert(true);
              //     setErrorMsg(errorMessage);
              // }
              //
              // setShow(false);
              // setConfirmUserAddition(true);

              const addUserContainer = document.querySelector('.add-user-page-content');
              if (addUserContainer != null) {
                  addUserContainer.classList.add('blur-background');
              }

              setShowSuccessModal(true);
          })
          .catch((err) => {
                console.log(err);

                const errorResponse = err.response;
                const data = errorResponse?.data;
                const errors = data?.errors;

                console.log(errors);

                // setErrorAlert(true);
                //
                // if (errors && Array.isArray(errors) && errors.length > 0) {
                //     const { message } = errors[0];
                //     setErrorMsg(message);
                // }
                //
                // setShow(false);
                // setUnableToConfirmUserAddition(true)

              const addUserContainer = document.querySelector('.add-user-page-content');
              if (addUserContainer != null) {
                  addUserContainer.classList.add('blur-background');
              }

              setShowErrorModal(true);
            }
          );
    };

    const handleRoleChange = (e) => {
        setRole(e.target.value);
    };

    const handleStuffOnModalHide = (showModal) => {
        showModal(false);

        const addUserContainer = document.querySelector('.add-user-page-content');
        if (addUserContainer != null) {
            addUserContainer.classList.remove('blur-background');
        }
    }

    return (
        <div className='mobile-view add-user-page' style={{background: "#FCFCFC"}}>
            <div className="section mt-2 p-3">
                <PageTitle>Users</PageTitle>
                <div className="mt-3 add-user-page-content">
                    <p className='text-dark' style={{fontSize: '1.4rem', fontWeight: 600}}>Invite New Users</p>
                    <section>
                        <form onSubmit={inviteUser}>
                            <div className='mt-4'>
                                <label className="text-dark" htmlFor="" style={{fontSize: '.9rem'}}>
                                    Email Address
                                </label>
                                {/* <p>{state}</p> */}
                                <div id='input-tags'>
                                    <div style={{margin: 0, background: "#DDDDDD"}}>
                                        <InputTags
                                            style={{backgroundColor: "#DDDDDD", fontSize: 14}}
                                            values={state}
                                            onTags={(value) => setState(value.values)}
                                        />
                                    </div>
                                    <div style={{
                                        display: (noAddedEmail) ? 'block' : 'none',
                                        fontSize: '0.875em',
                                        marginTop: '0.25rem',
                                        color: '#dc3545'
                                    }}>Please enter an email address &lt;followed by space or enter&gt;</div>

                                </div>
                            </div>
                            <div className='form-group' style={{marginTop: '40px'}}>
                                <label
                                  className="text-dark"
                                  style={{ fontSize: '.9rem' }}
                                  htmlFor="role"
                                >
                                    Role
                                </label>
                                <select
                                  className={ isRoleNotSelected ? "form-select is-invalid" : "form-select  " }
                                  name="role"
                                  id="role"
                                  value={role}
                                  onChange={handleRoleChange}
                                >
                                    <option value={""} disabled>Select Role</option>
                                    {
                                      subAccountRoles &&
                                      subAccountRoles.map((role, index) => {
                                          return <option key={index} value={role.value}>{role.label}</option>
                                      })
                                    }
                                </select>
                                <div className="invalid-feedback">Please select role</div>
                            </div>
                            <div className={'mt-5 text-end'}>
                                <button className="button" type="submit">Invite Users</button>
                            </div>
                        </form>
                    </section>
                </div>

            </div>

            {/* define modals */}
            {/*Success Modal*/}
            <Modal show={showSuccessModal} onHide={() => handleStuffOnModalHide(setShowSuccessModal)} onEnter={e => console.log(e)}>
                <header className={'position-relative d-flex align-items-center'} style={{zIndex: '3'}}>
                    <p className={'text-black text-center font-weight-bolder mb-0 text-capitalize'} style={{fontSize: '1.55rem', width: '90%'}}>Sent</p>
                    <figure className="text-right mb-0" style={{padding: '15px'}}>
                        <img onClick={() => handleStuffOnModalHide(setShowSuccessModal)} src={closeButton} alt="close button" style={{width: '25px'}}/>
                    </figure>
                </header>
                <Modal.Body style={{height: '265px', textAlign: 'center'}}>
                    <div className={'mx-auto'} style={{width: '80%'}}>
                        <p style={{fontSize: '1.1rem'}}>
                            User invitation(s) sent
                        </p>
                        <figure>
                            <img src={successImage} alt="success image"/>
                        </figure>
                        <div className={'mt-5 text-center'}>
                            <button
                              className={'btn text-black text-uppercase'}
                              style={{cursor: 'pointer', width: '120px', background: '#F7F7F7', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.4)', borderRadius: '15px'}}
                              onClick={() => history.push('/user-management')}
                            >
                                ok
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/*Error Modal*/}
            <Modal show={showErrorModal} onHide={() => handleStuffOnModalHide(setShowErrorModal)} onEnter={e => console.log(e)}>
                <header className={'position-relative d-flex align-items-center'} style={{zIndex: '3'}}>
                    <p className={'text-black text-center font-weight-bolder mb-0'} style={{fontSize: '1.55rem', width: '90%'}}>Sorry</p>
                    <figure className="text-right mb-0" style={{padding: '15px'}}>
                        <img onClick={() => handleStuffOnModalHide(setShowErrorModal)} src={closeButton} alt="close button" style={{width: '25px'}}/>
                    </figure>
                </header>
                <Modal.Body style={{height: '280px', textAlign: 'center'}}>
                    <div className={'mx-auto'} style={{width: '80%'}}>
                        <p style={{fontSize: '1.1rem'}}>
                            Whoa! something went wrong.
                        </p>
                        <p style={{fontSize: '1.1rem'}}>
                            Kindly try again
                        </p>
                        <figure>
                            <img src={warningImage} alt="warning image"/>
                        </figure>
                        <div className={'mt-4 text-center'}>
                            <button
                              className={'btn text-black'}
                              style={{cursor: 'pointer', width: '165px', background: '#F7F7F7', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.4)', borderRadius: '15px'}}
                              onClick={() => handleStuffOnModalHide(setShowErrorModal)}
                            >
                                Please try again
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default InviteUser