import noAvatar from '../../../../assets/img/png/no-avatar.png';
import smsIcon from '../../../../assets/img/png/sms-icon.png';
import callIcon from '../../../../assets/img/png/call-icon.png';
import emailIcon from '../../../../assets/img/png/email-icon.png';
import {PageTitle} from "../../../../layout/PageData";
import React, {useEffect, useState} from "react";
import {Dropdown, Modal} from "react-bootstrap";
import {FaEllipsisH} from "react-icons/fa";
import {useHistory, useLocation} from "react-router-dom";
import closeButton from "../../../../assets/img/svg/blue-close-button.svg";
import successImage from '../../../../assets/img/svg/success-white-background.svg';
import warningImage from '../../../../assets/img/svg/red-warning.svg';
import SERVICES from "../../../../services";
import axios from "axios";
import {Constants} from "../../../../utils/constants";
import {ResetPasswordModal} from "../../../DesktopLayout/ResetPasswordModal";
import FeedbackModall from "../../../../components/FeedbackModall";
import img from "../../../../assets/img/Web/Spacia/Ellipse.png";
import check from "../../../../assets/img/Web/Spacia/check.png";

const UserProfile = () => {
  const location = useLocation();
  const history = useHistory();

  const [user, setUser] = useState({});
  const [fullName, setFullName] = useState('');
  const [msisdn, setMsisdn] = useState('');
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');
  const [avatar, setAvatar] = useState(noAvatar);
  const [role, setRole] = useState('');
  const [emailOfSelectedUser, setEmailOfSelectedUser] = useState('');

  const [selectedUser, setSelectedUser] = useState({});
  const [context, setContext] = useState({});

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [showEnableModal, setShowEnableModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showConfirmResetPasswordModal, setShowConfirmResetPasswordModal] = useState(false);

  useEffect(() => {
    const locationState = location.state;
    console.log('location state:', locationState);

    setUser(locationState);
  }, []);
  
  useEffect(() => {
    setFullName(extractFullName(user));

    const email = user?.username;
    setEmail(email || '-');

    const contacts = user?.contacts;
    // find the first mobile contact type
    let mobile = '-';
    if (contacts != null && Array.isArray(contacts)) {
      const firstMobileContactType = contacts.find(contact => contact.type === 'mobile');
      mobile = firstMobileContactType ? firstMobileContactType.value : '-';
    }

    setMsisdn(mobile);

    const enabled = user?.enabled;
    setStatus(getStatus(enabled));

    const avatar = user?.avatar;
    const updatedAvatar = avatar || noAvatar;
    setAvatar(updatedAvatar);

    const role = user?.role;
    setRole(getRole(role));

  }, [user]);

  function extractFullName(user) {
    let fullName = '-';

    const firstName = user?.firstName;
    const lastName = user?.lastName;

    if (firstName && lastName) {
      fullName = `${firstName} ${lastName}`;
    } else if (firstName) {
      fullName = firstName;
    } else if (lastName) {
      fullName = lastName;
    }

    return fullName;
  }

  function getStatus(enabled) {
    if (enabled) {
      return 'Active';
    } else {
      return 'Disabled';
    }
  }

  function getRole(role) {
    const roles = [
      {
        value: 'ROLE_SUBSCRIBER_INDIVIDUAL',
        label: 'Employee'
      },
      {
        value: 'ROLE_SUBSCRIBER_APPROVER',
        label: 'Approver'
      },
      {
        value: 'ROLE_HOST_OPERATIONS',
        label: 'Operations'
      },
      {
        value: 'ROLE_SUBSCRIBER_OWNER',
        label: 'Corporate Admin'
      },
      {
        value: 'ROLE_HOST',
        label: 'Host'
      }
    ]

    let updatedRole = '-';

    if (role != null) {
      const firstMatchingRole = roles.find(e => e.value === role);
      updatedRole = firstMatchingRole.label;
    }

    return updatedRole;
  }

  const handleStuffOnModalHide = (showModal) => {
    showModal(false);

    const userProfileContainer = document.querySelector('.user-profile');
    if (userProfileContainer != null) {
      userProfileContainer.classList.remove('blur-background');
    }
  }

  const closeResetPasswordModal = () => {
    setShowResetPasswordModal(false);
  };

  const confirmResetPassword = () => {
    const emailOfUser = emailOfSelectedUser || '';
    console.log('email of selected user:', emailOfUser);

    axios.post(`${Constants.BASE_URL}/users/api/v1/users/reset-password?username=${encodeURIComponent(emailOfUser)}`,
      { params: {  } })
      .then((res) => {
        // email sent successfully
        setShowResetPasswordModal(false);

        setShowConfirmResetPasswordModal(true);
      })
      .catch((err)=>{
        console.log(err.response.data);
        const errorObj = err.response.data;

        const errors = errorObj ? errorObj.errors : [];
        const index0 = (errors.length > 0) ? errors[0] : null;

        const errorMsg = index0 ? index0.message : 'An error occurred. Please try again.';


        setShowErrorModal(true);
      })
  };

  const resetUserPassword = (user) => {
    setShowResetPasswordModal(true);

    const email = user?.username;

    console.log('about to set email of selected user. email: [ ' + email + ' ]');
    setEmailOfSelectedUser(email);
  };

  const onRemoveUserSelected = () => {
    setShowDeleteModal(true);
    setSelectedUser(user);

    const userProfileContainer = document.querySelector('.user-profile');
    if (userProfileContainer != null) {
      userProfileContainer.classList.add('blur-background');
    }
  }

  const onDisableUserSelected = () => {
    setShowDisableModal(true);
    setSelectedUser(user);

    const userProfileContainer = document.querySelector('.user-profile');
    if (userProfileContainer != null) {
      userProfileContainer.classList.add('blur-background');
    }
  }

  const onEnableUserSelected = () => {
    setShowEnableModal(true);
    setSelectedUser(user);

    const userProfileContainer = document.querySelector('.user-profile');
    if (userProfileContainer != null) {
      userProfileContainer.classList.add('blur-background');
    }
  }

  const confirmDelete = () => {
    handleStuffOnModalHide(setShowDeleteModal);

    const currentUser = SERVICES.getUser();
    const ownerId = currentUser ? currentUser.id : 0;

    const idOfUserToDelete = selectedUser ? selectedUser.id : 0;

    axios
      .delete(`${Constants.BASE_URL}/users/api/v1/users/delete-by-id/${idOfUserToDelete}`, { params: { ownerId }})
      .then((res) => {
        setContext('deleted');
        setShowSuccessModal(true);
      })
      .catch(err => {
        setShowErrorModal(true);
      })
  };

  const confirmDisableUser = () => {
    handleStuffOnModalHide(setShowDisableModal);

    const currentUser = SERVICES.getUser();
    const currentUserId = currentUser ? currentUser.id : 0;

    const idOfUserToDisable = selectedUser ? selectedUser.id : 0;

    axios
      .patch(
        `${Constants.BASE_URL}/users/api/v1/users/${idOfUserToDisable}/status?active=false&ownerId=${currentUserId}`,
        { params: {} }
      ).then((res) => {
      setContext('disabled');
      setShowSuccessModal(true);

      const response = res.data;
      const responseData = response?.data;
      const user = responseData ? responseData[0] : {};

      console.log('user after disabling:', user);
      setUser(user);
    }).catch(err => {
        setShowErrorModal(true);
      })
  };

  const confirmEnableUser = () => {
    handleStuffOnModalHide(setShowEnableModal);

    const currentUser = SERVICES.getUser();
    const currentUserId = currentUser ? currentUser.id : 0;

    const idOfUserToEnable = selectedUser ? selectedUser.id : 0;

    axios
      .patch(
        `${Constants.BASE_URL}/users/api/v1/users/${idOfUserToEnable}/status?active=true&ownerId=${currentUserId}`,
        { params: {} }
      ).then((res) => {
      setContext('enabled');
      setShowSuccessModal(true);

      const response = res.data;
      const responseData = response?.data;
      const user = responseData ? responseData[0] : {};

      console.log('user after enabling:', user);
      setUser(user);
    }).catch(err => {
        setShowErrorModal(true);
      })
  };

  const showEditUserPage = () => {
    history.push({
      pathname: '/profile-edit',
      state: user
    });
  }

  return (
    <div className='mobile-view' style={{background: "#FCFCFC"}}>
      <div className="section mt-2 p-3">
        <PageTitle>Users</PageTitle>
      </div>
      <div className={'user-profile'}>
        <div className={'text-center position-relative'}>
          <figure>
            <img
              src={avatar}
              alt="profile image"
              style={{ width: '150px', height: '150px', borderRadius: '50%', objectFit: 'cover'}}
            />
            <div
              role='presentation'
              className={'status position-absolute'}
              style={{top: '46%', left: '62%', background: (status === 'Active') ? "#02BD04" : "#F99500"}}
            >

            </div>
          </figure>
          <div>
            <p className={'mb-0'} style={{fontSize: '1.04rem'}}>Kumasi, Ghana</p>
            <p style={{fontWeight: 600, fontSize: '1.4rem'}}>
              { fullName }
            </p>
          </div>
        </div>
        <section className={'details mt-4'}>
          <div>
            <div className={'left'}>
              <p className={'title'}>Mobile</p>
              <p className={'subtitle'}>{msisdn}</p>
            </div>
            <div className={'right'}>
              <figure>
                <a href={msisdn ? `mailto:${msisdn}` : '!'}>
                  <img src={smsIcon} alt="" style={{width: '106%', cursor: 'pointer'}}/>
                </a>
              </figure>
              <figure>
                <a href={msisdn ? `mailto:${msisdn}` : '!'}>
                </a>
                <img src={callIcon} alt="" style={{width: '106%', cursor: 'pointer'}}/>
              </figure>
            </div>
          </div>
          <div>
            <div className={'left'}>
              <p className={'title'}>Email</p>
              <p className={'subtitle'}>{ email }</p>
            </div>
            <div className={'right'}>
              <figure>
                <a href={email ? `mailto:${email}` : '!'}>
                  <img src={emailIcon} alt="" style={{width: '106%', cursor: 'pointer'}}/>
                </a>
              </figure>
            </div>
          </div>
          <div>
            <div className={'left'}>
              <p className={'title'}>Status</p>
              <p className={'subtitle'}>{ status }</p>
            </div>
            <div className={'right'}>
              <div
                role='presentation'
                className={'status'}
                style={{background: (status === 'Active') ? "#02BD04" : "#F99500"}}
              >

              </div>
            </div>
          </div>
          <div>
            <div className={'left'}>
              <p className={'title'}>Role</p>
              <p className={'subtitle'}>{role}</p>
            </div>
            <div></div>
          </div>
          <div>
            <div className={'left'}>
              <p className={'title'}>Action</p>
            </div>
            <div className={'right'}>
              <Dropdown>
                <Dropdown.Toggle
                  color="red"
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    display: "flex",
                    padding: 0,
                    color: "transparent",
                  }}>
                  <FaEllipsisH color="black" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    style={{color: '#848484'}}
                    onClick={showEditUserPage}
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{color: '#FF4B4B'}}
                    onClick={onRemoveUserSelected}
                  >
                    Remove
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{color: '#848484'}}
                    onClick={status === 'Active' ? onDisableUserSelected : onEnableUserSelected}
                  >
                    {status === 'Active' ? 'Disable' : 'Enable'}
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{color: '#848484'}}
                    onClick={() => resetUserPassword(user)}
                  >
                    Reset Password
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </section>
      </div>

      {/* define modals */}

      {/*Delete Modal*/}
      <Modal show={showDeleteModal} onHide={() => handleStuffOnModalHide(setShowDeleteModal)} onEnter={e => console.log(e)}>
        <header className={'position-relative'} style={{zIndex: '3'}}>
          <figure className="text-right mb-0" style={{padding: '15px'}}>
            <img onClick={() => handleStuffOnModalHide(setShowDeleteModal)} src={closeButton} alt="close button" style={{width: '25px'}}/>
          </figure>
        </header>
        <Modal.Body style={{height: '250px'}}>
          <div>
            <p className={'text-black font-weight-bolder mb-4'} style={{fontSize: '1.55rem'}}>Delete Confirmation</p>
            <p style={{fontSize: '1.1rem'}}>
              Are you sure you want to delete user?
            </p>
            <div className={'mt-5 text-center'}>
              <button
                className={'btn text-black text-capitalize'}
                style={{cursor: 'pointer', marginRight: '25px', width: '110px', background: '#F7F7F7', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.4)', borderRadius: '15px'}}
                onClick={() => handleStuffOnModalHide(setShowDeleteModal)}
              >
                cancel
              </button>
              <button
                className={'btn btn-primary text-white text-capitalize'}
                style={{cursor: 'pointer', width: '110px', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.4)', borderRadius: '15px'}}
                onClick={confirmDelete}
              >
                delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/*Disable Modal*/}
      <Modal show={showDisableModal} onHide={() => handleStuffOnModalHide(setShowDisableModal)} onEnter={e => console.log(e)}>
        <header className={'position-relative'} style={{zIndex: '3'}}>
          <figure className="text-right mb-0" style={{padding: '15px'}}>
            <img onClick={() => handleStuffOnModalHide(setShowDisableModal)} src={closeButton} alt="close button" style={{width: '25px'}}/>
          </figure>
        </header>
        <Modal.Body style={{height: '250px'}}>
          <div>
            <p className={'text-black font-weight-bolder mb-4'} style={{fontSize: '1.55rem'}}>Disable Confirmation</p>
            <p style={{fontSize: '1.1rem'}}>
              Are you sure you want to disable user?
            </p>
            <div className={'mt-5 text-center'}>
              <button
                className={'btn text-black text-capitalize'}
                style={{cursor: 'pointer', marginRight: '25px', width: '110px', background: '#F7F7F7', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1)', borderRadius: '15px'}}
                onClick={() => handleStuffOnModalHide(setShowDisableModal)}
              >
                cancel
              </button>
              <button
                className={'btn btn-primary text-white text-capitalize'}
                style={{cursor: 'pointer', width: '110px', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1)', borderRadius: '15px'}}
                onClick={confirmDisableUser}
              >
                disable
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/*Enable Modal*/}
      <Modal show={showEnableModal} onHide={() => handleStuffOnModalHide(setShowEnableModal)} onEnter={e => console.log(e)}>
        <header className={'position-relative'} style={{zIndex: '3'}}>
          <figure className="text-right mb-0" style={{padding: '15px'}}>
            <img onClick={() => handleStuffOnModalHide(setShowEnableModal)} src={closeButton} alt="close button" style={{width: '25px'}}/>
          </figure>
        </header>
        <Modal.Body style={{height: '250px'}}>
          <div>
            <p className={'text-black font-weight-bolder mb-4'} style={{fontSize: '1.55rem'}}>Enable Confirmation</p>
            <p style={{fontSize: '1.1rem'}}>
              Are you sure you want to enable user?
            </p>
            <div className={'mt-5 text-center'}>
              <button
                className={'btn text-black text-capitalize'}
                style={{cursor: 'pointer', marginRight: '25px', width: '110px', background: '#F7F7F7', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1)', borderRadius: '15px'}}
                onClick={() => handleStuffOnModalHide(setShowEnableModal)}
              >
                cancel
              </button>
              <button
                className={'btn btn-primary text-white text-capitalize'}
                style={{cursor: 'pointer', width: '110px', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1)', borderRadius: '15px'}}
                onClick={confirmEnableUser}
              >
                enable
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/*Success Modal*/}
      <Modal show={showSuccessModal} onHide={() => handleStuffOnModalHide(setShowSuccessModal)} onEnter={e => console.log(e)}>
        <header className={'position-relative d-flex align-items-center'} style={{zIndex: '3'}}>
          <p className={'text-black text-center font-weight-bolder mb-0 text-capitalize'} style={{fontSize: '1.55rem', width: '90%'}}>{context}</p>
          <figure className="text-right mb-0" style={{padding: '15px'}}>
            <img onClick={() => handleStuffOnModalHide(setShowSuccessModal)} src={closeButton} alt="close button" style={{width: '25px'}}/>
          </figure>
        </header>
        <Modal.Body style={{height: '265px', textAlign: 'center'}}>
          <div className={'mx-auto'} style={{width: '80%'}}>
            <p style={{fontSize: '1.1rem'}}>
              You have successfully {context} the user
            </p>
            <figure>
              <img src={successImage} alt="success image"/>
            </figure>
            <div className={'mt-5 text-center'}>
              <button
                className={'btn text-black text-uppercase'}
                style={{cursor: 'pointer', width: '120px', background: '#F7F7F7', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.4)', borderRadius: '15px'}}
                onClick={() => {
                  if (context) {
                    console.log('current context:', context);

                    switch (context) {
                      case 'deleted':
                        history.push('/user-management');
                        break;

                      case 'disabled':
                        handleStuffOnModalHide(setShowSuccessModal);
                        break;

                      case 'enabled':
                        handleStuffOnModalHide(setShowSuccessModal);
                        break;
                    }
                  }
                }}
              >
                ok
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/*Error Modal*/}
      <Modal show={showErrorModal} onHide={() => handleStuffOnModalHide(setShowErrorModal)} onEnter={e => console.log(e)}>
        <header className={'position-relative d-flex align-items-center'} style={{zIndex: '3'}}>
          <p className={'text-black text-center font-weight-bolder mb-0'} style={{fontSize: '1.55rem', width: '90%'}}>Sorry</p>
          <figure className="text-right mb-0" style={{padding: '15px'}}>
            <img onClick={() => handleStuffOnModalHide(setShowErrorModal)} src={closeButton} alt="close button" style={{width: '25px'}}/>
          </figure>
        </header>
        <Modal.Body style={{height: '280px', textAlign: 'center'}}>
          <div className={'mx-auto'} style={{width: '80%'}}>
            <p style={{fontSize: '1.1rem'}}>
              Whoa! something went wrong.
            </p>
            <p style={{fontSize: '1.1rem'}}>
              Kindly try again
            </p>
            <figure>
              <img src={warningImage} alt="warning image"/>
            </figure>
            <div className={'mt-4 text-center'}>
              <button
                className={'btn text-black'}
                style={{cursor: 'pointer', width: '165px', background: '#F7F7F7', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.4)', borderRadius: '15px'}}
                onClick={() => handleStuffOnModalHide(setShowErrorModal)}
              >
                Please try again
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Reset Password */}
      <ResetPasswordModal
        title="Confirm Reset"
        isOpen={showResetPasswordModal}
        isClose={closeResetPasswordModal}
        onSubmit={confirmResetPassword}
        acceptButton="Reset Password"
        declineButton="Cancel"
        className='reset-password'
      >
        <h6>Are you sure you want to reset password for this user</h6>
      </ResetPasswordModal>

      <FeedbackModall
        isOpen={showConfirmResetPasswordModal}
        isClose={() => setShowConfirmResetPasswordModal(false)}
        doneButton="Okay"
      >
        <img src={check} alt="check" />
        <h6 style={{ paddingTop: 10 }}>
          The user will be sent a password reset link
        </h6>
      </FeedbackModall>
      {/* End of Reset Password */}
    </div>
  )
}

export default UserProfile