import React from "react";
import Interactive from "../Interactive";
import {Link} from "react-router-dom";
import InteractiveOption from "./InteractiveOption";
import {Dropdown} from "react-bootstrap";
import {FaEllipsisH} from "react-icons/fa";
const Workspace = () => {
	const link = {
		color: "black",
		textDecoration: "none",
	};
	const linkdisabled = {
		color: "grey",
		textDecoration: "none",
	};

	const defineProperty = (data) => {
		localStorage.setItem('propertyType', data);
	}
	return (
		<div>
			<Interactive title="What type of Property would you like to visit">
				<div>
					<h6 className="flatText">
						What type of listing would you like to add
					</h6>

					<Link style={link} onClick={()=>defineProperty("WORK")} to="/listproperty/typeofworkspace">
						<div className="selectCard">
							<h6 className="flatText">Work</h6>
						</div>
					</Link>

					<Link style={link} onClick={()=>defineProperty("STAY")} to="/listproperty/stay">
						<div className="selectCard">
							<h6 className="flatText">Stay</h6>
						</div>
					</Link>

					<Link style={linkdisabled}>
						<div className="selectCard disabled">
							<h6 className="flatText">Play</h6>
						</div>
					</Link>
				</div>
			</Interactive>
		</div>
	);
};

export default Workspace;
