import styled from 'styled-components';

export const HostStyles = styled.div`
  div.card {
    padding-bottom: 25px;
    margin-bottom: 30px;
  }
  div.table-wrapper {
    position: relative;
  }

  div.table-wrapper ul {
    position: absolute;
    right: 0
  }
  
  td {
    vertical-align: top;
  }
`
