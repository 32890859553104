/**
 * Project: spaces
 * File: FallBackView
 * Created by Pennycodes on 2/4/2022.
 * Copyright spaces
 */
import React from "react";
import {toAbsoluteUrl} from "../helpers";

export function FallbackView() {
    return (
        <div id="loader">
            <img src={toAbsoluteUrl('img/loading-icon.png')} alt="icon" className="loading-icon"  />
        </div>

    )
}
