import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Row} from "react-bootstrap";
import {FaCreditCard, FaMobile, FaMobileAlt, FaPhone} from "react-icons/fa";
import CardPaymentTab from "../CardPaymentTab";
import MomoPaymentTab from "../MomoPaymentTab";
import {SuccessAlert} from "./alerts/SuccessAlert";
import {ErrorAlert} from "./alerts/ErrorAlert";
import {FilterPropertiesStyles} from "../../screens/DesktopLayout/FilterPropertiesStyles";
// import Checkout from "./Checkout";

const MomoPayment = () => {
	const [successAlert, setSuccessAlert] = useState(false);
	const [errorAlert, setErrorAlert] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');
	const [successMsg, setSuccessMsg] = useState('');

	return (
		<div>
			<SuccessAlert show={successAlert} message={successMsg} close={() => setSuccessAlert(false)} />
			<ErrorAlert show={errorAlert} message={errorMsg} close={() => setErrorAlert(false)} />
			<div className="strip">
				<h4>Manage Payments</h4>
				<br />
			</div>
			<div className="flexStrip" style={{marginTop: 20}}>
				<Link
					style={{textDecoration: "transparent", display: 'none'}}
					to="/settings/payment"
				>
					<div style={{width: 170}} className="paymentCard">
						<FaCreditCard style={{marginRight: 10}} />
						<h6 style={{marginBottom: 0}}>Card</h6>
					</div>
				</Link>
				<button style={{width: 200}} className="paymentCard-active">
					<FaMobileAlt style={{marginRight: 10}} />
					<h6 style={{marginBottom: 0}}>Mobile Money</h6>
				</button>
				{/* <Link className="paymentCard" style={{ textDecoration: 'none' }} to="/checkout">
                Checkout
            </Link> */}
			</div>

			<br />
			{/*  */}
			{/* <CardPaymentTab /> */}
			<MomoPaymentTab setSuccessAlert={setSuccessAlert} setErrorAlert={setErrorAlert} setSuccessMsg={setSuccessMsg} setErrorMsg={setErrorMsg}/>
		</div>
	);
};

export default MomoPayment;
