export interface BundleType {
    ordinal: number;
    value: string;
    label: string;
}

export interface BundlePricing {
    id: number;
    cost: number;
    currency: string;
    billingPeriod: string;
    createdOn: string;
    updatedOn: string;
}

export interface PropertyType {
    ordinal: number;
    value: string;
    label: string;
}

interface AccessDuration {
    periodType: string;
    duration: number;
}

export interface MainBundleOffer {
    id: number;
    propertyType: PropertyType;
    accessDuration: AccessDuration;
    allowedNumberOfCheckIns: number;
    createdOn: string;
    updatedOn: string;
}

export interface Bundle {
    id: number;
    description: string;
    bundleType: BundleType;
    pricing: BundlePricing;
    offers: Array<MainBundleOffer>;
    isPromo: boolean;
    startDate: string;
    endDate: string;
    subscribedUsers: any;
    createdOn: string;
    updatedOn: string;
    minUsers?: number;
    maxUsers?: number;
}

export interface BundleTypeAndQuantity {
    bundleType: string;
    quantity: number;
}

export interface UpdatedBundle {
    id: number;
    description: string;
    bundleType: BundleType;
    pricing: BundlePricing;
    offers: Array<MainBundleOffer>;
    isPromo: boolean;
    startDate: string;
    endDate: string;
    subscribedUsers: any;
    createdOn: string;
    updatedOn: string;
    packageFromInterface: Package;
}

export interface BundleOffer {
    workFromAnywhere: Array<string>;
    maximizeProductivity: Array<string>;
}

export interface Package {
    title: string;
    description: string;
    cost: number;
    bundleOffer: BundleOffer;
}

export interface BundleMetricData {
    bundle: Bundle;
    company: any;
    totalPurchased: number;
    totalUsedUp: number;
    totalAvailable: number;
}

export interface PackageSelectData {
    packages: Array<Package>;
}

const enterpriseBundleOffer: BundleOffer = {
    workFromAnywhere: ["Unlimited Dedicated Co-Working Spaces", "10 Hour Meeting Room Access"],
    maximizeProductivity: ["High speed Wi-Fi", "Food & Beverage Discounts", "Networking Events"],
};

const corporateBundleOffer: BundleOffer = {
    workFromAnywhere: [
        "Unlimited Dedicated Co-Working Spaces",
        "15 Hour Meeting Room Access",
    ],
    maximizeProductivity: [
        "Food & Beverage Discounts",
        "Dedicated High speed Wi-Fi",
        "Networking Events",
        "Business Address",
        "Mail Handling Services",
        "Free Printing & Scanning",
    ],
};

const corporatePlusBundleOffer: BundleOffer = {
    workFromAnywhere: [
        "Unlimited Dedicated Co-Working Spaces",
        "Private Office Access",
        "20 Hour Meeting Room Access",
    ],
    maximizeProductivity: [
        "Food & Beverage Discounts",
        "Dedicated High speed Wi-Fi",
        "Networking Events",
        "Business Address",
        "Mail Handling Services",
        "Free Printing & Scanning",
    ],
};

const enterprisePackage: Package = {
    title: "Enterprise",
    description: "For Small Businesses",
    cost: 29000,
    bundleOffer: enterpriseBundleOffer,
};

const corporatePackage: Package = {
    title: "Corporate",
    description: "For Medium to Large Businesses",
    cost: 25000,
    bundleOffer: corporateBundleOffer,
};

const corporatePlusPackage: Package = {
    title: "Corporate Plus",
    description: "For Large to Corporate Businesses",
    cost: 23000,
    bundleOffer: corporatePlusBundleOffer,
};

export const packageSelectData: PackageSelectData = {
    // packages: [enterprisePackage, corporatePackage, corporatePlusPackage]
    packages: [],
};

export const packageSelectDataSignUp: PackageSelectData = {
    packages: [enterprisePackage, corporatePackage, corporatePlusPackage],
    // packages:[]
};
