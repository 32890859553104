/**
 * Project: spaces
 * File: LayoutInit
 * Created by Pennycodes on 2/4/2022.
 * Copyright spaces
 */

import {useEffect, useRef} from 'react'
import {AddToHome} from "./assets/js/AddToHome";
import Config from "./assets/js/Config";
import {Spaces} from "./assets/js/Spaces";
import {GoBackAnimation} from "./assets/js/GoBackAnimation";
import {ServiceWorker} from "./assets/js/ServiceWorker";
import {RTL} from "./assets/js/RTL";
import {Loader} from "./assets/js/Loader";
import {Tooltip} from "./assets/js/Tooltip";
import {HREFFix} from "./assets/js/HREFFix";
import {Input} from "./assets/js/Input";
import {SearchBox} from "./assets/js/SearchBox";
import {Upload} from "./assets/js/Upload";
import {DarkMode} from "./assets/js/DarkMode";
import {Quantity} from "./assets/js/Quantity";
import {Toast} from "./assets/js/Toast";


export function LayoutInit() {
    const isFirstRun = useRef(true)

    const pluginsInitialization = () => {
        isFirstRun.current = false
        Spaces.ready(function (){
           setTimeout(()=> {
               AddToHome.bootstrap(true)
               GoBackAnimation.bootstrap()
               ServiceWorker.bootstrap()
               RTL.bootstrap()
               Loader.bootstrap()
               Tooltip.bootstrap()
               HREFFix.bootstrap()
               Input.bootstrap()
               SearchBox.bootstrap()
               Upload.bootstrap()
               Quantity.bootstrap()
               Toast.bootstrap()
               DarkMode.bootstrap()}, 500)

        })


    }
    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false
            pluginsInitialization()
        }
    }, [Config])
    return <></>;
}
