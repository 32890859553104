import styled from 'styled-components';
import { colors, functions, snippets } from "../../../../styles";

export const ReportIssueStyles = styled.section`
  #CTA {
    background-color: ${colors.grey1};
    padding: 64px;
    .content {
      padding: 40px;
      display: flex;
      width: 70%;
      max-width: 70rem;
      margin: 0 auto;
      gap: 40px;
      background-color: ${colors.white};
      img {
        margin-botttom: -40px;
        margin-left: -40px;
      }
      .form {
        flex: 1;
        h2 {
          color: ${colors.grey4};
          margin-bottom: 40px;
          text-align: center;
        }
        form {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .button {
            margin-top: 30px;
          }
        }
      }
    }
  }
`
