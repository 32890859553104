import styled from "styled-components";

export const CartStyles = styled.div`
    table {
      width: 100%;
    }
  table th {
    font-weight: normal;
  }
  
  .accept-invoice {
    padding: 10px;
    background: #017BEF;
    color: white;
    border-radius: 5px;
    width: 150px;
    border: none;
    outline: none;
  }
  
  .service-bundle {
    padding: 10px;
    background: #F99500;
    color: white;
    border-radius: 5px;
    border: none;
    outline: none
  }
  
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    margin-left: 20px;
  }
  
  header > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 32%;
    margin-right: 20px;
  }
  
  thead > tr {
    height: 100px;
    background: #f7f7f7;
    font-size: .95rem;
  }
  
  .space-type {
    width: 200px;
  }
  
  .space-type + th {
    width: 150px;
  }
  
  div.spinner-border {
    width: 15px;
    height: 15px;
  }
  
  //
  //thead > tr > th:nth-child(2 + n) {
  //  width: 14%;
  //}
  
  //.datetime {
  //  width: 50%;
  //}
`
