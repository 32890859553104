import styled from 'styled-components';

export const BookingStyles = styled.div`
  div.container {
    max-width: 100% !important;;
  }
  
  div.toast {
    position: relative;
  }

  div.toast ul.whole-block {
    margin-top: 50px;
    padding-bottom: 50px;
    position: absolute;
    right: 0
  }

  caption {
    position: relative;
    margin-bottom: 30px;
  }

  caption.first-page {
    margin-bottom: 0 !important;
  }
  caption > ul {
    margin-top: 20px;
    position: absolute;
    right: 0
  }
  
  div.dropdown > button {
    vertical-align: top !important;
    padding-top: 0;
  }
  
  .dropdown > button > svg {
    vertical-align: top !important;
  }
  
  table tr,
  table td,
  table th {
    vertical-align: top !important;
  }
  
  table th {
    font-size: 1rem
  }
  
  table td {
    font-size: 0.9rem;
  }
`
