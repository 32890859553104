import React, {FC, useEffect, useState} from "react";
import {FaPlus, FaMinus} from "react-icons/fa";
import {BundleType} from "../../../data/package-select-data";
import {useDispatch, useSelector} from "react-redux";
import {updateBundleQuantity} from "../../../redux/actions/dashboard";

interface Props {
	bundleType: BundleType,
	bundleTypeAndQuantities: any
}

const QuantityCounter: FC<Props> = ({bundleType, bundleTypeAndQuantities}) => {

	useEffect(() => {
		if (bundleTypeAndQuantities && Array.isArray(bundleTypeAndQuantities) && bundleTypeAndQuantities.length > 0 && bundleType && bundleType.value) {
			// @ts-ignore
			const findOneBundleTypeAndQuantity = bundleTypeAndQuantities.find(e => e.bundleType === bundleType.value);
			const quantityFromObject = findOneBundleTypeAndQuantity ? findOneBundleTypeAndQuantity.quantity : 0;
			const { minUsers = 0, maxUsers = 0 } = findOneBundleTypeAndQuantity;
			setCount(prevState => {
				return prevState === 0 ? quantityFromObject : prevState;
			});
			setMinCount(prevState => {
				return prevState === 0 ? minUsers : prevState;
			});
			setMaxCount(prevState => {
				return prevState === 0 ? maxUsers : prevState;
			});
		}
	}, [bundleTypeAndQuantities, bundleType]);


	const [count, setCount] = useState(0);
	const [maxCount, setMaxCount] = useState(0);
	const [minCount, setMinCount] = useState(0);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(updateBundleQuantity(
			{
				bundleType: bundleType.value,
				quantity: count
			}));
	}, [count]);

	const inline = {
		display: "flex",
		justifyContent: "space-evenly",
		borderRadius: 5,
		border: "1px solid #ECECEC",
		width: "100px",
		// padding:5,
		verticalAlign: "middle",
	};

	const iconLeft = {
		// padding:3
		borderRight: "1px solid #ECECEC",
		padding: 10,
		cursor: "pointer",
		// paddingTop: 4
	};
	const iconRight = {
		// padding:3
		borderLeft: "1px solid #ECECEC",
		padding: 10,
		cursor: "pointer",
		// paddingTop: 4
	};

	return (
		<div className='quantity-counter'>
			<div style={inline}>
				<div style={iconLeft} onClick={() => {
					// @ts-ignore
					setCount((prevState) => {
						if (prevState === minCount) {
							return prevState;
						}
						return prevState - 1;
					});

				}}>
					<FaMinus size={7} />
				</div>
				{/*<h6 style={{paddingRight:10, paddingLeft:10, paddingTop:13, marginBottom:2, fontSize:16 }} >{count}</h6>*/}
				<input
					type="text"
					style={{
						width: "35px",
						textAlign: "center",
						background: "white",
						border: "none",
						outline: "none",
					}}
					value={count}
					onChange={(e) => {
						const count = Number(e.target.value);
						if (count <= maxCount && count >= minCount) {
							setCount(count);
						}
					}}
				/>
				<div style={iconRight} onClick={() => {
					// @ts-ignore
					setCount(prevState => {
						if (prevState === maxCount) {
							return prevState;
						}
						return prevState + 1;
					})

				}
				}>
					<FaPlus size={7} />
				</div>
			</div>
		</div>
	);
};

export default QuantityCounter;
