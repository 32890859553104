import actionTypes from "../actionTypes";

export const logoutUser = () => ({
	type: actionTypes.LOGOUT_USER,
});

export const saveFilterOptions = (filterOptions) => ({
	type: actionTypes.SAVE_FILTERS,
	payload: filterOptions,
});

export const saveSelectedFilters = (selectedFilters) => ({
	type: actionTypes.SAVE_SELECTED_FILTERS,
	payload: selectedFilters,
});

export const saveCartDetails = (cartDetails) => ({
	type: actionTypes.SAVE_CART_DETAILS,
	payload: cartDetails,
});

export const addEntry = (entry) => ({
	type: actionTypes.ADD_ENTRY,
	payload: entry,
});

export const saveCurrentEntry = currentEntry => ({
    type: actionTypes.SAVE_CURRENT_ENTRY,
    payload: currentEntry
})

export const addToCartTotalAmount = currentPrice => ({
    type: actionTypes.ADD_TO_TOTAL_AMOUNT,
    payload: currentPrice
})

export const setCartTotalAmount = total => ({
	type: actionTypes.SET_TOTAL_AMOUNT,
	payload: total
})

export const resetTotalAmount = () => ({
	type: actionTypes.RESET_TOTAL_AMOUNT
})

export const setCartEntries = () => ({
    type: actionTypes.SET_ENTRIES,
    payload: []
})

export const setHasItems = (hasItems) => ({
	type: actionTypes.HAS_ITEMS,
	payload: hasItems
})

export const removeFromCartEntryList = entryId => {
	return {
		type: actionTypes.REMOVE_ENTRY,
		payload: entryId
	}
};

export const updateBundleQuantity = payload => ({
	type: actionTypes.UPDATE_BUNDLE_QUANTITY,
	payload
})

export const resetBundleQuantity = payload => ({
	type: actionTypes.RESET_BUNDLE_QUANTITY,
	payload
})

export const saveOrderSummary = (orderSummary) => ({
    type: actionTypes.SAVE_ORDER_SUMMARY,
    payload: orderSummary
})
