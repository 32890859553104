export const saveUser = (data) => {
	try {
		localStorage.setItem("currentUser", JSON.stringify(data));
	} catch (e) {
		console.log("Error saving data");
	}
};

export const getUser = () => {
	try {
		return JSON.parse(localStorage.getItem("currentUser"));
	} catch (e) {
		console.log("An error occurred while parsing string to json.");
	}
};

export const saveListingData = async (data) => {
	try {
		localStorage.setItem("listingData", JSON.stringify(data));
	} catch (e) {
		console.log("Error saving listing data");
	}
}

export const clearListingData = () => {
	try {
		localStorage.removeItem("listingData");
	} catch (e) {
		console.log("an error occurred while removing listing data");
	}
}

export const getListingData = () => {
	try {
		return JSON.parse(localStorage.getItem("listingData"));
	} catch (e) {
		console.log("An error occurred while parsing string to json");
	}
}

export const getCurrency = () => {
	try {
		return JSON.parse(localStorage.getItem("userCurrency"));
	} catch (e) {
		console.log("An error occurred while parse string to json.");
	}
};


export const setCurrency = (data) => {
	try {
		localStorage.setItem("userCurrency", JSON.stringify(data));
	} catch (e) {
		console.log("An error occurred while parse string to json.");
	}
};

export const newProperty = () => {
	try {
		localStorage.getItem("newProperty");
		console.log(localStorage.getItem("newProperty"))
	} catch (e) {
		console.log("An error occurred while parse string to json.");
	}
};


export const setnewProperty = (data) => {
	try {
		console.log(data)
		localStorage.setItem("newProperty", data);
		console.log("Item set to true")
	} catch (e) {
		console.log("An error occurred while parse string to json.");
	}
};



export const setCompanyId = (data) => {
	try {
		localStorage.setItem("companyId", JSON.stringify(data));
	} catch (e) {
		console.log("An error occurred while parse string to json.");
	}
};

export const getCompanyId = () => {
	try {
		return JSON.parse(localStorage.getItem("companyId"));
	} catch (e) {
		console.log("An error occurred while parse string to json.");
	}
};

// confirm order response
export const setConfirmOrderResponse = (data) => {
	try {
		localStorage.setItem("confirmOrderResponse", JSON.stringify(data));
	} catch (e) {
		console.log("ERROR SAVING CONFIRM ORDER RESPONSE: ", e);
	}
};

export const getConfirmOrderResponse = () => {
	try {
		return JSON.parse(localStorage.getItem("confirmOrderResponse"));
	} catch (e) {
		console.log("ERROR GETTING CONFIRM ORDER RESPONSE: ", e);
	}
}

