import React, {FC, useEffect, useLayoutEffect, useState} from 'react';
import {toAbsoluteUrl} from "../../../layout/helpers";
import {toast, ToastContainer} from "react-toastify";
import {Link, useHistory} from "react-router-dom";
import {CloseCircle, LogoFacebook, LogoInstagram, LogoTwitter, MenuOutline} from "react-ionicons";
import {User} from "../../../utils/interfaces";
import {Enquiry} from "../../../types";
import {Constants} from "../../../utils/constants";
import SERVICES from '../../../services';
import axios from "axios";
import { Card } from 'react-bootstrap';
import { UserFAQPayload as userFAQs } from '../../../components/FAQs/UserFAQPayload';
import hostFAQs from "../../../components/FAQs/HostFAQPayload";
import { v4 as uuidv4 } from 'uuid';

const FAQs: FC = () => {
    const history = useHistory();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [msisdn, setMsisdn] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const [isEmptyFirstname, setIsEmptyFirstname] = useState(false);
    const [isEmptyLastname, setIsEmptyLastname] = useState(false);
    const [isEmptyPhoneNumber, setIsEmptyPhoneNumber] = useState(false);
    const [isEmptyEmail, setIsEmptyEmail] = useState(false);
    const [isEmptyMessage, setIsEmptyMessage] = useState(false);

    useLayoutEffect(() => {
        const appCapsuleDiv = document.querySelector('#appCapsule') as HTMLDivElement;

        if (appCapsuleDiv != null) {
            const hasSafeSpace = appCapsuleDiv.classList.contains('add-safe-space');

            if (hasSafeSpace) {
                appCapsuleDiv.classList.remove('add-safe-space');
            }
        }
    }, []);

    const isLoggedIn = (user: User) => {
        return user != null && Object.keys(user).length !== 0;
    }

    const redirectBasedOnRole = () => {
        const currentUser = SERVICES.getUser() as User;
        let destination = "/startBooking";

        if (!isLoggedIn(currentUser)) {
            destination = "/signin";
        } else {
            const role = currentUser.role;

            switch (role) {
                case 'ROLE_ADMINISTRATOR':
                    destination = "/dashboard";
                    break;

                case 'ROLE_SUBSCRIBER_OWNER':
                    destination = "/startBooking";
                    break;

                default:
                    destination = "/properties"
            }
        }

        return destination;
    };

    const goToLogin = () => {
        history.push('/signin');
    }

    const goToSignUp = () => {
        history.push('/signup');
    }

    const goToAboutPage = () => {
        history.push('/about');
    }

    function hasText(text: string) {
        return text != null && text !== '';
    }

    useEffect(() => {
        if (hasText(firstName)) {
            setIsEmptyFirstname(false);
        }
        if (hasText(lastName)) {
            setIsEmptyLastname(false);
        }
        if (hasText(email)) {
            setIsEmptyEmail(false);
        }
        if (hasText(msisdn)) {
            setIsEmptyPhoneNumber(false);
        }
        if (hasText(message)) {
            setIsEmptyMessage(false);
        }
    }, [firstName, lastName, email, msisdn, message]);

    const areFieldsOkay = () => {
        const requiredFields = [ firstName, lastName, email, msisdn, message ];
        // is any of the fields empty
        const anyEmpty = requiredFields.some(field => !field);
        if (anyEmpty) {
            if (!hasText(firstName)) {
                setIsEmptyFirstname(true);
            }
            if (!hasText(lastName)) {
                setIsEmptyLastname(true);
            }
            if (!hasText(email)) {
                setIsEmptyEmail(true);
            }
            if (!hasText(msisdn)) {
                setIsEmptyPhoneNumber(true);
            }
            if (!hasText(message)) {
                setIsEmptyMessage(true);
            }

            return false;
        } else {
            return true;
        }
    }

    const submitEnquiry = (event: any): void => {
        event.preventDefault();

        if (!areFieldsOkay()) return;

        const fullName = `${firstName} ${lastName}`;

        const enquiry: Enquiry = {
            name: fullName,
            email,
            phoneNumber: msisdn,
            message
        }

        const resourceUrl = `${Constants.BASE_URL}/platform/company/api/v1/enquiry`;
        axios.post(resourceUrl, enquiry)
            .then(res => {
                if (res.status === 200) {
                    toast.success('We have received your submission',
                        { theme: 'colored' });

                    setFirstName('');
                    setLastName('');
                    setEmail('');
                    setMsisdn('');
                    setMessage('');
                }
            })
            .catch(err => {
                toast.error('Sorry, error submitting your information', { theme: 'colored'});
            })
    }

    return (
        <div className='faq-page mobile'>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                style={{minWidth: '350px'}}
            />
            <header style={{minHeight: '75vh'}}>
                <div className="container top-compartment">
                    <div className="row align-items-center">
                        {/*<div className="col">*/}
                        <div className="col-5">
                            <figure style={{width: '75%'}}>
                                <img
                                    alt={"logo"}
                                    decoding={"async"}
                                    className={"w100 img-fluid"}
                                    src={toAbsoluteUrl("/img/logo-black.png")}
                                />
                            </figure>
                        </div>
                        {/*<div className="col-7">*/}
                        {/*    <div className="position-relative">*/}
                        {/*        <div role="button" onClick={goToLogin} className="btn rounded-pill bg-white justify-content-start" style={{width: '180px'}}>LOGIN</div>*/}
                        {/*        <div role="button" onClick={goToSignUp} className="position-absolute rounded-pill btn btn-primary text-white" style={{left: '90px'}}>SIGN UP</div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    {/*<div>*/}
                    {/*    <div className="row menu-items rounded-pill bg-white align-items-center" style={{height: '36px'}}>*/}
                    {/*        <p className="col text-center text-black">Become A Host</p>*/}
                    {/*        <p className="col text-center text-black">Find A Space</p>*/}
                    {/*        <p className="col text-center text-black">FAQ</p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                <div className="container bottom-compartment" style={{marginTop: '80px'}}>
                    <div className='row'>
                        <div className="bg-white text-black d-inline-block" style={{padding: '40px', borderRadius: '20px', width: '65%'}}>
                            <p className="lh-1" style={{fontSize: '250%', lineHeight: '116px'}}>
                                Frequently Asked <span className="font-weight-bolder">Questions</span>
                            </p>
                        </div>
                    </div>
                </div>
            </header>
            <div className="container faqs" style={{paddingBottom: '95px'}}>
                <div className="userFAQs" style={{marginBottom: '90px'}}>
                    <p className='text-black' style={{fontSize: '1.2rem', fontWeight: 700, padding: '40px 0 10px'}}>User FAQs</p>
                    <Card className={'shadow'}>
                        <Card.Body className="p-2">
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                {
                                    userFAQs.map((faq, index) => {
                                        const uuid = uuidv4() as string;

                                        return (
                                            <div key={index} className="accordion-item">
                                                <h2 className="accordion-header" id={`flush-heading-${uuid}`}>
                                                    <button className="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target={`#flush-collapse-${uuid}`}
                                                            aria-expanded="false" aria-controls={`flush-collapse-${uuid}`}>
                                                        { faq.question }
                                                    </button>
                                                </h2>
                                                <div id={`flush-collapse-${uuid}`} className="accordion-collapse collapse"
                                                     aria-labelledby={`flush-heading-${uuid}`} data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        { faq.answer }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                <div className="hostFAQs">
                    <p className="text-black" style={{fontSize: '1.2rem', fontWeight: 700, marginBottom: '40px'}}>Hosts FAQs</p>
                    <Card className={'shadow'}>
                        <Card.Body className="p-2">
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                {
                                    hostFAQs.map((faq, index) => {
                                        const uuid = uuidv4() as string;

                                        return (
                                            <div key={index} className="accordion-item">
                                                <h2 className="accordion-header" id={`flush-heading-${uuid}`}>
                                                    <button className="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target={`#flush-collapse-${uuid}`}
                                                            aria-expanded="false" aria-controls={`flush-collapse-${uuid}`}>
                                                        { faq.question }
                                                    </button>
                                                </h2>
                                                <div id={`flush-collapse-${uuid}`} className="accordion-collapse collapse"
                                                     aria-labelledby={`flush-heading-${uuid}`} data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        { faq.answer }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <div className={"d-flex flex-column"}>
                <section
                    className={
                        "section px-3 pt-3 background-size-position d-flex flex-column"
                    }
                    style={{
                        backgroundImage: `url(${toAbsoluteUrl(
                            "/img/diverse-women-mobile.jpeg"
                        )}), linear-gradient(89.83deg, rgb(255, 255, 255) 20.24%, rgb(255, 255, 255) 40.25%, rgba(255, 255, 255, 0.8) 99.55%)`,
                    }}
                >
                    <div
                        className={"mx-auto"}
                        style={{ width: "clamp(350px,340px + 0.1vw , 384px)" }}
                    >
                        <h1
                            className={"text-center w-75 mx-auto mb-2"}
                            style={{
                                fontSize: "25px",
                                lineHeight: "53px",
                                // fontFamily: "Monserrat",
                                fontWeight: 500,
                            }}
                        >
                            Get in touch with our Sales Team
                        </h1>
                        <div className="form-group basic">
                            <div className="input-wrapper">
                                <input
                                    type="text"
                                    className="form-control floating-input"
                                    id="firstName"
                                    placeholder=" "
                                    value={firstName}
                                    onChange={e => setFirstName(e.target.value)}
                                    style={{background: "#F9F9F9",
                                        border:"1px solid #E6E6E6",}}
                                />
                                <label style={{background:"rgba(0,0,0,0)",color: "#737373"}} className="floating-label form-label-contact form-label-email" htmlFor="firstName">
                                    First Name*
                                </label>
                                <i className="clear-input">
                                    <CloseCircle color={"#00000"} title={""} />
                                </i>
                            </div>
                        </div>

                        <div className="form-group basic">
                            <div className="input-wrapper">
                                <input
                                    type="text"
                                    className="form-control floating-input"
                                    id="lastName"
                                    placeholder=" "
                                    value={lastName}
                                    onChange={e => setLastName(e.target.value)}
                                    style={{background: "#F9F9F9",
                                        border:"1px solid #E6E6E6",}}
                                />
                                <label style={{background:"rgba(0,0,0,0)",color: "#737373"}} className="floating-label form-label-contact form-label-email" htmlFor="lastName">
                                    Last Name*
                                </label>
                                <i className="clear-input">
                                    <CloseCircle color={"#00000"} title={""} />
                                </i>
                            </div>
                        </div>

                        <div className="form-group basic">
                            <div className="input-wrapper">
                                <input
                                    type="email"
                                    className="form-control floating-input"
                                    id="email1"
                                    placeholder=" "
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    style={{background: "#F9F9F9",
                                        border:"1px solid #E6E6E6",}}
                                />
                                <label style={{background:"rgba(0,0,0,0)",color: "#737373"}} className="floating-label form-label-contact form-label-email" htmlFor="email1">
                                    E-mail*
                                </label>
                                <i className="clear-input">
                                    <CloseCircle color={"#00000"} title={""} />
                                </i>
                            </div>
                        </div>
                        <div className="form-group basic " >
                            <div className="input-wrapper did-input-group">
                                {/*<span className="did-input-group-prepend">*/}
                                {/*	<div className="did-input-group-text">*/}
                                {/*		<img*/}
                                {/*			src={toAbsoluteUrl("/img/ghana.svg")}*/}
                                {/*			alt={"gh"}*/}
                                {/*			width={15}*/}
                                {/*		/>{" "}*/}
                                {/*		&nbsp; +233*/}
                                {/*	</div>*/}
                                {/*</span>*/}
                                <input
                                    type="number"
                                    min={0}
                                    minLength={9}
                                    maxLength={10}
                                    className="form-control floating-input"
                                    id="phone"
                                    placeholder=" "
                                    value={msisdn}
                                    onChange={e => setMsisdn(e.target.value)}
                                    style={{background: "#F9F9F9",
                                        border:"1px solid #E6E6E6",}}

                                />
                                <label style={{background:"rgba(0,0,0,0)",color: "#737373"}} className="floating-label form-label-contact" htmlFor="phone">
                                    Phone Number
                                </label>
                                <i className="clear-input">
                                    <CloseCircle color={"#00000"} title={""} />
                                </i>
                            </div>
                            {/*<div className="input-info">e.g 555xxxxxx</div>*/}
                        </div>

                        {/*<div className="form-group basic">*/}
                        {/*  <div className="input-wrapper">*/}
                        {/*    <select*/}
                        {/*        className="floating-label form-label-contact form-control floating-select"*/}
                        {/*        id={"availability"}*/}
                        {/*        style={{background: "#F9F9F9",*/}
                        {/*          border:"1px solid #E6E6E6",color: "#737373"}}*/}
                        {/*    >*/}
                        {/*<option disabled value="" />*/}
                        {/*<option value="4:00 Available">4:00 Available</option>*/}
                        {/*<option value="host">Host</option>*/}
                        {/*</select>*/}

                        {/*<label className="floating-label" htmlFor="availability">*/}
                        {/*  Role*/}
                        {/*</label>*/}
                        {/*</div>*/}
                        {/*</div>*/}
                        <div className="form-group basic">
                            <div className="input-wrapper">
							<textarea
                                className="form-control floating-input"
                                id="message"
                                placeholder=" "
                                value={message}
                                onChange={e => setMessage(e.target.value)}
                                style={{background: "#F9F9F9",height: 140,
                                    border:"1px solid #E6E6E6",}}
                            />
                                <label  style={{background:"rgba(0,0,0,0)",color: "#737373"}} className="floating-label form-label-contact" htmlFor="message">
                                    Message
                                </label>
                                <i className="clear-input">
                                    <CloseCircle color={"#00000"} title={""} />
                                </i>
                            </div>
                        </div>
                        <div className="mt-4 w-75 mx-auto">
                            <button
                                type={"button"}
                                className={"btn btn-primary btn-block btn-lg active mb-4"}
                                style={{ borderRadius: "50rem" }}
                                onClick={submitEnquiry}
                            >
                                Book Appointment
                            </button>
                        </div>
                    </div>
                </section>
                {/*call to action*/}
                <section
                    className={"section px-3 pt-5 pb-5"}
                    style={{ backgroundColor: "#2EC5CE" }}
                >
                    <h2 className={"mt-4 fontsize-headingXLarge w-75"}>
                        Coming <span style={{ color: "white" }}>Soon</span>
                    </h2>

                    <p
                        className={"fontsize-heading"}
                        style={{
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "43px",
                            letterSpacing: "0.8px",
                            color: "#18191F"
                        }}
                    >
                        Search, compare & book flexible workspaces, accommodation and other
                        lifestyle services from the convenience of the Spacia app
                    </p>
                    <div
                        className={"mx-auto"}
                        style={{ width: "clamp(350px,340px + 0.1vw , 384px)" }}
                    >
                        <div className={"imaged d-flex flex-row justify-content-center mb-4"}>
                            <img
                                alt={"phone-mock-up"}
                                decoding={"async"}
                                src={toAbsoluteUrl("/img/phone-mock-up.png")}
                            />
                        </div>
                        <div className={"px-2"}>
                            <h3
                                className={"fontsize-sub-footer fontsize-heading mb-2 ms-1"}
                                style={{ fontWeight: 500 }}
                            >
                                Get the App
                            </h3>
                            <div className={"d-flex flex-row w-100 justify-content-center"}>
                                <button
                                    className={"btn me-1 py-1"}
                                    style={{ backgroundColor: "#000000", height: 60 }}
                                >
                                    <div className={"d-flex flex-row align-items-center"}>
                                        <div
                                            className={"imaged d-flex flex-row justify-content-center"}
                                        >
                                            <img
                                                alt={"phone-mock-up"}
                                                decoding={"async"}
                                                className={"w16"}
                                                src={toAbsoluteUrl("/img/google-play.png")}
                                            />
                                        </div>
                                        <div
                                            className={
                                                "d-flex flex-column ms-1 justify-content-between"
                                            }
                                        >
										<span
                                            className={
                                                "text-uppercase fs-10 fw-bold text-white"
                                            }
                                        >
											coming soon on
										</span>
                                            <span
                                                className={"text-capitalize fontsize-normal text-white"}
                                            >
											Google play
										</span>
                                        </div>
                                    </div>
                                </button>
                                <button
                                    className={"btn me-1 py-1"}
                                    style={{ backgroundColor: "#000000", height: 60 }}
                                >
                                    <div className={"d-flex flex-row align-items-center"}>
                                        <div
                                            className={"imaged d-flex flex-row justify-content-center"}
                                        >
                                            <img
                                                decoding={"async"}
                                                className={"w16"}
                                                src={toAbsoluteUrl("/img/apple-logo.png")}
                                                alt={"logo"}
                                            />
                                        </div>
                                        <div
                                            className={
                                                "d-flex flex-column ms-1 justify-content-between"
                                            }
                                        >
										<span className={"text-white fs-10 fw-bold"}>
											Coming Soon on the
										</span>
                                            <span
                                                className={
                                                    "text-capitalize fw-bold fontsize-normal text-white"
                                                }
                                            >
											App Store
										</span>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                <footer
                    className={"pt-5 pb-200 px-4 d-flex flex-column"}
                    style={{ backgroundColor: "#000000", paddingBottom: "100px" }}
                >
                    <div className={"row mt-4"}>
                        <div
                            className={
                                "col-sm-6 col-md-6 col-6 d-flex flex-column  justify-content-center"
                            }
                        >
              <span
                  className={
                      "text-capitalize fontsize-sub-footer  fw-bold text-white"
                  }
              >
                get started
              </span>
                            <Link
                                to={redirectBasedOnRole()}
                                className={"text-white fontsize-sub-footer  text-capitalize"}
                            >
                                Find a space
                            </Link>
                            <Link
                                to={'/host'}
                                className={
                                    "text-white fontsize-sub-footer  mb-5 text-capitalize"
                                }
                            >
                                Become A host
                            </Link>

                            <span
                                className={
                                    "text-capitalize fontsize-sub-footer  mt-3 fw-bold text-white"
                                }
                            >
                help & support
              </span>
                            <span
                                className={"text-white  fontsize-sub-footer  text-capitalize"}
                            >
                contact us
              </span>
                            <span
                                className={"text-white fontsize-sub-footer  text-capitalize"}
                            >
                Terms and Conditions
              </span>
                            <span
                                className={"text-white fontsize-sub-footer  text-capitalize"}
                            >
                Privacy Policy
              </span>
                        </div>
                        {/*Second column*/}
                        <div className={"col-sm-6 col-6 d-flex flex-column"}>
              <span
                  className={
                      "text-capitalize fontsize-sub-footer  fw-bold text-white"
                  }
              >
                Solutions
              </span>
                            <span
                                className={
                                    "text-white mb-5 fontsize-sub-footer  text-capitalize"
                                }
                            >
                Enterprise
              </span>

                            <span
                                className={
                                    "text-capitalize fontsize-sub-footer  fw-bold  mt-5 pt-1 text-white"
                                }
                            >
                Community
              </span>
                            <Link
                                to={'/frequently-asked-questions'}
                                className={
                                    "text-white fontsize-sub-footer fw-normal  text-capitalize"
                                }
                            >
                                FAQ
                            </Link>
                            <span
                                className={
                                    "text-white fontsize-sub-footer fw-normal  text-capitalize"
                                }
                            >
                blog
              </span>
                        </div>
                    </div>
                    <div className={"d-flex flex-row justify-content-between"}>
                        <div className={"d-flex flex-row mt-auto mb-3"}>
                            <a
                                href="https://www.facebook.com/Spacia-Africa-185163956487562"
                                target='_blank' rel={'noreferrer noopener nofollow'}>
                                <LogoFacebook cssClasses={"me-3"} color={"#ffffff"} />
                            </a>
                            <a
                                href="https://twitter.com/SpaciaAfrica_"
                                target='_blank' rel={'noreferrer noopener nofollow'}>
                                <LogoTwitter cssClasses={"me-3"} color={"#ffffff"} />
                            </a>
                            <a
                                href="https://www.instagram.com/spaciaafrica/"
                                target='_blank' rel={'noreferrer noopener nofollow'}>
                                <LogoInstagram cssClasses={"me-3"} color={"#ffffff"} />
                            </a>
                        </div>
                        <div
                            className={
                                "imaged mx-auto"
                            }
                        >
                            <img
                                decoding={"async"}
                                className={"w140 mx-auto img-fluid"}
                                src={toAbsoluteUrl("/img/logo-black-alt.png")}
                                alt={"logo"}
                            />
                        </div>
                    </div>
                    <div
                        className={
                            "d-flex flex-row my-4 border-1 dropdown-divider border-white"
                        }
                    />
                    <span
                        style={{ color: "#D1D5DB" }}
                        className={"w-75 fontsize-sub-footer mx-auto pb-5 mt-2"}
                    >
            &copy; 2022 SPACIA. All rights reserved
          </span>
                </footer>
            </div>
        </div>
    )
}

export default FAQs