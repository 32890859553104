/**
 * Project: spaces
 * File: ReservationCard
 * Created by Pennycodes on 2/5/2022.
 * Copyright spaces
 */
import React, {useEffect, useState} from 'react'
import {EllipsisHorizontal, Location} from "react-ionicons";
import {data} from "../data/reservations";
import {Booking, PendingApproval, Status} from "../utils/interfaces";
import moment from "moment";

interface Props {
    pendingApproval: PendingApproval;
}
const PendingApprovalsCard: (props: Props) => JSX.Element = (props: Props) => {
    const { pendingApproval } = props;

    const [propertyDesc, setPropertyDesc] = useState('');
    const [address, setAddress] = useState('');
    const [startOn, setStartOn] = useState('');
    const [endOn, setEndOn] = useState('');

    useEffect(() => {
        const listingItem = pendingApproval.itemInEntry;
        const description = listingItem?.description;
        setPropertyDesc(description || '');

        const location = listingItem?.location;
        const address = location?.address;
        setAddress(address || '');

        const startOn = pendingApproval.startOn;
        const from = formatDate(startOn);
        setStartOn(from);

        const endOn = pendingApproval.endOn;
        const to = formatDate(endOn);
        setEndOn(to);
    }, []);

    function formatDate(date: string) {
        let formattedDate = '-';

        try {
            if (date != null) {
                formattedDate = moment(date).format('DD/MM/yyyy');
            }
        } catch (e) {
            console.log('an error occurred while converting string to Date');
        }

        return formattedDate;
    }

    return (
        <div className="card shadowed mb-3">
            <div className={'d-flex justify-content-start align-items-start p-2'}>
                <button className={'btn btn-icon btn-primary me-1'}>
                    EB
                </button>
                <div className={'d-flex flex-column'}>
                    <div className={'d-flex justify-content-between'}>
                        <h4>{propertyDesc} </h4>
                    </div>
                    <div className={'d-flex flex-row'}>
                        <Location
                            color={'#6E7070'}
                            title={''}
                            width={'16px'}
                            height={'16px'}
                        /> &nbsp;
                        <p className={'p-0 fontsize-sub'}>{address}</p>
                    </div>
                    <div className={'d-flex flex-row justify-content-between'}>

                        <p className={'p-0 fontsize-sub'}><span>From</span> {startOn} </p>
                        <p className={'p-0 fontsize-sub'}><span>To</span> {endOn} </p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default PendingApprovalsCard
