import React, {useEffect, useState} from 'react';
import { CartStyles } from "./styles";
import home1 from '../../../assets/img/homes/home1.jpeg'
import QuantityCounter from "../../../components/QuantityCounter";
// import {TextField} from "@material-ui/core";
import axios from 'axios';
import CartEntry from "../../../components/cart-entry";
import { useSelector, useDispatch } from "react-redux";
// import {useCalendarState} from "@material-ui/lab/CalendarPicker/useCalendarState";
import imgPlaceholder from "../../../assets/img/Web/Spacia/imgplaceholder.png";

import {
    addEntry,
    saveCartDetails,
    saveCurrentEntry,
    saveFilterOptions,
    addToCartTotalAmount,
    setCartEntries,
    resetTotalAmount, removeFromCartEntryList, setCartTotalAmount, setHasItems
} from "../../../redux/actions/dashboard";
import SERVICES from '../../../services';
import { Constants } from '../../../utils/constants';
import TableRow from "../../../components/TableRow";
import moment from "moment-timezone";
import {Container, Table, Spinner, Modal} from "react-bootstrap";
import demo from "../../../assets/img/Web/Spacia/Rectangle 66.png";
import InformationModal from "../../../components/informationModal";
import SuccessAlert from '../../../components/Settings/alerts/SuccessAlert';
import { ErrorAlert } from '../../../components/Settings/alerts/ErrorAlert';
import closeButton from "../../../assets/img/svg/blue-close-button.svg";
import successImage from "../../../assets/img/png/success-with-bubbles.png";
import rejectedImage from "../../../assets/img/png/rejected-image.png";
import {useHistory, useLocation} from "react-router-dom";

function Cart() {
    const history = useHistory();
    const location = useLocation();
    // enum BookingException {
    //     BOOKING_NOT_FOUND = 'BOOKING_NOT_FOUND',
    //     BOOKING_TOO_CHECK_IN = 'BOOKING_TOO_CHECK_IN',
    //     BAD_BOOKING_REQUEST = 'BAD_BOOKING_REQUEST',
    //
    //     NO_ASSIGNED_PACKAGE = 'NO_ASSIGNED_PACKAGE',
    //
    //     SPACE_NOT_PART_OF_PACKAGE = 'SPACE_NOT_PART_OF_PACKAGE',
    //
    //     PERIOD_EXCEEDS_ALLOCATION = 'PERIOD_EXCEEDS_ALLOCATION',
    //
    //     NUMBER_OF_CHECKINS_EXCEEDED = 'NUMBER_OF_CHECKINS_EXCEEDED'
    // }
    const [entries, setEntries] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [userRole, setUserRole] = useState('');
    const [loading, setLoading] = useState(false);
    const [cartUpdated, setCartUpdated] = useState(false);

    const allEntries = useSelector(state => state.dashboard.entries);
    const totalCartAmount = useSelector(state => state.dashboard.totalCartAmount);
    const selectedFilters = useSelector(state => state.dashboard.selectedFilters);

    const dispatch = useDispatch();

    const [successAlert, setSuccessAlert] = useState(false);

    const [errorAlert, setErrorAlert] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorOnBooking, setErrorOnBooking] = useState('');

    const userCurrency = SERVICES.getCurrency();

    const cartDetails = useSelector(state => state.dashboard.cartDetails);
    useEffect(() => {
        // dispatch(saveCartDetails([]));
        // dispatch(setCartEntries());
        // dispatch(resetTotalAmount())

        const currentUser = SERVICES.getUser();

        const role = currentUser.role;
        setUserRole(role);

        const from = selectedFilters.from || '';
        const to = selectedFilters.to || '';

        setFromDate(from);
        setToDate(to);

        console.log('Inside cart');
        console.log('from:', from);
        console.log('to:', to);

        // dispatch(addEntry({}));

        // setTotalAmount(totalCartAmount);
        //
        // console.log('total cart amount: ', totalCartAmount);
        //
        // console.log('all entries:', allEntries);
        //
        // const reversed = [...allEntries].reverse();
        // setEntries(reversed);
    }, []);

    useEffect(() => {
        console.log('location:', location);
        const locationState = location.state;
        const bookingSuccessful = locationState?.bookingSuccessful;

        if (bookingSuccessful) {
            // reset all necessary values
            setEntries([]);
            setTotalAmount(0);
            setSuccessAlert(true)
            // todo: change this later
            dispatch(saveCartDetails([]));
            dispatch(setCartEntries());
            dispatch(resetTotalAmount());

            dispatch(setHasItems(false));
        }
    }, []);

    // re-render page anytime the total amount is updated
    useEffect(() => {
        // find out if there is already an existing cart
        const cartId = (cartDetails) ? cartDetails['cartId'] : null;
        console.log('current cart id is:', cartId);

        // get all cart entries for current cart
        axios.get(`${Constants.BASE_URL}/booking/api/v1/order/get/cart/items`, { params: { cartId }})
            .then(res => {
                if (res.status === 200) {
                    console.log(res.data);
                    const cartItems = res.data.data;
                    let updated = cartItems.map(item => {
                        const startDate = item.startOn;
                        const endDate = item.endOn;

                        let updatedStartDate = startDate.slice(0, startDate.lastIndexOf(':'));
                        let updatedEndDate = endDate.slice(0, endDate.lastIndexOf(':'));

                        return structureEntry(item, updatedStartDate, updatedEndDate);
                    });

                    const entriesList = updated.sort(function(a, b){
                        // Turn your strings into dates, and then subtract them
                        // to get a value that is either negative, positive, or zero.
                        return new Date(b.createdOn) - new Date(a.createdOn);
                    });

                    // console.log('a is: ', a);

                    console.log('updated is:', updated);

                    const totalCartAmount = res.data.totalCartAmount;
                    setTotalAmount(totalCartAmount);

                    setEntries(entriesList);
                }
            })
    }, [cartUpdated])

    function structureEntry(cartDetails, from, to) {
        console.log('cart details:', cartDetails);

        return {
            entryId: cartDetails.entryId,
            cartId: cartDetails.cartId,
            owner: {
                id: cartDetails.cartOwner.id,
                username: cartDetails.cartOwner.username
            },
            createdOn: cartDetails.createdOn,
            startOn: from,
            endOn: to,
            price: cartDetails.price,
            billingPeriod: cartDetails.itemInEntry.propertyPrice.billingPeriod,
            quantity: cartDetails.quantity,
            subTotal: cartDetails.subTotal,
            itemInEntry: {
                description: cartDetails.itemInEntry.description,
                image: cartDetails.itemInEntry.media || demo,
                // image: (cartDetails.itemInEntry.media.images && cartDetails.itemInEntry.media.images > 0) ? cartDetails.itemInEntry.media.images[0] : null,
                // imageDescription: cartDetails.itemInEntry.media.description
                imageDescription: cartDetails.itemInEntry.media || 'Nothing here',
              name: cartDetails.itemInEntry.name,
              subCategory: cartDetails.itemInEntry.subCategory
            }
        };
    }

    const submitForApproval = () => {
        setLoading(true);
        const resourceUrl = `${Constants.BASE_URL}/booking/api/v1/order/submit/for/approval`;

        // find out if there is already an existing cart
        const cartId = (cartDetails) ? cartDetails['cartId'] : null;

        console.log('about to send request to', resourceUrl);

        const currentUser = SERVICES.getUser();

        const id = currentUser.id;

        const billingAccountId = currentUser.inviterId || id;


        axios.post(resourceUrl, null, {
            params: { "cartId": cartId, "userId": id, "billingAccountId": billingAccountId }})
            .then(res => {
                if (res.status === 200) {
                    setLoading(false);
                    console.log(res.data);

                    setEntries([]);
                    setTotalAmount(0);
                    setSuccessAlert(true)

                    // todo: change this later
                    dispatch(saveCartDetails([]));
                    dispatch(setCartEntries());
                    dispatch(resetTotalAmount());

                    dispatch(setHasItems(false));
                }
                else{
                    setErrorAlert(true);
                    setLoading(false);
                }
            }).catch(err => {
                setErrorAlert(true);
                setLoading(false);
            })
    }

    const acceptInvoice = () => {
        setLoading(true);
        const resourceUrl = `${Constants.BASE_URL}/booking/api/v1/order/accept/invoice`;

        // find out if there is already an existing cart
        const cartId = (cartDetails) ? cartDetails['cartId'] : null;

        console.log('about to send request to', resourceUrl);

        const currentUser = SERVICES.getUser();

        const id = currentUser.id;

        const billingAccountId = currentUser.inviterId || id;

        axios.post(resourceUrl, {
            "cartId": cartId,
            "approverId": id,
            "billingUserId": billingAccountId
        }).then(res => {
                if (res.status === 200) {
                    setLoading(false);
                    console.log(res.data.data);

                    const bookingResponse = res.data.data;

                    const bookingIdsArray = [];

                    bookingResponse && bookingResponse.forEach(booking => {
                        bookingIdsArray.push(booking.id);
                    })

                    const currentUser = SERVICES.getUser();

                    const userId = currentUser && currentUser.id;

                    const resourceUrl = `${Constants.BASE_URL}/booking/api/v1/booking/email`;

                    setEntries([]);
                    setTotalAmount(0);
                    setSuccessAlert(true)
                    // todo: change this later
                    dispatch(saveCartDetails([]));
                    dispatch(setCartEntries());
                    dispatch(resetTotalAmount());

                    dispatch(setHasItems(false));

                    // trigger booking confirmation email
                    axios.get(resourceUrl, {params: {bookingIds: bookingIdsArray && bookingIdsArray.join(',')}})
                        .then(res => console.log(res))
                        .catch(err => console.log(err));
                }
            }).catch(err => {
                const errorResponse = err.response;
                console.log('error response:', errorResponse);

                const data = errorResponse?.data;
                const message = data?.message;
                const errorOnBooking = data?.errorOnBooking;

                setErrorAlert(true);
                setShowErrorModal(true);
                setErrorMsg(message);
                setErrorOnBooking(errorOnBooking);

                setLoading(false);
        })
    }

    const removeCartEntry = (id) => {
        console.log('about to process request to remove cart entry');

        const resourceUrl = `${Constants.BASE_URL}/booking/api/v1/order/delete/entry/${id}`;

        axios.delete(resourceUrl)
            .then(res => {
                if (res.status === 200) {
                    setEntries(entries.filter(entry => entry.entryId !== id));
                    setCartUpdated(prev => !prev);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    useEffect(() => {
        if (entries.length === 0) {
            dispatch(setHasItems(false));
        } else {
            dispatch(setHasItems(true));
        }
    }, [entries]);

    const createOrderForImmediateListingPayment = () => {
        const cartId = (cartDetails) ? cartDetails['cartId'] : null;
        const currentUser = SERVICES.getUser();

        const userId = currentUser ? currentUser.id : 0;

        const config = {
            params: { cartId, userId }
        }

        axios.post(`${Constants.BASE_URL}/booking/api/v1/order/create/for/listing/payment`, null, config)
            .then(res => {
                const response = res.data;
                const responseData = response?.data;

                console.log('response data:', responseData);

                const order = (responseData && Array.isArray(responseData) && responseData.length > 0) ? responseData[0] : null;

                history.push({
                    pathname: '/booking-order-summary',
                    state: order
                });
            }).catch(err => {
                // handle exception
                console.log('error:', err);
            })
    }

    const textToRenderBasedOnError = (error) => {
        let text = '';

        if (error) {
            switch (error) {
                case 'NO_ASSIGNED_PACKAGE':
                    text = 'Please contact owner account to assign you one.';
                    break;

                case 'SPACE_NOT_PART_OF_PACKAGE':
                    text = 'Would you like to make payment for this space?';
                    break;

                case 'PERIOD_EXCEEDS_ALLOCATION':
                    text = 'Adjust the booking period and try again!';
                    break;

                case 'NUMBER_OF_CHECKINS_EXCEEDED':
                    text = 'Would you like to make payment for this space?';
                    break;

                default:
                    text = 'An error occurred';
            }
        }

        return text;
    }

    const handleButtonClickOnFailureModal = (errorOnBooking) => {
        if (errorOnBooking) {
            switch (errorOnBooking) {
                case 'NO_ASSIGNED_PACKAGE':
                    setShowErrorModal(false);
                    break;

                case 'SPACE_NOT_PART_OF_PACKAGE':
                    createOrderForImmediateListingPayment();
                    break;

                case 'PERIOD_EXCEEDS_ALLOCATION':
                    history.push('/filterprops')
                    break;

                case 'NUMBER_OF_CHECKINS_EXCEEDED':
                    createOrderForImmediateListingPayment();
                    break;

                default:
                    setShowErrorModal(false);
            }
        }
    }

    return (
        <CartStyles>
            <SuccessAlert show={successAlert} message="Success" close={()=>setSuccessAlert(false)}/>
            {/*<ErrorAlert show={errorAlert} message={ errorMsg || "An error occurred" } close={()=>setErrorAlert(false)} />*/}
            <header>
                <h3>My Shortlist</h3>
                <div style={{width: '34%'}}>
                    <h5 style={{fontWeight: 'bolder', fontSize: '1.2rem'}}>Total: {userCurrency} {totalAmount.toLocaleString() || 0}</h5>
                    <button className='accept-invoice' type='button' disabled={entries.length === 0} onClick={(userRole === 'ROLE_SUBSCRIBER_INDIVIDUAL') ? submitForApproval : acceptInvoice}>{(userRole === 'ROLE_SUBSCRIBER_INDIVIDUAL') ? 'Submit For Approval' : 'Book'} <div style={{display: loading ? 'inline-block' : 'none', paddingLeft: '5px', paddingRight: '15px', width: '10px', height: '10px'}}>
                        	<Spinner animation="border" role="status">
                        		<span className="visually-hidden"></span>
                        	</Spinner>
                    </div></button>
                    {/*<button className='service-bundle' type='button'>Create Service Bundle</button>*/}
                </div>
            </header>
            <Table style={{tableLayout: 'fixed'}}>
                <tr style={{height: '60px', background: '#f7f7f7'}}>
                    <th style={{width: '20%'}}/>
                    <th scope='col' className='space-type'>Type of Space</th>
                    <th style={{width: '20%'}} scope='col' className='datetime'>From</th>
                    <th style={{width: '20%'}} scope='col' className='datetime'>To</th>
                    <th scope='col'>Price</th>
                    <th scope='col'>Subtotal</th>
                </tr>
                <tbody>
                {
                    entries &&
                    entries.map(entry => {
                        console.log('entry values:', entry);
                        let image = undefined;

                        const listingItem = entry.itemInEntry;
                        const imageObj = listingItem && listingItem.image;
                        const imageArray = imageObj && imageObj.images;

                        const imageAtFirstIndex = imageArray && imageArray[0];

                        image = imageAtFirstIndex ? imageAtFirstIndex.resourceUrl : imgPlaceholder;

                        const billingPeriod = entry.billingPeriod;
                        const price = entry.price;

                        let overall = undefined;
                        if (price && billingPeriod) {
                            let period = null;
                            switch (billingPeriod) {
                                case 'HOURLY':
                                    period = 'hour';
                                    break;

                                case 'WEEKLY':
                                    period = 'week';
                                    break;

                                case 'MONTHLY':
                                    period = 'month';
                                    break;

                                case 'DAILY':
                                    period = 'day';
                                    break;

                                default:
                                    period = 'month';
                            }

                            overall = `${price.toLocaleString()} per ${period}`;
                        } else if (price) {
                            overall = price;
                        } else {
                            overall = 'N/A';
                        }

                        return <CartEntry key={entry.entryId} {...entry} image={image} price={overall} removeCartEntry={removeCartEntry} setCartUpdated={setCartUpdated} setTotalAmount={setTotalAmount} setErrorMsg={setErrorMsg} setErrorAlert={setErrorAlert}/>
                    })
                }
                </tbody>
            </Table>
            {/*<table>*/}
            {/*    <thead>*/}
            {/*    <tr>*/}
            {/*        <th />*/}
            {/*        <th scope='col' className='space-type'>Type of Space</th>*/}
            {/*        <th scope='col' className='datetime'>From</th>*/}
            {/*        <th scope='col' className='datetime'>To</th>*/}
            {/*        <th scope='col'>Price</th>*/}
            {/*        <th scope='col'>Subtotal</th>*/}
            {/*    </tr>*/}
            {/*    </thead>*/}

            {/*</table>*/}
            {/*<tbody>*/}
            {/*{*/}
            {/*    entries &&*/}
            {/*    entries.map(entry => <CartEntry key={entry.entryId} {...entry}/>)*/}
            {/*}*/}
            {/*</tbody>*/}

            {/* payment successful modal */}
            <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
                <header className={'position-relative'} style={{zIndex: 3}}>
                    <figure className="text-right" style={{padding: '15px'}}>
                        <img onClick={() => setShowErrorModal(false)} src={closeButton} alt="close button" style={{width: '25px'}}/>
                    </figure>
                </header>
                <Modal.Body style={{height: '320px'}}>
                    <div className="text-center position-relative" style={{bottom: '25px'}}>
                        <figure>
                            <img
                                className={'position-relative'}
                                src={rejectedImage}
                                alt="Failure"
                                style={{width: '25%'}}
                            />
                        </figure>
                        <div>
                            <p className={'text-primary font-weight-bolder mt-5 mb-2'} style={{fontSize: '1.1rem'}}>
                                {errorMsg}
                            </p>
                            <p>
                                { textToRenderBasedOnError(errorOnBooking) }
                            </p>
                            <div className={'mt-4'} style={{display: (errorOnBooking) ? 'block' : 'none'}}>
                                <button
                                    className={'btn btn-primary'}
                                    onClick={() => handleButtonClickOnFailureModal(errorOnBooking)}
                                >
                                    Proceed
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </CartStyles>
    )
}

export default Cart
