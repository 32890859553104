/**
 * Project: spaces
 * File: AddToHome
 * Created by Pennycodes on 2/4/2022.
 * Copyright spaces
 */

import {getAndroidDetection, getIosDetection} from "./Detection"
import {getById} from "../utils/DOMHelpers"
import {Modal} from 'bootstrap'
import { Spaces } from "./Spaces"

class AddToHome {
    time:  number | undefined
    once: string | boolean

    constructor(_time: number , _once: string | boolean) {
        this.once = _once
        this.time = _time

        this._init()

    }

    private _androidAddToHome = () => {
        const modal = new Modal(getById('android-add-to-home-screen') || '')
        modal.toggle()
    }

    private _iosAddToHome = () => {
        const modal = new Modal(getById('ios-add-to-home-screen') || '')
        modal.toggle()
    }

    private _isRunningStandalone = (): boolean => {
       return (matchMedia('(display-mode: standalone)').matches) || ('standalone' in window.navigator)
}

    private _init = () => {
        const _isRunningStandalone = this._isRunningStandalone
        const _androidAddToHome = this._androidAddToHome
        const _iosAddToHome = this._iosAddToHome
        const time = this.time
        if (this.once) {
            const AddHomeStatus = localStorage.getItem("SpacesAddToHome")
            if (!AddHomeStatus) {
                localStorage.setItem("SpacesAddToHome", "1")
                Spaces.ready( function () {
                    if (!_isRunningStandalone()) {
                        if (getAndroidDetection()) {
                            setTimeout(() => {
                                _androidAddToHome()
                            }, time);
                        }
                        if (getIosDetection()) {
                            setTimeout(() => {
                                _iosAddToHome()
                            }, time);
                        }
                    }
                });
            }
        }
        else {

            Spaces.ready(function (){
                if (!_isRunningStandalone()) {
                    if (getAndroidDetection()) {
                        setTimeout(() => {
                            _androidAddToHome()
                        }, time);
                    }
                    if (getIosDetection()) {
                        setTimeout(() => {
                            _iosAddToHome()
                        }, time);
                    }
                }
            })
            window.addEventListener('DOMContentLoaded', function () {

            });
        }
    }

    // Create Instances
    public static createInstance(state: string | boolean) {
        new AddToHome( 2000, state )
    }
    public static bootstrap(state: boolean = false) {
        AddToHome.createInstance(state)
    }

    public static reInitialization(state: boolean = false) {
        AddToHome.createInstance(state)
    }

}
export {AddToHome}
