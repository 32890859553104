/**
 * Project: spaces
 * File: SearchBox
 * Created by Pennycodes on 2/4/2022.
 * Copyright spaces
 */
import {Spaces} from "./Spaces";
import {getById} from "../utils/DOMHelpers";

class SearchBox {
    searchBoxToggle: NodeListOf<Element>

    constructor() {
        this.searchBoxToggle = Spaces.selectAll('.toggle-searchbox')
        this._init()
    }

    private _init () {
        this.searchBoxToggle.forEach(function (el) {
            el.addEventListener("click", function () {
                var search = getById('search')
                // @ts-ignore
                var a = search.classList.contains("show")
                if (a) {
                    // @ts-ignore
                    search.classList.remove("show")
                }
                else {
                    // @ts-ignore
                    search.classList.add("show")
                    // @ts-ignore
                    search.querySelector(".form-control").focus();
                }
            })
        });
    }

    public static bootstrap() {

    }
}

export {SearchBox}
