import noAvatar from '../../../../assets/img/png/no-avatar.png';
import {Dropdown} from "react-bootstrap";
import {FaEllipsisH} from "react-icons/fa";
import React from "react";
import {useHistory} from "react-router-dom";

const UserRow = (props) => {
  const { status, profile, name, email, user, onDelete } = props;

  const history = useHistory()

  const showUserProfilePage = () => {
    history.push({
      pathname: '/user-profile',
      state: user
    });
  }

  return (
    <div
      className={'d-flex justify-content-between'}
      style={{borderBottom: '1px solid #E0E0E0', paddingBottom: '15px', cursor: 'pointer'}}
      onClick={status === 'CONFIRMED' ? showUserProfilePage : console.log('do nothing')}
    >
      <div className={'d-flex justify-content-start'}>
        <figure style={{width: '15%', marginRight: '20px', marginBottom: '0'}}>
          <img
            className={'img-fluid rounded-circle'}
            src={profile || noAvatar}
            alt={name}
            style={{height: '40px', width: '45px', objectFit: 'cover'}}
          />
        </figure>
        <section>
          <p className='user-name mb-0'>{ name }</p>
          <p className='user-email mb-0'>{ email }</p>
        </section>
      </div>
      <div role="presentation" style={{display: status === 'CONFIRMED' ? 'block' : 'none'}}>
        <Dropdown>
          <Dropdown.Toggle
            color="red"
            style={{
              border: "none",
              backgroundColor: "transparent",
              display: "flex",
              padding: 0,
              color: "transparent",
            }}>
            <FaEllipsisH color="black" />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item style={{color: '#848484'}}>Edit</Dropdown.Item>
            <Dropdown.Item style={{color: '#FF4B4B'}}>Remove</Dropdown.Item>
            <Dropdown.Item style={{color: '#848484'}}>
              {'Disable'}
            </Dropdown.Item>
            <Dropdown.Item style={{color: '#848484'}}>
              Reset Password
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div role="presentation" style={{display: status !== 'CONFIRMED' ? 'block' : 'none'}}>
        <Dropdown>
          <Dropdown.Toggle
            color="red"
            style={{
              border: "none",
              backgroundColor: "transparent",
              display: "flex",
              padding: 0,
              color: "transparent",
            }}>
            <FaEllipsisH color="black" />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              style={{color: '#FF4B4B'}}
              onClick={onDelete}
            >
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  )
}

export default UserRow