/**
 * Project: spaces
 * File: GoBackAnimation
 * Created by Pennycodes on 2/4/2022.
 * Copyright spaces
 */

import {Spaces} from "./Spaces";

class GoBackAnimation {
    element: NodeListOf<Element>

    constructor(className: string) {
        this.element = Spaces.selectAll(className)

        if (this.element) this._init()
    }

    private _goBackAnimation = () => {
        Spaces.pageBody().classList.add("animationGoBack")
        setTimeout(() => {
            window.history.go(-1);
        }, 300);
    }
    private _init () {
        const animate  = this._goBackAnimation
        this.element.forEach(function (el) {
            el.addEventListener("click", function () {
                if (Spaces.Config().Animation.goBack) {
                  animate()
                }
                else {
                    window.history.go(-1);
                }

            })
        })
    }

    public static bootstrap (element: string = '.goBack') {
        new GoBackAnimation(element)
    }
    public static reInitialization(element: string = '.goBack') {
        console.log('re-in')
        new GoBackAnimation(element)
    }
}

export {GoBackAnimation}
