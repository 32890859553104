import {BundleMetricData} from "../../../../data/package-select-data";
import {FC, useEffect, useState} from "react";
import {calculateRgba} from "react-spinners/helpers";

interface Props {
    bundleMetric: any,
    selectedPackage: string,
    setSelectedPackage: any
}

const BundlePlanCard: FC<Props> = ({bundleMetric, selectedPackage, setSelectedPackage}) => {
    const [bundleOffers, setBundleOffers] = useState<Array<string>>([]);
    const [title, setTitle] = useState('');
    const [bundleType, setBundleType] = useState('');
    const [totalPurchased, setTotalPurchased] = useState<any>(0);
    const [backgroundColor, setBackgroundColor] = useState('#017BEF');

    useEffect(() => {
        console.log('inside use effect. bundle metric data:', bundleMetric);

        const bundleOffers = bundleMetric.bundleOffers;
        const workFromAnywhere = bundleOffers?.workFromAnywhere;
        const maximizeProductivity = bundleOffers?.maximizeProductivity;

        if (workFromAnywhere && maximizeProductivity) {
            const updated = [...workFromAnywhere.slice(0,3), ...maximizeProductivity.slice(0,2)];

            setBundleOffers(updated);
        }

        const bundle = bundleMetric.bundle;
        const bundleType = bundle?.bundleType;
        const label = bundleType ? bundleType.label : '';
        const value = bundleType ? bundleType.value : '';
        setTitle(label);
        setBundleType(value);

        setTotalPurchased(bundleMetric.totalPurchased);
    }, []);

    useEffect(() => {
        if (selectedPackage === bundleType) {
            setBackgroundColor('#525252');
        } else {
            setBackgroundColor('#017BEF');
        }

        if (!totalPurchased) {
            setBackgroundColor(calculateRgba('#017BEF', .7));
        }
    }, [totalPurchased, selectedPackage, bundleType]);

    return (
        <section
            className={'text-center'}
            style={{width: '270px', height: '340px', border: '1px solid rgba(1, 123, 239, 0.57)',
                boxShadow: '0px 4px 5px 1px rgba(193, 193, 193, 0.35)',
                background: backgroundColor,
                borderRadius: '40px'}}>
            <div className={'pt-5 pb-5'}>
                <h4 className={'text-white text-uppercase mb-4'} style={{fontSize: '1.3rem'}}>{title}</h4>
                <div className={'mb-3'}>
                    <button
                        className={'bg-transparent text-white'}
                        style={{border: '2px solid #FFFFFF', borderRadius: '40px', width: '60%', padding: '10px 0', cursor: (!totalPurchased) ? 'not-allowed' : 'pointer'}}
                        disabled={!totalPurchased}
                        onClick={() => setSelectedPackage(bundleType)}
                    >
                        SELECT
                    </button>
                </div>
                <div className={'mb-4'} role={'presentation'} style={{border: '1px solid #FFFFFF', width: '35%', margin: '0 auto'}}></div>
                <div className={'text-white'} style={{fontSize: '.955rem'}}>
                    {
                        bundleOffers && bundleOffers.length > 0
                        && bundleOffers.map(offer => {
                            return (
                                <p>{ offer }</p>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default BundlePlanCard
