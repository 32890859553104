import React from "react";
import ReactDOM from "react-dom";
import { usePromiseTracker } from "react-promise-tracker";
import App from "./App";
import firebase from "firebase";
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from 'firebase/analytics';
import { Constants } from './utils/constants';
// import ScrollToTop from "./scrollComponent";


const LoadingIndicator = () => {
	const { promiseInProgress } = usePromiseTracker();
	return promiseInProgress && <h1>Hey some async call in progress!</h1>;
};

const app = firebase.initializeApp(Constants.firebaseConfig);
const analytics = firebase.analytics(app);

ReactDOM.render(
	<React.StrictMode>
		{/* <ScrollToTop /> */}
		<App />
		<LoadingIndicator />
	</React.StrictMode>,
	document.getElementById("root"),
);
