/**
 * Project: spaces
 * File: Login
 * Created by Pennycodes on 2/4/2022.
 * Copyright spaces
 */
import React, {FormEvent, useEffect, useState} from "react";
import { EyeOutline } from "react-ionicons";
import { AiOutlineMail } from "react-icons/ai";
import {Link, useHistory, useLocation} from "react-router-dom";
import {toAbsoluteUrl} from "../../../../layout/helpers";
import queryString from "query-string";
import {useDispatch} from "react-redux";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {Constants} from "../../../../utils/constants";
import SERVICES from "../../../../services";
import firebase from "firebase";
import {FaRegEye as FaEye, FaRegEyeSlash as FaEyeSlash} from "react-icons/fa";
import {ErrorAlert} from "../../../../components/Settings/alerts/ErrorAlert";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [isEmptyEmail, setIsEmptyEmail] = useState(false);
    const [isEmptyPassword, setIsEmptyPassword] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const history = useHistory();
    const location = useLocation();
    const search = queryString.parse(location.search);
    const params = search && search.payload ? search.payload : "";
    const dispatch = useDispatch();

    const [errorModal, setErrorModal] = useState(false);

    function hasText(text: string) {
        return text != null && text !== '';
    }

    useEffect(() => {
        if (hasText(email)) {
            setIsEmptyEmail(false);
        }
        if (hasText(password)) {
            setIsEmptyPassword(false);
        }
    }, [email, password]);

    const areFieldsOkay = () => {
        const requiredFields = [email, password];
        // is any of the fields empty
        const anyEmpty = requiredFields.some(field => !field);
        if (anyEmpty) {
            if (!hasText(email)) {
                setIsEmptyEmail(true);
            }
            if (!hasText(password)) {
                setIsEmptyPassword(true);
            }
            return false;
        } else {
            return true;
        }
    }

    const handleLogin = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!areFieldsOkay()) return;

        setLoading(true);
        let credentials = {
            password: password,
            username: email,
            // This is a hardcoded value,  WHY????
            companyId: 1,
        };
        axios.post(`${Constants.BASE_URL}/users/api/v1/login`, credentials)
            .then((res) => onLoginSuccessful(res))
            .catch((err) => onLoginError(err));

        setLoading(false);
    };

    const onLoginSuccessful = (res: AxiosResponse<any>) => {
        console.log(res.data);
        SERVICES.saveUser(res.data.data[0]);

        const currentUser = res.data.data[0];
        const userCountry = currentUser?.country;
        const currency = userCountry.currency;

        console.log('currency associated with company is:', currency);

        SERVICES.setCurrency(currency);

        const role = res.data.data[0].role;

        SERVICES.setCompanyId(res.data.data[0].companyId)

        // this should be a switch statement
        // Regular and approver => Find a space
        // Owner Subscriber => Modal

        switch (role) {
            case 'ROLE_ADMINISTRATOR':
            case 'ROLE_HOST':
                history.push('/');
                break;

            case 'ROLE_HOST_OPERATIONS':
                history.push("/properties");
                break;

            default:
                history.push('/startBooking');
        }
        // role === "ROLE_ADMINISTRATOR" || role === 'ROLE_HOST' || role === 'ROLE_HOST_OPERATIONS'
        // 	? history.push("/")
        // 	: history.push("/startBooking");
    };

    const onLoginError = (err: any) => {
        const { response } = err;
        const data = response ? response.data : null;
        const errors = data ? data.errors : [];

        console.log(errors);

        setErrorModal(true);

        if (errors && Array.isArray(errors) && errors.length > 0) {
            const { message } = errors[0];
            setErrorMsg(message);
        }
    }

    const googleLogin = () => {
        const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(googleAuthProvider).then((res)=>{
            console.log(res);

            const credential = res.credential;
            // @ts-ignore
            const accessToken = credential?.accessToken;

            console.log(accessToken);

            const config: AxiosRequestConfig = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }

            axios.post(`${Constants.BASE_URL}/users/api/v1/utils/exchange-token`, {
                accessToken,
                companyId: 1,
                "provider": "GOOGLE"
            }, config)
                .then((res) => onLoginSuccessful(res))
                .catch((err) => onLoginError(err));
        }).catch(console.error);

        // 	axios.post(`http://localhost:9002/api/v1/utils/exchange-token`, {
        // 		accessToken,
        // 		companyId: 1,
        // 		"provider": "GOOGLE"
        // 	}, headers)
        // 		.then((res) => onLoginSuccessful(res))
        // 		.catch((res)=>onLoginError(res));
        // }).catch(console.error);
    };

    const isValidJSON = (str: string) => {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    };

    let payload = null;

    let decodedString = "";
    if (typeof params === "string") {
        decodedString = atob(params);
    }
    if (isValidJSON(decodedString)) {
        payload = JSON.parse(decodedString);
        // localStorage.setItem('payload',payload)
        SERVICES.saveUser(payload);
    }

    const togglePasswordVisibility = (e: any) => {
        e.preventDefault();

        setShowPassword(!showPassword);
    }

	useEffect(() => {
		const capsule = document.querySelector("#appCapsule");

		capsule && capsule.classList.remove("add-safe-space");
	}, []);


	return (
		<div
            id="appCapsule"
			className="position-relative"
			style={
				{
					// background: `url(${toAbsoluteUrl('/img/background.png')})`,
					// backgroundPosition: "center",
					// backgroundSize: "cover"
				}
			}
		>
			<div
				className="row flex-fill m-0"
				style={{ width: "100vw", height: "100vh", padding: "-100px 0" }}
			>
				<div
					className="d-flex flex-column col-lg-5 col-md-6 col-sm-12 justify-content-center align-items-end py-4"
					style={{ paddingRight: "min(60px,4vw)" }}
				>
                    <header className={'mb-2'} style={{alignSelf: 'flex-start', marginLeft: '70px'}}>
                        <div className={"imaged "}>
                            <img
                                alt={"logo"}
                                decoding={"async"}
                                src={toAbsoluteUrl("/assets/img/spacia/web/spacia_logo.png")}
                                style={{width: '150px', cursor: 'pointer'}}
                                onClick={() => history.push('/home')}
                            />
                        </div>
                    </header>

					<form onSubmit={handleLogin} className="d-flex flex-column align-items-center ">
                        <div className={'w-100'}>
                            <ErrorAlert show={ errorModal } close={()=>setErrorModal(false)}  message={ errorMsg || "A problem was encountered, please try again" }/>
                        </div>
                        <h1 className="mt-5  text-start align-self-start mb-3">Welcome to SPACIA!</h1>
                        <h2 className="text-start align-self-start my-3">Sign In</h2>
						<div className="form-group basic my-md-2">
							<div className="input-wrapper d-flex flex-column mb-3" style={{
											width: "min(464px,34vw)",
											height: "52px",
										}}>
								<label
									className=""
									style={{ color: "#18345E" }}
									htmlFor="firstName"
								>
									Email
								</label>
								<div className="d-flex flex-row position-relative">
									<input
										type="text"
										className={ isEmptyEmail ? "form-control is-invalid floating-input" : "form-control floating-input"}
										id="firstName"
										placeholder=" johndoe@gmail.com"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
										style={{
											backgroundColor: "#F4F2F2",
											fontSize: "16px",
											lineHeight: "24px",
											textAlign: "start",
											color: "#18345E",
											width: "min(464px,34vw)",
											height: "52px",
										}}
									/>

									<AiOutlineMail
										style={{
											position: "absolute",
											top: "21px",
											right:"12px",
											color: "#18345E",
											zIndex: 2,
										}}
									/>
                                    <div className="invalid-feedback position-absolute" style={{top: '90%'}}>Please enter your email</div>
								</div>
							</div>
						</div>
						<div className="form-group basic my-md-2">
							<div className="input-wrapper" style={{
											width: "min(464px,34vw)",
											height: "52px",
										}}>
								<label
									style={{ color: "#18345E" }}
									className=""
									htmlFor="firstName"
								>
									Password
								</label>
                                <div className="d-flex flex-row position-relative">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        className={ isEmptyPassword ? "form-control is-invalid floating-input" : "form-control floating-input"}
                                        id="password1"
                                        autoComplete="off"
                                        placeholder="**************"
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        style={{
                                            backgroundColor: "#F4F2F2",
                                            fontSize: "16px",
                                            lineHeight: "24px",
                                            textAlign: "start",
                                            color: "#18345E",
                                        }}
                                    />
                                    <a onClick={togglePasswordVisibility}
                                       style={{
                                        position: "absolute",
                                        top: "15px",
                                        right:"12px",
                                        color: "#18345E",
                                        zIndex: 2,
                                    }}>
                                        <i>{showPassword ? <FaEye /> : <FaEyeSlash />}</i>
                                    </a>
                                    <div className="invalid-feedback position-absolute" style={{top: '90%'}}>Please enter password</div>
                                </div>
							</div>
						</div>
						<input
							type="submit"
							value="Sign in"
							className="btn btn-primary btn-block btn-lg mb-4 me-md-auto"
							style={{ marginTop: '50px', borderRadius: "25px", height: "50.58px" ,width: "min(464px,34vw)",}}
						/>
							<div className="mb-2 text-center mx-auto">
									<Link to={"resetpassword"}>Forgot password?</Link>{" "}
							</div>                        
						<div className="d-flex flex-column justify-content-center align-items-center">
							<button
                                type="button"
								className=" btn btn-outline-secondary d-flex flex-row align-items-center justify-content-center"
								style={{
									width: "min(314.44px,34vw)",
									height: "67px",
								}}
                                onClick={googleLogin}
							>
								<img
									src={toAbsoluteUrl("/img/google.png")}
									alt={"Google Sign In"}
									className="me-2"
								/>
								<span className="text-muted text-capitalize " style={{fontSize:"min(1.4vw,21px)"}}>
									sign in with google
								</span>
							</button>
							{/*<button*/}
							{/*	className="btn btn-outline-secondary d-flex flex-row align-items-center justify-content-center mt-2"*/}
							{/*	style={{*/}
							{/*		width: "min(314.44px,34vw)",*/}
							{/*		height: "67px",*/}
							{/*	}}*/}
							{/*>*/}
							{/*	<img*/}
							{/*		src={toAbsoluteUrl("/img/Facebook.png")}*/}
							{/*		alt={"Google Sign In"}*/}
							{/*		className="me-2"*/}
							{/*	/>*/}
							{/*	<span className="text-muted " style={{fontSize:"min(1.4vw,21px)"}}>*/}
							{/*		sign in with facebook*/}
							{/*	</span>*/}
							{/*</button>*/}
							<div className="mt-2 text-center mx-auto">
								<p className={"text-dark p-0 mb-0"}>
									Don't have an account? <Link to={"/package-signup"}>Sign Up</Link>{" "}
								</p>
							</div>
							<div className="mt-2 text-center mx-auto">
								<p className={"text-muted text-capitalize text-center mb-auto"}>
									&copy;2022 - 2024 SPACIA All rights reserved
								</p>
							</div>
						</div>
					</form>
				</div>
				<div className="d-flex flex-column justify-content-center col-lg-7 col-md-6 px-0 col-sm-12"
				style={{
					background: `url(${toAbsoluteUrl('/img/Slider.png')})`,
					// backgroundPosition: "center right",
					backgroundSize: "100%",
					backgroundRepeat: "no-repeat"
				}}
				
				>
					{/* <div className="carousel-full splide">
                <div className="splide__track">
                    <ul className="splide__list">

                        <li className="splide__slide">
                            <div className="card rounded-0">
                                <div className="card-body">
                                    <h5 className="card-title">Swipe me</h5>
                                    <p className="card-text">
                                        Some quick example text to build on the card title and make up the bulk
                                        of the card's content.
                                    </p>
                                </div>
                            </div>
                        </li>

                        <li className="splide__slide">
						<img
									src={toAbsoluteUrl("/img/Slider.png")}
									alt={"Google Sign In"}
									className="me-2"
								/>
                        </li>

                    </ul>
                </div>
            </div> */}
					{/* <img
						src={toAbsoluteUrl("/img/Slider.png")}
						alt={"Google Sign In"}
						className="img-fluid"
						style={{
							width: "100vw",
							height: "100vh",
							objectFit: "cover",
							objectPosition: "center right",
						}} */}
					
				</div>
			</div>
		</div>
	);
};
export default Login;
