import styled from 'styled-components';

export const PendingApprovalStyles = styled.div`
    tr:not(:first-child):nth-child(even) {
      background: #F6F6F6;
    }

  table {
    position: relative;
  }
  table > ul {
    margin-top: 50px;
    padding-bottom: 50px;
    position: absolute;
    right: 0
  }
`
