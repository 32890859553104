import React, { useState, useEffect } from "react";
import {Form, Row} from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { Constants} from "../utils/constants";
import SERVICES from "../services";
// import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { PhoneInputStyle } from "./PhoneInputStyle";

const MomoPaymentTab = ({setSuccessAlert, setErrorAlert, setSuccessMsg, setErrorMsg}) => {
	const [countryCode, setCountryCode] = useState('');
	const [supportedMobileWalletOperators, setSupportedMobileWalletOperators] = useState([]);
	const [selectedOperator, setSelectedOperator] = useState('');
	const [msisdn, setMsisdn] = useState(undefined);
	const [showNew, setShowNew] = useState(true);
	const [mobileNumber, setMobileNumber] = useState('');
	const [networkOperator, setNetworkOperator] = useState('');
	const [accountId, setAccountId] = useState('');
	const [removeRequestCompleted, setRemoveRequestCompleted] = useState(false);
	const [registeredMobileMoneyAccounts, setRegisteredMobileMoneyAccounts] = useState([]);

	const [isEmptyOperator, setIsEmptyOperator] = useState(false);
	const [isEmptyMsisdn, setIsEmptyMsisdn] = useState(false);
	const [phoneNumberValidationError,setPhoneNumberValidationError] = useState('');

	useEffect(() => {
		if (selectedOperator) {
			setIsEmptyOperator(false);
		}
		if (msisdn) {
			setIsEmptyMsisdn(false);
		}
	}, [selectedOperator, msisdn]);

	const areNewCardFieldsOkay = () => {
		const requiredFields = [selectedOperator, msisdn];
		// is any of the fields empty
		const anyEmpty = requiredFields.some(field => !field);
		if (anyEmpty) {
			if (!selectedOperator) {
				setIsEmptyOperator(true);
			}
			if (!msisdn) {
				setIsEmptyMsisdn(true);
				setPhoneNumberValidationError('Please enter mobile number');
			}

			return false;
		} else {
			return true;
		}
	}

	useEffect(() => {
		// make ajax call to get already registered mobile money accounts
		const currentUser = SERVICES.getUser();
		const userId = currentUser ? currentUser.id : 0;

		const resourceUrl = `${Constants.BASE_URL}/users/v1/user/${userId}/payment/methods`;

		axios.get(resourceUrl)
			.then(res => {
				if (res.status === 200) {
					console.log(res.data);
					const responseData = res.data.data;
					const allMobileWallets = responseData.filter(response => response.paymentMethod === 'mobileWallet');

					console.log('all mobile wallets.', allMobileWallets);

					setRegisteredMobileMoneyAccounts(allMobileWallets);
				}
			})
	}, [showNew, removeRequestCompleted]);

	useEffect(() => {
		// make ajax call for all supported mobile wallet operators
		const resourceUrl = `${Constants.BASE_URL}/users/v1/user/payment/method/mobile/wallet/operators`;
		const requestId = uuidv4();

		const currentUser = SERVICES.getUser();
		console.log('current user:', currentUser);
		const userId = currentUser ? currentUser.id : 0;
		const companyId = currentUser ? currentUser.companyId : 0;
		// const country = currentUser ? currentUser.country : null;
		// const countryName = country ? country.name.toUpperCase() : null;

		// make request for company
		axios.get(`${Constants.BASE_URL}/platform/company/v1/company/${companyId}?userId=${userId}`)
			.then(res => {
				const {data: {data}} = res;
				const country = data[0].country;
				const countryName = country ? country.name.toUpperCase() : null;
				console.log('data 0:', data[0]);

				const countryCode = country?.initial;

				setCountryCode(countryCode.toUpperCase());

				console.log('[ ' + requestId + ' ] about to make request to get all supported mobile wallet operators');

				axios.get(resourceUrl, { params: { country: countryName }})
					.then(res => {
						if (res.status === 200) {
							console.log(res.data);
							setSupportedMobileWalletOperators(res.data.data);
						}
					})
			})
	}, []);

	const handleChange = (e) => {
		console.log(e.target.value);
		setSelectedOperator(e.target.value);
	}

	const addMomoPaymentWallet = () => {
		if (!areNewCardFieldsOkay()) return;

		const currentUser = SERVICES.getUser();
		const userId = currentUser ? currentUser.id : 0;
		// make ajax call to add or update mobile wallet
		const resourceUrl = `${Constants.BASE_URL}/users/v1/user/${userId}/payment/method`;
		const requestId = uuidv4();

		let formattedMsisdn = msisdn;
		if (formattedMsisdn.charAt(0) === '+') {
			formattedMsisdn = formattedMsisdn.slice(1);
		}

		console.log('[ ' + requestId + ' ] about to make request to add or update mobile wallet');

		axios.post(resourceUrl, {
			paymentMethod: "mobileWallet",
			mobileWallet: {
				msisdn: formattedMsisdn,
				operator: selectedOperator
			}
		}).then(res => {
			if (res.status === 200) {
				console.log(res.data);

				// clear msisdn field and network operator field
				setMsisdn(undefined);
				setSelectedOperator('');

				setSuccessAlert(true);
				setSuccessMsg('Mobile Money Account added successfully');
			} else {
				console.log('an error occurred while adding new mobile money account:\n', res.data);
				setErrorAlert(true);
				setErrorMsg('There was a problem adding mobile money account');
			}
		}).catch(err => {
			console.log('an error occurred while adding new mobile money account');
			setErrorAlert(true);
			setErrorMsg('There was a problem adding mobile money account');
		})
	}

	const deleteRegisteredMobileWalletAccount = () => {
		const currentUser = SERVICES.getUser();
		const userId = currentUser ? currentUser.id : 0;
		const url = `${Constants.BASE_URL}/users/v1/user/${userId}/payment/method/${accountId}`;

		setRemoveRequestCompleted(false);

		axios.delete(url)
			.then(res => {
				if (res.status === 200) {
					console.log(res.data);

					setRemoveRequestCompleted(true);

					setSuccessAlert(true);
					setSuccessMsg('Mobile money account has been successfully removed');

					setMobileNumber('');
					setNetworkOperator('');
					setAccountId('');
				} else {
					setRemoveRequestCompleted(true);
					setErrorAlert(true);
					setErrorMsg('There was a problem removing mobile money account');

					setMobileNumber('');
					setNetworkOperator('');
					setAccountId('');
				}
			}).catch(err => {
				console.log(err.response.message);
				setRemoveRequestCompleted(true);
				setErrorAlert(true);
				setErrorMsg('There was a problem removing mobile money account');

			setMobileNumber('');
			setNetworkOperator('');
			setAccountId('');
		});
	}

	const changeSavedAccount = (e) => {
		const value = e.target.value;
		const [msisdn, operator, accountId] = value.split(',');

		setMobileNumber(msisdn);
		setNetworkOperator(operator);
		setAccountId(accountId);
	}

	return (
		<>
			<div style={{width: '60%'}}>
				<Row>
					<div className="col" onClick={() => setShowNew(true)} style={{cursor: 'pointer'}}>
						<h6>New Wallet</h6>
						<hr style={{ width: showNew && "35%", color: showNew ? "#017BEF" : 'white'}} />
					</div>
					<div className="col" onClick={() => setShowNew(false)} style={{cursor: 'pointer'}}>
						<h6>Saved Wallet</h6>
						<hr style={{ width: !showNew && "35%", color: !showNew ? "#017BEF" : "white"}} />
					</div>
				</Row>
				<div style={{display: showNew ? 'block' : 'none', marginTop: '20px'}}>
					<div className="form-group">
						<label htmlFor="">Mobile Operator</label>
						<select className={ isEmptyOperator ? "form-control is-invalid" : "form-control" } name="mobile_operator" value={selectedOperator} onChange={ handleChange } >
							<option selected={!selectedOperator} disabled value={ "" }>Select mobile wallet operator</option>
							{
								supportedMobileWalletOperators.length > 0 && supportedMobileWalletOperators.map(supportedOperator => {

									const label = supportedOperator?.label;

									return <option value={ supportedOperator?.operator }>{ label }</option>
								})
							}
						</select>
						<div className="invalid-feedback">Please select mobile wallet operator</div>
					</div>
					<br />
					<Form.Group controlId="formGroupEmail">
						<Form.Label>Phone Number</Form.Label>
						<PhoneInputStyle>
							<PhoneInput
								defaultCountry={countryCode}
								inputComponent={Form.Control}
								value={msisdn}
								onChange={(e) => {
									console.log(e);
									setMsisdn(e);
								}}
							/>
						</PhoneInputStyle>
						<div style={{display: isEmptyMsisdn ? 'block' : 'none', fontSize: '0.875em', marginTop: '0.25rem', color: '#dc3545'}}>{phoneNumberValidationError}</div>
					</Form.Group>
					{/*<div className="form-group">
						<label htmlFor="">Phone Number</label>
						<input
							type="text"
							className={ isEmptyMsisdn ? "form-control is-invalid" : "form-control" }
							onChange={e => setMsisdn(e.target.value)}
							value={msisdn}
						/>
						<div className="invalid-feedback">Please enter mobile number</div>
					</div>*/}
					<br />

					<button type='button' className="button" onClick={addMomoPaymentWallet}>Save</button>
				</div>
				<div style={{display: showNew ? 'none' : 'block', marginTop: '20px'}}>
					<div className="form-group">
						<label htmlFor="">Saved Accounts</label>
						<select className={ "form-control" } name="mobile_operator" onChange={changeSavedAccount}>
							<option selected={!mobileNumber && !networkOperator} disabled>Select already registered Mobile Money Account</option>
							{
								registeredMobileMoneyAccounts.length > 0 && registeredMobileMoneyAccounts.map(account => {

									const mobileWallet = account?.mobileWallet;
									const msisdn = mobileWallet?.msisdn;

									const accountId = account?.id;

									const operator = mobileWallet?.operator;
									const operatorLabel = operator?.label;

									return <option value={ `${msisdn},${operatorLabel},${accountId}` }>{ `${msisdn} - ${operatorLabel}` }</option>
								})
							}
						</select>
					</div>
					<br />
					<div className="form-group">
						<label htmlFor="">Mobile Operator</label>
						<input
							type="text"
							className="form-control"
							readOnly
							value={networkOperator}
						/>
					</div>
					<br />
					<div className="form-group">
						<label htmlFor="">Phone Number</label>
						<input
							type="text"
							className="form-control"
							readOnly
							value={mobileNumber}
						/>
					</div>
					<br/>
					<button type='button' className="button" disabled={!mobileNumber && !networkOperator} onClick={deleteRegisteredMobileWalletAccount}>Delete</button>
				</div>
			</div>
		</>
	);
};

export default MomoPaymentTab;
