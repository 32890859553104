import React, { Component } from "react";
import Alert from 'react-bootstrap/Alert'
import { FaRegWindowClose } from 'react-icons/fa'

export class ErrorAlert extends Component {
    constructor(){
        super();

        this.state = {};
    }

    setShow = (value) => {
        this.props.close(value);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if (this.props.show) {
        setTimeout(() => {
          this.setShow(false);
        }, 6000);
      }
    }

  render(){
        return <Alert className='error-alert desktop-alert' show={ this.props.show } variant={ 'danger' } style={this.props.alertStyle}>
        <div className="d-flex" style={{justifyContent:'space-between'}}>
        { this.props.message }
            <FaRegWindowClose onClick={ () => this.setShow(false) } variant="outline-danger" style={{width:'30px', marginTop:'auto', marginBottom:'auto'}}></FaRegWindowClose>
        </div>
  </Alert>
    }
}
