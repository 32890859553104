import styled from "styled-components";
import { colors, functions, snippets } from "../../../../styles";
import banner from "../../../../assets/img/png/banner_about.png";


const Styles = styled.div`
  * {
    font-size: 14px;
  }
  #Banner {
    padding: 80px;
    height: 80vh;
    max-height: 70rem;
    background-image: url(${banner});
    display: flex;
    flex-direction: column;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: ${functions.rgba(colors.black, 0.5)};
      width: 100%;
      height: 100%;
      z-index: 0;
    }
    &>* {
      z-index: 1;
      position: relative;
    }
    h1 {
      font-size: 96px;
      color: ${colors.white};
      a {
        font-size: inherit;
        color: inherit;
      }
    }
    .main-content {
      margin-top: auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      h2 {
        font-size: 72px;
        color: ${colors.white};
      }
      p {
        color: ${functions.rgba(colors.white, 0.6)};
        line-height: 28px;
        width: 40rem;;
      }
    }
  }
  #Topics {
    background-color: ${colors.white};
    width: 80%;
    max-width: 100rem;
    margin: 0 auto;
    margin-top: 80px;
    .topic {
      display: flex;
      gap: 80px;
      justify-content: center;
      &:not(:last-child) {
        margin-bottom: 80px;
      }
      &:nth-of-type(1) {
        h3 {
          &::after {
            top: auto;
            bottom: 0;
          }
        }
      }
      &:nth-of-type(even) {
        flex-direction: row-reverse;
        .description {
          text-align: right;
          .button {
            margin-left: auto;
          }
        }
      }
      .content {
        h3 {
          font-size: 43px;
          margin-bottom: 30px;
          padding: 20px 0;
          position: relative;
          text-transform: uppercase;
          &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 5px;
            width: 200px;
            background: linear-gradient(90deg, #FFAC12 0%, #000000 94.96%);
          }
        }
        .description {
          max-width: 500px;
          color: ${functions.rgba(colors.black, 0.6)};
          p {
            white-space: break-word;
          }
          .button {
            margin-top: 30px;
          }
        }
      }
      .image-cover {
        width: 589px;
        height: 553px;
        border-top-right-radius: 57px;
        overflow: hidden;
      }

    }
  }

  footer {
    margin-top: 80px;
    padding: 80px;
    background: linear-gradient(225.52deg, #000000 7.22%, #F85A47 100.47%);
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      padding-bottom: 30px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: ${functions.rgba(colors.white, 0.6)};
      }
      h2 {
        font-size: 42px;
        color: ${colors.white};
        span {
          color: #F85A47;
          font-size: inherit;
        }
      }
    }
    .bottom {
      ${snippets.flexCenter}
      .social-icons {
        display: flex;
        gap: 3rem;
      }
    }
  }

`;

export default Styles;
