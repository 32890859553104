/**
 * Project: spaces
 * File: Upload
 * Created by Pennycodes on 2/4/2022.
 * Copyright spaces
 */
import {Spaces} from "./Spaces";

class Upload {
    uploadComponent: NodeListOf<Element>

    constructor() {
        this.uploadComponent = Spaces.selectAll('.custom-file-upload')
        this._init()
    }
    private _init () {
        this.uploadComponent.forEach(function (el) {
            const fileUploadParent = '#' + el.id;
            const fileInput = Spaces.select(fileUploadParent + ' input[type="file"]');
            const fileLabel = Spaces.select(fileUploadParent + ' label');
            const fileLabelText = Spaces.select(fileUploadParent + ' label span');
            // @ts-ignore
            const fileLabelDefault = fileLabelText.innerHTML;
            // @ts-ignore
            fileInput.addEventListener('change', function (event: HTMLElementEvent) {
                const name = event.target.value.split('\\').pop();
                const tmpPath = URL.createObjectURL(event.target.files[0]);
                if (name) {
                    // @ts-ignore
                    fileLabel.classList.add('file-uploaded');
                    // @ts-ignore
                    fileLabel.style.backgroundImage = "url(" + tmpPath + ")";
                    // @ts-ignore
                    fileLabelText.innerHTML = name;
                }
                else {
                    // @ts-ignore
                    fileLabel.classList.remove("file-uploaded")
                    // @ts-ignore
                    fileLabelText.innerHTML = fileLabelDefault;
                }
            })
        })
    }
    public static bootstrap () {
        new Upload()
    }
}
export { Upload }
