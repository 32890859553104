import {FC} from "react";
import workTimeImage from '../../../assets/img/png/work-time.png';

const PricingCard: FC = () => {

    return (
        <section className={'row pricing-card'}>
            <div className="col-3">
                <section>
                    <h3>Enterprise</h3>
                    <hr/>
                    <div>
                        <p>Work from Anywhere</p>
                        <section>
                            <div>
                                <p></p>
                                <p>Co-work Spaces</p>
                            </div>
                            <div>
                                <p></p>
                                <p>Meeting Rooms</p>
                            </div>
                        </section>
                    </div>
                </section>
                <section>
                    <div>
                        <p></p>
                        <p>8 Co-Working Check-ins</p>
                    </div>
                    <div>
                        <p></p>
                        <p>1 Hour Meeting Room Access</p>
                    </div>
                </section>
                <section>
                    <button className="btn btn-primary">Choose plan</button>
                </section>
            </div>
            <div className="col">
                <section className={'blue-card'}>
                    <div>
                        <div className={'header price'}>
                            <h4>KES 12,000</h4>
                            <p>per month/user</p>
                        </div>
                        <div>
                            <p>Maximise your productivity</p>
                            <div>
                                <p></p>
                                <p>Fast & Secure Wi-Fi</p>
                            </div>
                            <div>
                                <p></p>
                                <p>F&B Discounts</p>
                            </div>
                        </div>
                    </div>
                    <figure>
                        <img src={workTimeImage} alt="Work Time Illustration"/>
                    </figure>
                </section>
            </div>
        </section>
    )
}

export default PricingCard