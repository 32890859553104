export const inputValidationService = {
    validateEmail,
    validatePhoneNo
}

function validateEmail(email){
    const regularExpression = RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    return regularExpression.test(email);
}

function validatePhoneNo(phoneNo){
    const regularExpression = RegExp(/\d/);
    return regularExpression.test(phoneNo);
}