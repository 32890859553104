import React, { Component } from "react";
import {Modal, Row} from 'react-bootstrap'
import { companyServiceRxJS } from './services/CompanyServiceRxJS.jsx'
import { inputValidationService } from './services/InputValidationService';
import { SuccessAlert } from './alerts/SuccessAlert';
import { ErrorAlert } from './alerts/ErrorAlert';
import { CompanyRegulatoryDocuments } from "./CompanyRegulatoryDocuments.jsx";
import closeButton from "../../assets/img/svg/blue-close-button.svg";
import successImage from "../../assets/img/svg/success-image.svg";
import SERVICES from "../../services";

export class CompanyProfile extends Component {
    constructor() {
        super();
        this.state = {
            logoUrl: "#",
            name: "",
            address : "",
            email : "",
            phoneNo : "",
            domain : "",
            webUrl : "",
            isEnabled : "",
            country : "",
            type : "",
            spaceType: "published",
            latitude : "",
            longitude : "",
            streetName : "",
            isNameInvalid : false,
            isAddressInvalid: false,
            isEmailInvalid: false,
            isPhoneNoInvalid: false,
            isDomainInvalid : false,
            isWebUrlInvalid: false,
            isCountryInvalid: false,
            feedbackMessage : "",
            showSuccess : false,
            showError : false,
            showSuccessModal: false,
            hasLogo: false,
            supportedCountries: []
        }
    }

    companyDetailsSubscription = null;
    updateProfileSubscription = null;
    supportedCountriesSubscription = null;
    uploadCompanyRegulatoryDocumentSubscription = null;
    

    componentDidMount = () => {

        const currentUserDetails = this.currentUserDetails();
        const companyId = currentUserDetails?.companyId;
        const userId = currentUserDetails?.id;
        const token = currentUserDetails?.token;

        //get a list of supported countries
        companyServiceRxJS.getSupportedCountries(token);

        //get current user's company details
        companyServiceRxJS.getCompanyDetailsSubject(companyId, userId);

        //listen to response to fetch company id
        this.companyDetailsSubscription = companyServiceRxJS.companyDetailsSubject.subscribe(e => {
            const keys = Object.keys(e);
            if (keys.length > 0) {
                const status = e?.status;

                if (status === 200) {
                    const data = e?.data;
                    if (data) {
                        data.map(value => {

                            this.setState({
                                name: value?.name,
                                address : value?.address
                            })

                            const contacts = value?.contacts;
                            const email = this.getCompanyEmail(contacts);
                            const phoneNo = this.getPhoneNumber(contacts);
                            
                            const domain = value?.domain;
                            const webUrl = value?.webUrl;
                            const isEnabled = value?.isEnabled;
                            const country = value?.country;
                            const type = value?.type;
                            const latitude = value?.latitude;
                            const longitude = value?.longitude;
                            const streetName = value?.streetName;
                            const spaceType = value?.spaceType;

                            this.setState({
                                email : email,
                                phoneNo : phoneNo,
                                domain : domain,
                                webUrl : webUrl,
                                isEnabled : isEnabled,
                                country : country?.initial,
                                type : type,
                                spaceType: spaceType,
                                latitude : latitude,
                                longitude : longitude,
                                streetName : streetName
                            });

                            return null;
                        });
                    }
                } else {
                    this.setState({
                        showSuccess : false,
                        showError : true,
                        feedbackMessage : "Error occurred while fetching your company profile."
                      });
                }
            }
        });

        //listen to response to update company details
        this.updateProfileSubscription = companyServiceRxJS.updateProfileSubject.subscribe(e => {
            const keys = Object.keys(e);
            if(keys.length > 0){
                const status = e?.status;
                let message = e?.message;
                message = message.charAt(0).toUpperCase() + message.slice(1);

                if(status === 200){
                    this.setState({
                        showSuccess : true,
                        showSuccessModal: true,
                        showError: false,
                        feedbackMessage : "Update company profile completed successfully"
                      });
                } else {
                    this.setState({
                        showSuccess : false,
                        showSuccessModal: false,
                        showError : true,
                        feedbackMessage : message
                      });
                }
            }
        });

        //listen to get supported countries response
        this.supportedCountriesSubscription = companyServiceRxJS.supportedCountriesSubject.subscribe(e => {

            const keys = Object.keys(e);

            if(keys.length > 0){
                const status = e?.status;

                if(status === 200){
                    const data = e?.data;

                    if (data) {
                        let countries = [];

                        data.map(value => {
                            countries.push(value);
                            return null;
                        });

                        this.setState({
                            supportedCountries: countries
                        });

                    }
                } else {

                }
            }
        });

        //subscribe to company document upload response
        this.getCompanyDocumentUploadResponse();
    }

    componentWillUnmount = () => {

        if(this.companyDetailsSubscription !== undefined && this.companyDetailsSubscription !== null){
            this.companyDetailsSubscription.complete();
    
        }

        if(this.updateProfileSubscription !== undefined && this.updateProfileSubscription !== null){
            this.updateProfileSubscription.complete();
        }

        if(this.supportedCountriesSubscription !== undefined && this.supportedCountriesSubscription !== null){
            this.supportedCountriesSubscription.complete();
        }

        if(this.uploadCompanyRegulatoryDocumentSubscription !== undefined && this.uploadCompanyRegulatoryDocumentSubscription !== null){
            this.uploadCompanyRegulatoryDocumentSubscription.complete();
        }
        
        companyServiceRxJS.resetCompanyDetailsSubject();
        companyServiceRxJS.resetGetCompanyDetailsSubject();

        this.setState({
          showSuccess : false,
          showError : false,
          feedbackMessage: ""
        });
    
      }

    currentUserDetails = () => {
        let userDetails = {};
        const jsonStr = localStorage.getItem("currentUser");

        if (jsonStr !== undefined) {
            userDetails = JSON.parse(jsonStr);
        }

        return userDetails;
    }

    getCompanyEmail = (contacts) => {
        let email = "";
        if(contacts.length > 0){
            contacts.map(contact => {
                const type = contact?.contactType;
                if(type === 'email'){
                    email = contact?.value;
                }
                return null;
            });
        }
        return email;
    }

    getPhoneNumber = (contacts) => {
        let mobile = "";
        if(contacts.length > 0){
            contacts.map(contact => {
                const type = contact?.contactType;
                if(type === 'mobile'){
                    mobile = contact?.value;
                }
                return null;
            });
        }
        return mobile;
    }

    onSubmit = (event) => {
        event.preventDefault();

        const currentUserDetails = this.currentUserDetails();
        const companyId = currentUserDetails?.companyId;
        const userId = currentUserDetails?.id;
        const token = currentUserDetails?.token;

        const { 
            name, 
            address, 
            email, 
            phoneNo, 
            domain,
            webUrl,
            isEnabled,
            country,
            type,
            spaceType,
            latitude,
            longitude,
            streetName
         } = this.state;

        const errors = [];

        if((name === undefined) || (name === "") || (name != null && name.trim() === "") || (name != null && name.trim().length === 0)){
            errors.push("invalid or empty company name");
            this.setState({
                isNameInvalid : true
            });
        } else {
            this.setState({
                isNameInvalid : false
            });
        }

        const isEmailInvalid = inputValidationService.validateEmail(email);
        if(!isEmailInvalid){
            errors.push("invalid or empty company email");
            this.setState({
                isEmailInvalid : true
            });
        } else {
            this.setState({
                isEmailInvalid : false
            });
        }

        const isPhoneNoInvalid = inputValidationService.validatePhoneNo(phoneNo);
        if(!isPhoneNoInvalid){
            errors.push("invalid or empty company phone number");
            this.setState({
                isPhoneNoInvalid : true
            });
        } else {
            this.setState({
                isPhoneNoInvalid : false
            });
        }

        if((address === undefined) || (address === "") || (address != null && address.trim() === "") || (address != null && address.trim().length === 0)) {
            errors.push("invalid or empty company address");
            this.setState({
                isAddressInvalid : true
            });
        } else {
            this.setState({
                isAddressInvalid : false
            });
        }

        if(errors.length === 0){
            let payload = {};

            const location = [{ latitude : "0.00", longitude: "0.00", streetName: "NA" }];

            payload['id'] = companyId;
            payload['name'] = name;
            payload['address'] = address;
            payload['userId'] = userId;
            payload['domain'] = domain;
            payload['webUrl'] = webUrl;
            payload['enabled'] = isEnabled;
            payload['country'] = country;
            payload['type'] = type;
            payload['spaceType'] = spaceType;
            payload['location'] = location;

            const contacts = [
                {
                   contactType: "email",
                   id: null,
                   value: email
                },
                {
                    contactType: "mobile",
                    id: null,
                    value: phoneNo
                },
            ]
            
            payload['contacts'] = contacts;

            console.log("payload to be sent : "+ JSON.stringify(payload));
            companyServiceRxJS.updateProfile(userId, companyId, payload, token);
        }



        if(errors.length > 0){
            return;
        }
    }

    handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name] : value
        })
    }

    toastCloseHandler = (value) => {
        this.setState({
          showSuccess : value,
          feedbackMessage: ""
        })
      }
    
      toastCloseErrorHandler = (value) => {
        this.setState({
          showError : value,
          feedbackMessage: ""
        })
      }

    getCompanyDocumentUploadResponse = () => {
        this.uploadCompanyRegulatoryDocumentSubscription = companyServiceRxJS.uploadCompanyRegulatoryDocumentSubject.subscribe(res => {
            const keys = Object.keys(res);

            if(keys.length > 0){
                const status = res?.status;
                let message = res?.message;

                if(status === 200){
                    this.setState({
                        showSuccess: true,
                        showError: false,
                        feedbackMessage: "The document was uploaded successfully"
                    });
                } else {
                    message = this.setErrorMessageByCode(status);
                    
                    this.setState({
                        showSuccess: false,
                        showError: true,
                        feedbackMessage: message
                    })
                }
            }
        });
    }

    setErrorMessageByCode = (status) => {
        let message = null;
        if (status === 413) {
            message = "File size too large. Kindly upload a file less than 1MB in size.";
        } else if (status === 415) {
            message = "Unsupported file type. Kindly upload a PDF or image file such as JPG or PNG."
        } else if (status === 408) {
            message = "Server taking too long to respond"
        } else if (status === 429) {
            message = "We're unable to process your request at this time. Please try again later."
        }
        return message;
    }

    handleStuffOnModalHide = () => {
        this.setState({
            showSuccessModal : false
        })
    }

    render() {
        const {
            logoUrl,
            name,
            address,
            email,
            phoneNo,
            isNameInvalid,
            isAddressInvalid,
            isEmailInvalid,
            isPhoneNoInvalid,
            isDomainInvalid,
            isWebUrlInvalid,
            isCountryInvalid,
            feedbackMessage,
            showSuccess,
            showError,
            hasLogo,
            domain,
            webUrl,
            country,
            spaceType,
            supportedCountries
        } = this.state;

        return <div className={'company-profile-container'}>
            <SuccessAlert show={ showSuccess } message={ feedbackMessage } close={ this.toastCloseHandler }/>
            <ErrorAlert show={ showError } message={ feedbackMessage } close={ this.toastCloseErrorHandler }/>
            <h4>Company Profile</h4>
            {/* <div className="ellipse">
                <img src={ hasLogo ? logoUrl : defaultCompanyLogo } alt={""} className="ellipse" />
                <FaCamera className="ellipseAttribute" color="blue" />
            </div> */}
            <form onSubmit={ this.onSubmit }>
                <br />
                <Row>
                <div className="form-group col">
                        <label htmlFor="">Name</label>
                        <input type="text" className={ isNameInvalid ? "form-control is-invalid" : "form-control"} defaultValue={ name } onChange={ this.handleChange } name="name" id="" aria-describedby="emailHelpId" placeholder="" />
                        <div className="invalid-feedback">Your company name is required</div>
                    </div>
                    <br />
                </Row>
                <Row>
                    <div className="form-group col">
                        <label htmlFor="">Phone Number</label>
                        <input type="text" className={ isPhoneNoInvalid ? "form-control is-invalid" : "form-control" } defaultValue={ phoneNo } onChange={ this.handleChange } name="phoneNo" id="" aria-describedby="emailHelpId" placeholder="" />
                        <div className="invalid-feedback">Your company phone number is required</div>
                    </div>
                    <br />
                    <div className="form-group col">
                        <label htmlFor="">E-mail</label>
                        <input type="text" className={ isEmailInvalid ? "form-control is-invalid" : "form-control" } defaultValue={ email } onChange={ this.handleChange } name="email" id="" aria-describedby="emailHelpId" placeholder="" />
                        <div className="invalid-feedback">Your company e-mail is required</div>
                    </div>
                    <br />
                </Row>

                <Row>
                    <div className="form-group col">
                        <label htmlFor="">Domain </label>
                        <input type="text" className={ isDomainInvalid ? "form-control is-invalid" : "form-control" } defaultValue={ domain } onChange={ this.handleChange } name="domain" id="" aria-describedby="emailHelpId" placeholder="" />
                        <div className="invalid-feedback">Your company internet domain name is required</div>
                    </div>
                    <br />
                    <div className="form-group col">
                        <label htmlFor="">Website Url </label>
                        <input type="text" className={ isWebUrlInvalid ? "form-control is-invalid" : "form-control" } defaultValue={ webUrl } onChange={ this.handleChange } name="webUrl" id="" aria-describedby="emailHelpId" placeholder="" />
                        <div className="invalid-feedback">Your company e-mail is required</div>
                    </div>
                    <br />
                </Row>

                <Row>
                    <div className="form-group col">
                        <label htmlFor="">Address</label>
                        <textarea className={ isAddressInvalid ? "form-control is-invalid" : "form-control" } defaultValue={ address } onChange={ this.handleChange } name="address" id="" aria-describedby="emailHelpId" placeholder=""></textarea>
                        <div className="invalid-feedback">Your company address is required</div>
                    </div>
                </Row>

                <Row>
                    <div className="form-group col">
                        <label htmlFor="">Country</label>
                        <select className={ isCountryInvalid ? "form-control is-invalid" : "form-control" } name="country" onChange={  this.handleChange } >
                            <option selected disabled value={ "" }>Select operating country</option>
                            {
                                supportedCountries.map(supportedCountry => {

                                    const countryName = supportedCountry?.name;

                                    return <option value={ supportedCountry?.initial } selected={ supportedCountry?.initial === country }>{ countryName.charAt(0).toUpperCase() + countryName.substr(1) }</option>
                                })
                            }
                        </select>
                        <div className="invalid-feedback">Your company operating country is required</div>
                    </div>
                    <br />
                
                <div className="form-group col">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                {/*    <div className="form-group col">
                        <label htmlFor="">Listing category</label>
                        <select className='form-control' value={spaceType} name="spaceType" onChange={  this.handleChange } >
                            <option selected disabled value={ "" }>Select your listing category</option>
                            <option value="low">Enterprise</option>
                            <option value="medium">Corporate</option>
                            <option value="high">Corporate Plus</option>
                        </select>
                    </div> */}
                    <br />
                {/*</div>*/}
                </Row>
                <br />

                {/* <h6>TEST</h6> */}
                <CompanyRegulatoryDocuments />

                <button type="submit" className="button" style={{width: '20%'}}>Save</button>
                <br />
            </form>
{/* 
            <Modal show={this.state.showSuccessModal} onHide={this.handleStuffOnModalHide} onEnter={e => console.log(e)}  style={{
                    overflow: "hidden",
                    borderRadius: "30px",
                }}>
                 <Modal.Header
                    style={{
                        borderBottom: "none",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        margin: "0px",
                    }}
                    closeButton
                >
                <div className=""></div>
                </Modal.Header>
                <Modal.Body style={{height: '310px'}}>
                    <div className="text-center position-relative" style={{bottom: '55px'}}>
                        <figure>
                            <img
                              className={'position-relative'}
                              src={successImage}
                              alt="Finish Setup Illustration"
                              style={{width: '40%'}}
                            />
                        </figure>
                        <p className={'text-primary font-weight-bolder mb-2'} style={{fontSize: '1.03rem'}}>Submitted Successfully</p>
                        <p style={{fontSize: '0.89rem'}}>
                            We have received your documents and will get back to you within 2-3 business days
                        </p>
                        <div className={'mt-4'}>
                            <button
                              className={'btn btn-primary text-white'}
                              style={{cursor: 'pointer'}}
                              onClick={() => {
                                  this.setState({
                                      showSuccessModal: false
                                  })
                              }
                            }
                            >
                                Resubmit
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal> */}
        </div>
    }
}

export default CompanyProfile;
