import {FC} from "react";
import {BundleOffer} from "../../../data/package-select-data";
import tick from '../../../assets/img/svg/blue-white-check.svg';

interface Props {
    offers: BundleOffer
}

const PackageSelectOffersCard: FC<Props> = ({offers}) => {

    return (
        <section className="pkg-select-offers-card">
            <h4 style={{textDecoration: 'underline', fontSize: '.789rem'}}>Work From Anywhere</h4>
            <div className={'work-from-anywhere'}>
                {
                    offers.workFromAnywhere && offers.workFromAnywhere.map(value => {
                        return (
                            <div className={'d-flex'}>
                                <figure>
                                    <img src={tick} alt="Check" style={{width: '14px'}} />
                                </figure>
                                <div style={{marginLeft: '5px', color: '#262626'}}>
                                    {
                                        value
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <h4 className={'mt-3'} style={{textDecoration: 'underline', fontSize: '.789rem'}}>Maximise your productivity</h4>
            <div className={'maximise-productivity'}>
                {
                    offers.workFromAnywhere && offers.maximizeProductivity.map(value => {
                        return (
                            <div className={'d-flex'}>
                                <figure>
                                    <img src={tick} alt="Check" style={{width: '14px'}}/>
                                </figure>
                                <div style={{marginLeft: '5px', color: '#262626'}}>
                                    {
                                        value
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </section>
    )
}

export default PackageSelectOffersCard