import React, {ChangeEvent, useState} from "react";
import { Link, useHistory } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Styles from "./styles";
import { SelectField, TextField } from "../../../../components";
import useInputEntry from "../../../../utils/forms";
import { SelectFieldOption, Enquiry } from "../../../../types";

import arrow_right from "../../../../assets/img/svg/arrow_right.svg";
import point from "../../../../assets/img/png/point_male.png";
import logo from '../../../../assets/img/spacia/web/spacia_logo_new.png';
import google from "../../../../assets/img/png/google.png";
import apple from "../../../../assets/img/svg/apple.svg";
import {Constants} from "../../../../utils/constants";
import axios from "axios";
import HubspotEmbed from "../hubspot";


const Home = () => {
  const openHours: SelectFieldOption[] = [
    {
      value: "1600",
      label: "4pm"
    },
    {
      value: "1000",
      label: "10am"
    }
  ]

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [msisdn, setMsisdn] = useState('');
  const [newsEmail, setNewsEmail] = useState('');

  // const {
  //   value: fullName,
  //   stringChange: fullNameChange,
  // } = useInputEntry("");
  // const {
  //   value: email,
  //   stringChange: emailChange
  // } = useInputEntry("");
  // const {
  //   value: msisdn,
  //   stringChange: msisdnChange
  // } = useInputEntry("");
  // const {
  //   value: hours,
  //   selectChange: hoursChange
  // } = useInputEntry(
  //   openHours[0].value,
  //   openHours.map(option => option.value)
  // );
  // const {
  //   value: newsEmail,
  //   stringChange: newsEmailChange
  // } = useInputEntry("");

  const history = useHistory();

  const goToLogin = () => {
    history.push('/signin');
  }

  const goToSignUp = () => {
    history.push('/signup');
  }

  const goToAboutPage = () => {
    history.push('/about');
  }

  const submitEnquiry = (event: any): void => {
    event.preventDefault();

    const enquiry: Enquiry = {
      name: fullName,
      email,
      phoneNumber: msisdn
    }

    const resourceUrl = `${Constants.BASE_URL}/platform/company/api/v1/enquiry`;
    axios.post(resourceUrl, enquiry)
        .then(res => {
          if (res.status === 200) {
            toast.success('We have received your submission',
                { theme: 'colored' });

            setFullName('');
            setEmail('');
            setMsisdn('');
          }
        })
        .catch(err => {
          toast.error('Sorry, error submitting your information', { theme: 'colored'});
        })
  }

  const subscribeToNewsletter = (event: any): void => {
    event.preventDefault();

    const resourceUrl = `${Constants.BASE_URL}/platform/company/api/v1/newsletter/subscribe`;
    axios.post(resourceUrl, {
      email: newsEmail
    })
        .then(res => {
          if (res.status === 200) {
            toast.success('We have received your submission',
                { theme: 'colored' });
          }

          setNewsEmail('');
        })
        .catch(err => {
          toast.error('Sorry, error submitting your information', { theme: 'colored'});
        })
  }


  return (
      <Styles className="root-page">

        {/* Banner */}
        <div id="Banner">
          <div className="nav-bar" style={{marginTop: '-50px'}}>
            <div>
              <figure>
                <img src={logo} alt="Spacia Logo"/>
              </figure>
            </div>
            <div className="wrapper" style={{marginLeft: '-530px'}}>
              <h3 style={{fontWeight: 'bolder', fontSize: '1.09rem', fontStyle: 'normal'}}>SPACIA ENTERPRISE</h3>
            </div>
            <div className="wrapper">
              <div onClick={goToLogin} className='login'>Login</div>
              <button className="button flex" type='button' onClick={goToSignUp}>
                <div>SIGN UP</div>
                <img src={arrow_right} alt="" />
              </button>
            </div>
          </div>
          <div className="main-content">
            <h1>The Future of Work is in SPACIA</h1>
            <p>
              The Spacia Enterprise App empowers your employees to work from <br />
              anywhere. We have a wide network of vetted workspaces so that <br />
              your employees working remotely can search for, book and access <br />
              remote workspaces on demand through our customizable app.
            </p>
            <div className="button-row">
              {/*<button type='button' className="button" style={{borderRadius: '37px', padding: '15px 36px'}} onClick={goToSignUp}>*/}
              {/*  Get Started*/}
              {/*</button>*/}
              <button type='button' className="button transparent-white" style={{borderRadius: '37px', padding: '15px 36px'}} onClick={goToAboutPage}>
                Learn More
              </button>
            </div>
          </div>
        </div>

        {/* Cards */}
        <div id="Cards">
          <div className="content">
            <div className="card">
              <h2>Enhance your teams productivity</h2>
              <p>
                Your team’s productivity is heavily dependent on the environment they choose to work in.
                We have a network of vetted quality remote co-working spaces, hot desks, meeting and
                conference rooms <br /> in the different zones of Nairobi.
              </p>
            </div>
            <div className="card">
              <h2>Manage Team’s Usage</h2>
              <p>
                Monitor and track your team’s use of remote working spaces.
                Corporate Administrators can onboard team members, approve requests
                and monitor usage through the Spacia Enterprise App.
              </p>
            </div>
            <div className="card primary">
              <h2>Billing and Monthly Reporting</h2>
              <p>
                Receive monthly usage reports and automated billing
                and payments made through our simple <br /> user-friendly app.
              </p>
            </div>
          </div>
        </div>

        {/* Call to Action */}
        <div id="CTA">
          <ToastContainer
              position="top-center"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              style={{minWidth: '350px'}}
          />
          <div className="content">
            <img src={point} alt="" />
            <div className="form">
              <h2>Book an Appointment</h2>
              <HubspotEmbed />
              {/*<h2>Get your team on Spacia</h2>*/}
              {/*<form onSubmit={submitEnquiry}>
                <TextField
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    label="Name *"
                    placeholder="Full Name *"
                />
                <TextField
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    label="Email Address *"
                    placeholder="example@gmail.com"
                />
                <TextField
                    value={msisdn}
                    onChange={(e) => setMsisdn(e.target.value)}
                    label="Phone Number *"
                    placeholder="Phone number"
                />
                <button className="button" type="submit">
                  Submit
                </button>
              </form>*/}
            </div>
          </div>
        </div>

        {/* Newsletter */}
        <div id="Newsletter">
          <div className="content">
            <h2>
              Subscribe to our Latest<br />Newsletter
            </h2>
            <form className="fused-text-field-button" onSubmit={subscribeToNewsletter}>
              <input type="text" placeholder='Your email' value={newsEmail} onChange={(e) => setNewsEmail(e.target.value)}/>
              <button
                  className="button"
                  type="submit"
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>

        {/* Footer */}
        <footer>
          <div className="content">
            <div className="list">
              <h4>Company</h4>
              <ul>
                <li><Link to="/about" className='about-link'>About Us</Link></li>
                <li><a href="https://blog.spacia.africa" target="_blank" rel='noopener noreferrer'>Blog</a></li>
                <li><Link to='/frequently-asked-questions'>FAQs</Link></li>
              </ul>
            </div>
            <div className="list">
              <h4>Resources</h4>
              <ul>
                <li>Pricing</li>
                <li><Link to='/report-issue'>Report a technical problem</Link></li>
              </ul>
            </div>
            <div className="list">
              <h4>Get in touch</h4>
              <ul>
                <li><span>Phone:</span> 0732188105</li>
                <li>Email: info@spaciaafrica.com</li>
                <li>The Address, Muthangari Dr Off Wayiaki Way,15th Floor</li>
              </ul>
            </div>
            {/*<div className="download-links">*/}
            {/*  <img src={apple} alt="" />*/}
            {/*  <img src={google} alt="" />*/}
            {/*</div>*/}
          </div>
        </footer>
      </Styles>
  )
}

export default Home;
