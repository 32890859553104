import React, {useEffect, useState} from "react";
import { toAbsoluteUrl } from "../helpers";
import {
  CloseCircle,
  LogoFacebook,
  LogoInstagram,
  LogoTwitter,
  MenuOutline,
} from "react-ionicons";
import {Link} from "react-router-dom";
import {Enquiry} from "../../types";
import {Constants} from "../../utils/constants";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {User} from "../../utils/interfaces";
import SERVICES from "../../services";
// import { Link } from "react-router-dom";

export type HostLandingLayoutProps = {
  children?: React.ReactNode;
  alternateHeader?:React.ReactNode;
  alternateColors?: boolean;
};

export default function HostLandingLayout({
                                            children,alternateColors,alternateHeader
                                          }: HostLandingLayoutProps) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [msisdn, setMsisdn] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const capsule = document.querySelector("#appCapsule");

    if(alternateColors) {
      capsule && capsule.classList.remove("add-safe-space");
    }
  }, []);

  const submitEnquiry = (): void => {
    const fullName = `${firstName} ${lastName}`;

    const enquiry: Enquiry = {
      name: fullName,
      email,
      phoneNumber: msisdn,
      message
    }

    const resourceUrl = `${Constants.BASE_URL}/platform/company/api/v1/enquiry`;
    axios.post(resourceUrl, enquiry)
        .then(res => {
          if (res.status === 200) {
            toast.success('We have received your submission',
                { theme: 'colored' });

            setFirstName('');
            setLastName('');
            setEmail('');
            setMsisdn('');
            setMessage('');
          }
        })
        .catch(err => {
          toast.error('Sorry, error submitting your information', { theme: 'colored'});
        })
  }

  const isLoggedIn = (user: User) => {
    return user != null && Object.keys(user).length !== 0;
  }

  const redirectBasedOnRole = () => {
    const currentUser = SERVICES.getUser() as User;
    let destination = "/startBooking";

    if (!isLoggedIn(currentUser)) {
      destination = "/signin";
    } else {
      const role = currentUser.role;

      if (role != null) {
        switch (role) {
          case 'ROLE_ADMINISTRATOR':
          case 'ROLE_HOST':
          case 'ROLE_SUBSCRIBER_INDIVIDUAL':
          case 'ROLE_SUBSCRIBER_APPROVER':
          case 'ROLE_SUBSCRIBER_OWNER':
          case 'ROLE_HOST_OPERATIONS':
            destination = 'searchPage'
            break;

          default:
            destination = '/signin';
        }
      }
    }

    return destination;
  };

  return (
    <div>
      <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          style={{minWidth: '350px'}}
      />
      <div className={"d-flex flex-column"}>
        <div className={"d-flex flex-column  "+`${alternateColors?"responsive-banner":""}`}
             style={alternateColors?{
               background:`url("${toAbsoluteUrl("/img/landing-desktop-background-mobile.jpeg")}")`,
             }:{}}>
        <header
          className={
            "d-flex flex-row justify-content-between align-items-center px-3"
          }
        >
          <div className={"imaged "}>
            <img
              alt={"logo"}
              decoding={"async"}
              className={"w100"}
              src={toAbsoluteUrl(alternateColors && alternateColors ? "/img/tower-logo.png" : "/img/logo-black.png")}
            />
          </div>
          <div className={"mobile-show"}>
            <a
              href="/#"
              className="headerButton  me-1"
              data-bs-toggle="modal"
              data-bs-target="#sidebarPanel"
              style={{fontSize: "41px"}}
            >
              <MenuOutline color={"#000000"} title={""} />
            </a>
          </div>
        </header>
          {alternateHeader}
          </div>
        {children}
        {/*Footer*/}
        <section
            className={
              "section px-3 pt-3 background-size-position d-flex flex-column"
            }
            style={{
              backgroundImage: `url(${toAbsoluteUrl(
                  "/img/diverse-women-mobile.jpeg"
              )}), linear-gradient(89.83deg, rgb(255, 255, 255) 20.24%, rgb(255, 255, 255) 40.25%, rgba(255, 255, 255, 0.8) 99.55%)`,
            }}
        >
          <div
              className={"mx-auto"}
              style={{ width: "clamp(350px,340px + 0.1vw , 384px)" }}
          >
            <h1
                className={"text-center w-75 mx-auto mb-2"}
                style={{
                  fontSize: "25px",
                  lineHeight: "53px",
                  // fontFamily: "Monserrat",
                  fontWeight: 500,
                }}
            >
              Get in touch with our Sales Team
            </h1>
            <div className="form-group basic">
              <div className="input-wrapper">
                <input
                    type="text"
                    className="form-control floating-input"
                    id="firstName"
                    placeholder=" "
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                    style={{background: "#F9F9F9",
                      border:"1px solid #E6E6E6",}}
                />
                <label style={{background:"rgba(0,0,0,0)",color: "#737373"}} className="floating-label form-label-contact form-label-email" htmlFor="firstName">
                  First Name*
                </label>
                <i className="clear-input">
                  <CloseCircle color={"#00000"} title={""} />
                </i>
              </div>
            </div>

            <div className="form-group basic">
              <div className="input-wrapper">
                <input
                    type="text"
                    className="form-control floating-input"
                    id="lastName"
                    placeholder=" "
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                    style={{background: "#F9F9F9",
                      border:"1px solid #E6E6E6",}}
                />
                <label style={{background:"rgba(0,0,0,0)",color: "#737373"}} className="floating-label form-label-contact form-label-email" htmlFor="lastName">
                  Last Name*
                </label>
                <i className="clear-input">
                  <CloseCircle color={"#00000"} title={""} />
                </i>
              </div>
            </div>

            <div className="form-group basic">
              <div className="input-wrapper">
                <input
                    type="email"
                    className="form-control floating-input"
                    id="email1"
                    placeholder=" "
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    style={{background: "#F9F9F9",
                      border:"1px solid #E6E6E6",}}
                />
                <label style={{background:"rgba(0,0,0,0)",color: "#737373"}} className="floating-label form-label-contact form-label-email" htmlFor="email1">
                  E-mail*
                </label>
                <i className="clear-input">
                  <CloseCircle color={"#00000"} title={""} />
                </i>
              </div>
            </div>
            <div className="form-group basic " >
              <div className="input-wrapper did-input-group">
                {/*<span className="did-input-group-prepend">*/}
                {/*	<div className="did-input-group-text">*/}
                {/*		<img*/}
                {/*			src={toAbsoluteUrl("/img/ghana.svg")}*/}
                {/*			alt={"gh"}*/}
                {/*			width={15}*/}
                {/*		/>{" "}*/}
                {/*		&nbsp; +233*/}
                {/*	</div>*/}
                {/*</span>*/}
                <input
                    type="number"
                    min={0}
                    minLength={9}
                    maxLength={10}
                    className="form-control floating-input"
                    id="phone"
                    placeholder=" "
                    value={msisdn}
                    onChange={e => setMsisdn(e.target.value)}
                    style={{background: "#F9F9F9",
                      border:"1px solid #E6E6E6",}}

                />
                <label style={{background:"rgba(0,0,0,0)",color: "#737373"}} className="floating-label form-label-contact" htmlFor="phone">
                  Phone Number
                </label>
                <i className="clear-input">
                  <CloseCircle color={"#00000"} title={""} />
                </i>
              </div>
              {/*<div className="input-info">e.g 555xxxxxx</div>*/}
            </div>

            {/*<div className="form-group basic">*/}
            {/*  <div className="input-wrapper">*/}
            {/*    <select*/}
            {/*        className="floating-label form-label-contact form-control floating-select"*/}
            {/*        id={"availability"}*/}
            {/*        style={{background: "#F9F9F9",*/}
            {/*          border:"1px solid #E6E6E6",color: "#737373"}}*/}
            {/*    >*/}
                  {/*<option disabled value="" />*/}
                  {/*<option value="4:00 Available">4:00 Available</option>*/}
                  {/*<option value="host">Host</option>*/}
                {/*</select>*/}

                {/*<label className="floating-label" htmlFor="availability">*/}
                {/*  Role*/}
                {/*</label>*/}
              {/*</div>*/}
            {/*</div>*/}
            <div className="form-group basic">
              <div className="input-wrapper">
							<textarea
                                className="form-control floating-input"
                                id="message"
                                placeholder=" "
                                value={message}
                                onChange={e => setMessage(e.target.value)}
                                style={{background: "#F9F9F9",height: 140,
                                  border:"1px solid #E6E6E6",}}
                            />
                <label  style={{background:"rgba(0,0,0,0)",color: "#737373"}} className="floating-label form-label-contact" htmlFor="message">
                  Message
                </label>
                <i className="clear-input">
                  <CloseCircle color={"#00000"} title={""} />
                </i>
              </div>
            </div>
            <div className="mt-4 w-75 mx-auto">
              <button
                  type={"button"}
                  className={"btn btn-primary btn-block btn-lg active mb-4"}
                  style={{ borderRadius: "50rem" }}
                  onClick={submitEnquiry}
              >
                Book Appointment
              </button>
            </div>
          </div>
        </section>
        {/*call to action*/}
        <section
            className={"section px-3 pt-5 pb-5"}
            style={{ backgroundColor: "#2EC5CE" }}
        >
          <h2 className={"mt-4 fontsize-headingXLarge w-75"}>
            Coming <span style={{ color: "white" }}>Soon</span>
          </h2>

          <p
              className={"fontsize-heading"}
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "43px",
                letterSpacing: "0.8px",
                color: "#18191F"
              }}
          >
            Search, compare & book flexible workspaces, accommodation and other
            lifestyle services from the convenience of the Spacia app
          </p>
          <div
              className={"mx-auto"}
              style={{ width: "clamp(350px,340px + 0.1vw , 384px)" }}
          >
            <div className={"imaged d-flex flex-row justify-content-center mb-4"}>
              <img
                  alt={"phone-mock-up"}
                  decoding={"async"}
                  src={toAbsoluteUrl("/img/phone-mock-up.png")}
              />
            </div>
            <div className={"px-2"}>
              <h3
                  className={"fontsize-sub-footer fontsize-heading mb-2 ms-1"}
                  style={{ fontWeight: 500 }}
              >
                Get the App
              </h3>
              <div className={"d-flex flex-row w-100 justify-content-center"}>
                <button
                    className={"btn me-1 py-1"}
                    style={{ backgroundColor: "#000000", height: 60 }}
                >
                  <div className={"d-flex flex-row align-items-center"}>
                    <div
                        className={"imaged d-flex flex-row justify-content-center"}
                    >
                      <img
                          alt={"phone-mock-up"}
                          decoding={"async"}
                          className={"w16"}
                          src={toAbsoluteUrl("/img/google-play.png")}
                      />
                    </div>
                    <div
                        className={
                          "d-flex flex-column ms-1 justify-content-between"
                        }
                    >
										<span
                                            className={
                                              "text-uppercase fs-10 fw-bold text-white"
                                            }
                                        >
											coming soon on
										</span>
                      <span
                          className={"text-capitalize fontsize-normal text-white"}
                      >
											Google play
										</span>
                    </div>
                  </div>
                </button>
                <button
                    className={"btn me-1 py-1"}
                    style={{ backgroundColor: "#000000", height: 60 }}
                >
                  <div className={"d-flex flex-row align-items-center"}>
                    <div
                        className={"imaged d-flex flex-row justify-content-center"}
                    >
                      <img
                          decoding={"async"}
                          className={"w16"}
                          src={toAbsoluteUrl("/img/apple-logo.png")}
                          alt={"logo"}
                      />
                    </div>
                    <div
                        className={
                          "d-flex flex-column ms-1 justify-content-between"
                        }
                    >
										<span className={"text-white fs-10 fw-bold"}>
											Coming Soon on the
										</span>
                      <span
                          className={
                            "text-capitalize fw-bold fontsize-normal text-white"
                          }
                      >
											App Store
										</span>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </section>
        <footer
          className={"pt-5 pb-200 px-4 d-flex flex-column"}
          style={{ backgroundColor: "#000000", paddingBottom: "100px" }}
        >
          <div className={"row mt-4"}>
            <div
              className={
                "col-sm-6 col-md-6 col-6 d-flex flex-column  justify-content-center"
              }
            >
              <span
                className={
                  "text-capitalize fontsize-sub-footer  fw-bold text-white"
                }
              >
                get started
              </span>
              <Link
                  to={redirectBasedOnRole()}
                className={"text-white fontsize-sub-footer  text-capitalize"}
              >
                Find a space
              </Link>
              <Link
                  to={'/host'}
                className={
                  "text-white fontsize-sub-footer  mb-5 text-capitalize"
                }
              >
                Become A host
              </Link>

              <span
                className={
                  "text-capitalize fontsize-sub-footer  mt-3 fw-bold text-white"
                }
              >
                help & support
              </span>
              <span
                className={"text-white  fontsize-sub-footer  text-capitalize"}
              >
                contact us
              </span>
              <span
                className={"text-white fontsize-sub-footer  text-capitalize"}
              >
                Terms and Conditions
              </span>
              <span
                className={"text-white fontsize-sub-footer  text-capitalize"}
              >
                Privacy Policy
              </span>
            </div>
            {/*Second column*/}
            <div className={"col-sm-6 col-6 d-flex flex-column"}>
              <span
                className={
                  "text-capitalize fontsize-sub-footer  fw-bold text-white"
                }
              >
                Solutions
              </span>
              <span
                className={
                  "text-white mb-5 fontsize-sub-footer  text-capitalize"
                }
              >
                Enterprise
              </span>

              <span
                className={
                  "text-capitalize fontsize-sub-footer  fw-bold  mt-5 pt-1 text-white"
                }
              >
                Community
              </span>
              <Link
                  to={'/frequently-asked-questions'}
                  className={
                    "text-white fontsize-sub-footer fw-normal  text-capitalize"
                  }
              >
                FAQ
              </Link>
              <span
                className={
                  "text-white fontsize-sub-footer fw-normal  text-capitalize"
                }
              >
                blog
              </span>
            </div>
          </div>
          <div className={"d-flex flex-row justify-content-between"}>
            <div className={"d-flex flex-row mt-auto mb-3"}>
              <a
                  href="https://www.facebook.com/Spacia-Africa-185163956487562"
                  target='_blank' rel={'noreferrer noopener nofollow'}>
                <LogoFacebook cssClasses={"me-3"} color={"#ffffff"} />
              </a>
              <a
                  href="https://twitter.com/SpaciaAfrica_"
                  target='_blank' rel={'noreferrer noopener nofollow'}>
                <LogoTwitter cssClasses={"me-3"} color={"#ffffff"} />
              </a>
              <a
                  href="https://www.instagram.com/spaciaafrica/"
                  target='_blank' rel={'noreferrer noopener nofollow'}>
                <LogoInstagram cssClasses={"me-3"} color={"#ffffff"} />
              </a>
            </div>
            <div
              className={
                "imaged mx-auto"
              }
            >
              <img
                decoding={"async"}
                className={"w140 mx-auto img-fluid"}
                src={toAbsoluteUrl("/img/logo-black-alt.png")}
                alt={"logo"}
              />
            </div>
          </div>
          <div
            className={
              "d-flex flex-row my-4 border-1 dropdown-divider border-white"
            }
          />
          <span
            style={{ color: "#D1D5DB" }}
            className={"w-75 fontsize-sub-footer mx-auto pb-5 mt-2"}
          >
            &copy; 2022 SPACIA. All rights reserved
          </span>
        </footer>
      </div>
    </div>
  );
}
