/**
 * Project: spaces
 * File: CartItem
 * Created by Pennycodes on 2/5/2022.
 * Copyright spaces
 */
import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from "../layout/helpers";
import {Location} from "react-ionicons";
import {useDispatch, useSelector} from "react-redux";
import {Constants} from "../utils/constants";
import SERVICES from "../services";
import axios from "axios";
import {addToCartTotalAmount} from "../redux/actions/dashboard";
import {CartEntry} from "../utils/interfaces";
import moment from "moment";

interface Props {
    key: number;
    cartEntry: CartEntry;
    image: string | undefined;
}

const CartItem: (props: Props) => JSX.Element = (props: Props) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [entrySubTotal, setEntrySubTotal] = useState(0);
    const [previousSubTotal, setPreviousSubTotal] = useState(0);
    const [entryItem, setEntryItem] = useState({});

    // @ts-ignore
    const cartDetails = useSelector(state => state.dashboard.cartDetails);

    const { cartEntry: { entryId, endOn, startOn, subTotal, billingPeriod, itemInEntry: {description, address} }, image } = props;

    useEffect(() => {
        const url = `${Constants.BASE_URL}/booking/api/v1/order/add-to-cart/${entryId}`;

        const currentUser = SERVICES.getUser();
        const userId = currentUser ? currentUser.id : 0;

        // find out if there is already an existing cart
        const cartId = (cartDetails) ? cartDetails['cartId'] : null;

        if (startDate || endDate) {
            if (startDate == endDate) {
                // setErrorAlert(true);
                // setErrorMsg('Start date and End date must be different');
                return;
            }

            axios.put(url, {
                userId,
                startOn: startDate,
                endOn: endDate
            }, { params: { cartId }})
                .then(res => {
                    console.log(res.data);
                    // setCartUpdated(prev => !prev);
                }).catch(err => {
                const statusCode = err.response.status;

                switch (statusCode) {
                    case 400:
                        // the may be an issue with the booking start and end date
                        console.log('status code 400:', err.response);

                        let responseMsg = err.response.data.message;
                        responseMsg = responseMsg.slice(responseMsg.indexOf(':') + 2);

                        // setErrorAlert(true);
                        //
                        // setErrorMsg(responseMsg);
                        break;
                }
            })
        }
    }, [startDate, endDate]);

    const dispatch = useDispatch();

    // @ts-ignore
    const totalCartAmount = useSelector(state => state.dashboard.totalCartAmount);

    useEffect(() => {
        if (entrySubTotal) {
            if (!previousSubTotal) {
                // setTotalAmount(prevState => prevState - subTotal);
                console.log('total cart amount: ' + totalCartAmount);
                // console.log('sub total: ' + subTotal);

                const newAmount = totalCartAmount - subTotal;
                dispatch(addToCartTotalAmount(-subTotal));
                console.log('after making update to total cart amount:', (totalCartAmount - subTotal));
            } else {
                // setTotalAmount(prevState => prevState - previousSubTotal);
                console.log('total cart amount: ' + totalCartAmount);
                // console.log('previous sub total: ' + previousSubTotal);

                dispatch(addToCartTotalAmount(-previousSubTotal));
                console.log('after making further updates to total cart amount:', (totalCartAmount - previousSubTotal));
            }

            // setTotalAmount(prevState => prevState + entrySubTotal);
            console.log('total cart amount: ' + totalCartAmount);

            dispatch(addToCartTotalAmount(entrySubTotal));
            console.log('after making last and totally new update to total cart amount:', (totalCartAmount + entrySubTotal));

            // console.log('previous sub total:', previousSubTotal);
            // console.log('new sub total:', entrySubTotal);
        }
    }, [previousSubTotal, entrySubTotal]);

    const convertBillingPeriod = (billingPeriod: string) => {
        let updated = '';

        if (billingPeriod != null) {
            switch (billingPeriod) {
                case 'DAILY':
                    updated = 'day';
                    break;

                case 'WEEKLY':
                    updated = 'week';
                    break;

                case 'MONTHLY':
                    updated = 'month';
                    break;

                case 'YEARLY':
                    updated = 'year';
                    break;

                default:
                    updated = 'hour';
            }
        }

        return updated;
    }

    const handleFromDateChange = (e:any) => {
        const from = e.target.value;
        setStartDate(from);

        if (!endDate) {
            setEndDate(endOn);
        }
    };

    const handleToDateChange = (e: any) => {
        const to = e.target.value;
        setEndDate(to);

        if (!startDate) {
            setStartDate(startOn);
        }
    }

    return (
        <div className={`d-flex justify-content-start mb-3`}>
            <div style={{width: '30%'}}>
                <img src={toAbsoluteUrl(`${image}`)} className={'w100 imaged img-fluid'} alt={'image'} />
            </div>
            <div className={'d-flex flex-column ms-2'} style={{width: '60%'}}>
                <h4>{description}</h4>
                <div className={'d-flex flex-row'}>
                    <Location
                        color={'#6E7070'}
                        title={''}
                        width={'16px'}
                        height={'16px'}
                    /> &nbsp;
                    <p className={'p-0 m-0 fontsize-sub'}>
                        {address}</p>
                </div>
                <div className={'d-flex flex-row justify-content-between'}>

                    <p className={'p-0 m-0 fontsize-sub'}>{moment(startOn).format('DD/MM/YYYY HH:mm')}</p>
                    <p className={'p-0 m-0 fontsize-sub'}>{moment(endOn).format('DD/MM/YYYY HH:mm')} </p>
                </div>
                <p className={'p-0 fs-6 m-0 text-primary'}>
                    <span className={'fw-bolder'}>{ entrySubTotal || subTotal }</span> per {convertBillingPeriod(billingPeriod)}</p>
            </div>
        </div>
    )
}

export default CartItem
