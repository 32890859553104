import React from 'react';
import moment from 'moment';
import SERVICES from '../../services'

function PendingApprovalRow({typeOfSpace, description, address, start, end, user, price,toggleBooking=()=>{}}) {

    const truncateDescription = (desc) => {
        if (desc.length > 45) {
            return desc.substring(0, 44) + '...';
        } else {
            return desc;
        }
    }

    return (
        <tr>
            <td><p>{typeOfSpace}</p></td>
            <td width="20%"><p>{truncateDescription(description)}</p></td>
            <td><p>{address}</p></td>
            <td>{moment(start).format('DD-MMM-YYYY HH:mm')}</td>
            <td>{moment(end).format('DD-MMM-YYYY HH:mm')}</td>
            <td>{user}</td>

            <td>{SERVICES.getCurrency()} {price ? price.toLocaleString() : 'N/A'}</td>
            <td>
            {SERVICES.getUser().role !== 'ROLE_SUBSCRIBER_INDIVIDUAL' &&
                <div className="form-check" style={{textAlign: 'center'}}>
                    <label className="form-check-label">
                        <input onChange={toggleBooking} className="form-check-input" name="" id="" type="checkbox" value="checkedValue"
                               aria-label="Text for screen reader"/>
                    </label>
                </div>
            }
            </td>
        </tr>
    )
}

export default PendingApprovalRow
