// @ts-nocheck

/**
 * Project: spaces
 * File: PageData
 * Created by Pennycodes on 2/5/2022.
 * Copyright spaces
 */

import React, {FC, createContext, useContext, useEffect, useState} from 'react'

export interface PageDataContextModel {
    pageTitle?: string
    setPageTitle: (_title: React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean) => void
    leftComponent?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean
    setLeftComponent: (_left: React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean) => void
    rightComponent?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean
    setRightComponent: (_right: React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean) => void
}

const PageDataContext = createContext<PageDataContextModel>({
    setPageTitle: (_title: any) => {},
    setLeftComponent: (_left: React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean) => {},
    setRightComponent: (_right: React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean) => {},
})

const PageDataProvider: React.FC = ({children}) => {
    const [pageTitle, setPageTitle] = useState<any>('')
    const [leftComponent, setLeftComponent] = useState<React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean>()
    const [rightComponent, setRightComponent] = useState<React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean>()
    const value: PageDataContextModel = {
        pageTitle,
        setPageTitle,
        leftComponent,
        setLeftComponent,
        rightComponent,
        setRightComponent,
    }
    return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>
}

function usePageData() {
    return useContext(PageDataContext)
}

type Props = {
    description?: string
}

const PageTitle: FC = ({children}) => {
    const {setPageTitle} = usePageData()
    useEffect(() => {
        if (children) {
            setPageTitle(children)
        }
        return () => {
            setPageTitle('')
        }
    }, [children])


    return <></>
}

const LeftComponent: React.FC = ({children}) => {
    const {setLeftComponent} = usePageData()
    useEffect(() => {
        if (children) {
            setLeftComponent(children)
        }
        return () => {
            // setLeftComponent('')
            const sidebarPanel = document.getElementById('sidebarPanel') as HTMLAnchorElement;
            sidebarPanel.addEventListener('shown.bs.modal', function (event) {
                sidebarPanel.setAttribute('data-bs-dismiss', 'modal');
            })
        }
    }, [children])
    return <></>
}

const RightComponent: React.FC = ({children}) => {
    const {setRightComponent} = usePageData()
    useEffect(() => {
        if (children) {
            setRightComponent(children)
        }
        return () => {
            setRightComponent('')
        }
    }, [children])
    return <></>
}

export {LeftComponent, RightComponent, PageTitle, PageDataProvider, usePageData}
