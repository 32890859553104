import React, { ChangeEvent } from "react";
import Styles from "./styles";


export interface SelectFieldOption {
  value: string;
  label: any;
}

interface Props {
  value: string | number;
  options: SelectFieldOption[];
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  label?: string;
  placeholder?: string;
  validationMsg?: string;
}

const SelectField: React.FC<Props> = ({
  value,
  options,
  onChange,
  label,
  placeholder,
  validationMsg
}) => {
  return (
    <Styles
      className={`select-field field ${validationMsg ? "error" : ""}`}
    >
      {label &&
        <label htmlFor="select-field" className="label active">
          {label}
        </label>
      }
      <select
        name="select-field"
        value={value}
        onChange={onChange}
      >
        <option
          value=""
          disabled
          defaultChecked={true}
        >
          {placeholder
            ? placeholder
            : "Please select an option"
          }
        </option>
        {options.map((option: SelectFieldOption, index: number) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {validationMsg &&
        <div className="validation-error">{validationMsg}</div>
      }
    </Styles>
  )
}

export default SelectField;