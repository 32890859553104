/**
 * Project: spaces
 * File: DarkMode
 * Created by Pennycodes on 2/4/2022.
 * Copyright spaces
 */
import {Spaces} from "./Spaces";

class DarkMode {
    checkDarkModeStatus : string | null
    switchDarkMode: NodeListOf<Element>
    pageBodyActive: boolean
    pageBody: HTMLBodyElement

    constructor() {
        this.checkDarkModeStatus = localStorage.getItem('SpacesDarkMode')
        this.switchDarkMode = Spaces.selectAll('.dark-mode-switch')
        this.pageBody = Spaces.pageBody()
        this.pageBodyActive = this.pageBody.classList.contains('dark-mode')

        this._init()
    }

    private _init () {
        this.EnableDarkMode()
        this.LocalDarkMode()
        this.AutoDetectDarkMode()
        if (this.checkDarkModeStatus === "1" || this.pageBody.classList.contains('dark-mode')) {
            this.switchDarkModeCheck(true);
            if (this.pageBodyActive) {
                // dark mode already activated
            }
            else {
                this.pageBody.classList.add("dark-mode")
            }
        }
        else {
            this.switchDarkModeCheck(false);
        }
        const bodyCheck = this.pageBody.classList.contains('dark-mode');
        const pageBody = this.pageBody;
        const switchMode = this.switchDarkModeCheck
        this.switchDarkMode.forEach(function (el) {
            el.addEventListener("change", function () {
                const darkModeCheck = localStorage.getItem("SpacesDarkMode");

                if ( darkModeCheck === "1" || bodyCheck) {
                    pageBody.classList.remove("dark-mode");
                    localStorage.setItem("SpacesDarkMode", "0");
                    switchMode(false);
                }
                else {
                    pageBody.classList.add("dark-mode")
                    switchMode(true);
                    localStorage.setItem("SpacesDarkMode", "1");
                }
            })
        })
    }

    private EnableDarkMode () {
        if (Spaces.Config().Dark_Mode.default)  this.pageBody.classList.add("dark-mode");

    }
    private LocalDarkMode () {
        if (Spaces.Config().Dark_Mode.local_mode.enable) {
            const nightStart = Spaces.Config().Dark_Mode.local_mode.start_time;
            const nightEnd = Spaces.Config().Dark_Mode.local_mode.end_time;
            const currentDate = new Date();
            const currentHour = currentDate.getHours();
            if (currentHour >= nightStart || currentHour < nightEnd) {
                // It is night time
                this.pageBody.classList.add("dark-mode");
            }
        }
    }

    private AutoDetectDarkMode () {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches && Spaces.Config().Dark_Mode.auto_detect.enable) {
            this.pageBody.classList.add("dark-mode");
        }
    }

    private switchDarkModeCheck (value: boolean) {
        this.switchDarkMode.forEach(function (el) {
            // @ts-ignore
            el.checked = value
        })
    }

    public static bootstrap() {
        new DarkMode()
    }
}

export { DarkMode}
