import styled from 'styled-components';

export const FilterPropertiesStyles = styled.div`
  .MuiOutlinedInput-root {
    height: 33px;
    //margin-top: 5px;
  }
  .MuiFormControl-root {
    width: 100%;
  }
  
  .MuiOutlinedInput-input {
    font-size: .948rem;
  }
  
  table {
    min-height: 10vh;
  }
  
  table > tbody {
    text-align: center
  }

  div.table-wrapper {
    position: relative;
  }

  div.table-wrapper ul {
    margin-top: 20px;
    padding-bottom: 50px;
    position: absolute;
    right: 0
  }
  
  .filters > .card {
    position: absolute;
    z-index: 100;
    width: 700px;
    left: -174px;
    top: 35px;
    padding: 30px 30px 40px;
    margin-bottom: 20px;
  }
  
  .filters > .card > section {
    display: flex;
    flex-wrap: wrap;
  }
  
  .card > section > div {
    width: calc(100% / 3);
    display: flex;
    margin-top: 20px;
  }
  
  .card > section > div > label {
    font-size: 1.09rem;
    margin-left: 7px;
  }
  
  .filter-button-container {
    display: flex;
    justify-content: space-between;
  }
  
  .filter-button-container > button {
    color: white;
    padding: 10px 30px;
    margin-top: 70px;
    width: 20%;
  }
  
  .custom-select {
    display: flex;
  }
`
