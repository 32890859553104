import styled from "styled-components";
import { colors } from "../../styles";


const Styles = styled.div`
  label {
    margin-bottom: 10px;
    display: block;
    color: ${colors.grey4};
  }
  input, select, textarea {
    width: 100%;
    padding: 11px 21px;
    background-color: ${colors.grey3};
    border: 1px solid ${colors.grey2};
    border-radius: 5px;
    &::placeholder {
      color: ${colors.grey5};
    }
  }
`;

export default Styles;
