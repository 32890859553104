import React, { Component } from 'react'
import Alert from 'react-bootstrap/Alert'
import { FaRegWindowClose } from 'react-icons/fa'


export class SuccessAlert extends Component {
    constructor(props){
        super();

        this.state = {}
    }

    setShow = (value) => {
        this.props.close(value);
    }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.show) {
      setTimeout(() => {
        this.setShow(false);
      }, 6000);
    }
  }

    render(){

        return <Alert className='success-alert desktop-alert' show={ this.props.show } variant={ 'success' } style={this.props.alertStyle}>
            <div className="d-flex" style={{justifyContent:'space-between'}}>
            { this.props.message }
                <FaRegWindowClose onClick={ () => this.setShow(false) } variant="outline-success" style={{width:'fit-content', marginTop:'auto', marginBottom:'auto'}}></FaRegWindowClose>
            </div>
      </Alert>
    }
}

export default SuccessAlert;
