/**
 * Project: spaces
 * File: ServiceWorker
 * Created by Pennycodes on 2/4/2022.
 * Copyright spaces
 */
import {Spaces} from "./Spaces";

class ServiceWorker {
    enable: boolean

    constructor() {
        this.enable = Spaces.Config().PWA.enable

       if(this.enable) this._init()

    }

    private _init () {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register(process.env.PUBLIC_URL+'/service-worker.js')
                .then(reg => console.log('service worker registered'))
                .catch(err => console.log('service worker not registered - there is an error.', err));
        }
    }

    public static bootstrap () {
        new ServiceWorker()
    }
}
export  {ServiceWorker}
