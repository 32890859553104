import React, {useEffect, useState} from "react";
import {CartEntryStyles} from "./styles";
import home1 from "../../assets/img/homes/home1.jpeg";
import QuantityCounter from "../QuantityCounter";
import floorSize from "../../assets/img/Web/Spacia/floor_size.svg";
import capacityImg from "../../assets/img/Web/Spacia/capacity.svg";
import {FaEye} from "react-icons/fa";
import SERVICES from '../../services'
import {Constants} from "../../utils/constants";
import axios from 'axios';
import {useSelector, useDispatch} from "react-redux";
import {
    addEntry,
    addToCartTotalAmount,
    removeFromCartEntryList,
    setCartTotalAmount
} from "../../redux/actions/dashboard";
import demo from "../../assets/img/Web/Spacia/Rectangle 66.png";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment-timezone";

function CartEntry({
                       entryId, price, itemInEntry, subTotal, setErrorMsg,
                       setErrorAlert, image, removeCartEntry, startOn, endOn,
                       setTotalAmount, setCartUpdated
}) {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [startDateObject, setStartDateObject] = useState(new Date());
    const [endDateObject, setEndDateObject] = useState(new Date());
    const [entrySubTotal, setEntrySubTotal] = useState(0);
    const [previousSubTotal, setPreviousSubTotal] = useState(0);
    const [entryItem, setEntryItem] = useState({});
    const [subCategory, setSubCategory] = useState('');
    const [listingName, setListingName] = useState('');

    const cartDetails = useSelector(state => state.dashboard.cartDetails);

    useEffect(() => {
      console.log('item in entry haa:', itemInEntry);
      const subCategory = itemInEntry?.subCategory;
      const name = itemInEntry?.name;

      setSubCategory(subCategory);
      setListingName(name);

      // set the start and end date from the user's cart items
        const cartEntryStartDate = startOn;
        if (cartEntryStartDate != null && typeof cartEntryStartDate === 'string') {
            setStartDateObject(new Date(cartEntryStartDate));
        }

        const cartEntryEndDate = endOn;
        if (cartEntryEndDate != null && typeof cartEntryEndDate === 'string') {
            setEndDateObject(new Date(cartEntryEndDate));
        }
    }, []);

    useEffect(() => {
        const url = `${Constants.BASE_URL}/booking/api/v1/order/add-to-cart/${entryId}`;

        const currentUser = SERVICES.getUser();
        const userId = currentUser ? currentUser.id : 0;

        // find out if there is already an existing cart
        const cartId = (cartDetails) ? cartDetails['cartId'] : null;

        if (startDate || endDate) {
            if (startDate == endDate) {
                setErrorAlert(true);
                setErrorMsg('Start date and End date must be different');
                return;
            }

            axios.put(url, {
                userId,
                startOn: startDate,
                endOn: endDate
            }, { params: { cartId }})
                .then(res => {
                    console.log(res.data);
                    setCartUpdated(prev => !prev);
                    // setEntrySubTotal(15);
                    // const responseData = res.data.data[0];
                    // const subTotal = responseData.subTotal;
                    // let updatedStartDate = responseData.startOn;
                    // let updatedEndDate = responseData.endOn;
                    //
                    // updatedStartDate = updatedStartDate.slice(0, updatedStartDate.lastIndexOf(':'));
                    // updatedEndDate = updatedEndDate.slice(0, updatedEndDate.lastIndexOf(':'));
                    //
                    // setEntryItem(responseData);
                    //
                    // const cartEntry = structureEntry(responseData, updatedStartDate, updatedEndDate);
                    //
                    // const startOn = cartEntry.startOn;
                    // console.log('start on:', startOn);
                    // console.log('last index of :', startOn.slice(0,startOn.lastIndexOf(':')));
                    // dispatch(removeFromCartEntryList(cartEntry.entryId));
                    // dispatch(addEntry(cartEntry));

                    // set new subtotal
                    // setEntrySubTotal(prev => {
                    //     console.log('prevState value:', prev);
                    //     setPreviousSubTotal(prev);
                    //
                    //     return subTotal
                    // });





                    // set new start date
                    // setStartDate(updatedStartDate);
                    // set new end date
                    // setEndDate(updatedEndDate);
                }).catch(err => {
                const statusCode = err.response.status;

                switch (statusCode) {
                    case 400:
                        // the may be an issue with the booking start and end date
                        console.log('status code 400:', err.response);

                        let responseMsg = err.response.data.message;
                        responseMsg = responseMsg.slice(responseMsg.indexOf(':') + 2);

                        setErrorAlert(true);

                        setErrorMsg(responseMsg);
                        break;
                }
            })
        }
    }, [startDate, endDate]);

    const dispatch = useDispatch();

    const totalCartAmount = useSelector(state => state.dashboard.totalCartAmount);

    const [a, setA] = useState(totalCartAmount);
    // useEffect(() => {
    //     setA(totalCartAmount);
    // }, [totalCartAmount]);
    useEffect(() => {
        // dispatch(addToCartTotalAmount(-previousSubTotal));
        // dispatch(addToCartTotalAmount(entrySubTotal));

        if (entrySubTotal) {
            if (!previousSubTotal) {
                setTotalAmount(prevState => prevState - subTotal);
                console.log('total cart amount: ' + totalCartAmount);
                // console.log('sub total: ' + subTotal);

                const newAmount = totalCartAmount - subTotal;
                dispatch(addToCartTotalAmount(-subTotal));
                console.log('after making update to total cart amount:', (totalCartAmount - subTotal));
            } else {
                setTotalAmount(prevState => prevState - previousSubTotal);
                console.log('total cart amount: ' + totalCartAmount);
                // console.log('previous sub total: ' + previousSubTotal);

                dispatch(addToCartTotalAmount(-previousSubTotal));
                console.log('after making further updates to total cart amount:', (totalCartAmount - previousSubTotal));
            }

            setTotalAmount(prevState => prevState + entrySubTotal);
            console.log('total cart amount: ' + totalCartAmount);
            // console.log('entry sub total: ' + entrySubTotal);

            dispatch(addToCartTotalAmount(entrySubTotal));
            console.log('after making last and totally new update to total cart amount:', (totalCartAmount + entrySubTotal));

            // console.log('previous sub total:', previousSubTotal);
            // console.log('new sub total:', entrySubTotal);
        }
    }, [previousSubTotal, entrySubTotal]);

    // useEffect(() => {dispatch(setCartTotalAmount(23));}, [])

    function currentDate(date) {
        let selectedDate = '';

        try {
            selectedDate = date.toISOString().substring(0, date.toISOString().lastIndexOf(':'));
        } catch (e) {
            console.error('an error occurred while converting date to ISO string. message:', e);
        }

        return selectedDate;
    }

    const handleFromDateChange = (dateValue) => {
        console.log('current selected start date:', dateValue);
        const selectedStartDate = new Date(dateValue);
        setStartDateObject(selectedStartDate);

        const startDate = currentDate(selectedStartDate);

        console.log('current date func:', startDate);

        setStartDate(startDate);

        if (!endDate) {
            setEndDate(endOn);
        }
    };

    const handleToDateChange = (dateValue) => {
        console.log('current selected end date:', dateValue);
        const selectedEndDate = new Date(dateValue);
        setEndDateObject(selectedEndDate);

        const endDate = currentDate(selectedEndDate);

        console.log('current date func:', endDate);

        setEndDate(endDate);

        if (!startDate) {
            setStartDate(startOn);
        }
    }

    const retainSelectedStartDate = (date) => {
        setStartDateObject(prevState => {
            return prevState
        })
    }

    const retainSelectedEndDate = (date) => {
        setEndDateObject(prevState => {
            return prevState
        })
    }

    const available = {
        backgroundColor: "#02BD04",
        color: "white",
        border: "none",
        fontWeight: "bold",
        borderRadius: "6px",
    };

    const tableCell = {
        verticalAlign: "top",
        paddingTop: 10
    };
    return (
        <CartEntryStyles>
            <td>
                    <img
                        src={image}
                        alt="demo"
                        style={{
                            width: "198px",
                            height: "125px",
                            borderRadius: "12px",
                            objectFit: "fill",
                        }}
                    />
                </td>
                <td style={{ width: 170 }}>
                    <div style={{ paddingLeft: 0 }}>
                        <p style={{fontWeight: 'bold'}}>{subCategory}</p>
                        <p style={{color: '#ababab'}}>{listingName}</p>
                        <p style={{color: 'red', cursor: 'pointer'}} onClick={() => removeCartEntry(entryId)}>Remove</p>
                    </div>
                </td>
                <td style={tableCell} className='start'>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            renderInput={(params) => <TextField {...params} />}
                            inputFormat={'dd/MM/yyyy hh:mm aa'}
                            value={startDateObject}
                            onChange={(newValue) => {
                                handleFromDateChange(newValue);
                            }}
                            // onClose={() => handleFromDateChange(startDateObject)}
                        />
                    </LocalizationProvider>
                    {/*<FormControl >*/}
                    {/*    <Input type="datetime-local"*/}
                    {/*           className="form-control"*/}
                    {/*           onChange={handleFromDateChange}*/}
                    {/*           defaultValue={startDate || startOn}*/}
                    {/*           id="datetime-local" />*/}
                    {/*</FormControl>*/}
                    {/*<TextField*/}
                    {/*    id="datetime-local"*/}
                    {/*    type="datetime-local"*/}
                    {/*    defaultValue={startOn}*/}
                    {/*    // className={classes.textField}*/}
                    {/*    InputLabelProps={{*/}
                    {/*        shrink: true,*/}
                    {/*    }}*/}
                    {/*/>*/}
                </td>
                <td style={tableCell} className='end'>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            renderInput={(params) => <TextField {...params} />}
                            inputFormat={'dd/MM/yyyy hh:mm aa'}
                            value={endDateObject}
                            onChange={(newValue) => {
                                handleToDateChange(newValue);
                            }}
                            // onClose={() => handleToDateChange(endDateObject)}
                        />
                    </LocalizationProvider>
                    {/*<FormControl >*/}
                    {/*    <Input type="datetime-local"*/}
                    {/*           className="form-control"*/}
                    {/*        onChange={handleToDateChange}*/}
                    {/*        defaultValue={endDate || endOn}*/}
                    {/*           id="datetime-local" />*/}
                    {/*</FormControl>*/}
                    {/*<TextField*/}
                    {/*    id="datetime-local"*/}
                    {/*    type="datetime-local"*/}
                    {/*    defaultValue={endOn}*/}
                    {/*    // className={classes.textField}*/}
                    {/*    InputLabelProps={{*/}
                    {/*        shrink: true,*/}
                    {/*    }}*/}
                    {/*/>*/}
                </td>
                <td style={tableCell}>
                    <h6 style={{fontSize: '0.9rem', marginTop: '12px'}}>{SERVICES.getCurrency()} { price }</h6>
                </td>
                <td style={tableCell}>
                    <div>
                        <div style={{ display: "flex" }}>
                            <h6 style={{fontSize: '0.9rem', marginTop: '12px'}}>{SERVICES.getCurrency()} { entrySubTotal || subTotal }</h6>
                            {/* <div onClick={onEditProperty} style={{display:'flex', cursor:'pointer', paddingRight:10, paddingLeft:10,color:'grey'}}>
                                <br/>
                            <FaEdit /><h6>Edit</h6>
                            </div>

                            <div onClick={onDelete} style={{display:'flex', cursor:'pointer', color:'grey'}}>
                                <br/>
                            <FaTimes /><h6>Delete</h6>
                            </div> */}
                        </div>
                    </div>
                </td>
        </CartEntryStyles>
    );

    // return (
    //     <CartEntryStyles>
    //             <td style={{paddingBottom: '20px', paddingTop:'30px'}}>
    //                 <div className='checkbox' style={{marginLeft: '20px'}}>
    //                     <input type="checkbox" style={{display: 'inline-block', width: '20px', height: '20px'}}/>
    //                 </div>
    //                 <img src={itemInEntry.image || 'assets/img/Web/Spacia/Rectangle 66.png'} alt={itemInEntry.imageDescription} style={{width: '190px', height: '120px', marginLeft: '20px', borderRadius: '12px', objectFit:'cover'}}/></td>
    //             <td style={{paddingTop: '30px'}}>
    //                 <p style={{fontWeight: 'bold'}}>Private Office</p>
    //                 <p style={{color: '#ababab'}}>Furnished</p>
    //                 <p style={{color: 'red', cursor: 'pointer'}}>Remove</p>
    //             </td>
    //             <td className='datetime-picker' style={{paddingTop: '30px'}}>
    //                 <TextField
    //                     id="datetime-local"
    //                     type="datetime-local"
    //                     defaultValue={from}
    //                     // className={classes.textField}
    //                     InputLabelProps={{
    //                         shrink: true,
    //                     }}
    //                 />
    //             </td>
    //             <td className='datetime-picker' style={{width: '100px', paddingTop: '30px'}}>
    //                 <TextField
    //                     id="datetime-local"
    //                     type="datetime-local"
    //                     defaultValue={to}
    //                     // className={classes.textField}
    //                     InputLabelProps={{
    //                         shrink: true,
    //                     }}
    //                 />
    //             </td>
    //             <td style={{paddingTop: '30px'}}><h6 style={{fontSize: '0.9rem'}}>{SERVICES.getCurrency()} { price.toLocaleString() }/month</h6></td>
    //             <td style={{paddingTop: '30px'}}><h6 style={{fontSize: '0.9rem'}}>{SERVICES.getCurrency()} { subTotal.toLocaleString() }</h6></td>
    //     </CartEntryStyles>
    // )
}

export default CartEntry;
