/**
 * Project: spaces
 * File: DOMHelpers
 * Created by Pennycodes on 2/4/2022.
 * Copyright spaces
 */

function getScrollTop(): number {
    return (document.scrollingElement || document.documentElement).scrollTop
}

function getById(target: string): HTMLElement | null {
    return document.getElementById(target)
}

function getByClass(target: string): Element {
    return document.getElementsByClassName(target)[0]
}

function selectAll(target: string): NodeListOf<Element> {
    return document.querySelectorAll(target)
}


function isVisibleElement(element: HTMLElement): boolean {
    return !(element.offsetWidth === 0 && element.offsetHeight === 0)
}


export {
    getScrollTop,
    isVisibleElement,
    getByClass,
    getById,
    selectAll
}
