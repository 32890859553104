// @ts-nocheck

/**
 * Project: spaces
 * File: MasterLayout
 * Created by Pennycodes on 2/4/2022.
 * Copyright spaces
 */
import React, {useEffect} from 'react'
import { useLocation } from 'react-router-dom'
import {PageDataProvider} from './PageData'
import {
    Header,
    FooterElements, FallbackView
} from './partials'
import {GoBackAnimation} from "./assets/js/GoBackAnimation";

const MasterLayout: React.FC = ({children}) => {
    const location = useLocation()
    useEffect(() => {
        setTimeout(() => {
             // GoBackAnimation.reInitialization()
        }, 500)
    }, [])
    useEffect(() => {
        setTimeout(() => {
             // GoBackAnimation.reInitialization()
        }, 500)
    }, [location.key])
    return (
        <PageDataProvider>
            <FallbackView />
            <Header />
        <div id="appCapsule" className="add-safe-space">
            {children}
        </div>
            <FooterElements />
            </PageDataProvider>
        )
}

export default MasterLayout
