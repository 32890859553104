import styled from 'styled-components'

export const MakePaymentStyles = styled.div`
    min-height: calc(100vh - 68.09px);
  padding-bottom: 100px;
    
    .header {
      padding: 20px 20px 20px 0 !important;
    }
  
  form {
    display: flex;
    justify-content: space-between;
  }
  
  form > section {
    border: 1px solid #8F8F8F;
    border-radius: 5px;
    background: white;
    height: auto;
  }
  
  form > section:first-child {
    width: 55%;
    padding: 30px;
  }
  
  section:first-child > h5 {
    margin-bottom: 40px;
  }
  
  form > section:last-child {
    width: 40%;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-right: 30px;
    height: fit-content !important;
  }
  
  section:first-child input:not(.expiry-date),
  section:first-child select {
    width: 70%;
  }

  section:first-child input.expiry-date {
    width: 12%;
  }
  
  input.amount {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: none;
    outline: none;
    display: block;
    text-align: right;
    width: 100%;
    min-height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1.21rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    /* border: 1px solid #ced4da; */
    -webkit-appearance: none;
    appearance: none;
}
  
  .payment-header {
    align-items: center;
  }
  
  .payment-header > p:first-child {
    font-size: 1.2rem;
    font-weight: 500;
    font-style: normal;
    margin-bottom: 0 !important;
    width: 50%;
  }

  .payment-header > div:last-child {
    font-size: 1.2rem;
    font-weight: 500;
    font-style: normal;
    color: #8F8F8F;
    width: 50%;
  }
  
  hr {
    background: black;
    margin-top: 70px;
  }
`
