import {Card, Table} from "react-bootstrap";
import VerifiedUserTableRow from "../../../../../../components/VerifiedUserTableRow";
import {v4 as uuidv4} from "uuid";
import Pagination from "../../../../../../components/Pagination";
import React, {useEffect, useState} from "react";
import UserActivity from "../../../../../../components/user-activity";
import { Constants } from '../../../../../../utils/constants';
import SERVICES from '../../../../../../services';
import axios from 'axios';

const HomeDashboard = () => {
    const defaultAvatar = 'https://storage.googleapis.com/maestro-8beff.appspot.com/user-543-_1626834155761_69ec79f5_1b40_4716_9958_1a6f557f6581.jpeg';

    const [checkInActivities, setCheckInActivities] = useState<any[]>([]);

    const inactive = {
        color: "#9B9B9B",
        fontWeight: "bold",
    };

    const approver = {
        backgroundColor: "#FEE6C2",
        color: "#F99500",
        border: "none",
        fontWeight: "bold",
        borderRadius: 5,
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 20,
        paddingRight: 20,
    };

    useEffect(() => {
        const currentUser = SERVICES.getUser();

        console.log('current logged in user:', currentUser);
        const ownerId = currentUser ? currentUser.id : 0;

        const config = {
            params: { ownerId }
        }

        axios.get(`${Constants.BASE_URL}/booking/api/v1/check/in/activities`, config)
            .then(res => {
                const response = res.data;

                console.log('response:', response);
            })
    }, [])

    return (
        <section>
            <div className={'mb-4'} style={{marginTop: '100px'}}>
                <p style={{fontSize: '1.3rem'}}>Activity</p>
            </div>
            <Card className="min-h-50" style={{width: '95%'}}>
                <Card.Body>
                    <Table striped>
                        <thead>
                        <tr className="tableUnderline">
                            <th>Name</th>
                            <th>Location</th>
                            <th>Check in</th>
                            <th>Check out</th>
                            <th>

                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {checkInActivities && Array.isArray(checkInActivities) && checkInActivities.length > 0
                            && checkInActivities.map((activity, i) =>  {
                                const location = activity.location;
                                const address = location ? location.address : '-';

                                const checkInAt = activity.checkInAt;
                                const checkOutAt = activity.checkOutAt;

                                const firstName = activity?.firstName;
                                const lastName = activity?.lastName;

                                let name = '-';

                                if (firstName && lastName) {
                                    name = `${firstName} ${lastName}`
                                } else if (firstName) {
                                    name = firstName
                                } else if (lastName) {
                                    name = lastName
                                }

                                return (
                                    <UserActivity
                                        key={uuidv4()}
                                        profile={defaultAvatar}
                                        name={name}
                                        location={location}
                                        checkInDate={checkInAt}
                                        checkOutDate={checkOutAt}
                                    />
                                );
                            })}
                        </tbody>
                        {
                          //   Array.isArray(verifiedUsers) && verifiedUsers.length > 0
                          //   && <caption>
                          //   <Pagination
                          //     className="pagination-bar"
                          //     currentPage={verifiedCurrentPage || 1}
                          //     totalCount={verifiedTotalPageSize}
                          //     pageSize={10}
                          //     onPageChange={changeVerifiedPage}
                          //   />
                          //       {/*<ReactPaginate*/}
                          //       {/*	previousLabel={'<'}*/}
                          //       {/*	nextLabel={'>'}*/}
                          //       {/*	pageCount={verifiedPageCount}*/}
                          //       {/*	marginPagesDisplayed={0}*/}
                          //       {/*	pageRangeDisplayed={6}*/}
                          //       {/*	onPageChange={changeVerifiedPage}*/}
                          //       {/*	containerClassName={'whole-block'}*/}
                          //       {/*	activeClassName={'paginate-active'}*/}
                          //       {/*	disabledClassName={'paginate-disabled'}*/}
                          //       {/*	previousLinkClassName={'previous-link'}*/}
                          //       {/*	nextLinkClassName={'next-link'}/>*/}
                          // </caption>
                        }
                    </Table>
                </Card.Body>
            </Card>
        </section>
    )
}

export default HomeDashboard