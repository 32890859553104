import styled from 'styled-components';

export const BillingStyles = styled.div`
    div.toast {
      position: relative;
    }

  div.toast ul.whole-block {
    margin-top: 50px;
    padding-bottom: 50px;
    position: absolute;
    right: 0
  }

  caption {
    position: relative;
    margin-bottom: 50px;
  }

  caption.first-page {
    margin-bottom: 0 !important;
  }
  caption > ul {
    margin-top: 30px;
    position: absolute;
    right: 0
  }
  
  .host-billing-table {
    font-size: 1.07rem;
  }
  
  .host-billing-table > tbody td {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
`
