// @ts-nocheck

/**
 * Project: spaces
 * File: Profile
 * Created by Pennycodes on 2/5/2022.
 * Copyright spaces
 */
import React, {useEffect, useState} from "react";
import {CartOutline, LogOutOutline, NotificationsOutline, PersonOutline} from "react-ionicons";
import SERVICES from "../../services";
import {toAbsoluteUrl} from "../../layout/helpers";
import {PageTitle} from "../../layout/PageData";
import {User} from "../../utils/interfaces";
import default_avatar from "../../assets/img/avatar.png";

const Profile: React.FC =(props) => {
    const [fullName, setFullName] = useState(<h1 className={'mx-3'} />);
    const [profileImage, setProfileImage] = useState(default_avatar);

    useEffect(() => {
        const currentUser = SERVICES.getUser() as User;
        console.log('current user:', currentUser);

        const firstName = currentUser?.firstName;
        const lastName = currentUser?.lastName;

        let fullName = <h1 className={'mx-3'} />;
        if (firstName && lastName) {
            fullName = <h1 className={'mx-3'}> {firstName} <br />{lastName}</h1>;
        } else if (firstName) {
            fullName = <h1 className={'mx-3'}> {firstName} </h1>
        } else if (lastName) {
            fullName = <h1 className={'mx-3'}> {lastName} </h1>
        }

        setFullName(fullName);

        const avatar = currentUser?.avatar;
        setProfileImage(avatar || default_avatar);
    }, []);

    return (
        <div className='mobile-view'>
            <div className="section mt-5 p-3">
                <PageTitle>Settings</PageTitle>
                <div className="avatar-section d-inline-flex align-items-center">
                    <a href="#" >
                        <img src={profileImage} alt="avatar" className="imaged w100 rounded" style={{height: '100px'}}/>
                    </a>
                    {fullName}
                </div>
                <div className={'mt-2'}>
                    <ul className="listview image-listview bg-transparent border-0">
                        <li>
                            <div  className="item">
                                <div className={'icon-box bg-secondary'}>
                                    <PersonOutline
                                        color={'#000000'}
                                    />
                                </div>
                                <div className="in">
                                    <div>Profile</div>
                                </div>
                            </div>
                        </li>
                        {/*<li>
                            <div  className="item">
                                <div className={'icon-box bg-secondary'}>
                                    <NotificationsOutline
                                        color={'#000000'}
                                    />
                                </div>
                                <div className="in">
                                    <div>Notifications</div>
                                </div>
                            </div>
                        </li>*/}
                        {/*<li>*/}
                        {/*    <div  className="item">*/}
                        {/*     <div className={'icon-box bg-secondary'}>*/}
                        {/*         <CartOutline*/}
                        {/*             color={'#000000'}*/}
                        {/*         />*/}
                        {/*     </div>*/}
                        {/*            <div className="in">*/}
                        {/*                <div>Cart</div>*/}
                        {/*            </div>*/}
                        {/*    </div>*/}
                        {/*</li>*/}
                        <li>
                            <div  className="item">
                                <div className={'icon-box bg-secondary'}>
                                    <LogOutOutline
                                        color={'#000000'}
                                    />
                                </div>
                                <div className="in">
                                    <div>Log Out</div>
                                </div>
                            </div>
                        </li>
                        {/*<li>
                            <div className="item">
                                <div className="in">
                                    <div>
                                        Dark Mode
                                    </div>
                                    <div className="form-check form-switch  ms-2">
                                        <input className="form-check-input dark-mode-switch" type="checkbox"
                                               id="darkModeSwitch" />
                                            <label className="form-check-label" htmlFor="darkModeSwitch"></label>
                                    </div>
                                </div>
                            </div></li>*/}
                        <li></li>
                    </ul>
                </div>
            </div>

        </div>
    )
}

export default Profile
