import React from "react";
import { Link, useHistory } from "react-router-dom";

import Styles from "./styles";
import arrow_right from "../../../../assets/img/svg/arrow_right.svg";
import facebook from "../../../../assets/img/svg/facebook.svg";
import twitter from "../../../../assets/img/svg/twitter.svg";
import instagram from "../../../../assets/img/svg/instagram.svg";
import work from "../../../../assets/img/png/work.png";
import stay from "../../../../assets/img/png/stay.png";
import play from "../../../../assets/img/png/play.png";
import about from "../../../../assets/img/png/about.png";


interface Topic {

  heading: string;
  description: React.ReactNode;
  image: string;
}


const About = () => {
    const history = useHistory();

    const goToSignUp = () => {
        history.push('/signup');
    }

  const topics: Topic[] = [
    {
      heading: "About Us",
      image: about,
      description: (
        <p>
          Made for Africa by Africans, the Spacia story is one that{" "}
          inspires. We are a bold company that is driven to make a{" "}
          difference. As the world evolves, so do we. We are{" "}
          trailblazers, we embrace change and use technology to{" "}
          adapt to our environment.
          <br /><br />
          We drew inspiration from the global changes to where{" "}
          and how employees work and combined that with our wealth of {" "}
          experience in serviced office provision to create the Spacia{" "}
          App. Our goal is to help your employees find quality remote{" "}
          workspaces around their cities in Africa. We found beautiful{" "}
          yet inspiring workspaces that are safe, spark productivity{" & "}
          build business communities.
          <br /><br />
          Our company culture is innovative, collaborative and{" "}
          adaptable we take pride in our resilience and solution{" "}
          orientation. All valuable qualities to cope in an{" "}
          ever-changing world.
        </p>
      ),
    },
    {
      heading: "Work",
      image: work,
      description: (
        <>
          <p>
            <b>Empower users to Work From Anywhere!</b>
            <br />
            <span>Packages available under Spacia Work include: </span>
            <b>Meeting Venues, Private Offices, Dedicated Desks and Hot Desks</b>
          </p>
          {/*<button className="button right-radius flex">*/}
          {/*  <div>Learn more</div>*/}
          {/*  <img src={arrow_right} alt="" />*/}
          {/*</button>*/}
        </>
      ),
    },
    {
      heading: "Stay",
      image: stay,
      description: (
        <>
          <p>
            <b>Empower users to book accomodation space with ease!</b>
            <br />
            <span>Packages available under Spacia Stay include: </span>
            <b>Hotel suites and Serviced Apartments</b>
          </p>
          {/*<button className="button right-radius flex">*/}
          {/*  <div>Learn more</div>*/}
          {/*  <img src={arrow_right} alt="" />*/}
          {/*</button>*/}
        </>
      ),
    },
    {
      heading: "Play",
      image: play,
      description: (
        <>
          <p>
            <b>Empower users to access premium concierge services and dynamic experiences with ease!</b>
            <br />
            <span>Packages available under Spacia Support include: </span>
            <b>Airport Transfers, Car Rental, Tour Packages, Event Booking, Dining, Reservations and Takeout</b>
          </p>
          {/*<button className="button right-radius flex">*/}
          {/*  <div>Learn more</div>*/}
          {/*  <img src={arrow_right} alt="" />*/}
          {/*</button>*/}
        </>
      ),
    }
  ]


  return (
    <Styles>
      {/* Banner */}
      <div id="Banner">
        <h1><Link to="/">SPACIA</Link></h1>
        <div className="main-content">
          <div className="wrapper">
            <h2>Remote Work <br />Re-imagined</h2>
            <p>
              The Spacia Enterprise App empowers your employees to work from anywhere.{" "}
              We have a wide network of vetted workspaces so that teams working remotely{" "}
              can search for, book and access remote workspaces on demand through our customizable app.
            </p>
          </div>
          <button type='button' className="button right-radius" onClick={goToSignUp}>
            Work with us
          </button>
        </div>
      </div>

      {/* Topics */}
      <div id="Topics">
        {topics.map(topic =>
          <section className="topic">
            <div className="content">
                {
                    (topic.heading.toLowerCase() === 'play') ?
                        <h3>{topic.heading} : <span style={{fontSize: 'inherit', color: '#f85a47'}}>Coming Soon</span></h3> :
                        <h3>{topic.heading}</h3>
                }
              <div className="description">
                {topic.description}
              </div>
            </div>
            <div
              className="image-cover"
              style={{ backgroundImage: `url(${topic.image})` }}
            />
          </section>
        )}
      </div>

      {/* Footer */}
      <footer>
        <div className="top">
          <h2>Make your dreams a <span>reality</span></h2>
          <button type='button' className="button right-radius flex" onClick={goToSignUp}>
            <div>Work with us</div>
            <img src={arrow_right} alt="" />
          </button>
        </div>
        <div className="bottom">
          <ul className="social-icons">
              <li>
                  <a href="https://www.facebook.com/profile.php?id=100069359208528"
                      title='facebook' target='_blank' rel='noopener noreferrer'>
                      <img src={facebook} alt="facebook" />
                  </a>
              </li>
              <li>
                  <a href="https://twitter.com/SpaciaAfrica_"
                     title='twitter' target='_blank' rel='noopener noreferrer'>
                      <img src={twitter} alt="twitter" />
                  </a>
              </li>
              <li>
                  <a href="https://www.instagram.com/spaciaafrica/"
                     title='instagram' target='_blank' rel='noopener noreferrer'>
                      <img src={instagram} alt="instagram" />
                  </a>
              </li>
          </ul>
        </div>
      </footer>
    </Styles >
  )
}

export default About;
