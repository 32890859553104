import FAQ from './FAQ';

const HostFAQPayload: FAQ[] = [
    {
        question: 'How do I get the payment?',
        answer: (
            <div>
                <p>
                All payouts will be made within 48hrs of guest check-in unless otherwise agreed.
                </p>
            </div>
        )
    },
    {
        question: 'What is the cancellation policy?',
        answer: (
            <p>
                In general, if a client cancels a reservation, the amount paid to Host is 
                determined by the cancellation policy that applies to that reservation.
            </p>
        )
    },
    {
        question: 'How often will the availability schedules be updated? Can we do the updates ourselves?',
        answer: (
            <div>
                <p>
                The host is in control of listing and can set prices, availability, and terms of use for their space.
                </p>
            </div>
        )
    },
    {
        question: 'What is the cost of listing on Spacia?',
        answer: (
            <div>
                <p>
                    Listing on Spacia is free for the first year of use. Spacia retains a 15% commission on the amount paid for reservation.
                </p>
            </div>
        )
    }
]

export default HostFAQPayload
