import React, {useEffect} from "react";
import { useHistory } from 'react-router-dom';
import SERVICES from "../services";
import {Constants} from "../utils/constants";
import axios from "axios";

const AdminOrdersTableRow = ({
								 date, customer, order, createdById, company, status, role, amount,
								 currency, onView, invoice, mobileWalletAccounts, setHasNoWalletWalletAccount,
								 setShowSuccessAlert, setShowErrorAlert
							 }) => {
	const active = {
		backgroundColor: "#F3D5D1",
		color: "#F85A47",
		border: "none",
		fontWeight: "bold",
		borderRadius: 5,
		paddingTop: 4,
		paddingBottom: 4,
		paddingLeft: 20,
		paddingRight: 20,
	};

	const incomplete = {
		backgroundColor: "#F3D5D1",
		color: "orange",
		border: "none",
		fontWeight: "bold",
		borderRadius: 5,
		paddingTop: 4,
		paddingBottom: 4,
		paddingLeft: 20,
		paddingRight: 20,
	};

	const paid = {
		backgroundColor: "#D1F3D6",
		color: "rgb(6, 128, 10)",
		border: "none",
		fontWeight: "bold",
		borderRadius: 5,
		paddingTop: 4,
		paddingBottom: 4,
		paddingLeft: 20,
		paddingRight: 20,
	};

	const cancelled = {
		backgroundColor: "#F8D147",
		color: "#F3E5D1",
		border: "none",
		fontWeight: "bold",
		borderRadius: 5,
		paddingTop: 4,
		paddingBottom: 4,
		paddingLeft: 20,
		paddingRight: 20,
	};

	const history = useHistory();

	const showMakePaymentPage = () => {
		if (mobileWalletAccounts && mobileWalletAccounts.length > 0) {
			history.push({
				pathname: '/make-payment',
				state: invoice
			});
		} else {
			setHasNoWalletWalletAccount(true);
		}
	}

	const statusHTML = (status) => {
		let button
		console.log(status)
		if (status === 'ACTIVE'){
			button =  <button className="user-admin" style={active}>
				{status}
			</button>
		}
		else if (status === 'PAID'){
			button =  <button className="user-admin" style={paid}>
				{status}
			</button>
		}
		else if (status === 'CANCELLED'){
			button =  <button className="user-admin" style={cancelled}>
				{status}
			</button>
		}
		else if (status === 'INCOMPLETE'){
			button =  <button className="user-admin" style={incomplete}>
				{status}
			</button>
		}
		return button
	}

	const proceedToCreateBookingFromOrder = () => {
		const currentUser = SERVICES.getUser();
		const userId = createdById ? createdById : 0;

		const cartEntries = order?.cartEntries;
		const firstEntry = (cartEntries && cartEntries.length > 0) ? cartEntries[0] : null;

		const listing = firstEntry?.itemInEntry;
		const listingId = listing ? listing.id : 0;
		const invoiceId = invoice ? invoice.id : 0;

		const startOn = firstEntry?.startOn;
		const endOn = firstEntry?.endOn;

		const startDate = startOn ? startOn.slice(0, -1) : null;
		const endDate = endOn ? endOn.slice(0, -1) : null;

		console.log('start date:', startDate, 'end date:', endDate);

		const payload = {
			userId,
			listingId,
			startOn: startDate,
			endOn: endDate,
			invoiceId
		}

		axios.post(`${Constants.BASE_URL}/booking/api/v1/booking/make/payment`, payload)
			.then(res => {
				console.log('response after creating booking:', res);

				if (res.status === 200) {
					const response = res.data;

					const bookingResponse = response?.data;

					const bookingIdsArray = [];
					// @ts-ignore
					bookingResponse && bookingResponse.forEach(booking => {
						bookingIdsArray.push(booking.id);
					})

					const currentUser = SERVICES.getUser();

					const userId = currentUser && currentUser.id;

					const resourceUrl = `${Constants.BASE_URL}/booking/api/v1/booking/email`;

					// trigger booking confirmation email
					axios.get(resourceUrl, {params: {bookingIds: bookingIdsArray && bookingIdsArray.join(',')}})
						.then(res => {
							console.log(res);

							// displayModal('success');
							// setShowSuccessAlert(true);

							window.location.reload();
						}).catch(err => {
							console.log(err);

							setShowErrorAlert(true);
						});
				}

			}).catch(err => {
			console.log('what is here');

			const errorResponse = err.response;
			console.log('error response:', errorResponse);

			const data = errorResponse?.data;
			let message = data?.message;
			const errorOnBooking = data?.errorOnBooking;

			console.log('error on booking:', errorOnBooking);

			if (errorOnBooking && message) {
				switch (errorOnBooking) {
					case 'SPACE_NOT_PART_OF_PACKAGE':
					case 'NUMBER_OF_CHECKINS_EXCEEDED':
						message += `. Please proceed to make payment`;
				}
			}

			// setBookingErrorAlert(true);
			// setShowErrorModal(true);
			// setBookingErrorMsg(message);
			// setErrorOnBooking(errorOnBooking);

			// setLoading(false);
		})
	}

	return (
		<tr>
			<td style={{verticalAlign: 'top'}}>{date}</td>
			<td style={{verticalAlign: 'top'}}>
				<div>
					{customer}
				</div>
				<div>{ (role && (role === 'ROLE_EMPLOYEE' || role === 'ROLE_APPROVER_EMPLOYEE' || role === 'ROLE_SUBSCRIBER_OWNER')) ? company : ''}</div>
			</td>
			<td style={{verticalAlign: 'top'}}>
				<h6>{currency} {amount}</h6>
			</td>
			<td style={{verticalAlign: 'top'}}>
				<a href="#" onClick={onView}>View</a>
				<div style={{display: (status && status === 'INCOMPLETE') ? 'inline-block' : 'none', marginLeft: '20px'}} onClick={proceedToCreateBookingFromOrder}>
				{/*<div style={{display: 'inline-block', marginLeft: '20px'}} onClick={proceedToCreateBookingFromOrder}>*/}
					<span style={{color: '#0d6efd', cursor: 'pointer'}}>Complete</span>
				</div>
			</td>
			<td style={{verticalAlign: 'top'}}>
				{statusHTML(status)}
			</td>
		</tr>
	);
};

export default AdminOrdersTableRow;
