import React, { useState, useEffect } from 'react';
import {Button, Container, Spinner} from "react-bootstrap";
import {Constants} from "../../../../utils/constants";
import { useLocation, useHistory } from 'react-router-dom';
import SERVICES from '../../../../services';
import moment from "moment";
import check from '../../../../assets/img/check.png';

const PaymentSuccess = () => {
    const [allInvoices, setAllInvoices] = useState([]);
    const [invoiceTotal, setInvoiceTotal] = useState(0);
    const [isProcessingPayment, setIsProcessingPayment] = useState(true);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [currency, setCurrency] = useState('');
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        setCurrency(SERVICES.getCurrency());
    }, []);

    const observePaymentStatus = (userId, invoiceToken) => {
        const resourceUrl = `${Constants.BASE_URL}/users/v1/process/payment/${invoiceToken}/response?userId=${userId}`;

        console.log('resource url:', resourceUrl);

        const sse = new EventSource(resourceUrl);

        sse.addEventListener(invoiceToken, (e) => {
            console.log('in event listener:', e.data);

            let data = null;
            try {
                data = JSON.parse(e.data);
            } catch (ex) {
                console.log('an error occurred while parse event data.', ex);
            }

            console.log('data value:', data);
            if (data) {
                console.log('parsed data:', data);
                const paymentProcessingTimeout = Number(Constants.paymentProcessingTimeout);

                const { isPaid } = data;

                setTimeout(() => {
                    if (!isPaid) {
                        setIsSuccessful(false);
                        setIsProcessingPayment(false);

                        sse.close();
                    }
                }, paymentProcessingTimeout);

                if (isPaid) {
                    console.log('invoice has been paid');

                    setIsSuccessful(true);
                    setIsProcessingPayment(false);

                    sse.close();
                }
            } else {
                setIsSuccessful(false);
                setIsProcessingPayment(false);
            }
        });
    }

    const observeMultipleInvoicePaymentStatus = (userId, callbackUrl) => {
        console.log('callback url:', callbackUrl);

        const updatedCallbackUrl = `${callbackUrl}&userId=${userId}`;

        console.log('updated callback url:', updatedCallbackUrl);

        const url = new URL(updatedCallbackUrl);
        const params = new URLSearchParams(url.search);

        console.log('params:', params.get('references'));

        const eventType = params.has('references') && params.get('references');

        const sse = new EventSource(updatedCallbackUrl);

        sse.addEventListener(eventType, (e) => {
            console.log('in event listener:', e.data);

            let data = null;
            try {
                data = JSON.parse(e.data);
            } catch (ex) {
                console.log('an error occurred while parse event data.', ex);
            }

            console.log('data value:', data);
            if (data) {
                console.log('parsed data:', data);
                const { isPaid } = data[0];

                const paymentProcessingTimeout = Number(Constants.paymentProcessingTimeout);

                setTimeout(() => {
                    if (!isPaid) {
                        setIsSuccessful(false);
                        setIsProcessingPayment(false);

                        sse.close();
                    }
                }, paymentProcessingTimeout);

                if (isPaid) {
                    console.log('invoice has been paid');

                    setIsSuccessful(true);
                    setIsProcessingPayment(false);

                    sse.close();
                }
            } else {
                setIsSuccessful(false);
                setIsProcessingPayment(false);
            }
        });
    }

    const displayBillingPage = () => {
        history.push('/billing');
    }

    // listen to payment event
    useEffect(() => {
        const locationState = location.state;
        const invoice = locationState && locationState.invoice;
        const selectedInvoices = locationState && locationState.selectedInvoices;
        const callbackUrl = locationState && locationState.callbackUrl;

        const currentUser = SERVICES.getUser();
        const userId = currentUser ? currentUser.id : 0;
        console.log('invoice is:', invoice);


        let allInvoices = [];
        if (selectedInvoices && Array.isArray(selectedInvoices)) {
            console.log('all selected invoices:', selectedInvoices);

            allInvoices = [...selectedInvoices];
            setAllInvoices(allInvoices);

            observeMultipleInvoicePaymentStatus(userId, callbackUrl);
        } else if (invoice) {
            allInvoices = [invoice];
            setAllInvoices(allInvoices);

            const invoiceToken = invoice?.referenceCode;

            observePaymentStatus(userId, invoiceToken);
        } else {
            setAllInvoices([]);
        }

        const invoiceTotal = allInvoices
            .map(invoice => invoice.grossAmount)
            .reduce((prev, curr) => prev + curr, 0);

        console.log('invoice total:', invoiceTotal);
        setInvoiceTotal(invoiceTotal);
    }, []);

    return (
        <Container>
                <div className='emptyState' style={{display: isProcessingPayment ? 'flex': 'none', justifyContent: 'center', alignItems: 'center', minHeight: 'calc(100vh - 68.9236px)'}}>
                    <div style={{textAlign: 'center'}}>
                        <Spinner size="sm" animation="border" role="status" style={{width: '50px', height: '50px'}}>
                            <span className="visually-hidden"></span>
                        </Spinner>
                        <p style={{marginTop: '40px', fontSize: '1.3rem'}}>Your request has been submitted successfully. Kindly complete payment authorization</p>
                    </div>
                </div>
                <div className='successState' style={{display: !isProcessingPayment && isSuccessful ? 'block': 'none', paddingBottom: '100px'}}>
                    {/* Header */}
                    <div className="header">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <div>
                                <h4>
                                    Payment Successful
                                </h4>
                            </div>
                        </div>
                    </div>
                    {/* End Header */}
                    <section style={{border: '1px solid #8F8F8F', borderRadius: '5px', width: '70%', margin: '0 auto 100px', padding: '70px 0 70px'}}>
                        <div style={{width: '65%', margin: '0 auto'}}>
                            <div style={{display: 'flex', position: 'relative', right: '10px', marginBottom: '70px', alignItems: 'center'}}>
                                <img src={check} alt="check"/>
                                <p style={{marginLeft: '15px', width: '70%'}}>Your payment was processed successfully</p>
                            </div>
                            <p style={{marginBottom: '55px', fontStyle: 'normal', fontWeight: '500'}}>Order Summary</p>
                            <table style={{width: '100%', borderCollapse: 'collapse'}}>
                                <tbody>
                                {
                                    allInvoices.length > 0 &&
                                    allInvoices.map(invoice => {
                                        console.log('invoice detail:', invoice);
                                        const amount = invoice?.grossAmount;

                                        const createdDate = invoice?.createdOn;
                                        const invoiceId = invoice?.id;

                                        return (
                                            <tr>
                                                <td style={{fontWeight: '500', paddingBottom: '40px'}}>{`Invoice ${invoiceId}`}</td>
                                                <td style={{textAlign: 'center', paddingBottom: '40px'}}>{moment(createdDate).format("DD-MMM-YYYY")}</td>
                                                <td style={{textAlign: 'right', fontWeight: 'bold', paddingBottom: '40px'}}>{`${currency} ${amount}`}</td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr style={{borderBottom: '1px solid #8F8F8F', height: '50px'}}>
                                    <td colSpan={3}></td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td colSpan={3} style={{textAlign: 'right', paddingTop: '30px', fontWeight: 'bold'}}>{`Total: GHS ${invoiceTotal}`}</td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </section>
                    <div style={{textAlign: 'center', margin: '-60px 0 0 100px'}}>
                        <Button variant="danger" size="md" onClick={displayBillingPage}>View Remaining Invoices</Button>
                    </div>
                </div>
                <div className='failedState' style={{display: !isProcessingPayment && !isSuccessful ? 'block': 'none', minHeight: 'calc(100vh - 68.9236px)'}}>
                    {/* Header */}
                    <div className="header">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <div>
                                <h4>
                                    Payment Failed
                                </h4>
                            </div>
                        </div>
                    </div>
                    {/* End Header */}
                    <section style={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh'}}>
                        <p style={{fontSize: '1.3rem'}}>Your payment could not be processed. Please try again later</p>
                    </section>
                </div>
            </Container>
    );
}

export default PaymentSuccess
