import React from "react";
import {toAbsoluteUrl} from "../../../layout/helpers";
import { ArrowDown, CloseCircle } from "react-ionicons";
import { Link } from "react-router-dom";
import HostLandingLayout from "../../../layout/host-landing-layout-mobile/host-landing-layout";
// import HostLandingLayout from "../../layout/host-landing-layout-mobile/host-landing-layout";

// export type HostLandingProps = {};

// eslint-disable-next-line no-empty-pattern
export default function HostLanding() {
  return (
    <HostLandingLayout>
      <section className={"section pt-3 px-0 position-relative"}>
        <svg
          width="143"
          height="282"
          className={"position-absolute"}
          style={{ top: "73%", left: "0", zIndex: 2 }}
        >
          <image
            width="43"
            height="282"
            xlinkHref={toAbsoluteUrl("/img/left-bubble-mobile.svg")}
          />
        </svg>
        <svg
          width="13"
          height="191"
          className={"position-absolute "}
          style={{ top: "-66px", right: "0", zIndex: 2 }}
        >
          <image
            width="13"
            height="191"
            xlinkHref={toAbsoluteUrl("/img/right-bubble-mobile.svg")}
          />
        </svg>
        <div className={"px-3"}>
          <h1 className={"fontsize-headingXLarge w-75  mb-2"}>
            Let Your Space Earn Smarter
          </h1>
          <p className={"text-start"}>
            With an increased demand for flexible workspace, now is the time to
            capitalise on your unused space. Broaden your tenant prospects by
            listing on Spacia, and grow your earnings
          </p>
          <div
            className={
              "d-flex flex-row justify-content-center align-items-center"
            }
          >
            <ArrowDown color={"#6C5CE7"} />
          </div>
        </div>
        <div>
          <div className={"image-grid"}>
            <img
              decoding={"async"}
              style={{ width: 142, height: 223 }}
              src={toAbsoluteUrl("/img/img_1.jpeg")}
              alt={"logo"}
            />
            <img
              decoding={"async"}
              style={{ width: 245, height: 183 }}
              src={toAbsoluteUrl("/img/img_2.jpeg")}
              alt={"logo"}
            />
            <img
              decoding={"async"}
              style={{ width: 92, height: 107 }}
              src={toAbsoluteUrl("/img/img_3.jpeg")}
              alt={"logo"}
            />{" "}
          </div>
          <div
            className={"image-grid"}
            style={{ transform: "translate(-10px)" }}
          >
            <img
              decoding={"async"}
              style={{ width: 142, height: 107 }}
              src={toAbsoluteUrl("/img/img_4.jpeg")}
              alt={"logo"}
            />
            <div
              className={"d-flex flex-column position-relative"}
              style={{ top: -35, width: "fit-content" }}
            >
              <img
                decoding={"async"}
                style={{ width: 142, height: 146 }}
                src={toAbsoluteUrl("/img/img_5.jpeg")}
                alt={"logo"}
              />
              <img
                decoding={"async"}
                style={{ width: 142, height: 146 }}
                src={toAbsoluteUrl("/img/img_6.jpeg")}
                alt={"logo"}
              />
            </div>
            <img
              decoding={"async"}
              style={{ width: 195, height: 182, top: -38 }}
              src={toAbsoluteUrl("/img/img_7.jpeg")}
              alt={"logo"}
            />
          </div>
        </div>
      </section>
      <section className={"section  d-flex flex-column  pt-3"}>
        <h2 className={"fontsize-heading text-center w-75 mx-auto"}>
          Access our dynamic network of corporate partners
        </h2>
        <p className={"text-start"}>
          On Spacia, you have the flexibility to choose how your space works for
          you. From listing a few workstations, a serviced office, event space
          or hotel rooms, we give you the freedom to conveniently showcase your
          space.
        </p>
        <div
          className={"card  box-shadow border-2 mx-auto"}
          style={{
            borderRadius: 25,
            width: "clamp(350px,340px + 0.1vw , 384px)",
          }}
        >
          <div className={"card-body"}>
            <h3
              className={"text-center py-4"}
              style={{
                color: "#000000",
                fontFamily: "Poppins,sans-serif",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: 22,
              }}
            >
              Put your space to &nbsp;
              <span
                style={{
                  color: "#00A3FF",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 22,
                }}
              >
                work
              </span>
            </h3>
            <ol className={"card-list"}>
              <li className={"mt-1"}>
                <div className={"d-flex flex-column w-100"}>
                  <h4 className={"fontsize-heading"}>Sign Up</h4>
                  <span className={"text-muted fontsize-normal"}>
                    Set up your free host account
                  </span>
                </div>
              </li>
              <li className={"mt-1"}>
                <div>
                  <h4>Get you space booked</h4>
                  <span className={"text-muted"}>
                    We reserve space at your location for our pool of corporate
                    clients
                  </span>
                </div>
              </li>

              <li className={"mt-1"}>
                <div>
                  <h4>Earn</h4>
                  <span className={"text-muted"}>
                    Check your bookings and track your earnings on the platform
                  </span>
                </div>
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section className={"section pt-5"}>
        <div
          className={"mx-auto"}
          style={{ width: "clamp(350px,340px + 0.1vw , 384px)" }}
        >
          <h2
            className={" text-center mb-4"}
            style={{
              fontSize: "22px",
              // lineHeight: "28px",
              // fontFamily: "Monserrat",
              fontWeight: 500,
              letterSpacing: "0.25px",
              color: "#2D3436",
            }}
          >
            The right tools for the job.
          </h2>
          <div className={"ps-3 mb-4 pb-2"}>
            <div
              className={"d-flex flex-row  my-4 align-items-center"}
              style={{ width: "fit-content" }}
            >
              <div className={"me-1"}>
                <div className={"tile"} data-color={"red"}>
                  <svg width="24" height="24">
                    <image
                      xlinkHref={toAbsoluteUrl("/img/calender.svg")}
                      width="24"
                      height="24"
                    />
                  </svg>
                </div>
              </div>
              <span className={"monserrat-font"}>Integrated Calendar</span>
            </div>

            <div
              className={"d-flex flex-row my-4 align-items-center"}
              style={{ width: "fit-content" }}
            >
              <div className={"me-1"}>
                <div className={"tile"} data-color={"green"}>
                  <svg width="24" height="24">
                    <image
                      xlinkHref={toAbsoluteUrl("/img/analytics.svg")}
                      width="24"
                      height="24"
                    />
                  </svg>
                </div>
              </div>
              <span className={"monserrat-font"}>Booking Analytics</span>
            </div>

            <div
              className={"d-flex flex-row  my-4 align-items-center"}
              style={{ width: "fit-content" }}
            >
              <div className={"me-1"}>
                <div className={"tile"} data-color={"ash"}>
                  <svg width="24" height="24">
                    <image
                      xlinkHref={toAbsoluteUrl("/img/notification.svg")}
                      width="24"
                      height="24"
                    />
                  </svg>
                </div>
              </div>
              <span className={"monserrat-font"}>Real Time Notifications</span>
            </div>

            <div
              className={"d-flex flex-row  my-4 align-items-center"}
              style={{ width: "fit-content" }}
            >
              <div className={"me-1"}>
                <div className={"tile"} data-color={"pink"}>
                  <svg width="24" height="24">
                    <image
                      xlinkHref={toAbsoluteUrl("/img/qrCode.svg")}
                      width="24"
                      height="24"
                    />
                  </svg>
                </div>
              </div>
              <span className={"monserrat-font"}>QR Code Check In</span>
            </div>
          </div>{" "}
        </div>
      </section>

    </HostLandingLayout>
  );
}
