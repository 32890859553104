/**
 * Project: spaces
 * File: ReservationCard
 * Created by Pennycodes on 2/5/2022.
 * Copyright spaces
 */
import React, {useEffect, useState} from 'react'
import {EllipsisHorizontal, Location} from "react-ionicons";
import {data} from "../data/reservations";
import {Booking, PropertyImage, Status} from "../utils/interfaces";
import moment from "moment";

interface Props {
    booking: Booking;
    hasIcon: boolean;
    showCancelBookingModal: any;
}
const ReservationCard: (props: Props) => JSX.Element = (props: Props) => {
    const { booking, hasIcon, showCancelBookingModal } = props;

    const [chipBackground, setChipBackground] = useState('');
    const [propertyDesc, setPropertyDesc] = useState('');
    const [address, setAddress] = useState('');
    const [startOn, setStartOn] = useState('');
    const [endOn, setEndOn] = useState('');
    const [propertyImage, setPropertyImage] = useState('');

    useEffect(() => {
        const status = booking.status;
        let chipBg = '';
        if (status != null) {
            const value = status.value;
            if (value === 'active') {
                setChipBackground('bg-success');
            } else {
                setChipBackground('bg-warning');
            }
        }

        const listingItem = booking.listingItem;
        const description = listingItem?.description;
        setPropertyDesc(description || '');

        const location = listingItem?.location;
        const address = location?.address;
        setAddress(address || '');

        const media = listingItem?.media;
        const mediaImages = media?.images;
        const anyRandomImage = (mediaImages != null && mediaImages.length > 0)
            ? mediaImages[0] : {} as PropertyImage;
        const imageUrl = anyRandomImage.resourceUrl;
        setPropertyImage(imageUrl);

        const startOn = booking.startOn;
        const from = formatDate(startOn);
        setStartOn(from);

        const endOn = booking.endOn;
        const to = formatDate(endOn);
        setEndOn(to);
    }, []);

    const truncateText = (text: string) => {
        if (text.length > 45) {
            return text.substring(0, 44) + '...';
        } else {
            return text;
        }
    }

    function formatDate(date: string) {
        let formattedDate = '-';

        try {
            if (date != null) {
                formattedDate = moment(date).format('DD/MM/yyyy');
            }
        } catch (e) {
            console.log('an error occurred while converting string to Date');
        }

        return formattedDate;
    }

    const displayCancelBookingModal = (e: any) => {
        e.preventDefault();

        showCancelBookingModal(booking.id)
    }

    return (
        <div className="card reservation shadowed mb-3">
            <div className={'d-flex justify-content-start align-items-start p-2'}>
                <figure style={{marginRight: '15px'}}>
                    <img src={propertyImage} alt={propertyDesc} className="img-fluid" style={{width: '100px', borderRadius: '10%'}}/>
                </figure>
                <div className={'d-flex flex-column'}>
                    <div className={'d-flex justify-content-between'}>
                        <h4>{truncateText(propertyDesc)} </h4>
                        {
                            hasIcon && <div className={'card-button dropdown'}>
                                <button type={'button'} className={'btn btn-link btn-icon'} data-bs-toggle="dropdown">
                                    <div className="chip chip-media bg-transparent">
                                        <i className={`chip-icon ${chipBackground}`}>
                                            <EllipsisHorizontal
                                                cssClasses={'md hydrated'}
                                                color={'#ffffff'}
                                            />
                                        </i>
                                    </div>
                                </button>
                                <div className="dropdown-menu dropdown-menu-end">
                                    <a className="dropdown-item" href="#" onClick={displayCancelBookingModal}>
                                        Cancel Reservation
                                    </a>

                                </div>
                            </div>
                        }

                    </div>
                    <div className={'d-flex flex-row'}>
                        <Location
                            color={'#6E7070'}
                            title={''}
                            width={'16px'}
                            height={'16px'}
                        /> &nbsp;
                        <p className={'p-0 fontsize-sub'}>{address}</p>
                    </div>
                    <div className={'d-flex flex-row justify-content-between'}>

                        <p className={'p-0 fontsize-sub'}><span>From</span> {startOn} </p>
                        <p className={'p-0 fontsize-sub'}><span>To</span> {endOn} </p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ReservationCard
