export default {
    user:{},
    filterOptions: {},
    selectedFilters: {},
    cartDetails: {},
    entries: [],
    currentEntry: {},
    hasItems: false,
    totalCartAmount: 0,
    bundleTypeAndQuantities: [],
    userSessionForBundles: {}
};
