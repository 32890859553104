/**
 * Project: spaces
 * File: Spaces
 * Created by Pennycodes on 2/4/2022.
 * Copyright spaces
 */
export interface SpacesConfig {
    PWA: {
        enable: boolean, // Enable or disable PWA
    },
    Dark_Mode: {
        default: boolean, // Set dark mode as main theme
        local_mode: { // Activate dark mode between certain times of the day
            enable: boolean, // Enable or disable local dark mode
            start_time: number, // Start at 20:00
            end_time: number, // End at 07:00
        },
        auto_detect: { // Auto detect user's preferences and activate dark mode
            enable: boolean,
        }
    },
    RTL: {
        enable: boolean, // Enable or disable RTL Mode
    },
    Animation: {
        goBack: boolean, // Go back page animation
    },
    Test: {
        enable: boolean, // Enable or disable test mode
        word: string, // The word that needs to be typed to activate test mode
        alert: boolean, // Enable or disable alert when test mode is activated
        alertMessage: string // Alert message
    }
}
const defaultSpacesConfig: SpacesConfig = {
    PWA: {
        enable: true, // Enable or disable PWA
    },
    //-------------------------------------------------------------------
    // Dark Mode Settings
    Dark_Mode: {
        default: false, // Set dark mode as main theme
        local_mode: { // Activate dark mode between certain times of the day
            enable: false, // Enable or disable local dark mode
            start_time: 20, // Start at 20:00
            end_time: 7, // End at 07:00
        },
        auto_detect: { // Auto detect user's preferences and activate dark mode
            enable: false,
        }
    },
    //-------------------------------------------------------------------
    // Right to Left (RTL) Settings
    RTL: {
        enable: false, // Enable or disable RTL Mode
    },
    //-------------------------------------------------------------------
    // Animations
    Animation: {
        goBack: false, // Go back page animation
    },
    //-------------------------------------------------------------------
    // Test Mode
    Test: {
        enable: true, // Enable or disable test mode
        word: "testmode", // The word that needs to be typed to activate test mode
        alert: true, // Enable or disable alert when test mode is activated
        alertMessage: "Test mode activated. Look at the developer console!" // Alert message
    }
}
class Spaces {
    options: SpacesConfig

    constructor(options?: SpacesConfig) {
        this.options = Object.assign(defaultSpacesConfig, options)

    }
    private getConfig () {
        return this.options
    }
    public static Config () {
        return  new Spaces().getConfig()
    }
    // Create Instances
    public static createInstance(state: string) {
        new Spaces()
    }
    public static ready(callback: EventListenerOrEventListenerObject) {
        // @ts-ignore
        if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
            // @ts-ignore
            callback();
        } else {
            document.addEventListener('DOMContentLoaded', callback);
        }
    }

    public static reInitialization(state: string = 'once') {
        Spaces.createInstance(state)
    }

    public static selectAll (element: string) : NodeListOf<Element> {
        return document.querySelectorAll(element)
    }

    public static select (element: string) : Element | null {
        return document.querySelector(element)
    }
    public static pageBody () : HTMLBodyElement {
        return <HTMLBodyElement>document.querySelector("body");
    }
    public static appSidebar () : HTMLElement | null{
        return  document.getElementById("sidebarPanel")
    }
    public static loader () : HTMLElement | null {
        return document.getElementById('loader')
    }
    public static pageHTML () : HTMLElement | null {
        return document.querySelector("html")
    }


}



export {
    Spaces,
    defaultSpacesConfig
}
