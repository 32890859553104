/**
 * Project: spaces
 * File: ClearInput
 * Created by Pennycodes on 2/4/2022.
 * Copyright spaces
 */
import {Spaces} from "./Spaces";

class Input {
    clearInput: NodeListOf<Element>
    formControl: NodeListOf<Element>

    constructor() {
        this.clearInput = Spaces.selectAll('.clear-input')
        this.formControl = Spaces.selectAll('.form-group .form-control')

        this._init()
    }

    private _init () {
        this.clearInput.forEach(function (el) {
            el.addEventListener("click", function () {
                // @ts-ignore
                const parent = this.parentElement;
                const input = parent.querySelector(".form-control");
                input.focus();
                input.value = "";
                parent.classList.remove("not-empty");
            })
        })
        this.formControl.forEach(function (el) {
            // active
            el.addEventListener("focus", () => {
                const parent = el.parentElement;
                // @ts-ignore
                parent.classList.add("active")
            });
            el.addEventListener("blur", blurHandler);
            function blurHandler(e: Event) {
                const parent = el.parentElement;
                // @ts-ignore
                parent.classList.remove("active");

                // @ts-ignore
                const inputCheck = this.value.length;
                if (inputCheck > 0) {
                    // @ts-ignore
                    parent.classList.add("not-empty")
                }
                else {
                    // @ts-ignore
                    parent.classList.remove("not-empty")
                }
            }
            // empty check
            el.addEventListener("keyup", log);
            function log(e: Event) {
                // @ts-ignore
                const inputCheck = this.value.length;
                if (inputCheck > 0) {
                    // @ts-ignore
                    this.parentElement.classList.add("not-empty")
                }
                else {
                    // @ts-ignore
                    this.parentElement.classList.remove("not-empty")
                }
            }
        })
    }

    public static bootstrap () {
        new Input()
    }
}

export { Input}
