import React from "react";
import { Button, Row, Col, Form } from "react-bootstrap";

// class SwitchExample extends Component {
//     constructor() {
//       super();
//       this.state = { checked: false };
//       this.handleChange = this.handleChange.bind(this);
//     }

//     handleChange(checked) {
//       this.setState({ checked });
//     }
// }

const Notifications = (props) => {
	return (
		<>
			<Row className="pt-3">
				<Col><h6>Manage your Notifications</h6></Col>
			</Row>
			<Row className="mt-4">
				<Col>
					<p><span className="align-top">Receive email notifications</span> <Form.Check type="switch"/></p>
					<p className="my-4"><span className="align-top">Receive SMS notifications</span> <Form.Check type="switch"/></p>
					<p><span className="align-top">Receive portal notifications</span> <Form.Check type="switch"/></p>

					<Button variant="danger" className="px-5 mt-5"> Save </Button>
				</Col>
			</Row>
		</>
	);
};

export default Notifications;
