export default {
	INDEX: "INDEX",
	DISPATCH_USER: "DISPATCH_USER",
	LOGIN_USER: "LOGIN_USER",
	LOGOUT_USER: "LOGOUT_USER",
	SAVE_FILTERS: "SAVE_FILTERS",
	SAVE_SELECTED_FILTERS: "SAVE_SELECTED_FILTERS",
	SAVE_CART_DETAILS: "SAVE_CART_DETAILS",
	ADD_ENTRY: "ADD_ENTRY",
	SAVE_CURRENT_ENTRY: "SAVE_CURRENT_ENTRY",
	ADD_TO_TOTAL_AMOUNT: "ADD_TO_TOTAL_AMOUNT",
	SET_TOTAL_AMOUNT: "SET_TOTAL_AMOUNT",
	SET_ENTRIES: "SET_ENTRIES",
	RESET_TOTAL_AMOUNT: "RESET_TOTAL_AMOUNT",
	REMOVE_ENTRY: "REMOVE_ENTRY",
	HAS_ITEMS: "HAS_ITEMS",
	UPDATE_BUNDLE_QUANTITY: "UPDATE_BUNDLE_QUANTITY",
	RESET_BUNDLE_QUANTITY: "RESET_BUNDLE_QUANTITY",
	USER_SESSION_FOR_BUNDLES: "USER_SESSION_FOR_BUNDLES"
};
