class Utils {

    static convertFirstLetterOfEachWordToUpperCase(value: string) {
        let result = '';

        if (value != null) {
            result = value.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        }

        return result;
    }
}

export default Utils