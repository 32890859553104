import React from "react";
// import HostLandingLayout from "../../layout/host-landing-layout-desktop/host-landing-layout";
import { toAbsoluteUrl } from "../../../layout/helpers";
import { CloseCircle } from "react-ionicons";
import { Link } from "react-router-dom";
import HostLandingLayout from "../../../layout/host-landing-layout-desktop/host-landing-layout";

// export type HostLandingWebProps = {};

// eslint-disable-next-line no-empty-pattern
export default function HostLandingWeb() {

  return (
    <HostLandingLayout>
      <section className={"section pt-5  px-md-5 pb-5 px-0 position-relative"}>
        <svg
          width="97"
          height="531"
          className={"position-absolute"}
          style={{
            top: "11%",
            left: "0",
            zIndex: 2,
          }}

        >
          <image
            width="97"
            height="531"
            xlinkHref={toAbsoluteUrl("/img/pc-left.svg")}
          />
        </svg>
        <svg
          width="93"
          height="531.77"
          className={"position-absolute "}
          style={{
            top: "-336px",
            right: "0",
            zIndex: 2,
            // transform: " rotate(-59.65deg)",
          }}
        >
          <image
            width="93"
            height="531.77"
            xlinkHref={toAbsoluteUrl("/img/pc-right.svg")}
          />
        </svg>
        <div>
          <div className={"image-grid  px-md-5 px-lg-5"}>
            <img
              decoding={"async"}
              style={{
                width: "min(22vw,193px)",
                height: "259px",
                borderRadius: "20px",
                objectFit: "cover",
                objectPosition: "center",
              }}
              src={toAbsoluteUrl("/img/rec1.png")}
              alt={"logo"}
              className={"mt-lg-auto"}
            />
            <img
              decoding={"async"}
              style={{
                width: "min(22vw,193px)",
                height: "346px",
                borderRadius: "20px",
                objectFit: "cover",
                objectPosition: "center",
              }}
              className={"mt-lg-auto"}
              src={toAbsoluteUrl("/img/rec2.png")}
              alt={"logo"}
            />
            <div
              className={"ms-2"}
              style={{
                height: "max(auto,346px)",
                overflow: "hidden",
              }}
            >
              <h1
                className={"mb-md-2"}
                style={{
                  fontSize: "min(22vw,80px)",
                  lineHeight: 1,
                }}
              >
                Let Your Space Earn Smarter
              </h1>
              <p
                className={"text-start mt-lg-3"}
                style={{
                  width: "min(50vw,695px)",
                  fontSize: "min(1.5vw, 21px)",
                  lineHeight: "clamp(35px,4.0323vw,43px)",
                }}
              >
                With an increased demand for flexible workspace, now is the time
                to capitalise on your unused space. Broaden your tenant
                prospects by listing on Spacia, and grow your earnings
              </p>
            </div>
          </div>
          <div
            className={"d-flex flex-row justify-content-center "}
            style={{ marginTop: "60px", gap: "50px", marginLeft: "auto", marginRight: "auto" }}
          >
            <img
              decoding={"async"}
              style={{
                width: "521px",
                height: "346px",
                borderRadius: "20px",
                // marginRig: "40px",
                objectFit: "cover",
                objectPosition: "center",
              }}
              src={toAbsoluteUrl("/img/rec3.png")}
              alt={"logo"}
            />
            <img
              decoding={"async"}
              style={{
                width: "521px",
                height: "346px",
                top: -38,
                borderRadius: "20px",
                // marginRight: "40px",
                objectFit: "cover",
                objectPosition: "center",
              }}
              src={toAbsoluteUrl("/img/rec4.png")}
              alt={"logo"}
            />
            {/* <img
              decoding={"async"}
              style={{
                width: "min(40vw,193px)",
                height: "231px",
                borderRadius: "20px",
                objectFit: "cover",
                objectPosition: "center",
              }}
              src={toAbsoluteUrl("/img/img_14.jpeg")}
              alt={"logo"}
            /> */}
          </div>
        </div>
      </section>
      <section
        className={"section  px-md-5 d-flex flex-column px-lg-5  pt-5 pb-5"}
      >
        <h2 className={"fontsize-headingXLarge text-start w-75 mb-lg-4 "}>
          Access our dynamic network of corporate partners
        </h2>
        <p
          className={"text-start fontsize-headingLarge"}
          style={{
            lineHeight: "43px",
            letterSpacing: 0.8,
          }}
        >
          On Spacia, you have the flexibility to choose how your space works for
          you. From listing a few workstations, a serviced office, event space
          or hotel rooms, we give you the freedom to conveniently showcase your
          space.
        </p>
        <div
          className={"row pt-5 pb-5"}
        // style={{
        //   borderRadius: 25,
        //   width: "clamp(350px,340px + 0.1vw , 384px)",
        // }}
        >
          <div className={"col-lg-6"}>
            <div className={"imaged px-md-5"}>
              <img
                src={toAbsoluteUrl("/img/user-viewing.png")}
                alt={"gh"}
                style={{
                  width: "569px",
                  height: "563px",
                  objectFit: "cover",
                  objectPosition: "center left",
                  borderRadius: 25,
                }}
              />
            </div>
          </div>
          <div
            className={
              "col-lg-6  d-flex flex-column align-items-start px-md-5"
            }
          >
            <h3
              className={"text-start py-4"}
              style={{
                color: "#000000",
                fontFamily: "Poppins,sans-serif",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "min(50px,3vw)",
              }}
            >
              Put your space to &nbsp;
              <span
                style={{
                  color: "#00A3FF",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "inherit",
                }}
              >
                work
              </span>
            </h3>
            <ol className={"card-list ms-4"}>
              <li className={"my-5"}>
                <div className={"d-flex flex-column w-100"}>
                  <h4 className={"fontsize-headingXLarge text-black"}>
                    Sign Up
                  </h4>
                  <span className={"text-muted fontsize-24"} style={{ fontSize: "24px" }}>
                    Set up your free host account
                  </span>
                </div>
              </li>
              <li className={"my-5"}>
                <div className={"d-flex flex-column w-100"}>
                  <h4 className={"fontsize-headingXLarge text-black"}>
                    Get you space booked
                  </h4>
                  <span className={"text-muted fontsize-24"} style={{ fontSize: "24px" }}>
                    We reserve space at your location for our pool of corporate
                    clients
                  </span>
                </div>
              </li>

              <li className={"mt-1"}>
                <div className={"d-flex flex-column w-100"}>
                  <h4 className={"fontsize-headingXLarge text-black"}>Earn</h4>
                  <span className={"text-muted fontsize-24"} style={{ fontSize: "24px" }}>
                    Check your bookings and track your earnings on the platform
                  </span>
                </div>
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section className={"section pt-5 px-lg-5 px-md-5"}>
        <div
        // className={"mx-auto"}
        // style={{ width: "clamp(350px,340px + 0.1vw , 384px)" }}
        >
          <div className={"top-bar mb-4"} />
          <h2
            className={" text-start mb-4"}
            style={{
              fontSize: "min(30px,2.8vw)",
              fontWeight: 600,
              lineHeight: "160%",
              color: "#000000",
            }}
          >
            The right tools for the job.
          </h2>
          <div
            className={
              "mb-4 pb-2 d-flex flex-row justify-content-between flex-wrap px-lg-4"
            }
          >
            <div
              className={"d-flex flex-row my-4 align-items-center w-50"}
              style={{ width: "fit-content" }}
            >
              <div className={"me-1 "}>
                <div className={"tile"} data-color={"red"}>
                  <svg width="47" height="47">
                    <image
                      xlinkHref={toAbsoluteUrl("/img/calender.svg")}
                      width="47"
                      height="47"
                    />
                  </svg>
                </div>
              </div>
              <span className={"fontsize-24"} style={{
                fontSize: "min(25px,2.4vw)",
                fontWeight: 500,
                letterSpacing: "0.01em",
                color: "#2E0D64",
              }}>Integrated Calendar</span>
            </div>





            <div
              className={"d-flex flex-row  my-4 align-items-center w-50"}
              style={{ width: "fit-content" }}
            >
              <div className={"me-1"}>
                <div className={"tile"} data-color={"green"}>
                  <svg width="47" height="47">
                    <image
                      xlinkHref={toAbsoluteUrl("/img/qrCode.svg")}
                      width="47"
                      height="47"
                    />
                  </svg>
                </div>
              </div>
              <span className={"fontsize-24"} style={{
                fontSize: "min(25px,2.4vw)",
                fontWeight: 500,
                letterSpacing: "0.01em",
                color: "#2E0D64",
              }} >QR Code Check In</span>
            </div>

            <div
              className={"d-flex flex-row my-4 align-items-center w-50"}
              style={{ width: "fit-content" }}
            >
              <div className={"me-1"}>
                <div className={"tile"} data-color={"ash"}>
                  <svg width="47" height="47">
                    <image
                      xlinkHref={toAbsoluteUrl("/img/analytics.svg")}
                      width="47"
                      height="47"
                    />
                  </svg>
                </div>
              </div>
              <span className={"fontsize-24"} style={{
                fontSize: "min(25px,2.4vw)",
                fontWeight: 500,
                letterSpacing: "0.01em",
                color: "#2E0D64",
              }}>Booking Analytics</span>
            </div>


            <div
              className={"d-flex flex-row  my-4 align-items-center w-50"}
              style={{ width: "fit-content" }}
            >
              <div className={"me-1"}>
                <div className={"tile"} data-color={"pink"}>
                  <svg width="47" height="47">
                    <image
                      xlinkHref={toAbsoluteUrl("/img/notification.svg")}
                      width="47"
                      height="47"
                    />
                  </svg>
                </div>
              </div>
              <span className={"fontsize-24"} style={{
                fontSize: "min(25px,2.4vw)",
                fontWeight: 500,
                letterSpacing: "0.01em",
                color: "#2E0D64",
              }}>Real Time Notifications</span>
            </div>



          </div>
        </div>
      </section>

    </HostLandingLayout>
  );
}
