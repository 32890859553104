import React, {useState, useEffect, useLayoutEffect} from "react";
import SERVICES from "../../../services";
import { Constants } from '../../../utils/constants';
import { useHistory, Link } from "react-router-dom";
import "react-bootstrap-tagsinput/dist/index.css";
import axios from "axios";
import 'react-tabs/style/react-tabs.css';
import { v4 as uuidv4 } from 'uuid';
import {PageTitle} from "../../../layout/PageData";
import {SearchOutline} from "react-ionicons";
import Pagination from "../../../components/Pagination";
import InformationModal from "../../../components/informationModal";
import UserProfile from "./userProfile";
import ReservationCard from "../../../components/ReservationCard";
import {Modal, Spinner} from "react-bootstrap";
import UserRow from "./userRow";
import noAvatar from '../../../assets/img/png/no-avatar.png';
import noUserData from '../../../assets/img/png/no-user-data.png';
import plusIcon from '../../../assets/img/png/blue-plus-icon.png';
import closeButton from "../../../assets/img/svg/blue-close-button.svg";
import successImage from "../../../assets/img/svg/success-white-background.svg";
import warningImage from "../../../assets/img/svg/red-warning.svg";

const UserManagement = () => {

	const [userStatus, setUserStatus] = useState('ACTIVE');
	const [verifiedSearchValue, setVerifiedSearchValue] = useState('');
    const [unVerifiedSearchValue, setUnverifiedSearchValue] = useState('');
	const [verifiedCurrentPage, setVerifiedCurrentPage] = useState(1);
	const [unverifiedCurrentPage, setUnverifiedCurrentPage] = useState(1);
	const [verifiedTotalPageSize, setVerifiedTotalPageSize] = useState(0);
	const [verifiedItemsPerPage, setVerifiedItemsPerPage] = useState(10);
	const [unverifiedTotalPageSize, setUnverifiedTotalPageSize] = useState(0);
	const [unverifiedItemsPerPage, setUnverifiedItemsPerPage] = useState(10);

	const [isRoleNotSelected, setIsRoleNotSelected] = useState(false);
	const [noAddedEmail, setNoAddedEmail] = useState(false);
	const [isBulkRoleNotSelected, setIsBulkRoleNotSelected] = useState(false);
	const [isInviteFileNotChosen, setIsInviteFileNotChosen] = useState(false);
	const [errorAlert, setErrorAlert] = useState(false);
	const [showInviteError, setShowInviteError] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');
	const [inviteErrorMsg, setInviteErrorMsg] = useState('');
	const [searchValue, setSearchValue] = useState('');
	const [requestCompleted, setRequestCompleted] = useState(false);

	const [context, setContext] = useState({});

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);

	// Functions
	const deleteUser = (id) => {
		setShowDeleteModal(true);
		setUserId(id);
	};
	const disableUser = (id) => {
		setDisableModal(true);
		setUserId(id);
	};

	const enableUser = (id) => {
		setEnableModal(true);
		setUserId(id);
	};

	const resetUserPassword = (email) => {
		setResetPasswordModal(true);

    console.log('about to set email of selected user. email: [ ' + email + ' ]');
    setEmailOfSelectedUser(email);
	};

	const [currentSelectedUser, setCurrentSelectedUser] = useState({});
	const [currentUserRole, setCurrentUserRole] = useState('');
	const [currentUserId, setCurrentUserId] = useState('');
	const [subAccountRoles, setSubAccountRoles] = useState([]);
	const [bulkSubAccountRoles, setBulkSubAccountRoles] = useState([]);

  const defaultAvatar = 'https://storage.googleapis.com/maestro-8beff.appspot.com/user-543-_1626834155761_69ec79f5_1b40_4716_9958_1a6f557f6581.jpeg';


  const disableuser = (id) => {
		axios
		.patch(`${Constants.BASE_URL}/users/api/v1/users/${id}/status`, {
		params: { active: false },
		})
		.then((res) => console.log(res));
	};
	// STYLES
	const admin = {
		backgroundColor: "#F3D5D1",
		color: "#F85A47",
		border: "none",
		fontWeight: "bold",
		borderRadius: 5,
		paddingTop: 4,
		paddingBottom: 4,
		paddingLeft: 20,
		paddingRight: 20,
	};
	const approver = {
		backgroundColor: "#FEE6C2",
		color: "#F99500",
		border: "none",
		fontWeight: "bold",
		borderRadius: 5,
		paddingTop: 4,
		paddingBottom: 4,
		paddingLeft: 20,
		paddingRight: 20,
	};
	const employee = {
		backgroundColor: "#D7EFD7",
		color: "#02BD04",
		border: "none",
		fontWeight: "bold",
		borderRadius: 5,
		paddingTop: 4,
		paddingBottom: 4,
		paddingLeft: 20,
		paddingRight: 20,
	};

	const inactive = {
		color: "#9B9B9B",
		fontWeight: "bold",
	};

	const active = {
		color: "#2B86FF",
		fontWeight: "bold",
	};

	const [userId, setUserId] = useState(0);
  const [emailOfSelectedUser, setEmailOfSelectedUser] = useState('');

	const [unVerifiedUsers, setUnVerifiedUsers] = useState([]);
	const [verifiedUsers, setVerifiedUsers] = useState([]);

	//set state to toggle invite user
	const [show, setShow] = useState(false);

	//set state to toggle edit user
	const [editShow, setEditShow] = useState(false);

	//set state to toggle delete user
	const [deleteshow, setDeleteShow] = useState(false);

	//set state to toggle confirm delete user
	const [confirmDelete, setConfirmDelete] = useState(false);

	//set state to toggle confirm adding user
	const [confirmUserAddition, setConfirmUserAddition] = useState(false);

	const [openProfileUpdate, setOpenProfileUpdate] = useState(false);
	const [profileUpdated, setProfileUpdated] = useState(false);
	// const [loaded, setLoaded] = useState(false);

	const [role, setRole] = useState("");
	const [bulkRole, setBulkRole] = useState("");
	const [selectedFile, setSelectedFile] = useState('');

	const [state, setState] = useState([]);

	useEffect(() => {
		console.log('state holding email of invitees:', state);
	}, [state]);

	const [UnableToConfirmUserAddition, setUnableToConfirmUserAddition] =
		useState(false);

	useEffect(() => {
		const currentUser = SERVICES.getUser();
		const currentUserRole = currentUser?.role;
    const id = currentUser ? currentUser.id : 0;

		console.log('current user role:', currentUserRole);

		setCurrentUserRole(currentUserRole);
    setCurrentUserId(id);

		setUserStatus('CONFIRMED');
	}, []);

	useEffect(() => {
		switch (currentUserRole) {
			case 'ROLE_SUBSCRIBER_OWNER':
				// set value and label
				const ownerSubAccounts = [
					// {
					// 	value: 'ROLE_SUBSCRIBER_APPROVER',
					// 	label: 'Approver'
					// },
					// {
					// 	value: 'ROLE_SUBSCRIBER_INDIVIDUAL',
					// 	label: 'Regular'
					// },
          {
            value: 'ROLE_EMPLOYEE',
            label: 'Employee'
          },
          {
            value: 'ROLE_APPROVER_EMPLOYEE',
            label: 'Administrator'
          }
				];

				setSubAccountRoles(ownerSubAccounts);
				break;

			case 'ROLE_HOST':
				// set value and label
				const hostSubAccounts = [
					{
						value: 'ROLE_HOST_OPERATIONS',
						label: 'Operations Account'
					}
				]
				setSubAccountRoles(hostSubAccounts);
        break;

      case 'ROLE_ADMINISTRATOR':
        // set value and label
        const adminSubAccounts = [
          {
            value: 'ROLE_SUBSCRIBER_OWNER',
            label: 'Corporate Admin'
          }
        ]
        setSubAccountRoles(adminSubAccounts);
		}
	}, [currentUserRole]);

  useEffect(() => {
    switch (currentUserRole) {
      case 'ROLE_SUBSCRIBER_OWNER':
        // set value and label
        const ownerSubAccounts = [
          {
            value: 'EMPLOYEE',
            label: 'Employee'
          }
        ];

        setBulkSubAccountRoles(ownerSubAccounts);
        break;

      case 'ROLE_HOST':
        // set value and label
        const hostSubAccounts = [
          {
            value: 'HOST_OPERATIONS',
            label: 'Operations Account'
          }
        ]
        setBulkSubAccountRoles(hostSubAccounts);
        break;

      case 'ROLE_ADMINISTRATOR':
        // set value and label
        const adminSubAccounts = [
          {
            value: 'OWNER_SUBSCRIBER',
            label: 'Corporate Admin'
          }
        ]
        setBulkSubAccountRoles(adminSubAccounts);
    }
  }, [currentUserRole]);

	// Functions to toggle Invite User Modal
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	// Functions to toggle Delete Modal
	const handleDeleteClose = () => setDeleteShow(false);
	const handleDeleteShow = (id) => {
		// console.log(id)
		console.log(id);
		setDeleteShow(true);
		deleteUser(id);
	};

	// Functions to toggle Edit User Modal
	const handleEditClose = () => setEditShow(false);
	const handleEditShow = (user) => {
		console.log('incoming user:', user);
		// set current user
		setCurrentSelectedUser(user);
		setEditShow(true);
	};

	// Functions to toggle confirm user deletion Modal
	const confirmDeleteClose = () => setConfirmDelete(false);
	const confirmDeleteOpen = () => {
		handleStuffOnModalHide(setShowDeleteModal);

		const currentUser = SERVICES.getUser();
	  const ownerId = currentUser ? currentUser.id : 0;

		axios
		.delete(`${Constants.BASE_URL}/users/api/v1/users/delete-by-id/${userId}`, { params: { ownerId }})
		.then((res) => {
			setContext('deleted');
			setShowSuccessModal(true);

			switch (userStatus) {
				case 'CONFIRMED':
					break;

				case 'PENDING':
					const newUnVerifiedUsers = unVerifiedUsers;

					const user = unVerifiedUsers.find((u) => u.id === userId);
					newUnVerifiedUsers.splice(unVerifiedUsers.indexOf(user), 1);
					setUnVerifiedUsers(newUnVerifiedUsers);
			}
		})
		.catch(err => {
			setShowErrorModal(true);
		});
	};

	// Functions to toggle confirm adding user
	const confirmUserClose = () => setConfirmUserAddition(false);
	const confirmUserOpen = () => {
		setConfirmUserAddition(true);
		setEditShow(!editShow);
	};

	const handleCloseProfileUpdate = () => setOpenProfileUpdate(false);

	const updateUserProfile = (updatedUser) => {
		setProfileUpdated(true);
		setEditShow(false);

		setVerifiedUsers([updatedUser, ...(verifiedUsers.filter(user => user.id !== updatedUser.id))]);
	};

	// Functions to toggle disable user
	const disableUserClose = () => setDisableModal(false);
	const confirmDisableUser = () => {
		setDisableModal(false);

    const currentUser = SERVICES.getUser();
    const currentUserId = currentUser ? currentUser.id : 0;

		axios
		.patch(
			`${Constants.BASE_URL}/users/api/v1/users/${userId}/status?active=false&ownerId=${currentUserId}`,
			{ params: {} }
		)
		.then((res) => {
			const user = verifiedUsers.find((u) => u.id === userId);
			user.enabled = false;
			setVerifiedUsers(verifiedUsers);
			setConfirmDisableModal(true);
		});
	};

	

	const [enableModal, setEnableModal] = useState(false);
	const [confirmEnableModal, setConfirmEnableModal] = useState(false);
	const enableUserClose = () => setConfirmUserAddition(false);
	const confirmEnableUser = () => {
		setEnableModal(false);

    const currentUser = SERVICES.getUser();
    const currentUserId = currentUser ? currentUser.id : 0;

		axios
		.patch(
			`${Constants.BASE_URL}/users/api/v1/users/${userId}/status?active=true&ownerId=${currentUserId}`,
			{ params: {} }
		)
		.then((res) => {
			const user = verifiedUsers.find((u) => u.id === userId);
			user.enabled = true;
			setVerifiedUsers(verifiedUsers);
			setConfirmEnableModal(true);
		}).catch(err => {
      console.log('error here:', err.response.data);
      const errorResponse = err.response.data;
      const errors = errorResponse?.errors;
      const errorAt0 = (errors && errors.length > 0) ? errors[0] : null;

      const message = errorAt0?.message;

      setErrorAlert(true);
      setErrorMsg(message || 'An error occurred! Please try again');
    });
	};


	const [disableModal, setDisableModal] = useState(false);
	const [confirmDisableModal, setConfirmDisableModal] = useState(false);

	const handleDisableUser = () => {
		console.log("Here");
		setDisableModal(true);
	};

	const loaded = true;

	// Reset Password

	const [resetPasswordModal, setResetPasswordModal] = useState(false);
	const [confirmResetPasswordModal, setConfirmResetPasswordModal] =
		useState(false);

	const closeResetPasswordModal = () => {
		setResetPasswordModal(false);
	};

	const confirmResetPassword = () => {
    const emailOfUser = emailOfSelectedUser || '';
    console.log('email of selected user:', emailOfUser);

    axios.post(`${Constants.BASE_URL}/users/api/v1/users/reset-password?username=${encodeURIComponent(emailOfUser)}`,
      { params: {  } })
      .then((res) => {
        // email sent successfully
        setResetPasswordModal(false);

        setConfirmResetPasswordModal(true);
      })
      .catch((err)=>{
        console.log(err.response.data);
        const errorObj = err.response.data;

        const errors = errorObj ? errorObj.errors : [];
        const index0 = (errors.length > 0) ? errors[0] : null;

        const errorMsg = index0 ? index0.message : 'An error occurred. Please try again.';
        setErrorMsg(errorMsg);

        setErrorAlert(true);
      })
	};

	useEffect(() => {
		if (role) {
			setIsRoleNotSelected(false);
		}
		if (bulkRole) {
			setIsBulkRoleNotSelected(false);
		}
		if (selectedFile) {
			setIsInviteFileNotChosen(false);
		}
	}, [role, bulkRole, selectedFile]);

	useEffect(() => {
		if (state.length > 0) {
			setNoAddedEmail(false);
		}
	}, [state]);

	const areFieldsOkay = () => {
		const requiredFields = [role];
		// is any of the fields empty
		const anyEmpty = requiredFields.some(field => !field) || state.length === 0;
		if (anyEmpty) {
			if (!role) {
				setIsRoleNotSelected(true);
			}
			if (state.length === 0) {
				setNoAddedEmail(true);
			}
			return false;
		}  else {
			return true;
		}

	}

	const areFieldsOkayForBulk = () => {
		const requiredFields = [selectedFile, bulkRole];
		// is any of the fields empty
		const anyEmpty = requiredFields.some(field => !field);
		if (anyEmpty) {
			if (!bulkRole) {
				setIsBulkRoleNotSelected(true);
			}

			if (!selectedFile) {
				setIsInviteFileNotChosen(true);
			}
			return false;
		} else {
			return true;
		}
	}

	let history = useHistory();

	const inviteUser = () => {
		if (!areFieldsOkay()) return;

		const user = SERVICES.getUser();
	
		const inviterId = user ? user.id : 0;

		let emailsOfInvitees = state;
		
		if(!emailsOfInvitees.length){
			emailsOfInvitees = Array
									.from(document.querySelectorAll('input[data-testid=input-tags]'))
									.map(o=>o.value)
									.filter(o=>!!o);
		}

		let localUrl = `${Constants.BASE_URL}/users/api/v1/users/invite`;

		axios
		  .post(localUrl, {
			emailsOfInvitees,
			inviterId,
			role: role
		  })
		  .then((res) => {
        const response = res.data;
        const responseError = response?.errors;

        const hasError = (responseError != null) && (responseError.length > 0);
        if (hasError) {
          const errorMessage = responseError[0].message;

          setErrorAlert(true);
          setErrorMsg(errorMessage);
        }
	
			setShow(false);
			setConfirmUserAddition(true);
	
			const resourceUrl = `${Constants.BASE_URL}/users/api/v1/users/${inviterId}/sub-accounts`;
	
			axios
			  .get(resourceUrl, { params: { sortBy: "updatedDate" } })
			  .then((res) => {
				if (res.status === 200) {
				  const subAccounts = res.data.data;

				  console.log('sub accounts:', subAccounts);
	
				  setVerifiedUsers(subAccounts.filter((s) => s.verified));
				  setUnVerifiedUsers(subAccounts.filter((s) => !s.verified));
				}
			  });
		  })
		  .catch(
			(err) => {
				console.log(err);

				const errorResponse = err.response;
				const data = errorResponse?.data;
				const errors = data?.errors;

				console.log(errors);

				setErrorAlert(true);

				if (errors && Array.isArray(errors) && errors.length > 0) {
					const { message } = errors[0];
					setErrorMsg(message);
				}

				setShow(false);
				setUnableToConfirmUserAddition(true)
			}
		  );
	  };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    }

		const bulkUpload = () => {
		if (!areFieldsOkayForBulk()) return;

        const user = SERVICES.getUser();

        const inviterId = user ? user.id : 0;

        let userRole = undefined;
        switch(bulkRole) {
            case 'ROLE_SUBSCRIBER_INDIVIDUAL':
                userRole = 'REGULAR_SUBSCRIBER';
                break;

            case 'ROLE_SUBSCRIBER_APPROVER':
                userRole = 'APPROVER_SUBSCRIBER';
                break;

            default:
                userRole = '';
        }

        let formData = new FormData();

        formData.append('inviterId', inviterId);
        formData.append('role', bulkRole);
        formData.append('file', selectedFile)

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

		let localUrl = `${Constants.BASE_URL}/users/api/v1/users/invite/bulk`;

        axios
            .post(localUrl, formData, config)
            .then(res => {
                // console.log(res.data.data);

                setShow(false);
                setConfirmUserAddition(true);

                const resourceUrl = `${Constants.BASE_URL}/users/api/v1/users/${inviterId}/sub-accounts`;

                axios
                    .get(resourceUrl, { params: { sortBy: "createdDate" } })
                    .then((res) => {
                        if (res.status === 200) {
                            const subAccounts = res.data.data;

                            console.log('sub accounts:', subAccounts);

                            setVerifiedUsers(subAccounts.filter((s) => s.verified));
                            setUnVerifiedUsers(subAccounts.filter((s) => !s.verified));
                        }
                    });
            })
            .catch(err => {
                    console.log(err);
                    setShow(false);
                    setUnableToConfirmUserAddition(true);
            });
    };

	const handleRoleChange = (e) => {
		setRole(e.target.value);
	};

	const handleBulkRoleChange = (e) => {
		setBulkRole(e.target.value);
	};
	// const deleteTask = async (id) =>{
	//     await fetch(`http://localhost:5000/tasks/${id}`,{
	//       method:'DELETE'
	//     })
	//     setTasks(tasks.filter((task) => task.id !== id))
	//     console.log('deleted', id)
	//   }

	const deleteuser = (id) => {
		const user = SERVICES.getUser();
		const ownerId = user ? user.id : 0;

		console.log('user id is', id, 'and owner id is', ownerId);
		
		axios
			.delete(`${Constants.BASE_URL}/users/api/v1/users/delete-by-id/${id}?ownerId=${ownerId}`)
			.then((res) => {
				console.log(res);
			})
			.catch((err) => console.log(err));
	};

	useEffect(() => {
		setRequestCompleted(false);

		const currentUser = SERVICES.getUser();
		const ownerId = currentUser ? currentUser.id : 0;
		const resourceUrl = `${Constants.BASE_URL}/users/api/v1/users/${ownerId}/sub-accounts`;

		console.log('getting all approved users');

		if (userStatus != null) {
			switch (userStatus) {
				case 'CONFIRMED':
					// get verified users
					axios
						.get(resourceUrl, { params: { sortBy: "updatedDate", verified: true, page: (verifiedCurrentPage - 1) } })
						.then((res) => {
							if (res.status === 200) {
								setRequestCompleted(true);

								console.log(res);
								const subAccounts = res.data.data;

								// const verifiedUsers = subAccounts.filter((s) => s.verified);
								setVerifiedUsers(subAccounts);

								setVerifiedTotalPageSize(res.data.totalCount);
								// setTotalPageSize(500);
								setVerifiedItemsPerPage(res.data.pageSize);

								// const unverifiedUsers = subAccounts.filter((s) => !s.verified);
								// setUnVerifiedUsers(unverifiedUsers);
							}
						}).catch(err => {
						setRequestCompleted(true);
					});
					break;

				case 'PENDING':
					// get unverified users
					axios
						.get(resourceUrl, { params: { sortBy: "createdDate", verified: false, page: (verifiedCurrentPage - 1) } })
						.then((res) => {
							if (res.status === 200) {
								setRequestCompleted(true);

								console.log(res);
								const subAccounts = res.data.data;

								// const verifiedUsers = subAccounts.filter((s) => s.verified);
								setUnVerifiedUsers(subAccounts);

								setUnverifiedTotalPageSize(res.data.totalCount);
								// setTotalPageSize(500);
								setUnverifiedItemsPerPage(res.data.pageSize);

								// const unverifiedUsers = subAccounts.filter((s) => !s.verified);
								// setUnVerifiedUsers(unverifiedUsers);
							}
						}).catch(err => {
						setRequestCompleted(true);
					});
			}
		}
	}, [userStatus]);

	// useEffect(() => {
	// 	setRequestCompleted(false);
	//
	// 	const currentUser = SERVICES.getUser();
	// 	const ownerId = currentUser ? currentUser.id : 0;
	// 	const resourceUrl = `${Constants.BASE_URL}/users/api/v1/users/${ownerId}/sub-accounts`;
	//
	// 	// get unverified users
	// 	axios
	// 		.get(resourceUrl, { params: { sortBy: "createdDate", verified: false, page: (verifiedCurrentPage - 1) } })
	// 		.then((res) => {
	// 			if (res.status === 200) {
	// 				setRequestCompleted(true);
	//
	// 				console.log(res);
	// 				const subAccounts = res.data.data;
	//
	// 				// const verifiedUsers = subAccounts.filter((s) => s.verified);
	// 				setUnVerifiedUsers(subAccounts);
	//
	// 				setUnverifiedTotalPageSize(res.data.totalCount);
	// 				// setTotalPageSize(500);
	// 				setUnverifiedItemsPerPage(res.data.pageSize);
	//
	// 				// const unverifiedUsers = subAccounts.filter((s) => !s.verified);
	// 				// setUnVerifiedUsers(unverifiedUsers);
	// 			}
	// 		}).catch(err => {
	// 			setRequestCompleted(true);
	// 	});
	// }, []);

	const convertRole = (role) => {
		switch (role) {
			case "ROLE_SUBSCRIBER_APPROVER":
				return "APPROVER";
			case "ROLE_SUBSCRIBER_INDIVIDUAL":
				return "REGULAR";
			case "ROLE_CONTENT_PUBLISHER":
				return "CONTENT PUBLISHER";
			case "ROLE_HOST_OPERATIONS":
				return "OPERATIONS ACCOUNT";
      		case "ROLE_SUBSCRIBER_OWNER":
        		return "CORPORATE ADMIN";
      		case "ROLE_HOST":
        		return "HOST";
      		case "ROLE_EMPLOYEE":
        		return "EMPLOYEE";
			case "ROLE_APPROVER_EMPLOYEE":
				return "Administrator";
			default:
				return "N/A";
		}
	};

	const convertFromVerifiedStatus = (user) => {
		if (user.verified) {
			if (user.enabled) {
				return "Active";
			}
			return "Inactive";
		} else {
			return "Pending";
		}
	};

	const performSearchForVerified = (e) => {
		setRequestCompleted(false);

		e.preventDefault();

		console.log('current page (verified):', verifiedCurrentPage);

		const currentUser = SERVICES.getUser();
		const ownerId = currentUser ? currentUser.id : 0;
		const resourceUrl = `${Constants.BASE_URL}/users/api/v1/users/${ownerId}/sub-accounts`;

		axios
			.get(resourceUrl, { params: { sortBy: "createdDate", verified: true, page: (verifiedCurrentPage - 1), search: searchValue } })
			.then((res) => {
				if (res.status === 200) {
					setRequestCompleted(true);

					console.log(res);
					const subAccounts = res.data.data;

					setVerifiedUsers(subAccounts);

					setVerifiedTotalPageSize(res.data.totalCount);
					// setTotalPageSize(500);
					setVerifiedItemsPerPage(res.data.pageSize);
				}
			}).catch(err => {
				setRequestCompleted(true);
		});
	}

	const performSearchForUnverified = (e) => {
		setRequestCompleted(false);

	    e.preventDefault();

        const currentUser = SERVICES.getUser();
        const ownerId = currentUser ? currentUser.id : 0;
        const resourceUrl = `${Constants.BASE_URL}/users/api/v1/users/${ownerId}/sub-accounts`;

        axios
            .get(resourceUrl, { params: { sortBy: "createdDate", verified: false, page: (unverifiedCurrentPage - 1), search: searchValue } })
            .then((res) => {
                if (res.status === 200) {
									setRequestCompleted(true);

									console.log(res);
									const subAccounts = res.data.data;

									setUnVerifiedUsers(subAccounts);

									setUnverifiedTotalPageSize(res.data.totalCount);
									// setTotalPageSize(500);
					setUnverifiedItemsPerPage(res.data.pageSize);
                    // setUnVerifiedUsers(subAccounts.filter((s) => !s.verified));
                }
            }).catch(err => {
							setRequestCompleted(true);
				});
    }

	const [verifiedPageCount, setVerifiedPageCount] = useState(0);
	const [unverifiedPageCount, setUnverifiedPageCount] = useState(0);

	useEffect(() => {
		console.log('total page size:', verifiedTotalPageSize);
		console.log('items per page:', verifiedItemsPerPage);

		console.log('rounded count: ' + Math.ceil(verifiedTotalPageSize / verifiedItemsPerPage));

		setVerifiedPageCount(Math.ceil(verifiedTotalPageSize / verifiedItemsPerPage))
	}, [verifiedTotalPageSize,verifiedItemsPerPage ]);

	useEffect(() => {
		console.log('total page size (unverified):', unverifiedTotalPageSize);
		console.log('items per page (unverified):', unverifiedItemsPerPage);

		console.log('rounded count (unverified): ' + Math.ceil(unverifiedTotalPageSize / unverifiedItemsPerPage));

		setUnverifiedPageCount(Math.ceil(unverifiedTotalPageSize / unverifiedItemsPerPage))
	}, [unverifiedTotalPageSize, unverifiedItemsPerPage ]);

	const changeVerifiedPage = (selected) => {
		setRequestCompleted(false);

		const currentUser = SERVICES.getUser();
		const ownerId = currentUser ? currentUser.id : 0;
		const resourceUrl = `${Constants.BASE_URL}/users/api/v1/users/${ownerId}/sub-accounts`;

		console.log('selected page number is', selected);
		setVerifiedCurrentPage(selected);

		axios
			.get(resourceUrl, { params: { sortBy: "createdDate", verified: true, page: (selected - 1), search: verifiedSearchValue } })
			.then((res) => {
				if (res.status === 200) {
					setRequestCompleted(true);

					console.log(res);
					const subAccounts = res.data.data;

					setVerifiedUsers(subAccounts);

					setVerifiedTotalPageSize(res.data.totalCount);
					// setTotalPageSize(500);
					setVerifiedItemsPerPage(res.data.pageSize);
				}
			}).catch(err => {
				setRequestCompleted(true);
		});
	}

	const changeUnverifiedPage = (selected) => {
		setRequestCompleted(false);

		const currentUser = SERVICES.getUser();
		const ownerId = currentUser ? currentUser.id : 0;
		const resourceUrl = `${Constants.BASE_URL}/users/api/v1/users/${ownerId}/sub-accounts`;

		console.log('selected page number is', selected);
		setVerifiedCurrentPage(selected);

		axios
			.get(resourceUrl, { params: { sortBy: "createdDate", verified: false, page: (selected - 1), search: verifiedSearchValue } })
			.then((res) => {
				if (res.status === 200) {
					console.log(res);

					setRequestCompleted(true);

					const subAccounts = res.data.data;

					setUnVerifiedUsers(subAccounts);

					setUnverifiedTotalPageSize(res.data.totalCount);
					// setTotalPageSize(500);
					setUnverifiedItemsPerPage(res.data.pageSize);

					// setUnVerifiedUsers(subAccounts.filter((s) => s.verified));
				}
			}).catch(err => {
				setRequestCompleted(true);
		});
	}

  const downloadBulkUploadSample = () => {
    const link = document.createElement('a');
    link.href = 'https://storage.googleapis.com/download/storage/v1/b/spacia-16ab5.appspot.com/o/spacia-com-reg-doc-1647851464800-_nvitees%20List%20-%20Shee?generation=1647851458873027&alt=media';
    link.setAttribute('download', 'bulk upload sample.csv');
    document.body.appendChild(link);
    link.click();
  }

	const handleSwitch = (e) => {

		if (e.target.id === "confirmedBtn") {
			setUserStatus('CONFIRMED');
		}else {
			setUserStatus('PENDING');
		}
	}

	const handleStuffOnModalHide = (showModal) => {
		showModal(false);

		const userManagementContainer = document.querySelector('.user-management-content');
		if (userManagementContainer != null) {
			userManagementContainer.classList.remove('blur-background');
		}
	}

	const componentToRender = () => {
		if (requestCompleted) {
			if (userStatus === 'CONFIRMED' && Array.isArray(verifiedUsers) && verifiedUsers.length > 0) {
				return (
					verifiedUsers
						.filter(user => user.id !== currentUserId)
						.map((user, i) =>  {
							return (
								<UserRow
									key={uuidv4()}
									user={user}
									profile={user.avatar || noAvatar}
									status={userStatus}
									name={
										user.firstName &&
										user.lastName
											? `${user.firstName} ${user.lastName}`
											: "N/A"
									}
									email={user.username}
								/>
							)
					}
				))
			} else if (userStatus === 'PENDING' && Array.isArray(unVerifiedUsers) && unVerifiedUsers.length > 0) {
				return unVerifiedUsers
					.filter(user => user.id !== currentUserId)
					.map((user, i) =>  {
						return (
							<UserRow
								key={uuidv4()}
								user={user}
								profile={user.avatar || noAvatar}
								status={userStatus}
								name={
									user.firstName &&
									user.lastName
										? `${user.firstName} ${user.lastName}`
										: "N/A"
								}
								email={user.username}
								onDelete={() => deleteUser(user.id)}
							/>
						)
					})
			} else if (userStatus === 'CONFIRMED' && Array.isArray(verifiedUsers) && verifiedUsers.length === 0) {
				return (
					<div className="container-for-perfect-center">
						<div className='perfect-center'>
              <figure className={'w-100 text-center'}>
                <img
                  src={noUserData}
                  alt="no user data"
                  style={{width: '70%'}}
                />
              </figure>
						</div>
					</div>
				)
			} else if (userStatus === 'PENDING' && Array.isArray(unVerifiedUsers) && unVerifiedUsers.length === 0) {
				return (
					<div className="container-for-perfect-center">
						<div className='perfect-center'>
              <figure className={'w-100 text-center'}>
                <img
                  src={noUserData}
                  alt="no user data"
                  style={{width: '70%'}}
                />
              </figure>
							{/*<p style={{fontWeight: 'bold', fontSize: '1.3rem'}}>No Pending Users Found</p>*/}
						</div>
					</div>
				)
			}
		} else if (!requestCompleted) {
			return (
				<div className='container-for-perfect-center'>
					<div className='perfect-center'>
						<Spinner size="sm" animation="border" role="status" style={{width: '50px', height: '50px'}}>
							<span className="visually-hidden"></span>
						</Spinner>{" "}
					</div>
				</div>
			)
		}
	}

	return (
		<div className='mobile-view user-management' style={{background: "#FCFCFC"}}>
			<div className="section mt-2 p-3 position-relative" style={{minHeight: '88vh'}}>
				<PageTitle>Users</PageTitle>
				<div className="user-management-content" style={{marginTop: '25px'}}>
					<form className="search-form" onSubmit={(e) => {
						if (userStatus === 'CONFIRMED') {
							performSearchForVerified(e);
						} else if (userStatus === 'PENDING') {
							performSearchForUnverified(e);
						}
					}}>
						<div className="form-group searchbox">
							<input
								type="text"
								className="form-control"
								placeholder={'Search by name or email'}
								value={searchValue}
								onChange={e => setSearchValue(e.target.value)}
							/>
							<i className="input-icon">
								<SearchOutline
								/>
							</i>
						</div>
					</form>
				</div>
				<div className={'mt-3 text-center'}>
					<div className="btn-group btn-lg " role="group">
						<input type="radio" className="btn-check" name="confirmedBtn" id="confirmedBtn" checked={userStatus === 'CONFIRMED'} onChange={handleSwitch} onClick={handleSwitch} />
						<label className={userStatus === 'CONFIRMED' ? 'reservation-btn btn btn-primary' : 'reservation-btn btn btn-outline-primary'} htmlFor="confirmedBtn">Confirmed</label>

						<input type="radio" className="btn-check" name="pendingBtn" id="pendingBtn"   checked={userStatus === 'PENDING'} onChange={handleSwitch}  onClick={handleSwitch} />
						<label className={userStatus === 'PENDING' ? 'reservation-btn btn btn-primary' : 'reservation-btn btn btn-outline-primary'} htmlFor="pendingBtn">Pending</label>

					</div>
				</div>
				<div className="mt-5 users-holder" style={{maxHeight: '45vh'}}>
					{
						componentToRender()
					}
				</div>
				<div className="position-absolute" style={{bottom: 0, right: 0}}>
					<figure className="mb-0">
						<img
							className="btn"
							role="button"
							src={plusIcon}
							alt="add user button"
							style={{width: '100px', height: '70px'}}
							onClick={() => history.push('/add-user')}
						/>
					</figure>
				</div>
				{
					Array.isArray(verifiedUsers) && verifiedUsers.length > 0 &&
					<div className='mobile-pagination'>
						<Pagination
							className="pagination-bar"
							currentPage={verifiedCurrentPage}
							totalCount={verifiedTotalPageSize}
							pageSize={verifiedItemsPerPage}
							onPageChange={changeVerifiedPage}
						/>
					</div>
				}
				{
					Array.isArray(unVerifiedUsers) && unVerifiedUsers.length > 0 &&
					<div className='mobile-pagination'>
						<Pagination
							className="pagination-bar"
							currentPage={unverifiedCurrentPage}
							totalCount={unverifiedTotalPageSize}
							pageSize={unverifiedItemsPerPage}
							onPageChange={changeUnverifiedPage}
						/>
					</div>
				}
			</div>

			{/* define modals */}

			{/*Delete Modal*/}
			<Modal show={showDeleteModal} onHide={() => handleStuffOnModalHide(setShowDeleteModal)} onEnter={e => console.log(e)}>
				<header className={'position-relative'} style={{zIndex: '3'}}>
					<figure className="text-right mb-0" style={{padding: '15px'}}>
						<img onClick={() => handleStuffOnModalHide(setShowDeleteModal)} src={closeButton} alt="close button" style={{width: '25px'}}/>
					</figure>
				</header>
				<Modal.Body style={{height: '250px'}}>
					<div>
						<p className={'text-black font-weight-bolder mb-4'} style={{fontSize: '1.55rem'}}>Delete Confirmation</p>
						<p style={{fontSize: '1.1rem'}}>
							Are you sure you want to delete user?
						</p>
						<div className={'mt-5 text-center'}>
							<button
								className={'btn text-black text-capitalize'}
								style={{cursor: 'pointer', marginRight: '25px', width: '110px', background: '#F7F7F7', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.4)', borderRadius: '15px'}}
								onClick={() => handleStuffOnModalHide(setShowDeleteModal)}
							>
								cancel
							</button>
							<button
								className={'btn btn-primary text-white text-capitalize'}
								style={{cursor: 'pointer', width: '110px', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.4)', borderRadius: '15px'}}
								onClick={confirmDeleteOpen}
							>
								delete
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			{/*Success Modal*/}
			<Modal show={showSuccessModal} onHide={() => handleStuffOnModalHide(setShowSuccessModal)} onEnter={e => console.log(e)}>
				<header className={'position-relative d-flex align-items-center'} style={{zIndex: '3'}}>
					<p className={'text-black text-center font-weight-bolder mb-0 text-capitalize'} style={{fontSize: '1.55rem', width: '90%'}}>{context}</p>
					<figure className="text-right mb-0" style={{padding: '15px'}}>
						<img onClick={() => handleStuffOnModalHide(setShowSuccessModal)} src={closeButton} alt="close button" style={{width: '25px'}}/>
					</figure>
				</header>
				<Modal.Body style={{height: '265px', textAlign: 'center'}}>
					<div className={'mx-auto'} style={{width: '80%'}}>
						<p style={{fontSize: '1.1rem'}}>
							You have successfully {context} the user
						</p>
						<figure>
							<img src={successImage} alt="success image"/>
						</figure>
						<div className={'mt-5 text-center'}>
							<button
								className={'btn text-black text-uppercase'}
								style={{cursor: 'pointer', width: '120px', background: '#F7F7F7', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.4)', borderRadius: '15px'}}
								onClick={() => {
									if (context) {
										console.log('current context:', context);

										switch (context) {
											case 'deleted':
												handleStuffOnModalHide(setShowSuccessModal);
												break;

											case 'disabled':
												handleStuffOnModalHide(setShowSuccessModal);
												break;

											case 'enabled':
												handleStuffOnModalHide(setShowSuccessModal);
												break;
										}
									}
								}}
							>
								ok
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			{/*Error Modal*/}
			<Modal show={showErrorModal} onHide={() => handleStuffOnModalHide(setShowErrorModal)} onEnter={e => console.log(e)}>
				<header className={'position-relative d-flex align-items-center'} style={{zIndex: '3'}}>
					<p className={'text-black text-center font-weight-bolder mb-0'} style={{fontSize: '1.55rem', width: '90%'}}>Sorry</p>
					<figure className="text-right mb-0" style={{padding: '15px'}}>
						<img onClick={() => handleStuffOnModalHide(setShowErrorModal)} src={closeButton} alt="close button" style={{width: '25px'}}/>
					</figure>
				</header>
				<Modal.Body style={{height: '280px', textAlign: 'center'}}>
					<div className={'mx-auto'} style={{width: '80%'}}>
						<p style={{fontSize: '1.1rem'}}>
							Whoa! something went wrong.
						</p>
						<p style={{fontSize: '1.1rem'}}>
							Kindly try again
						</p>
						<figure>
							<img src={warningImage} alt="warning image"/>
						</figure>
						<div className={'mt-4 text-center'}>
							<button
								className={'btn text-black'}
								style={{cursor: 'pointer', width: '165px', background: '#F7F7F7', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.4)', borderRadius: '15px'}}
								onClick={() => handleStuffOnModalHide(setShowErrorModal)}
							>
								Please try again
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default UserManagement;
