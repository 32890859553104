// @ts-nocheck

/**
 * Project: spaces
 * File: Header
 * Created by Pennycodes on 2/4/2022.
 * Copyright spaces
 */
import React, {useEffect} from "react";
import {ChevronBackOutline,} from "react-ionicons";
import { usePageData} from "../PageData";
import { useHistory } from 'react-router-dom';

export function Header() {
    const history = useHistory()
    const {pageTitle, leftComponent, rightComponent} = usePageData();

    function goBack() {
        history.go(-1);
    }

    function onHomePage() {
        const location = history.location;
        const pathname = location?.pathname;
        
        return pathname != null && pathname === '/home';
    }

    return (
        <>
        {(pageTitle || leftComponent || rightComponent) &&  <div className="appHeader text-dark" style={{display: (onHomePage()) ? 'none' : 'flex'}}>
            <div className="left">
                {leftComponent ||   <a href="#!" onClick={goBack} className="headerButton ">
                    <ChevronBackOutline
                    />
                </a>}
            </div>
            <div className="pageTitle">
                {pageTitle}
            </div>
            <div className="right">
                {rightComponent || null}
            </div>
        </div>}
        </>
    )
}
