/**
 * Project: spaces
 * File: Detection
 * Created by Pennycodes on 2/4/2022.
 * Copyright spaces
 */
const osDetection = navigator.userAgent || navigator.vendor ;
const windowsPhoneDetection = /windows phone/i.test(osDetection);
const androidDetection = /android/i.test(osDetection);
const iosDetection = /iPad|iPhone|iPod/.test(osDetection) && !('MSStream' in window);

 function getWindowsPhoneDetection (): boolean {
    return windowsPhoneDetection
}
 function getAndroidDetection (): boolean {
    return androidDetection
}
 function getIosDetection (): boolean {
    return iosDetection
}
 function getOsDetection (): string {
    return osDetection
}

export {
    getWindowsPhoneDetection,
    getAndroidDetection,
    getIosDetection,
    getOsDetection
}
