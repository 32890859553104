import React from "react";
import { Modal } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";

function FeedbackModal({
	declineButton,
	doneButton,
	acceptButton,
	body,
	title,
	isOpen,
	isClose,
	onSubmit,
	children,
}) {
	return (
		<Modal
			show={isOpen}
			onHide={isClose}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			contentClassName="padding-40px"
                style={{
                    overflow: "hidden",
                    borderRadius: "30px",
                }}
		>
		
		<Modal.Header
                    style={{
                        borderBottom: "none",
                        paddingLeft: "30px",
					paddingBottom: 0,

                        paddingRight: "30px",
                        margin: "0px",
                    }}
                    closeButton
                >
                    <Modal.Title
                        id="contained-modal-title-vcenter"
                        style={{ width: "100%", textAlign: "center" }}
                    >
                        {/* <h6 className="text-primary">Confirm Payment</h6> */}
                    </Modal.Title>
                </Modal.Header>
			{/* <Modal.Body> */}
			<div   className={"text-primary"} style={{ textAlign: "center", padding: 0,  fontSize: "1.3rem", fontWeight: 200 }}>{children}</div>
			{/* </Modal.Body> */}
			{/* <Modal.Footer> */}
			<div
				style={{
					display: "flex",
					justifyContent: "space-evenly",
					padding: 10,
					paddingBottom: 20,
				}}
			>
				<button onClick={isClose}  className="btn btn-outline-primary"
                               style={{
                                    borderRadius: "20px",
                                    marginLeft: "0px",
                                    width:"8rem"
                                }}>
					{doneButton}
				</button>
				{/* <button onClick={onSubmit} className="button">{acceptButton}</button> */}
			</div>
			{/* </Modal.Footer> */}
		</Modal>
	);
}

function BlockerModal({
	declineButton,
	doneButton,
	acceptButton,
	body,
	title,
	isOpen,
	isClose,
	onSubmit,
	children,
}) {
	return (
		<Modal
			show={isOpen}
			onHide={isClose}
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			{/* <div
				style={{
					display: "flex",
					flexDirection: "row-reverse",
					padding: 30,
				}}
			>
				<FaTimes  onClick={isClose}/>
				<h6 style={{ margin: "auto" }}>
					<b>{title}</b>
				</h6>
			</div> */}
			<Modal.Header
                    style={{
                        borderBottom: "none",
                        paddingLeft: "30px",
					paddingBottom: 0,

                        paddingRight: "30px",
                        margin: "0px",
                    }}
                    closeButton
                >
                    <Modal.Title
                        id="contained-modal-title-vcenter"
                        style={{ width: "100%", textAlign: "center" }}
                    >
					{title}
                        {/* <h6 className="text-primary">Confirm Payment</h6> */}
                    </Modal.Title>
                </Modal.Header>
			{/* <Modal.Body> */}
			<div style={{ textAlign: "center", padding: 30 }}>{children}</div>
			{/* </Modal.Body> */}
			{/* <Modal.Footer> */}
			<div
				style={{
					display: "flex",
					justifyContent: "space-evenly",
					padding: 10,
					paddingBottom: 30,
				}}
			>
				<button onClick={onSubmit} className="button-calm">
					{doneButton}
				</button>
				{/* <button onClick={onSubmit} className="button">{acceptButton}</button> */}
			</div>
			{/* </Modal.Footer> */}
		</Modal>
	);
}

export { FeedbackModal, BlockerModal };

export default FeedbackModal;
