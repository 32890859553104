// @ts-nocheck

/**
 * Project: spaces
 * File: SearchPage
 * Created by Pennycodes on 2/4/2022.
 * Copyright spaces
 */
import React, {ChangeEvent, CSSProperties, FormEvent, useEffect, useState} from "react";
import {ChevronForwardOutline, MenuOutline} from "react-ionicons";
import InputRange from 'react-input-range';
import {PageTitle, LeftComponent} from '../../layout/PageData';
import { v4 as uuidv4 } from 'uuid';
import {Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import SERVICES from "../../services";
import QuantityCounter from "../../components/QuantityCounter";
import {FilterOptions, Location, SubCategoryType, User} from "../../utils/interfaces";
import Slide from "rc-slider";
import {Col} from "react-bootstrap";
// import FormControl from "@material-ui/core/FormControl";
// import Input from "@material-ui/core/Input";
import {saveSelectedFilters} from "../../redux/actions/dashboard";
import {ErrorAlert} from "../../components/Settings/alerts/ErrorAlert";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment-timezone";
import Utils from "../../utils";
import axios from "axios";
import {Constants} from "../../utils/constants";
import Autocomplete from 'react-autocomplete';

const SearchPage = () => {
    // all filter options
    // @ts-ignore
    const filters = useSelector(state => state.dashboard.filterOptions);

    const dispatch = useDispatch();
    const history = useHistory();

    /* Define all states for the StartBooking component
    *  before doing any other business */

    const [filterOptions, setFilterOptions] = useState({} as FilterOptions); // save all filters options received from api

    const [propertyType, setPropertyType] = useState(''); // handle property type state. default to `OFFICE_SPACE`
    const [workspaceType, setWorkspaceType] = useState(''); // handle workspace type state. default to `PRIVATE_OFFICE`
    const [startDateObject, setStartDateObject] = useState(new Date()); // start date for a booked property initialized to the current date
    const [startDate, setStartDate] = useState('');
    const [endDateObject, setEndDateObject] = useState(new Date()); // end date for a booked property initialized to the current date
    const [endDate, setEndDate] = useState('');
    const [capacity, setCapacity] = useState(1); // handle capacity count
    const [maxPrice, setMaxPrice] = useState(0); // maximum price filter
    const [location, setLocation] = useState('');

    let [searchValue, setSearchValue] = useState('');
    const [locationList, setLocationList] = useState([]);
    const [locationAddressList, setLocationAddressList] = useState([]);
    const [locationCityList, setLocationCityList] = useState([]);
    const [locationCountryList, setLocationCountryList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [combinedLocationsMatch, setCombinedLocationsMatch] = useState([]);
    const [selectedLocationAndMatch, setSelectedLocationAndMatch] = useState('');

    const [workSpaceTypes, setWorkSpaceTypes] = useState([] as Array<SubCategoryType>); // an array of all sub categories

    const [newUserModal, setNewUserModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorAlert, setErrorAlert] = useState(false);

    const userCurrency = SERVICES.getCurrency();

    useEffect(() => {
        const now = moment(new Date()).format('yyyy-MM-DDTHH:mm');
        console.log('today is:', now);

        const startDateObject = new Date(now);
        const endDateObject = new Date(now);

        setStartDateObject(startDateObject);
        setEndDateObject(endDateObject);

        setStartDate(currentDate(startDateObject));
        setEndDate(currentDate(endDateObject));
    }, []);

    const isLoggedIn = (user: User) => {
        return user != null && Object.keys(user).length !== 0;
    }

    useEffect(() => {
        // redirect to sign in page when user is not logged in

        const user = SERVICES.getUser() as User;

        if (!isLoggedIn(user)) {
            history.push('/signin');
        }
    }, []);

    // set the filter options state when the page first renders
    useEffect(() => {
        console.log('filter options:', filters);

        setFilterOptions(filters);
    }, []);

    useEffect(() => {
        if (Object.keys(filterOptions).length !== 0) {
            // default property type to `OFFICE_SPACE`
            setPropertyType('OFFICE_SPACE');

            // default workspace type to `PRIVATE_OFFICE`
            setWorkspaceType('PRIVATE_OFFICE');
        }
    }, [filterOptions]);

    // set the value of workSpaceTypes
    useEffect(() => {
        switch (propertyType) {
            case 'OFFICE_SPACE':
                const officeSpaceSubCategories = (filterOptions && filterOptions['subCategoryTypes']) ? filterOptions['subCategoryTypes'].filter(sub => sub.parent === propertyType) : [];
                console.log(officeSpaceSubCategories);
                setWorkSpaceTypes(officeSpaceSubCategories);
                break;

            case 'RESIDENTIAL_SPACE':
                const residentialSpaceSubCategories = (filterOptions && filterOptions['subCategoryTypes']) ? filterOptions['subCategoryTypes'].filter(sub => sub.parent === propertyType) : [];
                setWorkSpaceTypes(residentialSpaceSubCategories);
                break;

            case 'SERVICE':
                const serviceSubCategories = (filterOptions && filterOptions['subCategoryTypes']) ? filterOptions['subCategoryTypes'].filter(sub => sub.parent === propertyType) : [];
                setWorkSpaceTypes(serviceSubCategories);
                break;

            default:
                setWorkSpaceTypes([]);
        }
    }, [propertyType]);

    const formatLocation = (location: Location) => {
        const city = location && location.city;
        const country = location && location.country;

        let label = (country) ? country.label : '';
        label = label.charAt(0).toUpperCase() + label.slice(1);

        return `${city}, ${label.toUpperCase()}`;
    }

    const handlePropertyTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const selectedPropertyType = e.target.value;
        console.log('selected property type is', selectedPropertyType);

        setPropertyType(selectedPropertyType);
    }

    const handleWorkSpaceChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const selectedWorkspace = e.target.value;
        console.log('selected workspace is', selectedWorkspace);

        setWorkspaceType(selectedWorkspace);
    }

    const handleLocationChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        console.log(value);

        const cityAndCountry = value.split(',');
        const [city, country] = cityAndCountry;

        const location = `${city},${country}`;
        setLocation(location);

        console.log('city', city, 'country', country);
    }

    const handleStartDateChange = (dateValue: Date | null) => {
        console.log('current selected start date:', dateValue);

        if (dateValue != null) {
            const selectedStartDate = new Date(dateValue);
            setStartDateObject(selectedStartDate);

            const startDate = currentDate(selectedStartDate);

            console.log('current date func:', startDate);

            setStartDate(startDate);
        }
    };

    const handleEndDateChange = (dateValue: Date | null) => {
        console.log('current selected end date:', dateValue);

        if (dateValue != null) {
            const selectedEndDate = new Date(dateValue);
            setEndDateObject(selectedEndDate);

            const endDate = currentDate(selectedEndDate);

            console.log('current date func:', endDate);

            setEndDate(endDate);
        }
    }

    function currentDate(date: Date) {
        // return date.toISOString().substring(0, date.toISOString().lastIndexOf(':'));
        console.log('current selected date:', date);
        const formatted = moment(date).format('yyyy-MM-DDTHH:mm');
        console.log('formatted current selected date:', formatted);

        return moment(date).format('yyyy-MM-DDTHH:mm');
    }

    // a function that saves all selected filters for use later
    const dispatchFilters = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        console.log('start date:', startDate);
        console.log('end date:', endDate);

        if (startDate == endDate) {
            setErrorAlert(true);
            setErrorMsg('Start date and End date must be different');
            return;
        }

        if (startDate > endDate) {
            setErrorAlert(true);
            setErrorMsg('Start date must not be greater than the end date');
            return;
        }

        const selectedFilters = {
            cost: maxPrice,
            location: selectedLocationAndMatch,
            locationDisplayValue: searchValue,
            propertyType,
            workspaceType,
            capacity,
            from: startDate,
            to: endDate
        }

        console.log('selected filters here:', selectedFilters);

        // dispatch the selected filters with redux
        dispatch(saveSelectedFilters(selectedFilters));

        // load the `/filterprops` page
        history.push('/search');
    }

    const handleStyle: CSSProperties = { background: 'white', marginTop: '-5px' }
    const trackStyle: CSSProperties = { height: '6px' }

    const selectionRange = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }
    const handleSelect = (ranges:any) =>{
        console.log(ranges);
        // {
        //   selection: {
        //     startDate: [native Date Object],
        //     endDate: [native Date Object],
        //   }
        // }
    }

    const [value, setValue] = useState<any>(0)

    useEffect(() => {
        const momentDate = moment();
        console.log('moment date:', momentDate.get('date'));
    }, []);

    const [doneCombiningLocations, setDoneCombiningLocations] = useState(false);
    useEffect(() => {
        setDoneCombiningLocations(false);

        const addressesInLocation = locationList.filter(location => location.match === 'ADDRESS')
            .map(location => {
                const { address, city, country } = location;
                const countryName = country != null ? Utils.convertFirstLetterOfEachWordToUpperCase(country.label) : '';

                const displayValue = `${address}, ${city}, ${countryName}`;

                return {
                    displayValue,
                    ...location
                }
            });

        // get distinct locations based on the address
        const uniqueAddressesInLocation = [...new Map(addressesInLocation.map((location) => [location["address"], location])).values()];

        const citiesInLocation = locationList.filter(location => location.match === 'CITY')
            .map(location => {
                const { city, country } = location;
                const countryName = country != null ? Utils.convertFirstLetterOfEachWordToUpperCase(country.label) : '';

                const displayValue = `${city}, ${countryName}`;

                return {
                    displayValue,
                    ...location
                }
            });

        // get distinct locations based on the city
        const uniqueCitiesInLocation = [...new Map(citiesInLocation.map((location) => [location["city"], location])).values()];

        const countriesInLocation = locationList.filter(location => location.match === 'COUNTRY')
            .map(location => {
                const { country } = location;
                const countryName = country != null ? Utils.convertFirstLetterOfEachWordToUpperCase(country.label) : '';

                const displayValue = `${countryName}`;

                return {
                    displayValue,
                    ...location
                }
            });

        // get distinct locations based on the country
        const uniqueCountriesInLocation =  [...new Map(countriesInLocation.map((location) => {
            const country = location["country"];
            const label = country != null ? country.label : '';

            return [label, location];
        })).values()];

        const companyNames = locationList.filter(location => location.match === 'COMPANY_NAME')
            .map(location => {
                const { name: companyName } = location;

                const displayValue = `${companyName}`;

                return {
                    displayValue,
                    ...location
                }
            });

        // get distinct companies based on the name
        const uniqueCompanyNames = [...new Map(companyNames.map((location) => [location["name"], location])).values()];

        setLocationAddressList(uniqueAddressesInLocation);
        setLocationCityList(uniqueCitiesInLocation);
        setLocationCountryList(uniqueCountriesInLocation);
        setCompanyList(uniqueCompanyNames);

        console.log('location address list:', uniqueAddressesInLocation);
        console.log('location city list:', uniqueCitiesInLocation);
        console.log('location country list:', uniqueCountriesInLocation);
        console.log('company list:', uniqueCompanyNames);

        console.log('did I reach here?');
        setDoneCombiningLocations(true);
    }, [locationList]);

    useEffect(() => {
        console.log('done combining locations:', doneCombiningLocations);
        if (doneCombiningLocations) {
            setCombinedLocationsMatch([...locationAddressList, ...locationCityList, ...locationCountryList, ...companyList]);
        }
    }, [doneCombiningLocations]);

    const buildListOfLocations = (locationSearchResponse) => {
        if (locationSearchResponse != null) {
            let allLocations = [];

            for (const locationSearchResponseKey in locationSearchResponse) {
                console.log('location search key:', locationSearchResponseKey);

                const locations = locationSearchResponse[locationSearchResponseKey] // array of location objects

                // go through each location and add the match type
                const locationsUpdate = locations.map(location => ({match: locationSearchResponseKey, ...location}));
                console.log('locations update:', locationsUpdate);

                allLocations.push(...locationsUpdate);
            }

            console.log('all locations sigh:', allLocations);
            setLocationList(allLocations);
        }
    }

    const makeSearchForLocation = () => {
        // setLocationList([]);
        setDoneCombiningLocations(false);

        const config = {
            params: { search: searchValue }
        }

        if (searchValue) {
            axios.get(`${Constants.BASE_URL}/booking/api/v1/location/search`, config)
                .then(res => {
                    const response = res.data;
                    const responseData = response?.data;

                    buildListOfLocations(responseData);
                }).catch(err => {
                // handle exception
            })
        }
    }

    useEffect(() => {
        makeSearchForLocation();
    }, [searchValue]);

    useEffect(() => {
        if (!searchValue) {
            setCombinedLocationsMatch([]);
        }
    }, [searchValue]);

    // @ts-ignore
    // @ts-ignore
    return (
       <div className='mobile-view'>
           <ErrorAlert show={errorAlert} message={ errorMsg } close={() => setErrorAlert(false)} />
           <PageTitle>Search</PageTitle>
           <LeftComponent>
               <a href="#" className="headerButton" data-bs-toggle="modal" data-bs-target="#sidebarPanel">
                   <MenuOutline
                       color={'#000000'}
                       title={''}
                   />

               </a>
           </LeftComponent>
           <div className="section mt-2 mx-3">
               <h2 className={'text-capitalize'}>Find the Perfect Space</h2>
           </div>
           <form onSubmit={dispatchFilters}>
               <div className="card-body pb-1" style={{padding: '50px'}}>
                   <div className="form-group basic">
                       <div className="input-wrapper">
                           <select className={'form-control floating-select'} id={'propertyType'} onChange={handlePropertyTypeChange} value={propertyType}>
                               {
                                   (filterOptions['propertyTypes']) &&
                                   filterOptions['propertyTypes'].map(type => {
                                       console.log('type.label here:', type.label);
                                       return (
                                           <option key={uuidv4()} value={type.value} disabled={type.label === 'Concierge'}>
                                               {type.label}
                                           </option>
                                       )
                                   })
                               }
                           </select>

                           <label className="floating-label" htmlFor="propertyType">Type of Service</label>
                       </div>
                   </div>
                   <div className="form-group basic">
                       <div className="input-wrapper">
                           <select className={'form-control floating-select'} id={'workspaceType'} onChange={handleWorkSpaceChange} value={workspaceType}>
                               <option disabled value=''>Service offering</option>
                               {
                                   workSpaceTypes &&
                                   workSpaceTypes.map((type) => <option key={uuidv4()} value={type.value}>{type.label}</option>)
                               }
                           </select>

                           <label className="floating-label" htmlFor="workspaceType">Workspace Type</label>
                       </div>
                   </div>
                   <div style={{height: '60px', margin: '8px 0 20px'}}>
                       <Autocomplete
                           getItemValue={(location) => {
                               const { match, address, city, country, name: companyName, displayValue } = location;

                               let value = '';
                               if (match != null) {
                                   switch (match) {
                                       case 'ADDRESS':
                                           value = `${match}~${address}~${displayValue}`;
                                           break;

                                       case 'CITY':
                                           value = `${match}~${city}~${displayValue}`;
                                           break;

                                       case 'COUNTRY':
                                           const initial = country != null ? country.value : '';
                                           value = `${match}~${initial}~${displayValue}`;
                                           break;

                                       case 'COMPANY_NAME':
                                           value = `${match}~${companyName}~${displayValue}`;
                                   }
                               }

                               return value;
                           }}
                           items={combinedLocationsMatch}
                           renderInput={(props) => (
                               <input
                                   {...props}
                                   type="text"
                                   className="form-control h-100"
                                   placeholder="Search for location..."
                                   onChange={(e) => setSearchValue(e.target.value)}
                               />
                           )}
                           renderItem={(location, isHighlighted) =>
                               <div style={{ borderBottom: '1px solid lightgray', padding: '15px', cursor: 'pointer', background: isHighlighted ? 'lightgray' : 'white' }}>
                                   {location.displayValue}
                               </div>
                           }
                           menuStyle={{
                               borderRadius: '3px',
                               boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                               background: 'rgba(255, 255, 255, 0.9)',
                               padding: '2px 0',
                               fontSize: '90%',
                               position: 'fixed',
                               zIndex: '3',
                               width: '30%',
                               overflow: 'auto',
                               maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
                           }}
                           wrapperStyle={{height: '100%'}}
                           value={searchValue}
                           onSelect={(value) => {
                               const firstIndex = value.indexOf('~');
                               const lastIndex = value.indexOf('~', firstIndex + 1);
                               const displayValue = value.slice(lastIndex + 1);

                               console.log('selected value:', displayValue);

                               const selected = value.slice(0, lastIndex);
                               console.log('full string value:', selected);

                               setSearchValue(displayValue);
                               setSelectedLocationAndMatch(selected);
                           }}
                       />

                       {/*<div className="input-wrapper">*/}
                       {/*    <select*/}
                       {/*        className={'form-control floating-select'}*/}
                       {/*        id={'location'}*/}
                       {/*        value={location}*/}
                       {/*        onChange={handleLocationChange}*/}
                       {/*    >*/}
                       {/*        <option value="" disabled>Location</option>*/}
                       {/*        {*/}
                       {/*            (filterOptions['location']) &&*/}
                       {/*            filterOptions['location'].map((type) => {*/}
                       {/*                return <option key={uuidv4()} value={`${type.city},${type.country.value}`}>*/}
                       {/*                    {formatLocation(type)}*/}
                       {/*                </option>*/}
                       {/*            })*/}
                       {/*        }*/}
                       {/*    </select>*/}
                       {/**/}
                       {/*    <label className="floating-label" htmlFor="location">Location</label>*/}
                       {/*</div>*/}
                   </div>
                   <div className="card bg-secondary mb-2">
                       <div className="card-body">
                           {/*<div className={'d-flex flex-row justify-content-between align-items-start'}>*/}
                           <div>
                               <div className="" style={{marginBottom: '20px'}}>
                                   <h4 className="card-text text-black-50 fontsize-sub">Start Date</h4>
                                   <div>
                                       <LocalizationProvider dateAdapter={AdapterDateFns}>
                                           <DateTimePicker
                                               renderInput={(params) => <TextField {...params} />}
                                               inputFormat={'dd/MM/yyyy hh:mm aa'}
                                               value={startDateObject}
                                               onChange={(newValue) => {
                                                   handleStartDateChange(newValue);
                                               }}
                                               // onClose={() => handleStartDateChange(startDateObject)}
                                           />
                                       </LocalizationProvider>
                                   </div>
                               </div>
                               <div className="">
                                   <h4 className="card-text text-black-50 fontsize-sub">End Date</h4>
                                   <div>
                                       <LocalizationProvider dateAdapter={AdapterDateFns}>
                                           <DateTimePicker
                                               renderInput={(params) => <TextField {...params} />}
                                               inputFormat={'dd/MM/yyyy hh:mm aa'}
                                               value={endDateObject}
                                               onChange={(newValue) => {
                                                   handleEndDateChange(newValue);
                                               }}
                                               // onClose={() => handleEndDateChange(endDateObject)}
                                           />
                                       </LocalizationProvider>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
                   <div className="mb-2">
                       <div className="card-body">
                           <div className={'d-flex flex-row justify-content-between align-items-center'}>
                               <div className="col-sm-6">
                                   <h4 className="card-text text-dark mb-0">Capacity</h4>
                               </div>
                               <div className="col-sm-6">
                                   <QuantityCounter count={capacity} setCount={setCapacity} />
                               </div>
                           </div>
                       </div>
                   </div>
                   <div className="mb-2">
                       <div className="card-body">
                           <div className={''}>
                               <div>
                                   <h4 className="card-text text-black-50 fw-bolder">{userCurrency}{maxPrice ? maxPrice.toLocaleString() : 0}</h4>
                               </div>
                               <div>
                                   <Slide min={0} max={100000} step={500} railStyle={{ height: '6px' }}
                                          trackStyle={trackStyle} handleStyle={handleStyle} onChange={(e) => setMaxPrice(e)} />
                               </div>
                           </div>
                       </div>
                   </div>

                   <div className="mt-4">
                       <button type='submit' className={'btn btn-primary btn-block btn-lg'}> Search </button>
                   </div>

               </div>



           </form>
       </div>
    )
}

export default SearchPage
