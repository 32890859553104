/**
 * Project: spaces
 * File: RTL
 * Created by Pennycodes on 2/4/2022.
 * Copyright spaces
 */
import {Spaces} from "./Spaces";

class RTL {
    enabled: boolean
    htmlPage: HTMLElement | null
    htmlBody: HTMLBodyElement
    appSidebar: HTMLElement | null

    constructor() {
        this.enabled = Spaces.Config().RTL.enable
        this.htmlPage = Spaces.pageHTML()
        this.htmlBody = Spaces.pageBody()
        this.appSidebar = Spaces.appSidebar()

        if (this.enabled) this._init()
    }

    private _init () {
        const page = this.htmlPage
       // @ts-ignore
        page.dir = 'rtl'
        this.htmlBody.classList.add("rtl-mode")
        if (this.appSidebar != null) {
            this.appSidebar.classList.remove("panelbox-left")
            this.appSidebar.classList.add("panelbox-right")
            Spaces.selectAll('.carousel-full, .carousel-single, .carousel-multiple, .carousel-small, .carousel-slider')
                .forEach(function (el) {
                    el.setAttribute('data-splide', '{"direction":"rtl"}')
                })
        }
    }

    public static bootstrap () {
        new RTL()
    }
}

export {RTL}
