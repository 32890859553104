import FAQ from './FAQ';

export const UserFAQPayload: FAQ[] = [
    {
        question: 'Do I have to pay to use the site?',
        answer: (
            <div>
                <p>
                Spacia Africa has different categories and packages catered to enterprise users as well as all who would want to have 
                access to Spacia spaces, with plans based on usage from basic, to plus to premium.
                </p>
            </div>
        )
    },
    {
        question: 'Can I use Spacia as an individual away from my organisation’s spaces?',
        answer: (
            <p>
                Spacia’s message of work anywhere anytime is fundamental to our business model, and as such we cater for individual work 
                wherever they feel comfortable across all our spaces.
            </p>
        )
    },
    {
        question: 'Am I limited in terms of how far I can work or how many times I can access a space?',
        answer: (
            <div>
                <p>
                    Spacia provides access to all our reserved spaces as well as all spaces that our hosts have made open or available, 
                    and any amenity on the platform can be utilised.
                </p>
            </div>
        )
    },
    {
        question: 'Will I have access to Spacia events and all such promotions that may be advertised such as book clubs and discounts?',
        answer: (
            <div>
                <p>
                    Spacia events are accessible to all members of our platform, enterprise or otherwise, and the only requirement 
                    to fulfil is to be under the Spacia package that caters to the aforementioned.
                </p>
            </div>
        )
    },
    {
        question: 'Can I upgrade or downgrade my membership package?',
        answer: (
            <p>
                Spacia offers the flexibility to not only improve your membership situation but also to switch to a package you feel 
                comfortable spending on, depending on Spacia’s terms and conditions of service with regards to cancellation.
            </p>
        )
    },
    {
        question: 'How is my data used by Spacia Africa?',
        answer: (
            <p>
                Spacia takes usage of your data very seriously, and the collected information has the benefit of helping Spacia know 
                where and when the majority of our efforts towards producing a better product should be focused.
            </p>
        )
    }
]
