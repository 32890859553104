import React from "react";
import { Modal } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";

function InformationModal({
	declineButton,
	acceptButton,
	body,
	title,
	isOpen,
	isClose,
	onSubmit,
	children,
	className = ''
}) {
	return (
		<Modal
			show={isOpen}
			onHide={isClose}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			contentClassName="padding-40px"
			style={{
				overflow: "hidden",
				fontFamily: "Montserrat",
				borderRadius: "30px",
			}}
		>
			<Modal.Header
				style={{
					borderBottom: "none",
					paddingLeft: "30px",
					paddingRight: "30px",
						paddingBottom: 0,

					margin: "0px",
				}}
				closeButton
			>
				<Modal.Title
					id="contained-modal-title-vcenter"
					style={{ width: "100%", textAlign: "center" }}
				>
					{/* <h6 className="text-primary">Confirm Payment</h6> */}
				</Modal.Title>
			</Modal.Header>

			<Modal.Body className="text-center position-relative"
				style={{ bottom: "15px" }}>

				<div
					style={{
						display: "flex",
						flexDirection: "row-reverse",
						padding: 0,
					}}
				>
					{/* <FaTimes /> */}
					{/* 
				<h6 style={{ margin: "auto" }}>
					<b    className={"text-primary mt-5"}>{title}</b>
				</h6> */}
				</div>
				<div
                            className={"text-primary mt-0"}
                            style={{ fontSize: "1.3rem", fontWeight: 200 }}
                        >
                            {children}
                        </div>
				<div
					    className={"mt-3"}
				>

					<button
						className="btn btn-outline-primary"
						style={{
							borderRadius: "20px",
							marginLeft: "0px",
							width: "8rem"
						}}
						onClick={isClose}
					>
						Cancel
					</button>
					<button
						className="btn btn-outline-primary"
						style={{
							borderRadius: "20px",
							marginLeft: "20px",
							width: "8rem"
						}}
						onClick={onSubmit} 
					>
						Confirm
					</button>
				</div>
			</Modal.Body>

		</Modal>
	);
}

export default InformationModal;
