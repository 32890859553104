/**
 * Project: spaces
 * File: HREFFix
 * Created by Pennycodes on 2/4/2022.
 * Copyright spaces
 */
import {Spaces} from "./Spaces";

class HREFFix {
    WithHref: NodeListOf<Element>

    constructor() {
        this.WithHref = Spaces.selectAll('a[href*="#"]')
        this._init()
    }

    private _init () {
        this.WithHref.forEach(function (el) {
            el.addEventListener("click", function (e) {
                e.preventDefault();
            })
        });
    }

    public static bootstrap () {
        new HREFFix()
    }

}

export {HREFFix}
