import React, {useState} from 'react'
import {Row} from 'react-bootstrap'

const WorkAmenities = ({access, changeAccess, loungeSpace, changeLoungeSpace, mothersRoom, changeMothersRoom, outdoorSpace, changeOutdoorSpace, whiteboard, changeWhiteboard, bicycleRacks, changeBicycleRacks, kitchenFacilities, changeKitchenFacilities, onsiteDining, changeOnSiteDining, printingServices, changePrintingServices, storage, changeStorage, disableFriendly, changeDisableFriendly, onSiteParking, changeOnSiteParking, vcFacilities, changeVcFacilities }) => {

  // const [loungeSpace, setloungeSpace] = useState(false);
  // const [mothersRoom, setmothersRoom] = useState(false);
  // const [outdoorspace, setoutdoorspace] = useState(false);
  // const [whiteboard, setwhiteboard] = useState(false);
  // const [bicycleRacks, setbicycleRacks] = useState(false);
  // const [kitchenFacilities, setkitchenFacilities] = useState(false);
  // const [onsiteDining, setonsiteDining] = useState(false);
  // const [printingServices, setprintingServices] = useState(false);
  // const [storage, setstorage] = useState(false);
  // const [disableFriendly, setdisableFriendly] = useState(false);
  // const [onSiteParking, setonSiteParking] = useState(false);
  // const [vcFacilities, setvcFacilities] = useState(false);


    const label = {
        fontWeight: "300",
        marginBottom: 10,
      };
    
    return (
        <>
           <Row>
                <div className="col-md-4">
                  <div className="form-check">
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={access}
                        onChange={changeAccess}
                        aria-label="Text for screen reader"
                      />{" "}
                      24 Hr Access
                    </label>
                  </div>


                </div>
                <div className="col-md-4">
                  <div className="form-check">
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={bicycleRacks}
                        onChange={changeBicycleRacks}
                      />{" "}
                      Bicycle Racks
                    </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-check">
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={disableFriendly}
                        onChange={changeDisableFriendly}
                      />{" "}
                      Disabled Friendly
                    </label>
                  </div>
                </div>
                <br />
                <div className="col-md-4">
                  <div className="form-check">
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={loungeSpace}
                        onChange={changeLoungeSpace}
                      />{" "}
                      Lounge Space
                    </label>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-check">
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={kitchenFacilities}
                        onChange={changeKitchenFacilities}
                      />{" "}
                      Kitchen Facilities
                    </label>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-check">
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={mothersRoom}
                        onChange={changeMothersRoom}
                      />{" "}
                      Mothers Room
                    </label>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-check">
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={onsiteDining}
                        onChange={changeOnSiteDining}
                      />{" "}
                      Onsite Dining
                    </label>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-check">
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={onSiteParking}
                        onChange={changeOnSiteParking}
                      />{" "}
                      Onsite Parking
                    </label>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-check">
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={outdoorSpace}
                        onChange={changeOutdoorSpace}
                      />{" "}
                      Outdoor Space
                    </label>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-check">
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={printingServices}
                        onChange={changePrintingServices}
                      />{" "}
                      Printing Services
                    </label>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-check">
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={vcFacilities}
                        onChange={changeVcFacilities}
                      />{" "}
                      VC Facilities
                    </label>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-check">
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={whiteboard}
                        onChange={changeWhiteboard}
                      />{" "}
                      Whiteboard
                    </label>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-check">
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={storage}
                        onChange={changeStorage}
                      />{" "}
                      Storage
                    </label>
                  </div>
                </div>
              </Row> 
        </>
    )
}

export default WorkAmenities
