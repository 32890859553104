import styled from "styled-components";

export const CartEntryStyles = styled.tr`
  
  border-bottom: 1px dashed;
  height: 170px;
  
  td {
    font-size: .93rem !important;
  }
	.checkbox,
	img {
		display: inline-block;
	}

	.MuiFormControl-root {
		margin-top: 8px;
		width: 80%;
	}

	.MuiOutlinedInput-root {
		height: 40px;
      width: 200px;
	}
  
  .start,
  .end {
    padding-left: 0!important;
    padding-right: 0!important;
    padding-top: 15px !important;
  }
`;
