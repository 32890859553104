import { MakePaymentStyles as Container } from "./styles";
import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import {Row, Spinner} from "react-bootstrap";
import visa from '../../../assets/img/visa.png';
import mastercard from '../../../assets/img/mastercard.png';
import { v4 as uuidv4 } from 'uuid';
import SERVICES from '../../../services'
import axios from "axios";
import {Constants} from "../../../utils/constants";
import CryptoJS from 'crypto-js';
import aesEcb from 'aes-ecb';
import mtn from '../../../assets/img/mtn_logo.png';
import vodafone from '../../../assets/img/vodafone.png';
import airtelTigo from '../../../assets/img/airtelTigo.png';
import airtel from '../../../assets/img/airtel.png';
import safaricom from '../../../assets/img/safaricom.png';
import {ErrorAlert} from "../../../components/Settings/alerts/ErrorAlert";

function MakePayment() {
    const [invoiceAmount, setInvoiceAmount] = useState('');
    const [invoice, setInvoice] = useState(null);
    const [selectedInvoices, setSelectedInvoices] = useState(null);
    const [invoiceId, setInvoiceId] = useState(0);
    const [invoiceToken, setInvoiceToken] = useState(null);
    const [msisdn, setMsisdn] = useState('');
    const [companyRSAPublicKey, setCompanyRSAPublicKey] = useState('');
    const [supportedMobileWalletOperators, setSupportedMobileWalletOperators] = useState([]);
    const [registeredMobileMoneyAccounts, setRegisteredMobileMoneyAccounts] = useState([]);
    const [selectedNetworkOperator, setSelectedNetworkOperator] = useState(null);
    const [paymentMethodCode, setPaymentMethodCode] = useState(null);
    const [isAllEmpty, setIsAllEmpty] = useState(false);
    const [isInvalidOperator, setIsInvalidOperator] = useState(false);
    const [isInvalidMsisdn, setIsInvalidMsisdn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currency, setCurrency] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [errorModal, setErrorModal] = useState(false);

    const location = useLocation();
    const history = useHistory();

    function generateKey(keyLength) {
        const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@()+$';
        let result = '';
        for (let i = 0; i < keyLength; i++) {
            result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
        }
        return result;
    }

    function hasText(text) {
        return text != null && text !== '';
    }

    useEffect(() => {
        if (hasText(paymentMethodCode) &&
            !hasText(selectedNetworkOperator) &&
            !hasText(msisdn)) {
            setIsAllEmpty(false);
        }
    }, [paymentMethodCode]);

    useEffect(() => {
        if (!hasText(paymentMethodCode) && hasText(selectedNetworkOperator) && hasText(msisdn)) {
            setIsInvalidOperator(false);
        }
    }, [selectedNetworkOperator]);

    useEffect(() => {
        if (!hasText(paymentMethodCode) && hasText(selectedNetworkOperator) && !hasText(msisdn)) {
            setIsInvalidMsisdn(false);
        }
    }, [msisdn]);

    useEffect(() => {
        const invoice = location.state;
        const invoiceAmount = invoice?.invoiceAmount;
        const selectedInvoices = invoice?.selectedInvoices;

        console.log('selected invoices:', selectedInvoices);

        console.log('invoice:', invoice);
        console.log('invoice amount:', invoiceAmount);
        const amount = invoice?.grossAmount;
        const id = invoice?.id;
        const token = invoice?.referenceCode;

        setInvoice(invoice);
        setSelectedInvoices(selectedInvoices);
        setInvoiceAmount((invoice && invoice.grossAmount) ? amount : invoiceAmount);
        setInvoiceId(id);
        setInvoiceToken(token);

        const currency = SERVICES.getCurrency();
        setCurrency(currency);

        console.log('hide card:', Constants.hideCard);
    }, []);

    useEffect(() => {
        // make ajax call for all supported mobile wallet operators
        const resourceUrl = `${Constants.BASE_URL}/users/v1/user/payment/method/mobile/wallet/operators`;
        const requestId = uuidv4();

        const currentUser = SERVICES.getUser();
        const country = currentUser ? currentUser.country : null;
        const countryName = country ? country.name.toUpperCase() : null;

        console.log('[ ' + requestId + ' ] about to make request to get all supported mobile wallet operators');

        axios.get(resourceUrl, { params: { country: countryName }})
            .then(res => {
                if (res.status === 200) {
                    console.log(res.data);
                    setSupportedMobileWalletOperators(res.data.data);
                }
            })
    }, []);

    useEffect(() => {
        // make ajax call to get already registered mobile money accounts
        const currentUser = SERVICES.getUser();
        const userId = currentUser ? currentUser.id : 0;

        const resourceUrl = `${Constants.BASE_URL}/users/v1/user/${userId}/payment/methods`;

        axios.get(resourceUrl)
            .then(res => {
                if (res.status === 200) {
                    console.log(res.data);
                    const responseData = res.data.data;
                    const allMobileWallets = responseData.filter(response => response.paymentMethod === 'mobileWallet');

                    console.log('all mobile wallets.', allMobileWallets);

                    setRegisteredMobileMoneyAccounts(allMobileWallets);
                }
            })
    }, []);

    const encryptData = (data, key) => {
        return CryptoJS.AES.encrypt(data, key, { mode: CryptoJS.mode.ECB });
    }

    const makeRequestToProcessPayment = (bit256, payload, processPaymentUrl, userId, isMultiple) => {
        const encrypt = aesEcb.encrypt(bit256, JSON.stringify(payload.data));
        console.log('encrypted buffer:', encrypt);
        payload.data = encrypt;

        axios.post(processPaymentUrl, payload, { params: { userId }})
            .then(res => {
                if (res.status === 200) {
                    console.log(res);

                    const data = res.data.data;
                    const index0 = data && data[0];

                    const callbackUrl = index0 && index0['subscriptionCallbackUrl'];

                    console.log('subscription callback url:', callbackUrl);

                    history.push({
                        pathname: '/payment-success',
                        state: {
                            invoice,
                            selectedInvoices,
                            callbackUrl: isMultiple && callbackUrl
                        }
                    })

                    // hide spinner
                    setIsLoading(false);
                } else {
                    // hide spinner
                    setIsLoading(false);
                }
            })
            .catch(err => {
                console.log(err.response);

                const {response: { data: { errors }}} = err;
                // const { field } = errors[0];
                let errorMsg = (errors && Array.isArray(errors) && errors.length > 0) ? errors[0].field : '';

                setErrorModal(true);

                errorMsg = errorMsg ? errorMsg.charAt(0).toUpperCase() + errorMsg.slice(1) : err.response.data.message;

                setErrorMsg(errorMsg);
                console.log('error message:', errorMsg);

                setIsLoading(false);
            });
    }

    const processPayment = e => {
        e.preventDefault();

        const errors = [];

        if (!hasText(paymentMethodCode) &&
            !hasText(selectedNetworkOperator) &&
            !hasText(msisdn)) {
            errors.push('Please select already registered mobile money account or use new mobile money account');
            setIsAllEmpty(true);
        } else if (hasText(paymentMethodCode) &&
            !hasText(selectedNetworkOperator) &&
            !hasText(msisdn)) {
            setIsAllEmpty(false);
        }

        if (!hasText(paymentMethodCode) && !hasText(selectedNetworkOperator) && hasText(msisdn)) {
            errors.push('Please choose a network operator');
            setIsInvalidOperator(true);
        } else if (!hasText(paymentMethodCode) && hasText(selectedNetworkOperator) && hasText(msisdn)) {
            setIsInvalidOperator(false);
        }

        if (!hasText(paymentMethodCode) && hasText(selectedNetworkOperator) && !hasText(msisdn)) {
            errors.push('Please enter mobile number');
            setIsInvalidMsisdn(true);
        } else if (!hasText(paymentMethodCode) && hasText(selectedNetworkOperator) && !hasText(msisdn)) {
            setIsInvalidMsisdn(false);
        }

        // const errorMsg = validateInputs();

        // if (hasText(errorMsg)) {
        //     console.log('error msg:', errorMsg);
        //     return;
        // }

        if (errors.length === 0) {
            const requestId = uuidv4();
            const currentUser = SERVICES.getUser();
            const userId = currentUser ? currentUser.id : 0;
            const companyId = currentUser ? currentUser.companyId : 0;

            // display spinner
            setIsLoading(true);

            const currency = SERVICES.getCurrency();
            console.log('currency:', currency);

            const resourceUrl = `${Constants.BASE_URL}/platform/company/v1/company/${companyId}/rsa/key/public`;

            console.log('[ ' + requestId + ' ] about to make request to fetch company RSA public key');
            axios.get(resourceUrl, { params: { userId }})
                .then(res => {
                    if (res.status === 200) {
                        let responseData = res.data.data[0];
                        console.log('[ ' + requestId + ' ] done processing request to fetch company RSA public key\n' +
                            'response data:', res.data.data[0]);

                        const publicKey = responseData.rsaPublicKey;
                        setCompanyRSAPublicKey(publicKey);

                        // generate 256 bit key
                        const bit256 = generateKey(32);
                        console.log('256 bit key is', bit256);

                        // encrypt 256 bit key with RSA Public Key
                        const url = `${Constants.BASE_URL}/users/v1/generate/base64/encrypted/key`;
                        axios.post(url, {
                            bitKey: bit256,
                            rsaPublicKey: publicKey
                        }).then(res => {
                            if (res.status === 200) {
                                console.log('encrypted key string:', res.data);
                                const requestKey = res.data;

                                if (selectedInvoices && Array.isArray(selectedInvoices)) {
                                    // get all invoice tokens from list of invoices
                                    console.log('selected invoices array:', selectedInvoices);
                                    const invoiceTokens = selectedInvoices.map(invoice => invoice.referenceCode);
                                    console.log('all invoice tokens:', invoiceTokens);

                                    // process payment
                                    const processPaymentUrl = `${Constants.BASE_URL}/users/v1.1/process/payment`;

                                    const payload = {
                                        key: requestKey,
                                        data: {
                                            requestId,
                                            invoiceIds: [],
                                            invoiceTokens,
                                            currency,
                                            paymentMethodCode,
                                            userId,
                                            paymentMethod: 'mobileWallet',
                                            amount: invoiceAmount,
                                            isCashOnDelivery: false
                                        }
                                    }

                                    console.log('raw payload:', payload);

                                    makeRequestToProcessPayment(bit256, payload, processPaymentUrl, userId, true);
                                } else if (invoice) {
                                    // process payment
                                    const processPaymentUrl = `${Constants.BASE_URL}/users/v1/process/payment`;

                                    const payload = {
                                        key: requestKey,
                                        data: {
                                            requestId,
                                            invoiceToken,
                                            currency,
                                            paymentMethodCode,
                                            userId,
                                            paymentMethod: 'mobileWallet',
                                            amount: invoiceAmount,
                                            mobileWallet: {
                                                msisdn,
                                                operator: selectedNetworkOperator
                                            }
                                        }
                                    }

                                    makeRequestToProcessPayment(bit256, payload, processPaymentUrl, userId);
                                }
                            } else {
                                setIsLoading(false);
                            }
                        }).catch(err => {
                            console.log(err.response);

                            const {response: { data: { message }}} = err;
                            const errorMsg = message;

                            setErrorModal(true);
                            setErrorMsg(errorMsg);
                        })
                    } else {
                        setIsLoading(false);
                    }
                }).catch(err => {
                console.log(err.response);

                const {response: { data: { message }}} = err;
                const errorMsg = message;

                setErrorModal(true);
                setErrorMsg(errorMsg);
            })
        }
    }

    return (
        <Container>
            <ErrorAlert show={ errorModal } close={()=>setErrorModal(false)}  message={ errorMsg || "A problem was encountered, please try again later" }/>
            {/* Header */}
            <div className="header">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <div>
                        <h4>
                            Make a Payment
                        </h4>
                    </div>
                </div>
            </div>
            {/* End Header */}
            <form onSubmit={processPayment} autoComplete='off'>
                <section style={{height: 'fit-content'}}>
                    <div style={{display: Boolean(Constants.hideCard) && 'none'}}>
                        <h5>Credit Card Information:</h5>
                        <Row style={{marginBottom: '30px'}}>
                            <div className="col">
                                <label htmlFor="">Name on card:</label>
                                <input type="text" className={ "form-control"} readOnly name="name" id="" aria-describedby="emailHelpId" placeholder="Name" />
                            </div>
                            <br />
                        </Row>
                        <Row style={{marginBottom: '20px'}}>
                            <div className="col">
                                <label htmlFor="">Card Number:</label>
                                <div style={{display: 'flex'}}>
                                    <input type="text" className={"form-control"} name="name" readOnly aria-describedby="emailHelpId" placeholder="Card Number" />
                                    <figure style={{marginLeft: '20px', marginRight: '10px'}}>
                                        <img src={visa} alt="visa logo"/>
                                    </figure>
                                    <figure>
                                        <img src={mastercard} alt="mastercard logo"/>
                                    </figure>
                                </div>
                            </div>
                            <br />
                        </Row>
                        <Row style={{marginBottom: '20px'}}>
                            <div className="col">
                                <label htmlFor="">Expiration Date:</label>
                                <div style={{display: 'flex', marginBottom: '20px'}}>
                                    <input type="text" style={{marginRight: '25px'}} readOnly className={ "form-control expiry-date"} size='2' maxLength='2' placeholder="MM" autoComplete='off'/>
                                    <input type="text" className={ "form-control expiry-date"} readOnly size='4' maxLength='4' placeholder="YYYY" autoComplete='off' />
                                </div>
                                <div>
                                    <input type="text" className={ "form-control expiry-date"} readOnly size='3' maxLength='3' placeholder='CCV' autoComplete='off'/>
                                </div>
                            </div>
                            <br />
                        </Row>
                        <p>or</p>
                    </div>
                    <Row style={{marginBottom: '20px'}}>
                        <div className="col">
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <select className={ isAllEmpty ? "form-control is-invalid" : "form-control" } style={{border: '1px solid red'}} name="mobile_operator" onChange={e => setPaymentMethodCode(e.target.value)}>
                                    <option selected disabled value={ "" }>Select Mobile Wallet</option>
                                    {
                                        registeredMobileMoneyAccounts.length > 0 && registeredMobileMoneyAccounts.map(account => {

                                            const mobileWallet = account?.mobileWallet;
                                            const msisdn = mobileWallet?.msisdn;

                                            const operator = mobileWallet?.operator;
                                            const operatorLabel = operator?.label;

                                            return <option value={ account?.code }>{ `${msisdn} - ${operatorLabel}` }</option>
                                        })
                                    }
                                </select>
                                <figure style={{marginBottom: '0', marginLeft: '4px'}}>
                                    <img src={safaricom} alt="safaricom"/>
                                </figure>
                                <figure style={{marginBottom: '0', marginLeft: '4px'}}>
                                    <img src={mtn} alt="mtn"/>
                                </figure>
                                <figure style={{marginBottom: '0', marginLeft: '4px'}}>
                                    <img src={vodafone} alt="vodafone"/>
                                </figure>
                                <figure style={{marginBottom: '0', marginLeft: '4px'}}>
                                    <img src={airtelTigo} alt="airtelTigo"/>
                                </figure>
                                {/*<figure style={{marginBottom: '0', marginLeft: '4px'}}>*/}
                                {/*    <img src={airtel} alt="airtel"/>*/}
                                {/*</figure>*/}
                            </div>
                            <div className="invalid-feedback">Please select already registered mobile money account or use new mobile money account</div>
                        </div>
                    </Row>
                    <div style={{display: Boolean(Constants.hideMobileWalletAccount) && 'none'}}>
                        <p>New Mobile Money Account:</p>
                        <Row style={{marginBottom: '20px'}}>
                            <div className="col">
                                <label htmlFor="">Network:</label>
                                <select className={ isInvalidOperator ? "form-control is-invalid" : "form-control" } name="mobile_operator" onChange={e => setSelectedNetworkOperator(e.target.value)}>
                                    <option selected disabled value={ "" }>Select Mobile Network</option>
                                    {
                                        supportedMobileWalletOperators.length > 0 && supportedMobileWalletOperators.map(supportedOperator => {

                                            const label = supportedOperator?.label;

                                            return <option value={ supportedOperator?.operator }>{ label }</option>
                                        })
                                    }
                                </select>
                                <div className='invalid-feedback'>Please choose a network operator</div>
                            </div>
                        </Row>
                        <Row>
                            <div className="col">
                                <label htmlFor="">Phone Number:</label>
                                <div>
                                    <input type="text" className={ isInvalidMsisdn ? "form-control is-invalid" : "form-control"} onChange={e => setMsisdn(e.target.value)} name="msisdn" id="" aria-describedby="emailHelpId" placeholder="Phone Number" />
                                    <div className='invalid-feedback'>Please enter mobile number</div>
                                </div>
                            </div>
                            <br />
                        </Row>
                    </div>
                </section>
                <section>
                    <div style={{width: '75%', marginLeft: 'auto', marginRight: 'auto'}}>
                        <div className='payment-header' style={{display: 'flex', justifyContent: 'space-between'}}>
                            <p>Payment Amount</p>
                            <div>
                                <input type="text" className='amount' placeholder='Enter Amount' readOnly aria-describedby="emailHelpId" value={`${currency} ${invoiceAmount}`} />
                            </div>
                        </div>
                        <hr/>
                        <div style={{textAlign: 'right', marginTop: '30px'}}>
                            <button type='submit' className="button" style={{ width: "50%" }}>
                                <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
                                    <span style={{fontSize: '1.1rem'}}>Pay</span>
                                    <Spinner style={{display: isLoading ? 'inline-block' : 'none'}} animation="border" role="status">
                                        <span className="visually-hidden"></span>
                                    </Spinner>
                                </div>
                            </button>
                        </div>
                    </div>
                </section>
            </form>
        </Container>
    );
}

export default MakePayment
