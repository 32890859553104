import {CalendarOutline, CartOutline, SearchOutline} from "react-ionicons";
import React from "react";

export class Constants {
	static sidebarMenus = [
		{
			icon: 'dashboard.svg',
			name: "Dashboard",
			route: "/dashboard",
            restricted_to: ['ROLE_SUBSCRIBER_OWNER', 'ROLE_ADMINISTRATOR', 'ROLE_HOST']
		},
		{
			icon: 'property-location.svg',
			name: "Properties",
			route: "/properties",
            restricted_to: ['ROLE_ADMINISTRATOR', 'ROLE_HOST', 'ROLE_HOST_OPERATIONS']
		},
		{
			icon: 'find-a-space.svg',
			name: "Find a Space",
			route: "/startBooking",
			restricted_to: ['ROLE_SUBSCRIBER_OWNER', 'ROLE_SUBSCRIBER_APPROVER', 'ROLE_SUBSCRIBER_INDIVIDUAL', 'ROLE_EMPLOYEE', 'ROLE_APPROVER_EMPLOYEE']
		},
		{
			icon: 'calendar.svg',
			name: "Reservations",
			route: "/reservations",
			restricted_to: ['ROLE_SUBSCRIBER_OWNER', 'ROLE_SUBSCRIBER_APPROVER', 'ROLE_SUBSCRIBER_INDIVIDUAL', 'ROLE_EMPLOYEE', 'ROLE_APPROVER_EMPLOYEE']
		},
		// {
		// 	icon: 'calendar.svg',
		// 	name: "Booking",
		// 	route: "",
        //     restricted_to: ['ROLE_SUBSCRIBER_OWNER', 'ROLE_SUBSCRIBER_APPROVER', 'ROLE_SUBSCRIBER_INDIVIDUAL', 'ROLE_EMPLOYEE', 'ROLE_APPROVER_EMPLOYEE'],
		// 	children: [
		// 		{
		// 			name: "Find a Space",
		// 			route: "/startBooking",
		// 		},
		// 		{
		// 			name: "Reservations",
		// 			route: "/reservations",
		// 		}
		// 		// {
		// 		// 	name: "Pending Approvals",
		// 		// 	route: "/editbooking",
		// 		// },
		// 	],
		// },
		{
			icon: 'calendar.svg',
			name: "Reservations",
			route: "/reservations",
            restricted_to: ['ROLE_ADMINISTRATOR', 'ROLE_HOST', 'ROLE_HOST_OPERATIONS'],
		},
		{
			icon: 'users.svg',
			name: "Users",
			route: "/user-management",
            restricted_to: ['ROLE_SUBSCRIBER_OWNER', '', 'ROLE_HOST', 'ROLE_ADMINISTRATOR']
		},
		{
			icon: 'bill.svg',
			name: "Billing",
			route: "/host-billing",
			restricted_to: ['ROLE_HOST']
		},
		{
			icon: 'users.svg',
			name: "Hosts",
			route: "",
            restricted_to: ['ROLE_ADMINISTRATOR'],
			children: [
				{
					name: "Pending",
					route: "/companyApprovals",
				},
				{
					name: "Approved",
					route: "/approvedReservations",
				},
			],
		},
		{
			icon: 'membership-plan.svg',
			name: "Membership plan",
			route: "/membership-plan",
			restricted_to: ['ROLE_SUBSCRIBER_OWNER']
		},
		{
			icon: 'bill.svg',
			name: "Billing",
			route: "/billing",
            restricted_to: ['ROLE_SUBSCRIBER_OWNER', '']
		},
		{
			icon: 'bill.svg',
			name: "Orders",
			route: "/orders",
			restricted_to: ['ROLE_ADMINISTRATOR', '']
		},
		// {
		// 	icon: 'chart.svg',
		// 	name: "Reports",
		// 	route: "/report",
        //     restricted_to: ['ROLE_SUBSCRIBER_OWNER', 'ROLE_ADMINISTRATOR']
		// },
		{
			icon: 'settings.svg',
			name: "Settings",
			route: "/settings",
			restricted_to: [
				'ROLE_SUBSCRIBER_OWNER',
				'ROLE_SUBSCRIBER_APPROVER',
				'ROLE_SUBSCRIBER_INDIVIDUAL',
				'ROLE_HOST',
        		'ROLE_EMPLOYEE',
				'ROLE_APPROVER_EMPLOYEE'
			]
		}
	];

	static mobileSidebarMenus = [
		{
			icon: (
				<SearchOutline
				color={'#00000'}
				title={''}
			/>
			),
			name: "Find A Space",
			route: "/searchPage",
			restricted_to: [
				'ROLE_SUBSCRIBER_OWNER',
				'ROLE_SUBSCRIBER_INDIVIDUAL',
				'ROLE_SUBSCRIBER_APPROVER',
				'ROLE_EMPLOYEE',
				'ROLE_APPROVER_EMPLOYEE'
			]
		},
		// {
		// 	icon: <img src={'/assets/img/Web/Spacia/dashboard-mobile.svg'} alt="Dashboard Icon"/>,
		// 	name: "Dashboard",
		// 	route: "/dashboard",
		// 	restricted_to: ['ROLE_SUBSCRIBER_OWNER', 'ROLE_ADMINISTRATOR', 'ROLE_HOST']
		// },
		{
			icon: (
				<img src={'/assets/img/Web/Spacia/property-location-mobile.svg'} alt="Property Icon"/>
			),
			name: "Properties",
			route: "/properties",
			restricted_to: ['ROLE_ADMINISTRATOR', 'ROLE_HOST', 'ROLE_HOST_OPERATIONS']
		},
		{
			icon: (
				<CalendarOutline
					color={'#00000'}
					title={''}
				/>
			),
			name: "Reservations",
			route: "/reservation",
			restricted_to: [
				'ROLE_SUBSCRIBER_OWNER',
				'ROLE_SUBSCRIBER_APPROVER',
				'ROLE_SUBSCRIBER_INDIVIDUAL',
				'ROLE_EMPLOYEE',
				'ROLE_HOST',
				'ROLE_HOST_OPERATIONS',
				'ROLE_APPROVER_EMPLOYEE'
			]
		},
		{
			icon: <img src={'/assets/img/Web/Spacia/users-mobile.svg'} alt="User Icon"/>,
			name: "Users",
			route: "/user-management",
			restricted_to: ['ROLE_SUBSCRIBER_OWNER', 'ROLE_HOST', 'ROLE_ADMINISTRATOR']
		},
		{
			icon: (
				<CartOutline
					color={'#00000'}
					title={''}
				/>
			),
			name: "Shortlist",
			route: "/cart",
			restricted_to: [
				'ROLE_SUBSCRIBER_OWNER',
				'ROLE_SUBSCRIBER_INDIVIDUAL',
				'ROLE_SUBSCRIBER_APPROVER',
				'ROLE_EMPLOYEE',
				'ROLE_APPROVER_EMPLOYEE'
			]
		},
		// {
		// 	icon: <img src={'/assets/img/Web/Spacia/settings-mobile.svg'} alt="Settings Icon"/>,
		// 	name: "Settings",
		// 	route: "/settings",
		// 	restricted_to: [
		// 		'ROLE_SUBSCRIBER_OWNER',
		// 		'ROLE_SUBSCRIBER_APPROVER',
		// 		'ROLE_SUBSCRIBER_INDIVIDUAL',
		// 		'ROLE_HOST',
		// 		'ROLE_EMPLOYEE',
		//		'ROLE_APPROVER_EMPLOYEE'
		// 	]
		// }
	];

	static firebaseConfig = {
		apiKey: "AIzaSyBQQmGg2svzQTx_c2Op5q046-wFhcOSt0A",
		authDomain: "spacia-16ab5.firebaseapp.com",
		projectId: "spacia-16ab5",
		storageBucket: "spacia-16ab5.appspot.com",
		messagingSenderId: "1053152375145",
		appId: "1:1053152375145:web:2773e95b7024c05f3b4df8",
		measurementId: "G-FTF1GB80TL",
	};

	static BASE_URL = process.env.REACT_APP_BASE_URL;

    static getMenusByRole(role) {
        return this.sidebarMenus.filter((d) => d.restricted_to.includes(role));
    }

	static getMenusForMobileByRole(role) {
		return this.mobileSidebarMenus.filter((d) => d.restricted_to.includes(role));
	}

    static hideCard = process.env.REACT_APP_HIDE_CARD;
    static hideMobileWalletAccount = process.env.REACT_APP_HIDE_NEW_MOBILE_ACCOUNT;
    static paymentProcessingTimeout = process.env.REACT_APP_PAYMENT_PROCESSING_TIMEOUT;
	static maxFileSizeLimit = process.env.REACT_APP_MAX_FILE_SIZE_LIMIT;
	static paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;
}
