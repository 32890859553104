import store from "../store";
import actionTypes from "../actions/actionTypes";

export default (state = store, {type, payload}) => {
	switch (type) {
		case actionTypes.SAVE_FILTERS:
			return {
				...state,
				filterOptions: payload,
			};

		case actionTypes.SAVE_SELECTED_FILTERS:
			return {
				...state,
				selectedFilters: payload,
			};

		case actionTypes.SAVE_CART_DETAILS:
			return {
				...state,
				cartDetails: payload,
			};

		case actionTypes.ADD_ENTRY:
			return {
				...state,
				entries: [...state.entries, payload],
			};

		case actionTypes.REMOVE_ENTRY:
			return {
				...state,
				entries: [...state.entries].filter(entry => entry.entryId !== payload),
			};

		case actionTypes.SET_ENTRIES:
			return {
				...state,
				entries: payload,
			};
		// case actionTypes.ADD_ENTRY:
		// return {
		//     ...state,
		//     entries: []
		// }

		case actionTypes.ADD_TO_TOTAL_AMOUNT:
			return {
				...state,
				totalCartAmount: (state.totalCartAmount += payload)
			};

		case actionTypes.SET_TOTAL_AMOUNT:
			return {
				...state,
				totalCartAmount: payload
			};

		case actionTypes.RESET_TOTAL_AMOUNT:
			return {
				...state,
				totalCartAmount: 0
			}

		case actionTypes.SAVE_CURRENT_ENTRY:
			return {
				...state,
				currentEntry: payload,
			};

		case actionTypes.HAS_ITEMS:
			return {
				...state,
				hasItems: payload
			}

		case actionTypes.UPDATE_BUNDLE_QUANTITY:
			const bundleTypeAndQuantities = state.bundleTypeAndQuantities;

			let updatedState = [];

			if (bundleTypeAndQuantities && Array.isArray(bundleTypeAndQuantities)) {
				if (bundleTypeAndQuantities.length > 0) {
					if (bundleTypeAndQuantities.find(e => e.bundleType === payload.bundleType) === undefined) {
						const bundleTypeAndQuantity = {
							bundleType: payload.bundleType,
							quantity: payload.quantity
						}

						updatedState = [...bundleTypeAndQuantities, bundleTypeAndQuantity];
					} else {
						const bundleTypeAndQuant = bundleTypeAndQuantities.filter(e => e.bundleType === payload.bundleType)[0];
						const updated = {...bundleTypeAndQuant, quantity: payload.quantity};

						const excludeBundleType = bundleTypeAndQuantities.filter(e => e.bundleType !== payload.bundleType);

						updatedState = [...excludeBundleType, updated]
					}
				} else {
					const bundleTypeAndQuantity = {
						bundleType: payload.bundleType,
						quantity: payload.quantity
					}

					updatedState = [...bundleTypeAndQuantities, bundleTypeAndQuantity];
				}
			}

			return {
				...state,
				bundleTypeAndQuantities: updatedState
			}

		case actionTypes.RESET_BUNDLE_QUANTITY:
			return {
				...state,
				bundleTypeAndQuantities: payload
			}

		default:
			return state;
	}
};
