import React from "react";
import bed from "../assets/img/Web/Spacia/bed.png";
import homeicon from "../assets/img/Web/Spacia/Homedd.png";
import bathtub from "../assets/img/Web/Spacia/bathtub.png";
import view from "../assets/img/Web/Spacia/view.png";
import {
	FaEdit,
	FaEye,
	FaHome,
	FaPencilAlt,
	FaRegEye,
	FaTimes,
	FaUser,
} from "react-icons/fa";
import {Row} from "react-bootstrap";
import moment from "moment-timezone";
import SERVICES from '../services'

const TableRow = ({
					  listing,
					  image,
					  info,
					  added,
					  status,
					  price, size, capacity,
					  onDelete,
					  propertyStatus,
					  onView, address
				  }) => {
	const available = {
		backgroundColor: "#02BD04",
		color: "white",
		border: "none",
		fontWeight: "bold",
		borderRadius: 5,
		paddingTop: 4,
		paddingBottom: 4,
		paddingLeft: 20,
		paddingRight: 20,
	};

	const tableCell = {
		verticalAlign: "top",
		paddingTop: 12,
		color: "#737373",
		width: '240px'
	};
	return (
		<tr
		onClick={() => onView(listing)}
			
		style={{borderBottom: "1px dashed black"}}
		>
			<td style={{tableCell}}>
				<img src={image} className="img-fluid" style={{objectFit: 'fill', width: '75%', height: '110px', borderRadius: '5px'}} alt="demo" />
			</td>
			<td
				style={{
					width: 200,
					verticalAlign: "top",
					paddingTop: 10,
					color: "#737373",
				}}
			>
				<div>
					<h6
						style={{
							fontSize: ".945rem",
							width: 200,
							fontWeight: 'normal',
							whiteSpace: "wrap",
							overflow: "hidden",
							textOverflow: "ellipsis",
							textTransform: 'uppercase'
						}}
					>
						{info}
					</h6>
					<br />
					<div style={{display: "flex"}}>
						{/* <img src={bed} style={{ width:20, height:20}} alt="bed"></img> */}
						<FaHome color="grey" title='size'/>
						<h6 style={{paddingLeft: 10, paddingRight: 20}}>
							{size}
						</h6>
						{/* <img src={homeicon}  alt="bathtub"></img> */}
						<FaUser color="grey" title='capacity'/>
						<h6 style={{paddingLeft: 10, paddingRight: 20}}>
							{capacity}
						</h6>
					</div>
				</div>
			</td>
			{/*<td style={{...tableCell, width: '15%'}}>{moment(added).format("DD-MMM-YYYY")}</td>*/}
			<td
				style={{
					verticalAlign: "top",
					paddingTop: 12,
					width: 150,
          color: "#737373",
          fontWeight: 'normal',
          whiteSpace: "wrap"
				}}
			>
        <div>{address}</div>
			</td>
			{/*<td style={{verticalAlign: "top", paddingTop: 17, width: 150}}>*/}
			{/*	<button style={available}>AVAILABLE</button>*/}
			{/*</td>*/}
			<td style={{verticalAlign: "top", paddingTop: 5, width: 150}}>
				<div style={{textAlign: "right"}}>
					<b>{price}</b>
					{/* <Moment>{price}</Moment> */}
					<br />
					<br />
					{/* <br/> */}

					<div style={{display: "flex", marginTop: 30}}>
						{/* <FaRegEye/> */}
						{/* <div onClick={onView} style={{display:'flex', cursor:'pointer', color:'grey', margin:"auto"}}>
                            <div>
                                <img src={{view}} alt="view"/><h6 style={{margin:0}}> View</h6>
                            </div>
                            </div> */}

						{/*<div*/}
						{/*	onClick={() => onView(listing)}*/}
						{/*	style={{*/}
						{/*		display: "flex",*/}
						{/*		cursor: "pointer",*/}
						{/*		color: "grey",*/}
						{/*		margin: "auto",*/}
						{/*		width: 70*/}
						{/*	}}*/}
						{/*>*/}
						{/*	<FaRegEye color="black" style={{margin: "auto"}} />*/}
						{/*	<h6 style={{margin: 0, paddingLeft: 5}}> View</h6>*/}
						{/*</div>*/}

						{/* <div onClick={onEditProperty} style={{display:'flex', cursor:'pointer', color:'grey', margin:"auto"}}>
                            <FaPencilAlt color="black" style={{margin:'auto'}}/><h6 style={{margin:0, paddingLeft:5}}> Edit</h6>
                            </div> */}


						{/* <div onClick={onEditProperty} style={{display:'flex', cursor:'pointer', paddingRight:10, paddingLeft:10,color:'grey'}}>
                                <br/>
                            <FaEdit /><h6>Edit</h6>
                            </div>

                            <div onClick={onDelete} style={{display:'flex', cursor:'pointer', color:'grey'}}>
                                <br/>
                            <FaTimes /><h6>Delete</h6>
                            </div> */}
					</div>
				</div>
			</td>
		</tr>
	);
};

export default TableRow;
