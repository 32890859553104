/**
 * Project: spaces
 * File: Tooltip
 * Created by Pennycodes on 2/4/2022.
 * Copyright spaces
 */
import {Spaces} from "./Spaces";
import {DataUtil} from "../utils/DataUtils";
import {Tooltip as Tool} from "bootstrap";

class Tooltip {
    element: HTMLElement

    constructor(_element: HTMLElement) {

        this.element = _element

        DataUtil.set(_element,'tooltip', this)
    }

    // Static methods
    public static hasInstance(element: HTMLElement) {
        return DataUtil.has(element, 'tooltip')
    }

    public static getInstance(element: HTMLElement) {
        if (element !== null && Tooltip.hasInstance(element)) {
            return DataUtil.get(element, 'tooltip')
        }
    }
    // Create Instances
    public static createInstances(selector: string) {
        const elements = Spaces.selectAll(selector)
        const tooltipTriggerList = [].slice.call(elements);
         tooltipTriggerList.map(function (element) {
            const item = element as HTMLElement
            let tooltip = Tooltip.getInstance(item)
            if (!tooltip) {
                tooltip = new Tooltip(item)
                new Tool(element)
            }

        });

    }

    public static bootstrap(attr: string = '[data-bs-toggle="tooltip"]') {
        Tooltip.createInstances(attr)
    }

    public static reInitialization(attr: string = '[data-bs-toggle="tooltip"]') {
        Tooltip.createInstances(attr)
    }
}

export {Tooltip}
