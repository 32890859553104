import React, {useEffect, useRef, useState} from "react";
import {Constants} from "../../../../../utils/constants";
import axios from "axios";
import SERVICES from "../../../../../services";
import {Modal, Row, Spinner} from "react-bootstrap";
import {PageTitle} from "../../../../../layout/PageData";
import noAvatar from '../../../../../assets/img/png/no-avatar.png';
import {useHistory, useLocation} from "react-router-dom";
import closeButton from "../../../../../assets/img/svg/blue-close-button.svg";
import successImage from "../../../../../assets/img/svg/success-white-background.svg";
import warningImage from "../../../../../assets/img/svg/red-warning.svg";

const ProfileEdit = () => {
  const history = useHistory();

  const input = {
    backgroundColor: "#ECECEC",
    border: "1px solid #DDDDDD",
  };

//  const roles = [
//    {
//      value: 'ROLE_SUBSCRIBER_INDIVIDUAL',
//      label: 'Employee',
//      owner: 'ROLE_SUBSCRIBER_OWNER'
//    },
//    {
//      value: 'ROLE_SUBSCRIBER_APPROVER',
//      label: 'Approver',
//      owner: 'ROLE_SUBSCRIBER_OWNER'
//    },
//    {
//      value: 'ROLE_HOST_OPERATIONS',
//      label: 'Operations',
//      owner: 'ROLE_HOST'
//    }
//  ]

  const inputFile = useRef(null);

  const [user, setUser] = useState({});
  const [fullName, setFullName] = useState('');
  const [userRole, setUserRole] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [url, setUrl] = useState(noAvatar);
  const [loading, setLoading] = useState(false);
  const [currentUserRole, setCurrentUserRole] = useState('');

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const location = useLocation();

  const roles = (userRole === 'ROLE_SUBSCRIBER_OWNER' || userRole === 'ROLE_HOST') ?
  [
    {
    value: userRole,
    label: 'ACCOUNT OWNER',
    owner: userRole
    }
  ] :
  [
    {
    value: 'ROLE_EMPLOYEE',
    label: 'Employee',
    owner: 'ROLE_SUBSCRIBER_OWNER'
    },
    {
    value: 'ROLE_APPROVER_EMPLOYEE',
    label: 'Administrator',
    owner: 'ROLE_SUBSCRIBER_OWNER'
    },
    {
    value: 'ROLE_HOST_OPERATIONS',
    label: 'Operations',
    owner: 'ROLE_HOST'
    }
  ]

  useEffect(() => {
    const locationState = location.state;
    console.log('location state:', locationState);

    setUser(locationState);

    const currentUser = SERVICES.getUser();
    const role = currentUser?.role;

    setCurrentUserRole(role);
  }, []);

  useEffect(() => {
    setFullName(extractFullName(user));

    const email = user?.username;
    setEmail(email || '');

    const contacts = user?.contacts;
    // find the first mobile contact type
    let mobile = '';
    if (contacts != null && Array.isArray(contacts)) {
      const firstMobileContactType = contacts.find(contact => contact.type === 'mobile');
      mobile = firstMobileContactType ? firstMobileContactType.value : '';
    }

    setMobile(mobile);

    const avatar = user?.avatar;
    const updatedAvatar = avatar || noAvatar;
    setUrl(updatedAvatar);

    const role = user?.role;
    setUserRole(role);
  }, [user]);

  function extractFullName(user) {
    let fullName = '';

    const firstName = user?.firstName;
    const lastName = user?.lastName;

    if (firstName && lastName) {
      fullName = `${firstName} ${lastName}`;
    } else if (firstName) {
      fullName = firstName;
    } else if (lastName) {
      fullName = lastName;
    }

    return fullName;
  }

  const updateUser = () => {
    setLoading(true);

    const id = user ? user.id : 0;

    // make ajax request to update user
    const resourceUrl = `${Constants.BASE_URL}/users/api/v1/users/${id}`;

    const [firstName, ...restOfName] = fullName.split(' ');

    let lastName = undefined;
    if (restOfName && Array.isArray(restOfName)) {
      lastName = restOfName.join(" ");
    } else if (restOfName && typeof restOfName === 'string') {
      lastName = restOfName;
    }

    axios.put(resourceUrl, {
      avatar: url,
      role: userRole,
      contacts: [
        {
          isContactable: true,
          isPrimary: true,
          type: "mobile",
          value: mobile
        }
      ],
      firstName: (!firstName) ? null : firstName,
      lastName: (!lastName) ? null : lastName
    }).then(res => {
      const response = res.data;
      const [updatedUser, ...rest] = response ? response.data : [];

      console.log('updated user here:', updatedUser);

      // onSubmit(updatedUser);

      setLoading(false);

      setShowSuccessModal(true);

      const userProfileContainer = document.querySelector('.user-profile');
      if (userProfileContainer != null) {
        userProfileContainer.classList.add('blur-background');
      }
    }).catch(err => {
      setLoading(false);

      setShowErrorModal(true);
    })
  }

  const handleFileUpload = (event) => {
    event.stopPropagation();
    event.preventDefault();

    const file = event.target.files[0];
    console.log(file);

    if(window.FileReader){
      let reader = new FileReader();
      reader.onload = function(r){
        setUrl(r.target.result);
      }
      reader.readAsDataURL(file);
    }
    else {
      console.log(`Browser doesn't support file reader`);
    }
  }

  const handleStuffOnModalHide = (showModal) => {
    showModal(false);

    const userProfileContainer = document.querySelector('.user-profile');
    if (userProfileContainer != null) {
      userProfileContainer.classList.remove('blur-background');
    }
  }

  const moveToUsersPage = () => {
    history.push('/user-management');
  }

  return (
    <div className='mobile-view' style={{background: "#FCFCFC", color: '#333333'}}>
      <div className="section mt-2 p-3">
        <PageTitle>Users</PageTitle>
      </div>
      <div style={{ textAlign: "center", padding: 30, paddingTop: 10 }}>
        <section className={'text-left'}>
          <p className={'mb-3'} style={{fontSize: '1.5rem'}}>Edit User</p>
          <figure
            onClick={() => inputFile.current.click()}
            style={{
              width: '141px',
              height: '130px',
              background: '#F7F7F7',
              border: '2px solid #017BEF',
              borderRadius: '5px',
              display: (url) ? 'block': 'flex',
              justifyContent: (url) ? 'normal' : 'center',
              alignItems: (url) ? 'normal' : 'center'
          }}>
            <img
              src={url}
              alt="profile image"
              className={'img-fluid'}
              style={{width: (url) ? '100%' : '80%', height: (url) ? '100%' : '85%', objectFit: 'cover'}}
            />
          </figure>
          <input type="file" id='file' ref={inputFile} style={{display: 'none'}} accept={'image/*'} onChange={handleFileUpload}/>
        </section>

        <br />
        <div className="form-group">
          {/* <label for="">Email</label> */}
          <input
            style={input}
            type="name"
            className="form-control"
            name=""
            id=""
            aria-describedby="emailHelpId"
            placeholder="Full Name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
          <br />
          <input
            style={input}
            type="email"
            className="form-control"
            readOnly
            aria-describedby="emailHelpId"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {/* <label for="">Role</label> */}
          <br />
          <input
            style={input}
            type="text"
            className="form-control"
            aria-describedby="emailHelpId"
            placeholder="Phone Number"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
          <br />
          <Row style={{width: '60%'}}>
            <div className="form-group col-md-12">
              <select
                className="form-select"
                value={userRole} onChange={e => setUserRole(e.target.value)}
                style={{ background: 'rgb(236, 236, 236)', border: '1px solid rgb(221, 221, 221)'}}
              >
                <option selected defaultValue={'' || null || undefined} disabled>Select Role</option>
                {
                  roles.filter(role => role.owner === currentUserRole).map(role => {
                    return (
                      <option value={role.value}>{role.label}</option>
                    )
                  })
                }
              </select>
            </div>
          </Row>
        </div>
        <div className={'text-center'} style={{marginTop: '4rem'}}>
          <button
            className={'btn text-black text-capitalize'}
            onClick={moveToUsersPage}
            style={{cursor: 'pointer', marginRight: '25px', width: '110px', background: '#F7F7F7', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.4)', borderRadius: '15px'}}
          >
            cancel
          </button>
          <button
            className={'btn btn-primary text-white text-capitalize'}
            style={{cursor: 'pointer', width: '110px', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.4)', borderRadius: '15px'}}
            onClick={updateUser}
          >
            save
          </button>
        </div>
      </div>

      {/*Success Modal*/}
      <Modal show={showSuccessModal} onHide={() => handleStuffOnModalHide(setShowSuccessModal)} onEnter={e => console.log(e)}>
        <header className={'position-relative d-flex align-items-center mt-4'} style={{zIndex: '3'}}>
          <p className={'text-black text-center font-weight-bolder mb-0 text-capitalize'} style={{fontSize: '1.55rem', width: '100%'}}>Saved</p>
        </header>
        <Modal.Body style={{height: '280px', textAlign: 'center'}}>
          <div className={'mx-auto'} style={{width: '80%'}}>
            <p style={{fontSize: '1.1rem'}}>
              You have successfully saved your changes
            </p>
            <figure>
              <img src={successImage} alt="success image"/>
            </figure>
            <div className={'mt-5 text-center'}>
              <button
                className={'btn text-black text-uppercase'}
                style={{cursor: 'pointer', width: '120px', background: '#F7F7F7', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.4)', borderRadius: '15px'}}
                onClick={() => {
                  handleStuffOnModalHide(setShowSuccessModal);
                  moveToUsersPage();
                }
              }
              >
                ok
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/*Error Modal*/}
      <Modal show={showErrorModal} onHide={() => handleStuffOnModalHide(setShowErrorModal)} onEnter={e => console.log(e)}>
        <header className={'position-relative d-flex align-items-center'} style={{zIndex: '3'}}>
          <p className={'text-black text-center font-weight-bolder mb-0'} style={{fontSize: '1.55rem', width: '90%'}}>Sorry</p>
          <figure className="text-right mb-0" style={{padding: '15px'}}>
            <img onClick={() => handleStuffOnModalHide(setShowErrorModal)} src={closeButton} alt="close button" style={{width: '25px'}}/>
          </figure>
        </header>
        <Modal.Body style={{height: '280px', textAlign: 'center'}}>
          <div className={'mx-auto'} style={{width: '80%'}}>
            <p style={{fontSize: '1.1rem'}}>
              Whoa! something went wrong.
            </p>
            <p style={{fontSize: '1.1rem'}}>
              Kindly try again
            </p>
            <figure>
              <img src={warningImage} alt="warning image"/>
            </figure>
            <div className={'mt-4 text-center'}>
              <button
                className={'btn text-black'}
                style={{cursor: 'pointer', width: '165px', background: '#F7F7F7', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.4)', borderRadius: '15px'}}
                onClick={() => handleStuffOnModalHide(setShowErrorModal)}
              >
                Please try again
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ProfileEdit