/**
 * Project: spaces
 * File: Config
 * Created by Pennycodes on 2/4/2022.
 * Copyright spaces
 */

export default {
        //-------------------------------------------------------------------
        // PWA Settings
        PWA: {
            enable: true, // Enable or disable PWA
        },
        //-------------------------------------------------------------------
        // Dark Mode Settings
        Dark_Mode: {
            default: false, // Set dark mode as main theme
            local_mode: { // Activate dark mode between certain times of the day
                enable: false, // Enable or disable local dark mode
                start_time: 20, // Start at 20:00
                end_time: 7, // End at 07:00
            },
            auto_detect: { // Auto detect user's preferences and activate dark mode
                enable: false,
            }
        },
        //-------------------------------------------------------------------
        // Right to Left (RTL) Settings
        RTL: {
            enable: false, // Enable or disable RTL Mode
        },
        //-------------------------------------------------------------------
        // Animations
        Animation: {
            goBack: false, // Go back page animation
        },
        //-------------------------------------------------------------------
        // Test Mode
        Test: {
            enable: true, // Enable or disable test mode
            word: "testmode", // The word that needs to be typed to activate test mode
            alert: true, // Enable or disable alert when test mode is activated
            alertMessage: "Test mode activated. Look at the developer console!" // Alert message
        }
        //-------------------------------------------------------------------

}
