/**
 * Project: spaces
 * File: Quantity
 * Created by Pennycodes on 2/5/2022.
 * Copyright spaces
 */
import {Spaces} from "./Spaces";
import {getById} from "../utils/DOMHelpers";

class Quantity {
    increment: NodeListOf<Element>
    decrement: NodeListOf<Element>
    input: HTMLElement | null
    constructor() {
        this.increment = Spaces.selectAll('.quantity-plus')
        this.decrement = Spaces.selectAll('.quantity-minus')
        this.input = getById('quantity-input')

        this._init()
    }

    private _init() {
        const element = this.input

        this.increment.forEach(function (el) {
            el.addEventListener("click", function () {
                // @ts-ignore
                element.stepUp()
            })
        })
        this.decrement.forEach(function (el) {
            el.addEventListener("click", function () {
                // @ts-ignore
                element.stepDown()
            })
        })
    }

    public static bootstrap () {
        new Quantity()
    }

}

export {Quantity}
