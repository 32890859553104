import { FC, useEffect, useState, useMemo } from "react";
import {
  BundleType,
  BundleTypeAndQuantity,
  Package,
  UpdatedBundle,
} from "../../../data/package-select-data";
import pkgSelectGradient from "../../../assets/img/svg/pkg-select-gradient.svg";
import QuantityCounter from "../../bundles/quantity-counter/QuantityCounter";
import SERVICES from "../../../services";
import { useDispatch, useSelector } from "react-redux";
import { updateBundleQuantity } from "../../../redux/actions/dashboard";

interface Props {
  bundlePackage: UpdatedBundle;
  bundleTypeAndQuantities: any;
}

const PackageSelectCard: FC<Props> = ({
  bundlePackage,
  bundleTypeAndQuantities,
}) => {
  const [currency, setCurrency] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [cost, setCost] = useState(0);
  const [bundleType, setBundleType] = useState<BundleType>({} as BundleType);
  const organizationSize = useMemo(
    () => parseInt(localStorage.getItem("organizationSize") || "-1"),
    []
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const bundleType = bundlePackage.bundleType;
    const title = bundleType ? bundleType.label : "-";
    const description = bundlePackage.description;

    setTitle(title);
    setDescription(description);
    setBundleType(bundleType);

    const bundlePricing = bundlePackage.pricing;
    const currency = bundlePricing ? bundlePricing.currency : "-";
    const cost = bundlePricing ? bundlePricing.cost : 0;

    setCurrency(currency);
    setCost(cost);
  }, []);

  // useEffect(() => {
  //     // dispatch(updateBundleQuantity(
  //     //     {
  //     //         bundleType: bundleType.value,
  //     //         quantity
  //     //     }
  //     // ))
  //     setCost(prevState => prevState + 1);
  // }, [quantity]);

  return (
    <section className="pkg-select-card">
      <div className="d-flex">
        <figure>
          <img
            src={pkgSelectGradient}
            alt="package select gradient"
            style={{ width: "50px" }}
          />
        </figure>
        <div className={"heading"} style={{ marginLeft: "10px" }}>
          <p className={"desc"}>{bundlePackage.description}</p>
          <p className={"title"}>{title}</p>
        </div>
      </div>
      <div className={"mt-2"}>
        <p className={"cost"}>
          {currency} <span>{cost?.toLocaleString()}</span>
        </p>
      </div>
      <div className={'mt-3'}>
          <p style={{fontSize: '.789rem'}}>Number of Users: <b>{organizationSize}</b></p>
           {/* <QuantityCounter bundleType={bundleType} bundleTypeAndQuantities={bundleTypeAndQuantities}/> */}
      </div>
    </section>
  );
};

export default PackageSelectCard;
