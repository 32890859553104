import { createGlobalStyle } from "styled-components";
import { properties, colors } from "./index";

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    //font-family: 'DM Sans', sans-serif;
    //font-size: 14px;
  }
  html {
    min-height: 100vh;
  }
  body {
    max-width: 100vw;
  }
  body, h1, h2, h3, h4, h5, h6, p, figure {
    margin: 0;
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
  }
  h2 {
    font-size: 24px;
  }
  input, textarea, select, button {
    border: 1px solid transparent;
    border-radius: ${properties.borderRadius};
    outline: none;
    &:focus {
      outline: none;
    }
  }
  ol, ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  img {
    object-position: center;
    object-fit: contain;
    vertical-align: middle;
    user-select: none;
  }
  a {
    text-decoration: none;
    &:focus {
      outline: none;
    }
  }
  button {
    background-color: transparent;
    padding: 0;
    border: none;
  }

  .fused-text-field-button {
    display: flex;
    //justify-content: space-between;
    border: 1px solid ${colors.grey2};
    border-radius: 5px;
    //overflow: hidden;
    //&>* {
    //  height: 100%;
    //}
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border: none;
      padding: 15px 22.5px;
      background: white;
    }
    
    .button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    input, .button {
      //border: none;
      //border-radius: 0;
      //padding: 15px 22.5px;
    }
  }

  .button {
    background-color: ${colors.primary};
    color: ${colors.white};
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 15px 25px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    &.rounded {
      border-radius: 37px;
      padding: 15px 36px;
    }
    &.transparent-white {
      background-color: transparent;
      border-color: ${colors.white};
    }
    &.flex {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      justify-content: center;
    }
  }
  .button-row {
    display: flex;
    gap: 10px;
  }
`;

export default GlobalStyles;
