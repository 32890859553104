import React from 'react';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from "../layout/helpers";
import {
    LogoFacebook,
    LogoInstagram,
    LogoTwitter,
} from "react-ionicons";
import { User } from '../utils/interfaces';
import SERVICES from "../services";
// import { HashLink } from 'react-router-hash-link';



const Footer = () => {
    const isLoggedIn = (user: User) => {
        return user != null && Object.keys(user).length !== 0;
    };

    const redirectBasedOnRole = () => {
        const currentUser = SERVICES.getUser() as User;
        let destination = "/startBooking";

        if (!isLoggedIn(currentUser)) {
            destination = "/signin";
        } else {
            const role = currentUser.role;

            switch (role) {
                case 'ROLE_ADMINISTRATOR':
                    destination = "/dashboard";
                    break;

                case 'ROLE_SUBSCRIBER_OWNER':
                    destination = "/startBooking";
                    break;

                default:
                    destination = "/properties";
            }
        }

        return destination;
    };
    return (
        <footer className="pt-5 pb-5 px-4 d-flex flex-row px-lg-5 flex-wrap" style={{ backgroundColor: "#000000", paddingBottom: "100px" }}>
            <div className="d-flex flex-row flex-wrap pt-lg-5 justify-content-between w-75">
                <div className="d-flex flex-column align-items-start">
                    <span className="text-capitalize fontsize-sub-footer text-start fw-bold text-white my-2">get started</span>
                    <Link to={redirectBasedOnRole()} className="text-white fontsize-sub-footer text-start text-capitalize my-2">Find a space</Link>
                    <Link to="/host" className="text-white fontsize-sub-footer text-start mb-5 text-capitalize">Become A host</Link>
                </div>
                <div className="d-flex flex-column align-items-start">
                    <span className="text-capitalize fontsize-sub-footer text-start fw-bold text-white my-lg-2">Solutions</span>
                    <Link to="/corporate-page" className="text-white fontsize-sub-footer text-start mb-5 text-capitalize">enterprise</Link>
                </div>
                <div className="d-flex flex-column align-items-start">
                    <span className="text-capitalize fontsize-sub-footer text-start fw-bold text-white my-lg-2">community</span>
                    <a className="text-white fontsize-sub-footer text-start text-capitalize my-lg-2" href="https://blog.spacia.africa" target="_blank" rel="noopener noreferrer">blog</a>
                    <a href="/corporate-page#faqSection" className="text-white fontsize-sub-footer text-start text-capitalize my-lg-2">FAQ</a>
                </div>
                <div className="d-flex flex-column align-items-start">
                    <span className="text-capitalize fontsize-sub-footer text-start fw-bold text-white my-lg-2 ">Help & Support</span>
                    <span style={{ color: "white" }} className="text-white fontsize-sub-footer text-start mb-lg-2 text-capitalize "><a className=' text-white' href="mailto:sales@spacia.africa">contact us</a></span>
                    <span className="text-white fontsize-sub-footer text-start mb-lg-2 text-capitalize">terms & conditions</span>
                    <span className="text-white fontsize-sub-footer text-start mb-5 text-capitalize">privacy policy</span>
                </div>
            </div>
            <div className="imaged desktop-show mx-auto d-flex flex-row justify-content-center">
                <img decoding="async" className="mt-5 mx-auto" src={toAbsoluteUrl("/img/logo-black-alt.png")} alt="logo" style={{ width: "154px", height: "154px" }} />
            </div>
            <div className="d-flex flex-row mt-auto mb-3 w-100">
                <div role="presentation" style={{ marginRight: '100px' }}>
                    <a href="https://www.facebook.com/Spacia-Africa-185163956487562" target='_blank' rel='noreferrer noopener nofollow'>
                        <LogoFacebook cssClasses="me-3" color="#ffffff" />
                    </a>
                    <a href="https://twitter.com/SpaciaAfrica_" target='_blank' rel='noreferrer noopener nofollow'>
                        <LogoTwitter cssClasses="me-3" color="#ffffff" />
                    </a>
                    <a href="https://www.instagram.com/spaciaafrica/" target='_blank' rel='noreferrer noopener nofollow'>
                        <LogoInstagram cssClasses="me-3" color="#ffffff" />
                    </a>
                </div>
                <div className="d-flex justify-content-between" style={{ flexBasis: '61%' }}>
                    <p className="text-white" style={{ fontSize: '1.03rem', fontWeight: 'bolder' }}>
                        The Address 15th Floor, Muthangari Dr
                    </p>
                    <p className="text-white" style={{ fontSize: '1.03rem', fontWeight: 'bolder' }}>
                        Email: <a href="mailto:sales@spacia.africa">sales@spacia.africa</a>
                    </p>
                    <p className="text-white" style={{ fontSize: '1.03rem', fontWeight: 'bolder' }}>
                        Phone: <a href="tel:+254732188105">+254 732 188 105</a>
                    </p>
                </div>
            </div>
            <div className="d-flex flex-row border-1 dropdown-divider border-white w-100 mx-lg-4" />
            <span style={{ color: "#D1D5DB" }} className="w-75 text-center fontsize-sub-footer mx-auto pb-5 mt-2">
                &copy; 2022 SPACIA. All rights reserved
            </span>
        </footer>
    );
};


export default Footer;
