import React, {useEffect, useState} from "react";
import {Container, Table, Toast,} from "react-bootstrap";
import axios from "axios";
import SERVICES from '../../services';
import moment from 'moment-timezone';
import {Constants} from '../../utils/constants';
import {BillingStyles} from "./BillingStyles";
import {useHistory} from 'react-router-dom';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {TextField} from "@mui/material";

const HostBilling = () => {
	const [currency, setCurrency] = useState('');

	const [selectedYear, setSelectedYear] = useState(new Date());
	const [yearStartDate, setYearStartDate] = useState(getStartOfYear(new Date()));
	const [yearEndDate, setYearEndDate] = useState(getEndOfYear(new Date()));
	const [customerAccounts, setCustomerAccounts] = useState([]);
	const [revenueAccountTransactions, setRevenueAccountTransactions] = useState([]);
	const [corporateAccountTransactions, setCorporateAccountTransactions] = useState([]);
	const [combinedTransactions, setCombinedTransactions] = useState([]);
	const [yearlyTransactions, setYearlyTransactions] = useState([]);
	const [revenueAccountTransactionsRetrievalSuccessful, setRevenueAccountTransactionsRetrievalSuccessful] = useState(false);
	const [corporateAccountTransactionsRetrievalSuccessful, setCorporateAccountTransactionsRetrievalSuccessful] = useState(false);

	const history = useHistory();

	useEffect(() => {
		const currentUser = SERVICES.getUser();
		const userId = currentUser ? currentUser.id : 0;

		axios.get(`${Constants.BASE_URL}/users/v1/customer/accounts`, { params: {userId, customerId: userId} })
			.then(res => {
				const response = res.data;
				const data = response ? response.data : []
				console.log('after getting all customer accounts:\n', data);

				setCustomerAccounts(data);
			})
	}, []);

	// get transactions for revenue
	useEffect(() => {
		setYearlyTransactions([]);
		setRevenueAccountTransactionsRetrievalSuccessful(false);

		const currentUser = SERVICES.getUser();
		const userId = currentUser ? currentUser.id : 0;

		if (customerAccounts.length !== 0) {
			const revenueAccountTypes = customerAccounts.filter(customerAccount => customerAccount.type === 'revenueAcccount');
			const firstAccount = (revenueAccountTypes && revenueAccountTypes.length !== 0) ? revenueAccountTypes[0] : null;

			const revenueAccountId = firstAccount ? firstAccount.id : 0;
			console.log('revenue account id:', revenueAccountId);

			const config = {
				params: { userId, customerId: userId, startAt: yearStartDate, endAt: yearEndDate }
			};
			axios.get(`${Constants.BASE_URL}/users/v1/customer/account/${revenueAccountId}/transactions`, config)
				.then(res => {
					const response = res.data;
					const data = response ? response.data : []
					console.log('transaction response:', response);

					const updated = (data) ? data.map(transaction => ({
						...transaction,
						type: 'revenueAccount'
						})) : [];

					console.log('updated for revenue:', updated);

					setRevenueAccountTransactions(updated);

					setRevenueAccountTransactionsRetrievalSuccessful(true);
				}).catch(err => {
					setRevenueAccountTransactionsRetrievalSuccessful(false);
			})
		}
	}, [customerAccounts, selectedYear]);

	// get transactions for corporate account
	useEffect(() => {
		setYearlyTransactions([]);
		setCorporateAccountTransactionsRetrievalSuccessful(false);

		const currentUser = SERVICES.getUser();
		const userId = currentUser ? currentUser.id : 0;

		if (customerAccounts.length !== 0) {
			const corporateAccountTypes = customerAccounts.filter(customerAccount => customerAccount.type !== 'revenueAcccount');
			const firstAccount = (corporateAccountTypes && corporateAccountTypes.length !== 0) ? corporateAccountTypes[0] : null;

			const corporateAccountId = firstAccount ? firstAccount.id : 0;
			console.log('revenue account id:', corporateAccountId);

			const config = {
				params: { userId, customerId: userId, startAt: yearStartDate, endAt: yearEndDate }
			};
			axios.get(`${Constants.BASE_URL}/users/v1/customer/account/${corporateAccountId}/transactions`, config)
				.then(res => {
					const response = res.data;
					const data = response ? response.data : []
					console.log('transaction response:', response);

					const updated = (data) ? data.map(transaction => ({
						...transaction,
						type: 'corporateAccount'
					})) : [];

					setCorporateAccountTransactions(updated);
					setCorporateAccountTransactionsRetrievalSuccessful(true);
				}).catch(err => {
					setCorporateAccountTransactionsRetrievalSuccessful(false);
			})
		}
	}, [customerAccounts, selectedYear]);

	useEffect(() => {
		if (revenueAccountTransactionsRetrievalSuccessful && corporateAccountTransactionsRetrievalSuccessful) {
			setCombinedTransactions([...revenueAccountTransactions, ...corporateAccountTransactions]);
		}
	}, [revenueAccountTransactionsRetrievalSuccessful, corporateAccountTransactionsRetrievalSuccessful]);

	const getRevenue = (transactions, month) => {
		const transactionsForMonth = transactions
			.filter(transaction => transaction.type === 'revenueAccount')
			.filter(transaction => moment(transaction.createdOn).format('MMMM') === month);

		if (transactionsForMonth == null || transactionsForMonth.length === 0) {
			return 0;
		}

		return transactionsForMonth.map(transaction => transaction.amount).reduce((prev, current) => prev + current);
	}

	const getAmountPaid = (transactions, month) => {
		const transactionsForMonth = transactions
			.filter(transaction => transaction.type === 'corporateAccount')
			.filter(transaction => moment(transaction.createdOn).format('MMMM') === month);

		if (transactionsForMonth == null || transactionsForMonth.length === 0) {
			return 0;
		}

		return transactionsForMonth.map(transaction => transaction.amount).reduce((prev, current) => prev + current);
	}

	const buildYearlyTransactionsObject = (transactions) => {
		const currency = transactions.map(transaction => transaction.currency)[0];
		const createdOn = transactions.map(transaction => transaction.createdOn)[0];
		const year = moment(createdOn).format('YYYY');

		return [
			{
				month: 'January',
				revenue: getRevenue(transactions, 'January'),
				amountPaid: getAmountPaid(transactions, 'January'),
				currency,
				year
			},
			{
				month: 'February',
				revenue: getRevenue(transactions, 'February'),
				amountPaid: getAmountPaid(transactions, 'February'),
				currency,
				year
			},
			{
				month: 'March',
				revenue: getRevenue(transactions, 'March'),
				amountPaid: getAmountPaid(transactions, 'March'),
				currency,
				year
			},
			{
				month: 'April',
				revenue: getRevenue(transactions, 'April'),
				amountPaid: getAmountPaid(transactions, 'April'),
				currency,
				year
			},
			{
				month: 'May',
				revenue: getRevenue(transactions, 'May'),
				amountPaid: getAmountPaid(transactions, 'May'),
				currency,
				year
			},
			{
				month: 'June',
				revenue: getRevenue(transactions, 'June'),
				amountPaid: getAmountPaid(transactions, 'June'),
				currency,
				year
			},
			{
				month: 'July',
				revenue: getRevenue(transactions, 'July'),
				amountPaid: getAmountPaid(transactions, 'July'),
				currency,
				year
			},
			{
				month: 'August',
				revenue: getRevenue(transactions, 'August'),
				amountPaid: getAmountPaid(transactions, 'August'),
				currency,
				year
			},
			{
				month: 'September',
				revenue: getRevenue(transactions, 'September'),
				amountPaid: getAmountPaid(transactions, 'September'),
				currency,
				year
			},
			{
				month: 'October',
				revenue: getRevenue(transactions, 'October'),
				amountPaid: getAmountPaid(transactions, 'October'),
				currency,
				year
			},
			{
				month: 'November',
				revenue: getRevenue(transactions, 'November'),
				amountPaid: getAmountPaid(transactions, 'November'),
				currency,
				year
			},
			{
				month: 'December',
				revenue: getRevenue(transactions, 'December'),
				amountPaid: getAmountPaid(transactions, 'December'),
				currency,
				year
			}
		];
	}
	useEffect(() => {
		if (combinedTransactions !== null && combinedTransactions.length !== 0) {
			const yearlyTransactionsObject = buildYearlyTransactionsObject(combinedTransactions);

			console.log('yearly transactions object:', yearlyTransactionsObject);

			setYearlyTransactions(yearlyTransactionsObject);
		}
	}, [combinedTransactions]);

	useEffect(() => {
		setCurrency(SERVICES.getCurrency());
	}, []);

	function formatDateToString(date) {
		let dateStr = null;

		if (date) {
			console.log("about to format date: " + date + " to 'dd/MM/yyyy'");

			try {
				dateStr = moment(date).format("DD/MM/yyyy");
			} catch (e) {
				console.log("an error occurred while formatting date to 'dd/MM/yyyy' string");
			}
		}
		return dateStr;
	}

	function getStartOfYear(date) {
		const year = date.getFullYear(); // get date full year
		const month = date.getMonth(); // get month


		 // first day of January
		return formatDateToString(new Date(year, month, 1));
	}

	function getEndOfYear(date) {
		const year = date.getFullYear(); // get date full year
		const month = date.getMonth(); // get month

		return formatDateToString(new Date(year, (month + 12), 0)); // last day of December
	}

	const handleYearChange = (value) => {
		console.log('month / year value is:', value);

		const date = new Date(value);
		const year = date.getFullYear(); // get date full year
		const month = date.getMonth(); // get month

		const startOfYear = formatDateToString(new Date(year, month, 1)); // first day of January
		const endOfYear = formatDateToString(new Date(year, (month + 12), 0)); // last day of December

		console.log('start of year:', startOfYear);
		console.log('end of year:', endOfYear);

		setYearStartDate(startOfYear);
		setYearEndDate(endOfYear);

		setSelectedYear(value);
	}

	return (
		<div>
			<div>
				{/* Header */}
				<div className="header">
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
                            alignItems: 'center'
						}}
					>
						<div>
							<h4>
								<b>Billing</b>
							</h4>
						</div>

						<form
							className="form-inline my-2 my-xl-0"
							style={{ width: "30vw" }}
						>
						</form>
					</div>
				</div>
				{/* End Header */}

				<Container>
					<BillingStyles>
						<Toast
							style={{ width: "100%", padding: 20, marginBottom: 50, maxWidth: '100%' }}
						>
							<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
								<h6 style={{ fontWeight: "bold", paddingLeft: '0.75rem' }}>
									Transaction History
								</h6>
								<div className='year-picker-container'>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<DatePicker
											views={['year']}
											minDate={new Date('2020-01-01')}
											maxDate={new Date('2050-12-31')}
											value={selectedYear}
											onChange={(newValue) => {
												handleYearChange(newValue);
											}}
											renderInput={(params) => <TextField {...params} helperText={null} /> }
										/>
									</LocalizationProvider>
								</div>
							</div>
							<Table className='host-billing-table'>
								<thead>
								<tr className="tableUnderline">
									<th>Month</th>
									<th>Year</th>
									<th>Revenue Earned</th>
									<th>Amount Paid</th>
								</tr>
								</thead>
								<tbody>
								{
									yearlyTransactions.map(transaction => {
										return (
											<tr>
												<td>{transaction.month}</td>
												<td>{transaction.year}</td>
												<td>{transaction.revenue}</td>
												<td>{transaction.amountPaid}</td>
											</tr>
										)
									})
								}
								</tbody>
							</Table>
						</Toast>
					</BillingStyles>
				</Container>
			</div>
		</div>
	);
};

export default HostBilling;
