import React from "react";
import { Row } from "react-bootstrap";

const CardPaymentTab = () => {
	return (
		<>
			<Row>
				<div className="col">
					<h6>New Card</h6>
					<hr style={{ width: "30%", color: "#017BEF" }} />
				</div>
				<div className="col headerUnderline">
					<h6>Saved Card</h6>
				</div>
			</Row>
			<br />

			<div className="form-group">
				<label htmlFor="">Card Name</label>
				<input
					type="email"
					className="form-control"
					name=""
					id=""
					aria-describedby="emailHelpId"
					placeholder=""
				/>
				{/* <small id="emailHelpId" className="form-text text-muted">Help text</small> */}
			</div>
			<br />
			<div className="form-group">
				<label htmlFor="">Card Number</label>
				<input
					type="email"
					className="form-control"
					name=""
					id=""
					aria-describedby="emailHelpId"
					placeholder=""
				/>
				{/* <small id="emailHelpId" className="form-text text-muted">Help text</small> */}
			</div>
			<br />
			<Row>
				<div className="form-group col">
					<label htmlFor="">Expiry Date</label>
					<input
						type="email"
						className="form-control"
						name=""
						id=""
						aria-describedby="emailHelpId"
						placeholder=""
					/>
					{/* <small id="emailHelpId" className="form-text text-muted">Help text</small> */}
				</div>
				<div className="form-group col">
					<label htmlFor="">CVV</label>
					<input
						type="email"
						className="form-control"
						name=""
						id=""
						aria-describedby="emailHelpId"
						placeholder=""
					/>
					{/* <small id="emailHelpId" className="form-text text-muted">Help text</small> */}
				</div>
			</Row>
			<br />
			<button className="button">Save</button>
		</>
	);
};

export default CardPaymentTab;
