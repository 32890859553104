import React, {FC, useEffect, useState} from "react";
import {BundleMetricData, BundleType, Package, UpdatedBundle} from "../../../data/package-select-data";
import point from '../../../assets/img/svg/blue-point.svg';
import QuantityCounter from '../../../components/bundles/quantity-counter/QuantityCounter';
import SERVICES from '../../../services';

interface Props {
    bundleMetric: BundleMetricData;
    bundleTypeAndQuantities: any;
    selected?: boolean;
    onSelected: () => void;
}
const MembershipPlanCard: FC<Props> = ({bundleMetric, bundleTypeAndQuantities, selected = false, onSelected}) => {
    const [quantity, setQuantity] = useState(0);
    const [cost, setCost] = useState(0);
    const [bundleTitle, setBundleTitle] = useState('-');
    const [currency, setCurrency] = useState('');
    const [packagesAcquired, setPackagesAcquired] = useState(0);
    const [packagesAssigned, setPackagesAssigned] = useState(0);
    const [packagesRemaining, setPackagesRemaining] = useState(0);
    const [bundleType, setBundleType] = useState<BundleType>({} as BundleType);

    useEffect(() => {
        const currency = SERVICES.getCurrency();
        setCurrency(currency);

        const bundle = bundleMetric?.bundle;
        const pricing = bundle?.pricing;
        const cost = pricing ? pricing.cost : 0;
        setCost(cost);

        const bundleType = bundle?.bundleType;
        const bundleTitle = bundleType ? bundleType.label : '-';
        setBundleTitle(bundleTitle);

        const acquired = bundleMetric.totalPurchased;
        const assigned = bundleMetric.totalUsedUp;
        const remaining = bundleMetric.totalAvailable;

        setPackagesAcquired(acquired || 0);
        setPackagesAssigned(assigned || 0);
        setPackagesRemaining(remaining || 0);

        setBundleType(bundleType || {} as BundleType)
    }, [])

    return (
        <section className={`membership-plan-card${selected? " selected":" "}`} onClick={() => onSelected()}>
            <header className='pb-4'>
                <h3 className={'title'}>{bundleTitle}</h3>
                <p className={'cost pt-2'}>{currency} <span>{cost?.toLocaleString()}</span></p>
            </header>
            <section className={'package-details pb-4'}>
                <div className={'pb-2'}>
                    <figure className='d-inline-block'>
                        <img className={'blue-bullet'} src={point} alt="bullet"/>
                    </figure>
                    <p className='d-inline-block'>Packages Acquired - <span className={'text-primary'}>{packagesAcquired}</span></p>
                </div>

                <div className={'pb-2'}>
                    <figure className='d-inline-block'>
                        <img className={'blue-bullet'} src={point} alt="bullet"/>
                    </figure>
                    <p className='d-inline-block'>Packages Assigned - <span className={'text-primary'}>{packagesAssigned}</span></p>
                </div>

                <div className={'pb-2'}>
                    <figure className='d-inline-block'>
                        <img className={'blue-bullet'} src={point} alt="bullet"/>
                    </figure>
                    <p className='d-inline-block'>Packages Remaining - <span className={'text-primary'}>{packagesRemaining}</span></p>
                </div>
            </section>
            <section className={'d-flex no-of-users pb-5 justify-content-between'}>
                <p style={{textDecoration: 'underline', alignSelf: 'end'}}>Number of Users</p>
                <QuantityCounter bundleType={bundleType} bundleTypeAndQuantities={bundleTypeAndQuantities}/>
            </section>
            {/*<div className={'text-center'}>*/}
            {/*    <button className="btn btn-primary">Confirm</button>*/}
            {/*</div>*/}
        </section>
    )
}

export default MembershipPlanCard