// @ts-nocheck

/**
 * Project: spaces
 * File: SearchDetails
 * Created by Pennycodes on 2/5/2022.
 * Copyright spaces
 */

import React, {ChangeEvent, useEffect, useState} from 'react'
import {toAbsoluteUrl} from "../../layout/helpers";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import {BookmarkOutline, Location, LogOutOutline} from "react-ionicons";
import {Toast} from "../../layout/assets/js/Toast";
import {PageTitle, RightComponent} from "../../layout/PageData";
import {useLocation} from "react-router-dom";
import {Listing, PropertyImage} from "../../utils/interfaces";
import imgplaceholder from "../../assets/img/Web/Spacia/imgplaceholder.png";
import SERVICES from "../../services";
import {Constants} from "../../utils/constants";
import axios from "axios";
import {saveCartDetails, setHasItems} from "../../redux/actions/dashboard";
import {useDispatch, useSelector} from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';


const SearchDetails: React.FC = () => {
    // @ts-ignore
    const cartDetails = useSelector(state => state.dashboard.cartDetails);
    // @ts-ignore
    const selectedFilters = useSelector(state=> state.dashboard.selectedFilters); // all user selected filters

    const dispatch = useDispatch();
    const location = useLocation();

    const [listing, setListing] = useState({} as Listing);
    const [source, setSource] = useState('');
    const [mainImage, setMainImage] = useState(imgplaceholder);
    const [images, setImages] = useState([] as Array<PropertyImage>);
    const [propertyStatus, setPropertyStatus] = useState('');
    const [listingListedBy, setListingListedBy] = useState('');
    const [floorNumber, setFloorNumber] = useState('');
    const [capacity, setCapacity] = useState('');
    const [size, setSize] = useState('');
    const [address, setAddress] = useState('');
    const [description, setDescription] = useState('');
    const [amount, setAmount] = useState('');
    const [period, setPeriod] = useState('');
    const [userCurrency, setUserCurrency] = useState('');
    const [image, setImage] = useState(imgplaceholder);

    useEffect(() => {
        console.log('search details location:', location);
        const locationState = location?.state;
        const source = locationState?.source;
        const listing = locationState?.listing;

        setSource(source);
        setListing(listing);

        const currency = SERVICES.getCurrency();
        setUserCurrency(currency || '');
    }, []);

    useEffect(() => {
        if (listing != null && Object.keys(listing).length !== 0) {
            const media = listing.media;
            const images = media && media.images;
            const mainImage = (images && Array.isArray(images) && images.length > 0) ? images[0].resourceUrl : imgplaceholder

            // setMainImage(mainImage);
            setImage(mainImage);

            // set images in carousel
            setImages(images);

            const propertyStatus = listing.propertyStatus;
            const propertyStatusAsString = propertyStatus ? propertyStatus.label : '-';
            setPropertyStatus(propertyStatusAsString);

            const subCategory = listing.subCategory;
            const owner = listing.createdBy;

            const firstName = owner?.firstName;
            const lastName = owner?.lastName;

            let fullName = '-';
            if (firstName && lastName) {
                fullName = `${firstName} ${lastName}`;
            } else if (firstName && !lastName) {
                fullName = firstName;
            } else if (!firstName && lastName) {
                fullName = lastName;
            }

            const listingListedBy = `${subCategory} listed by ${fullName}`;
            setListingListedBy(listingListedBy);

            const listingDetails = listing.listingDetails;
            const floorNumber = listingDetails ? listingDetails.floorNumber : '';
            const capacity = listingDetails?.capacity;
            const capacityAsString = capacity ? `${capacity}` : '';

            const size = listingDetails?.propertySize;
            const sizeAsString = size ? `${size}` : '';

            setFloorNumber(floorNumber);
            setCapacity(capacityAsString);
            setSize(sizeAsString);

            const location = listing.location;
            const address = location ? location.address : '-';

            setAddress(address);

            const description = listing.description;
            setDescription(description);

            // get property price
            const propertyPrice = listing.propertyPrice;
            const price = propertyPrice && propertyPrice.price;
            const billingPeriod = propertyPrice && propertyPrice.billingPeriod;

            let period = '';
            if (price && billingPeriod) {
                switch (billingPeriod) {
                    case 'HOURLY':
                        period = 'hour';
                        break;

                    case 'WEEKLY':
                        period = 'week';
                        break;

                    case 'MONTHLY':
                        period = 'month';
                        break;

                    case 'DAILY':
                        period = 'day';
                        break;

                    default:
                        period = 'month';
                }

            }

            setAmount(`${price}` || '-');
            setPeriod(period);
        }
    }, [listing]);

    const changeImage = (e: any) => {
        setImage(e.target.src)
    }

    const addToCart = () => {
        const resourceUrl = `${Constants.BASE_URL}/booking/api/v1/order/add-to-cart`;

        const listingId = listing ? listing.id: 0;

        const propertyPrice = listing.propertyPrice;
        const amount = propertyPrice ? propertyPrice.price : 0;

        // calculate sub total
        const subTotal = amount * 1; // 1 because quantity is 1 for now

        const currentUser = SERVICES.getUser();

        const id = currentUser ? currentUser.id : 0;

        // find out if there is already an existing cart
        const cartId = (cartDetails) ? cartDetails['cartId'] : null;

        const from = selectedFilters.from || '';
        const to = selectedFilters.to || '';

        axios.post(resourceUrl,{
            endOn: to,
            itemPrice: amount,
            listingId,
            quantity: 1,
            startOn: from,
            subTotal,
            userId: id
        }, { params: {cartId}}).then((res) => {
            console.log(res.data.data[0]);

            if (res.status === 200) {
                toast.success('Item Successfully added',
                    { theme: 'colored' });

                console.log('done adding to cart. response:', res.data.data[0]);
                const cartDetails = res.data.data[0];
                dispatch(saveCartDetails(cartDetails));
                dispatch(setHasItems(cartDetails !== null));
            } else {
                console.warn('an error occurred while adding item to cart');
            }
        }).catch(err => {
            const statusCode = err.response.status;

            switch (statusCode) {
                case 400:
                    // the may be an issue with the booking start and end date
                    console.log('status code 400:', err.response);

                    let responseMsg = err.response.data.message;
                    responseMsg = responseMsg.slice(responseMsg.indexOf(':') + 2);

                    console.log(responseMsg);

                    break;
            }
        })
    }

    const handleShow = (e: any)=> {
        const toast = new Toast()
        toast.showToast('toast-16', 1000)
    }
    return (
        <div className='mobile-view' style={{background: "#FCFCFC"}}>
            <ToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                toastStyle={{ backgroundColor: "#00FF00" }}
                style={{minWidth: '350px'}}
            />
            <PageTitle>Details</PageTitle>
            {/*<RightComponent><BookmarkOutline/></RightComponent>*/}
            <div className="section mt-5 p-3">
                   <div className={'position-relative mx-3 h-200px rounded'} style={{background: `url(${image}) center center`}}>
                       <div className={'position-absolute bottom-0 end-0 mb-2 mx-3'}>
                           <button role={'button'} className={'btn btn-light square text-uppercase border-0'}><span className={'text-white'}>{propertyStatus}</span></button>
                       </div>
                   </div>
                        <div className="card-body">
                            <div className="on-mobile image-siblings-container">
                                {
                                    (images && images.length > 0) &&
                                    images.map(image => {
                                        const imageUrl = image.resourceUrl || imgplaceholder;

                                        return (
                                            <div key={uuid()}>
                                                <img src={imageUrl} onClick={changeImage} className="sibling-images img-fluid" alt="Image 1"/>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                <div className={'mt-2'}>
                    <h2>{listingListedBy}</h2>
                    <div className={'d-flex justify-content-between'}>
                        <p className={'text-dark'}>  <img src={toAbsoluteUrl('/img/stairs.png')}  alt="image" /> { `${floorNumber} floors` } </p>
                        <p  className={'text-dark'}> <img src={toAbsoluteUrl('/img/capacity.png')}  alt="image" /> { `${capacity} capacity` }</p>
                        <p  className={'text-dark'}> <img src={toAbsoluteUrl('/img/size.png')}  alt="image" /> { `${size} size` }</p>
                    </div>
                </div>

                <div className={'mt-3'}>
                    <h3>Where you'll be</h3>
                    <div className={'d-flex'}>
                        <Location
                            color={'#6E7070'}
                            title={''}
                            width={'16px'}
                            height={'16px'}
                        /> &nbsp;
                        <p className={'p-0 fontsize-sub'}>{address}</p>
                    </div>
                </div>
                <div className={'mt-3'}>
                    <h2>Description</h2>
                    <p className={'mt-2 text-dark'}>{description}</p>
                </div>
                <div className={'mt-2'}>
                    <div className={'d-flex justify-content-between align-items-center'}>
                        <p className={'text-dark pb-0 mb-0'}><span className={'fw-bolder'}>{userCurrency} {amount}</span> / {period}</p>
                        {
                            (source != null && source === 'properties-page') ? <div /> :
                                <button type={'button'} className={'btn btn-primary btn-lg'} onClick={addToCart}> Book </button>
                        }
                    </div>
                </div>
            </div>
            {/*<div className={'form-button-group transparent'}>*/}
            {/*    <button role={'button'} className={'btn btn-lg btn-block square fw-bolder'} style={{background: "#D4EDDA", color: "#155724"}}>*/}
            {/*        Item Successfully added</button>*/}
            {/*</div>*/}
        </div>
    )
}
export default SearchDetails
