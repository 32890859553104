import styled from 'styled-components';

export const UserManagementStyles = styled.div`
  caption {
    position: relative;
    margin-bottom: 70px;
  }
  
  caption.first-page {
    margin-bottom: 0 !important;
  }
    caption > ul {
      margin-top: 50px;
      position: absolute;
      right: 0
    }
`
