import React, { ChangeEvent } from "react";

import Styles from "./styles";


interface Props {
  label?: string;
  placeholder: string;
  type?: string;
  value: string | number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  autoComplete?: string;
  disabled?: boolean;
  validationMsg?: string;
}

const TextField: React.FC<Props> = ({
  label,
  placeholder,
  type = "text",
  value = "",
  onChange,
  autoComplete,
  disabled,
  validationMsg
}) => {
  return (
    <Styles className={`field text-field ${validationMsg ? "error" : ""}`}>
      {label &&
        <label style={{fontWeight: 'bolder', fontFamily: "'ProximaBold' sans-serif !important"}}>
          {label}
        </label>
      }
      <input
        type={type}
        value={value}
        onChange={onChange}
        autoComplete={autoComplete}
        disabled={disabled}
        placeholder={placeholder}
      />
      {validationMsg &&
        <div className="validation-error">{validationMsg}</div>
      }
    </Styles >
  )
}

export default TextField;
